import React from 'react';
/**
 * should import style from a scss file
 * should have its own separate scss file
 * name of the scss file should match the component file
 */
import './Sample.scss';
import useCounter from '../../hooks/useCounter/useCounter';
import Counter from '../counter/Counter';

/**
 * Each props interface should:
 *   start with a capital letter
 *   should start with name of the component (is this case Sample)
 *   should end with "Props"
 *   should be exported
 */
export interface SampleProps {
  name: string;
}

/**
 *  Each component should:
 *    be an arrow function
 *    should be exported as default at the bottom of the page
 *    the type should be React.FC<...>
 *    must have a first level className with {componentName}-comp format
 *      and all other classNames are children of that first level className
 *      (take a look at Sample.scss)
 */
const Sample: React.FC<SampleProps> = ({ name }) => {
  const { increment, decrement, value } = useCounter(1, 0);

  return (
    <div className="sample-comp">
      <p>{name}</p>
      <a href="/dashboard">click</a>
      <Counter value={value} />
      <div>
        <button type="button" className="blue-button" onClick={increment}>Increment</button>
        &nbsp;
        <button type="button" className="red-button" onClick={decrement}>Decrement</button>
      </div>
    </div>
  );
};

// Should be exported as default
export default Sample;
