import React from 'react';
import './App.css';
import { QueryClientProvider } from 'react-query';
import { graphQLClient } from './lib/gqlClient/gqlClient';
import LoginContainer, { LoginContainerProps } from './features/auth/container/LoginContainer/LoginContainer';
import { StorageKeyEnum, useSessionStorage } from './hooks';

const App: React.FC = () => {
  const [token, setToken] = useSessionStorage(StorageKeyEnum.TOKEN);

  const onLoginSuccess: LoginContainerProps['onSuccess'] = (v) => {
    setToken(v?.sessionToken);
  };

  return (
    <QueryClientProvider client={graphQLClient}>
      <div className="App">
        {!token && <LoginContainer onSuccess={onLoginSuccess} />}
      </div>
    </QueryClientProvider>
  );
};
export default App;
