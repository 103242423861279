import { missingRestfulImplementation } from '../../../errors/errors';
import { useLoginWithUsernameOrEmailMutation } from '../../../generates';
import { gqlHeaders, gqlUrl } from '../../../lib/gqlClient/gqlClient';

const useLoginGql = () => useLoginWithUsernameOrEmailMutation<Error>({
  endpoint: gqlUrl,
  fetchParams: {
    headers: gqlHeaders,
  },
});

// useLogin is an abstraction for changing the implementation
const useLogin = process.env.REACT_APP_USE_RESTFUL ? missingRestfulImplementation('useLogin') : useLoginGql;

export default useLogin;
