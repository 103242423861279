import { missingGqlServerApiKey, missingGqlServerUrl } from '../../errors/errors';

export default class EnvConfig {
  static get gqlServerUrl() {
    const url = process.env.REACT_APP_GQL_SERVER_URL;
    if (url === undefined) missingGqlServerUrl();
    return url as string;
  }

  static get gqlServerApiKey() {
    const apiKey = process.env.REACT_APP_GQL_API_KEY;
    if (apiKey === undefined) missingGqlServerApiKey();
    return apiKey as string;
  }
}
