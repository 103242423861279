import { useMutation, UseMutationOptions } from 'react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(endpoint: string, requestInit: RequestInit, query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch(endpoint, {
      method: 'POST',
      ...requestInit,
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  }
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BGDailyMeasureTimes: any;
  BGUnit: any;
  BGValue: any;
  BPPosture: any;
  BPUnit: any;
  BPValue: any;
  CalDate: any;
  ConnectionCursor: any;
  Date: any;
  EID: any;
  EmailAddress: any;
  Gender: any;
  HRUnit: any;
  HRValue: any;
  HSUnit: any;
  HSValue: any;
  HTUnit: any;
  HTValue: any;
  JSON: any;
  MongoID: any;
  PIUnit: any;
  PIValue: any;
  POUnit: any;
  POValue: any;
  Ref: any;
  RepeatFrequency: any;
  STEPUnit: any;
  STEPValue: any;
  TMUnit: any;
  TMValue: any;
  Time: any;
  Token: any;
};

export enum Account_Event_Enum {
  AutoLogOut = 'AUTO_LOG_OUT',
  Login = 'LOGIN',
  Logout = 'LOGOUT'
}

export type AssessmentResult = Result & {
  __typename?: 'ASSESSMENTResult';
  assessment?: Maybe<Assessment>;
  assessmentFilled?: Maybe<AssessmentFilled>;
  careStatus?: Maybe<CareStatus>;
  caredBy?: Maybe<User>;
  closedAt?: Maybe<Scalars['Date']>;
  comments?: Maybe<Array<Maybe<ResultCommentType>>>;
  completed?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Date']>;
  dismissed?: Maybe<Scalars['Boolean']>;
  enrolledProgram?: Maybe<EnrolledProgram>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['EID']>;
  late?: Maybe<Scalars['Boolean']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measure?: Maybe<Measure>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  notes?: Maybe<CursorPaginatedProviderNotes>;
  notified?: Maybe<Scalars['Boolean']>;
  refId?: Maybe<Scalars['EID']>;
  /** # 08/18/2017 COCONUT ## */
  repeat?: Maybe<Scalars['String']>;
  resultUpdates?: Maybe<Array<Maybe<ResultUpdateType>>>;
  reviewedAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<TaskSchedule>;
  severity?: Maybe<AlertSeverity>;
  taskStatus?: Maybe<TaskStatus>;
  threshold?: Maybe<Array<Maybe<AlertThreshold>>>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<TaskType>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
  violators?: Maybe<Array<Maybe<MeasureType>>>;
};


export type AssessmentResultEnrolledProgramArgs = {
  relatedTaskOnly?: InputMaybe<Scalars['Boolean']>;
};


export type AssessmentResultNotesArgs = {
  after?: InputMaybe<Scalars['Ref']>;
  before?: InputMaybe<Scalars['Ref']>;
  count?: InputMaybe<Scalars['Int']>;
};

export type AbortTimeOperatorsFilterFindManyTimeTrackerInput = {
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  gt?: InputMaybe<Scalars['Date']>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  gte?: InputMaybe<Scalars['Date']>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  lt?: InputMaybe<Scalars['Date']>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  lte?: InputMaybe<Scalars['Date']>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  ne?: InputMaybe<Scalars['Date']>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
};

export type AbortTimeOperatorsFilterTimeTrackerInput = {
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  gt?: InputMaybe<Scalars['Date']>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  gte?: InputMaybe<Scalars['Date']>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  lt?: InputMaybe<Scalars['Date']>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  lte?: InputMaybe<Scalars['Date']>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  ne?: InputMaybe<Scalars['Date']>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
};

export type AbortTimeOperatorsFilterUpdateManyTimeTrackerInput = {
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  gt?: InputMaybe<Scalars['Date']>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  gte?: InputMaybe<Scalars['Date']>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  lt?: InputMaybe<Scalars['Date']>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  lte?: InputMaybe<Scalars['Date']>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  ne?: InputMaybe<Scalars['Date']>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
};

export enum AccessTmpl {
  Group = 'GROUP',
  Leaf = 'LEAF'
}

export type AccountEventHistory = {
  __typename?: 'AccountEventHistory';
  createdAt?: Maybe<Scalars['Date']>;
  event?: Maybe<Account_Event_Enum>;
  id?: Maybe<Scalars['EID']>;
  member?: Maybe<User>;
  organization?: Maybe<Organization>;
  roleId?: Maybe<Scalars['EID']>;
};

export type AccountEventHistoryList = {
  __typename?: 'AccountEventHistoryList';
  data?: Maybe<Array<Maybe<AccountEventHistory>>>;
};

export type AccountEventHistoryListOpts = {
  event?: InputMaybe<Account_Event_Enum>;
  fromDate?: InputMaybe<Scalars['Date']>;
  memberId?: InputMaybe<Scalars['EID']>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type AccountRoleCapacity = {
  __typename?: 'AccountRoleCapacity';
  capacitys?: Maybe<Array<Maybe<RoleCapacity>>>;
  id?: Maybe<Scalars['EID']>;
};

export type ActiveUsersDetail = {
  __typename?: 'ActiveUsersDetail';
  measureCounts?: Maybe<MeasureCounts>;
  user?: Maybe<User>;
};

export type ActiveUsersTrend = {
  __typename?: 'ActiveUsersTrend';
  data?: Maybe<Array<Maybe<ActiveUsersTrendData>>>;
  groupBy?: Maybe<ActiveUsersTrendGroupBy>;
  nextDate?: Maybe<Scalars['Date']>;
  organization?: Maybe<Organization>;
  previousDate?: Maybe<Scalars['Date']>;
};

/**
 * Each data point is designed to have an average/minimum/maximum value for
 * a single or group of data.
 */
export type ActiveUsersTrendData = {
  __typename?: 'ActiveUsersTrendData';
  activeUsersCount?: Maybe<Scalars['Int']>;
  details?: Maybe<Array<Maybe<ActiveUsersDetail>>>;
  group?: Maybe<Scalars['String']>;
  noData?: Maybe<Scalars['Boolean']>;
};

export type ActiveUsersTrendFilters = {
  fromLastMeasure?: InputMaybe<Scalars['Boolean']>;
  groupBy?: InputMaybe<ActiveUsersTrendGroupBy>;
  organizationId: Scalars['EID'];
  timePeriod?: InputMaybe<ActiveUsersTrendLength>;
};

export enum ActiveUsersTrendGroupBy {
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export enum ActiveUsersTrendLength {
  BiAnnual = 'BI_ANNUAL',
  Month = 'MONTH',
  OneYear = 'ONE_YEAR',
  Quarter = 'QUARTER'
}

export enum ActivityLevel {
  Active = 'ACTIVE',
  Athletic = 'ATHLETIC',
  ExtremelyActive = 'EXTREMELY_ACTIVE',
  ExtremelyInactive = 'EXTREMELY_INACTIVE',
  IrregularlyExercising = 'IRREGULARLY_EXERCISING',
  ModeratelyActive = 'MODERATELY_ACTIVE',
  Sedentary = 'SEDENTARY',
  VeryActive = 'VERY_ACTIVE'
}

/**
 * ## Address for a user
 *  Address for a
 */
export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<CodedValue>;
  floor?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  type?: Maybe<AddressType>;
  unit?: Maybe<Scalars['String']>;
};

export enum AddressType {
  Main = 'MAIN',
  Other = 'OTHER'
}

/**
 * ## AdminProgram
 *  A program that _will_ be assigned to an individual user including its required tasks and
 *  devices that are needed. Only super-admins can query _orgnizationName_ and it is only
 *  available in a list query.
 */
export type AdminProgram = {
  __typename?: 'AdminProgram';
  createdAt?: Maybe<Scalars['Date']>;
  /** creator: User */
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  devices?: Maybe<Array<Maybe<ProgramDevice>>>;
  duration?: Maybe<Duration>;
  healthCondition?: Maybe<HealthCondition>;
  id?: Maybe<Scalars['EID']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['Date']>;
  modifiedBy?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  status?: Maybe<ProgramStatus>;
  tasks?: Maybe<Array<Maybe<Task>>>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type AdminProgramDevicesInput = {
  BG?: InputMaybe<BgDeviceModel>;
  BP?: InputMaybe<BpDeviceModel>;
  HR?: InputMaybe<HrDeviceModel>;
  HS?: InputMaybe<HsDeviceModel>;
  PO?: InputMaybe<PoDeviceModel>;
  TABLET?: InputMaybe<TabletDeviceModel>;
  TM?: InputMaybe<TmDeviceModel>;
};

/**
 * ## Program Summary
 *  Aggregated view representing the summary of each program (template) and corresponding enrolled-programs.
 */
export type AdminProgramWithSummary = {
  __typename?: 'AdminProgramWithSummary';
  adminProgram?: Maybe<AdminProgram>;
  alertSummary?: Maybe<AlertSummary>;
  memberSummary?: Maybe<MemberSummary>;
};

/**
 * ## Age groups type for enrolled patient statistics
 *  group1: less than 18, group2: 18-35, group3: 36-55, group4: 56-75, group5: 76 or greater
 */
export type AgeGroupsType = {
  __typename?: 'AgeGroupsType';
  group1?: Maybe<Scalars['Int']>;
  group2?: Maybe<Scalars['Int']>;
  group3?: Maybe<Scalars['Int']>;
  group4?: Maybe<Scalars['Int']>;
  group5?: Maybe<Scalars['Int']>;
};

export enum AlertFormula {
  AfterMeal = 'AFTER_MEAL',
  BeforeMeal = 'BEFORE_MEAL',
  BeforeOrAfterMeal = 'BEFORE_OR_AFTER_MEAL',
  BloodOxygen = 'BLOOD_OXYGEN',
  BodyTemperature = 'BODY_TEMPERATURE',
  Diastolic = 'DIASTOLIC',
  Exercise = 'EXERCISE',
  HeartRate = 'HEART_RATE',
  PerfusionIndex = 'PERFUSION_INDEX',
  Systolic = 'SYSTOLIC',
  SystolicOrDiastolic = 'SYSTOLIC_OR_DIASTOLIC',
  Weight = 'WEIGHT',
  WeightChange = 'WEIGHT_CHANGE',
  WeightChangeBaseline = 'WEIGHT_CHANGE_BASELINE'
}

export enum AlertSeverity {
  Critical = 'CRITICAL',
  Missed = 'MISSED',
  Normal = 'NORMAL',
  Risk = 'RISK'
}

/**
 * ## Outstanding Alerts
 *  Numbers for all outstanding alerts.
 */
export type AlertSummary = {
  __typename?: 'AlertSummary';
  critical?: Maybe<Scalars['Int']>;
  missed?: Maybe<Scalars['Int']>;
  risk?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

/**
 * ## Alert Threshold
 *  Threshold for alert and notification logic.
 */
export type AlertThreshold = {
  __typename?: 'AlertThreshold';
  baseline?: Maybe<Scalars['Float']>;
  baselineUpdatedAt?: Maybe<Scalars['Date']>;
  exerciseGoal?: Maybe<ExerciseGoal>;
  measure?: Maybe<MeasureType>;
  range?: Maybe<Array<Maybe<Scalars['Float']>>>;
  unit?: Maybe<Scalars['String']>;
};

/**
 * ## Alert Threshold Input
 *  Threshold input for alert and notification logic.
 */
export type AlertThresholdInput = {
  baseline?: InputMaybe<Scalars['Float']>;
  exerciseGoal?: InputMaybe<ExerciseGoalInput>;
  measure: MeasureType;
  range: Array<InputMaybe<Scalars['Float']>>;
  unit?: InputMaybe<Scalars['String']>;
};

export enum AlertTrigger {
  CriticalHigh = 'CRITICAL_HIGH',
  CriticalLow = 'CRITICAL_LOW',
  High = 'HIGH',
  Low = 'LOW',
  Normal = 'NORMAL'
}

export enum AllDeviceModels {
  Bg1 = 'BG1',
  Bg1S = 'BG1S',
  Bg5 = 'BG5',
  Bg5S = 'BG5S',
  Bp3L = 'BP3L',
  Bp5 = 'BP5',
  Hs2 = 'HS2',
  Hs2S = 'HS2S',
  Hs4S = 'HS4S',
  Hs6 = 'HS6',
  IpadMini = 'IPAD_MINI',
  Po3 = 'PO3',
  Tm3 = 'TM3',
  Ts28B = 'TS28B'
}

/**
 * ## App Settings
 *  Localizable app settings.
 */
export type AppSettings = {
  __typename?: 'AppSettings';
  group?: Maybe<Array<Maybe<Organization>>>;
  parent?: Maybe<Organization>;
  role?: Maybe<Array<Maybe<UserRole>>>;
};

export type AppUpgradeInfo = {
  __typename?: 'AppUpgradeInfo';
  appVersion?: Maybe<Scalars['String']>;
  msg?: Maybe<Scalars['String']>;
  upgradeFlag?: Maybe<Scalars['Boolean']>;
};

export enum AreasOfImprovementEnums {
  BloodGlucoseManagement = 'BLOOD_GLUCOSE_MANAGEMENT',
  BloodOxygenControl = 'BLOOD_OXYGEN_CONTROL',
  BloodPressureManagement = 'BLOOD_PRESSURE_MANAGEMENT',
  Others = 'OTHERS'
}

/** ## Assessment full data */
export type Assessment = {
  __typename?: 'Assessment';
  _id?: Maybe<Scalars['EID']>;
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  healthCondition?: Maybe<HealthCondition>;
  id?: Maybe<Scalars['EID']>;
  modifiedAt?: Maybe<Scalars['Date']>;
  modifiedBy?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  nameKey?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['EID']>;
  questions?: Maybe<Array<Maybe<AssessmentQuestion>>>;
  status?: Maybe<ProgramStatus>;
  type?: Maybe<AssessmentType>;
  updatedAt?: Maybe<Scalars['Date']>;
  version?: Maybe<Scalars['Int']>;
};

/** ## Question Answer */
export type AssessmentAnswer = {
  __typename?: 'AssessmentAnswer';
  /** type: AssessmentAnswerType */
  content?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
};

/** ## NOT USED */
export enum AssessmentAnswerType {
  Array = 'ARRAY',
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Number = 'NUMBER',
  String = 'STRING'
}

/**
 * ## Assessment Evaluation
 *  NOTE: it's just a placeholder field with no actually meaning yet
 */
export type AssessmentEvaluation = {
  __typename?: 'AssessmentEvaluation';
  date?: Maybe<Scalars['Date']>;
  score?: Maybe<Scalars['Int']>;
};

/** ## Assessment Filled */
export type AssessmentFilled = {
  __typename?: 'AssessmentFilled';
  _id?: Maybe<Scalars['EID']>;
  assessment?: Maybe<Assessment>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  healthCondition?: Maybe<HealthCondition>;
  id?: Maybe<Scalars['EID']>;
  name?: Maybe<Scalars['String']>;
  nameKey?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  questions?: Maybe<Array<Maybe<AssessmentQuestion>>>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<AssessmentType>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  version?: Maybe<Scalars['Int']>;
};

export type AssessmentFilledListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<AssessmentListFilledSortField>;
};

export enum AssessmentFilledSearchFieldKey {
  Name = 'NAME'
}

export enum AssessmentListFilledSortField {
  UpdatedAt = 'UPDATED_AT'
}

export type AssessmentListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<AssessmentListSortField>;
};

export enum AssessmentListSortField {
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT'
}

export type AssessmentOption = {
  __typename?: 'AssessmentOption';
  label?: Maybe<Scalars['String']>;
  meaning?: Maybe<Scalars['String']>;
};

export type AssessmentQuestion = {
  __typename?: 'AssessmentQuestion';
  _id?: Maybe<Scalars['EID']>;
  answer?: Maybe<AssessmentAnswer>;
  category?: Maybe<AssessmentQuestionCategory>;
  options?: Maybe<Array<Maybe<AssessmentOption>>>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<AssessmentQuestionType>;
};

export enum AssessmentQuestionCategory {
  ClosedEnded = 'CLOSED_ENDED',
  OpenEnded = 'OPEN_ENDED'
}

export enum AssessmentQuestionType {
  InputDate = 'INPUT_DATE',
  InputLong = 'INPUT_LONG',
  InputNumber = 'INPUT_NUMBER',
  InputShort = 'INPUT_SHORT',
  MultiSelect = 'MULTI_SELECT',
  Scaled = 'SCALED',
  SingleSelect = 'SINGLE_SELECT',
  TrueOrFalse = 'TRUE_OR_FALSE'
}

export enum AssessmentSearchFieldKey {
  Description = 'DESCRIPTION',
  Name = 'NAME'
}

export enum AssessmentType {
  Assessment = 'ASSESSMENT',
  Survey = 'SURVEY'
}

export type AssigneeInput = {
  assignedToCA?: InputMaybe<InputMiniProvider>;
  assignedToHC?: InputMaybe<InputMiniProvider>;
  assignedToRD?: InputMaybe<InputMiniProvider>;
};

export type AssigneeOption = {
  __typename?: 'AssigneeOption';
  assignedToCA?: Maybe<Array<Maybe<AssigneeOptionInfo>>>;
  assignedToRD?: Maybe<Array<Maybe<AssigneeOptionInfo>>>;
};

export type AssigneeOptionInfo = {
  __typename?: 'AssigneeOptionInfo';
  fullName?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['EID']>;
  occupancyRate?: Maybe<Scalars['Float']>;
  orgAssignedPatientCount?: Maybe<Scalars['Int']>;
  providerLanguage?: Maybe<Array<Maybe<LanguageCode>>>;
  selectedRole?: Maybe<Scalars['String']>;
};

export type Audit = {
  __typename?: 'Audit';
  action?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  docId?: Maybe<Scalars['EID']>;
  id?: Maybe<Scalars['EID']>;
  ip?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  resource?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  viewer?: Maybe<User>;
};

export type AuditInfo = {
  networkSpeed?: InputMaybe<Scalars['String']>;
  networkType?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
};

export type AuditListFilters = {
  action?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type AuditListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<AuditListSortField>;
};

export enum AuditListSortField {
  CreatedAt = 'CREATED_AT',
  Action = 'action'
}

export type AuditRecord = {
  __typename?: 'AuditRecord';
  _id?: Maybe<Scalars['MongoID']>;
  action?: Maybe<Scalars['String']>;
  apiKey?: Maybe<Scalars['String']>;
  appId?: Maybe<Scalars['String']>;
  args?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  docId?: Maybe<Scalars['MongoID']>;
  /** List of fields requested/accessed by viewer */
  fields?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  networkSpeed?: Maybe<Scalars['String']>;
  networkType?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  /** Organization ID of the logged-in user (viewer) */
  organizationId?: Maybe<Scalars['MongoID']>;
  phoneName?: Maybe<Scalars['String']>;
  /** Resource (collection) name */
  resource?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  sessionInfo?: Maybe<AuditRecordSessionInfo>;
  sessionToken?: Maybe<Scalars['String']>;
  status?: Maybe<EnumAuditRecordStatus>;
  success?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['MongoID']>;
  username?: Maybe<Scalars['String']>;
  viewerId?: Maybe<Scalars['MongoID']>;
};

export type AuditRecordSessionInfo = {
  __typename?: 'AuditRecordSessionInfo';
  _id?: Maybe<Scalars['MongoID']>;
  appScheme?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  phoneId?: Maybe<Scalars['String']>;
  phoneModel?: Maybe<Scalars['String']>;
  phoneName?: Maybe<Scalars['String']>;
  phoneOSVersion?: Maybe<Scalars['String']>;
  serverUrl?: Maybe<Scalars['String']>;
};

export type AuditRecordSessionInfoInput = {
  _id?: InputMaybe<Scalars['MongoID']>;
  appScheme?: InputMaybe<Scalars['String']>;
  appVersion?: InputMaybe<Scalars['String']>;
  phoneId?: InputMaybe<Scalars['String']>;
  phoneModel?: InputMaybe<Scalars['String']>;
  phoneName?: InputMaybe<Scalars['String']>;
  phoneOSVersion?: InputMaybe<Scalars['String']>;
  serverUrl?: InputMaybe<Scalars['String']>;
};

export type AuthorInfo = {
  __typename?: 'AuthorInfo';
  info?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type AuthorityInfo = {
  __typename?: 'AuthorityInfo';
  audioStatus?: Maybe<PhoneReportInfoPermissionStatus>;
  autoUpdateStatus?: Maybe<PhoneReportInfoPermissionStatus>;
  cameraStatus?: Maybe<PhoneReportInfoPermissionStatus>;
  coarseLocationStatus?: Maybe<PhoneReportInfoPermissionStatus>;
  contactsStatus?: Maybe<PhoneReportInfoPermissionStatus>;
  fineLocationStatus?: Maybe<PhoneReportInfoPermissionStatus>;
  locationAlwaysStatus?: Maybe<PhoneReportInfoPermissionStatus>;
  locationWhenInUseStatus?: Maybe<PhoneReportInfoPermissionStatus>;
  microphoneStatus?: Maybe<PhoneReportInfoPermissionStatus>;
  notificationStatus?: Maybe<PhoneReportInfoPermissionStatus>;
  openAppCountsAfterLogin?: Maybe<Scalars['Int']>;
  photoLibraryStatus?: Maybe<PhoneReportInfoPermissionStatus>;
  readContactsStatus?: Maybe<PhoneReportInfoPermissionStatus>;
  writeContactsStatus?: Maybe<PhoneReportInfoPermissionStatus>;
};

export type AutoCompletedAddress = {
  __typename?: 'AutoCompletedAddress';
  adr_address?: Maybe<Scalars['String']>;
  formatted_address?: Maybe<Scalars['String']>;
};

export enum AutoMsgCategoryEnum {
  All = 'ALL',
  Diabetes = 'DIABETES',
  Hypertension = 'HYPERTENSION'
}

/** ## AutoMsgConnfig type */
export type AutoMsgConfig = {
  __typename?: 'AutoMsgConfig';
  androidMinAppVersion?: Maybe<Scalars['String']>;
  category?: Maybe<AutoMsgCategoryEnum>;
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  excludeOrganizations?: Maybe<Array<Maybe<Scalars['String']>>>;
  fileKey?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  iosMinAppVersion?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<Language>>>;
  members?: Maybe<Array<Maybe<Scalars['String']>>>;
  messages?: Maybe<Array<Maybe<AutoMsgMessageType>>>;
  modifiedAt?: Maybe<Scalars['Date']>;
  modifiedBy?: Maybe<User>;
  organizations?: Maybe<Array<Maybe<Scalars['String']>>>;
  sendDateTime?: Maybe<Scalars['Date']>;
  sentAt?: Maybe<Scalars['Date']>;
  subjectId?: Maybe<Scalars['String']>;
};

/** # Auto Message Config list filters */
export type AutoMsgConfigListOpts = {
  all?: InputMaybe<Scalars['Boolean']>;
};

export type AutoMsgMessageInput = {
  language?: InputMaybe<Language>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type AutoMsgMessageType = {
  __typename?: 'AutoMsgMessageType';
  language?: Maybe<Language>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum AutoMsgTypeEnum {
  AppUpdate = 'APP_UPDATE',
  NewsArticle = 'NEWS_ARTICLE'
}

/** iHealth Blood Glucose device models. */
export enum BgDeviceModel {
  Bg1 = 'BG1',
  Bg1S = 'BG1S',
  Bg5 = 'BG5',
  Bg5S = 'BG5S'
}

/** List of meal types for blood glucose measurements. This does not represent or relate to the time of measurement in any way. */
export enum BgMealType {
  Bedtime = 'BEDTIME',
  Breakfast = 'BREAKFAST',
  Dinner = 'DINNER',
  Lunch = 'LUNCH',
  Other = 'OTHER',
  Overnight = 'OVERNIGHT',
  Snack = 'SNACK'
}

/** Blood Glucose measurement data. */
export type BgMeasure = Measure & {
  __typename?: 'BGMeasure';
  beforeMeal?: Maybe<Scalars['Boolean']>;
  blood_glucose?: Maybe<Scalars['BGValue']>;
  date?: Maybe<Scalars['Date']>;
  deviceAddress?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  /**
   * hadMedicine: Boolean added to support hadMedicine flag
   * hadActivity: Boolean added to support hadActivity flag
   * hadInsulin: Boolean added to support hadInsulin flag
   * tags:[String] added to support mood tags
   */
  id?: Maybe<Scalars['EID']>;
  imageUrl?: Maybe<Scalars['String']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  mealTimePassed?: Maybe<Scalars['Int']>;
  mealType?: Maybe<BgMealType>;
  measureBy?: Maybe<User>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  resultId?: Maybe<Scalars['EID']>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<Vital>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
};

/** Blood Glucose measurement data. */
export type BgMeasureAudit = MeasureAudit & {
  __typename?: 'BGMeasureAudit';
  beforeMeal?: Maybe<Scalars['Boolean']>;
  blood_glucose?: Maybe<Scalars['BGValue']>;
  date?: Maybe<Scalars['Date']>;
  deviceAddress?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  /**
   * hadMedicine: Boolean added to support hadMedicine flag
   * hadActivity: Boolean added to support hadActivity flag
   * hadInsulin: Boolean added to support hadInsulin flag
   * tags:[String] added to support mood tags
   */
  id?: Maybe<Scalars['EID']>;
  imageUrl?: Maybe<Scalars['String']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  mealTimePassed?: Maybe<Scalars['Int']>;
  mealType?: Maybe<BgMealType>;
  measureBy?: Maybe<User>;
  measurementSession?: Maybe<AuditRecord>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  networkSpeed?: Maybe<Scalars['String']>;
  networkType?: Maybe<Scalars['String']>;
  phoneName?: Maybe<Scalars['String']>;
  phoneSessionRecord?: Maybe<AuditRecord>;
  resultId?: Maybe<Scalars['EID']>;
  sessionId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<Vital>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
};

export type BgResult = Result & {
  __typename?: 'BGResult';
  assessmentFilled?: Maybe<AssessmentFilled>;
  beforeMeal?: Maybe<Scalars['Boolean']>;
  blood_glucose?: Maybe<Scalars['BGValue']>;
  careStatus?: Maybe<CareStatus>;
  caredBy?: Maybe<User>;
  closedAt?: Maybe<Scalars['Date']>;
  comments?: Maybe<Array<Maybe<ResultCommentType>>>;
  date?: Maybe<Scalars['Date']>;
  dismissed?: Maybe<Scalars['Boolean']>;
  enrolledProgram?: Maybe<EnrolledProgram>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['EID']>;
  late?: Maybe<Scalars['Boolean']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  mealTimePassed?: Maybe<Scalars['Int']>;
  mealType?: Maybe<BgMealType>;
  measure?: Maybe<Measure>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  notes?: Maybe<CursorPaginatedProviderNotes>;
  notified?: Maybe<Scalars['Boolean']>;
  refId?: Maybe<Scalars['EID']>;
  /** # 08/18/2017 COCONUT ## */
  repeat?: Maybe<Scalars['String']>;
  resultUpdates?: Maybe<Array<Maybe<ResultUpdateType>>>;
  reviewedAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<TaskSchedule>;
  severity?: Maybe<AlertSeverity>;
  taskStatus?: Maybe<TaskStatus>;
  threshold?: Maybe<Array<Maybe<AlertThreshold>>>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<TaskType>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
  violators?: Maybe<Array<Maybe<MeasureType>>>;
};


export type BgResultEnrolledProgramArgs = {
  relatedTaskOnly?: InputMaybe<Scalars['Boolean']>;
};


export type BgResultNotesArgs = {
  after?: InputMaybe<Scalars['Ref']>;
  before?: InputMaybe<Scalars['Ref']>;
  count?: InputMaybe<Scalars['Int']>;
};

export type BgStatType = {
  __typename?: 'BGStatType';
  afterMeal?: Maybe<VitalStatDetailType>;
  all?: Maybe<VitalStatDetailType>;
  bedtime?: Maybe<VitalStatDetailType>;
  bedtimeOvernightHypo?: Maybe<VitalStatDetailType>;
  beforeMeal?: Maybe<VitalStatDetailType>;
  criticalHigh?: Maybe<VitalStatDetailType>;
  criticalLow?: Maybe<VitalStatDetailType>;
  fasting?: Maybe<VitalStatDetailType>;
  overnight?: Maybe<VitalStatDetailType>;
};

export type BgTest = {
  __typename?: 'BGTest';
  freq?: Maybe<Scalars['Int']>;
  testRegularly?: Maybe<Scalars['Boolean']>;
  unit?: Maybe<Scalars['String']>;
};

/** iHealth Blood Pressure monitor device models. */
export enum BpDeviceModel {
  Bp3L = 'BP3L',
  Bp5 = 'BP5'
}

/** Blood Pressure & Heart Rate measurement data. */
export type BpMeasure = Measure & {
  __typename?: 'BPMeasure';
  arrhythmia?: Maybe<Scalars['Boolean']>;
  body_posture?: Maybe<Scalars['BPPosture']>;
  date?: Maybe<Scalars['Date']>;
  deviceAddress?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  diastolic_blood_pressure?: Maybe<Scalars['BPValue']>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  heart_rate?: Maybe<Scalars['HRValue']>;
  /**
   * hadMedicine: Boolean added to support hadMedicine flag
   * hadActivity: Boolean added to support hadActivity flag
   * hadInsulin: Boolean added to support hadInsulin flag
   * tags:[String] added to support mood tags
   */
  id?: Maybe<Scalars['EID']>;
  imageUrl?: Maybe<Scalars['String']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measureBy?: Maybe<User>;
  measureIndex?: Maybe<Scalars['Int']>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  resultId?: Maybe<Scalars['EID']>;
  systolic_blood_pressure?: Maybe<Scalars['BPValue']>;
  timezone?: Maybe<Scalars['String']>;
  triageInfo?: Maybe<Scalars['String']>;
  triageLevel?: Maybe<TriageLevel>;
  type?: Maybe<Vital>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
  wavelet?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

/** Blood Pressure & Heart Rate measurement data. */
export type BpMeasureAudit = MeasureAudit & {
  __typename?: 'BPMeasureAudit';
  arrhythmia?: Maybe<Scalars['Boolean']>;
  body_posture?: Maybe<Scalars['BPPosture']>;
  date?: Maybe<Scalars['Date']>;
  deviceAddress?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  diastolic_blood_pressure?: Maybe<Scalars['BPValue']>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  heart_rate?: Maybe<Scalars['HRValue']>;
  /**
   * hadMedicine: Boolean added to support hadMedicine flag
   * hadActivity: Boolean added to support hadActivity flag
   * hadInsulin: Boolean added to support hadInsulin flag
   * tags:[String] added to support mood tags
   */
  id?: Maybe<Scalars['EID']>;
  imageUrl?: Maybe<Scalars['String']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measureBy?: Maybe<User>;
  measureIndex?: Maybe<Scalars['Int']>;
  measurementSession?: Maybe<AuditRecord>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  networkSpeed?: Maybe<Scalars['String']>;
  networkType?: Maybe<Scalars['String']>;
  phoneName?: Maybe<Scalars['String']>;
  phoneSessionRecord?: Maybe<AuditRecord>;
  resultId?: Maybe<Scalars['EID']>;
  sessionId?: Maybe<Scalars['String']>;
  systolic_blood_pressure?: Maybe<Scalars['BPValue']>;
  timezone?: Maybe<Scalars['String']>;
  triageInfo?: Maybe<Scalars['String']>;
  triageLevel?: Maybe<TriageLevel>;
  type?: Maybe<Vital>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
  wavelet?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type BpResult = Result & {
  __typename?: 'BPResult';
  arrhythmia?: Maybe<Scalars['Boolean']>;
  assessmentFilled?: Maybe<AssessmentFilled>;
  body_posture?: Maybe<Scalars['BPPosture']>;
  careStatus?: Maybe<CareStatus>;
  caredBy?: Maybe<User>;
  closedAt?: Maybe<Scalars['Date']>;
  comments?: Maybe<Array<Maybe<ResultCommentType>>>;
  date?: Maybe<Scalars['Date']>;
  diastolic_blood_pressure?: Maybe<Scalars['BPValue']>;
  dismissed?: Maybe<Scalars['Boolean']>;
  enrolledProgram?: Maybe<EnrolledProgram>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  heart_rate?: Maybe<Scalars['HRValue']>;
  id?: Maybe<Scalars['EID']>;
  late?: Maybe<Scalars['Boolean']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measure?: Maybe<Measure>;
  measureIndex?: Maybe<Scalars['Int']>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  notes?: Maybe<CursorPaginatedProviderNotes>;
  notified?: Maybe<Scalars['Boolean']>;
  refId?: Maybe<Scalars['EID']>;
  /** # 08/18/2017 COCONUT ## */
  repeat?: Maybe<Scalars['String']>;
  resultUpdates?: Maybe<Array<Maybe<ResultUpdateType>>>;
  reviewedAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<TaskSchedule>;
  severity?: Maybe<AlertSeverity>;
  systolic_blood_pressure?: Maybe<Scalars['BPValue']>;
  taskStatus?: Maybe<TaskStatus>;
  threshold?: Maybe<Array<Maybe<AlertThreshold>>>;
  timezone?: Maybe<Scalars['String']>;
  triageInfo?: Maybe<Scalars['String']>;
  triageLevel?: Maybe<TriageLevel>;
  type?: Maybe<TaskType>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
  violators?: Maybe<Array<Maybe<MeasureType>>>;
  wavelet?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type BpResultEnrolledProgramArgs = {
  relatedTaskOnly?: InputMaybe<Scalars['Boolean']>;
};


export type BpResultNotesArgs = {
  after?: InputMaybe<Scalars['Ref']>;
  before?: InputMaybe<Scalars['Ref']>;
  count?: InputMaybe<Scalars['Int']>;
};

export type BpStatType = {
  __typename?: 'BPStatType';
  afternoon?: Maybe<VitalStatDetailType>;
  all?: Maybe<VitalStatDetailType>;
  arrhythmia?: Maybe<VitalStatDetailType>;
  criticalHigh?: Maybe<VitalStatDetailType>;
  elevated?: Maybe<VitalStatDetailType>;
  evening?: Maybe<VitalStatDetailType>;
  hypertension1?: Maybe<VitalStatDetailType>;
  hypertension2?: Maybe<VitalStatDetailType>;
  low?: Maybe<VitalStatDetailType>;
  morning?: Maybe<VitalStatDetailType>;
  normal?: Maybe<VitalStatDetailType>;
  overnight?: Maybe<VitalStatDetailType>;
  pulsePressure?: Maybe<VitalStatDetailType>;
};

export type BpTest = {
  __typename?: 'BPTest';
  freq?: Maybe<Scalars['Int']>;
  testRegularly?: Maybe<Scalars['Boolean']>;
  unit?: Maybe<Scalars['String']>;
};

export type BehavioralGoal = {
  __typename?: 'BehavioralGoal';
  category?: Maybe<Scalars['String']>;
  type?: Maybe<BehavioralGoalsType>;
  value?: Maybe<Scalars['String']>;
};

/** ## BehavioralGoalTemplate Type */
export type BehavioralGoalTemplate = {
  __typename?: 'BehavioralGoalTemplate';
  category?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  type?: Maybe<BehavioralGoalsType>;
  values: Array<Scalars['String']>;
};

export enum BehavioralGoalsType {
  BloodGlucoseManagement = 'BLOOD_GLUCOSE_MANAGEMENT',
  BloodPressureManagement = 'BLOOD_PRESSURE_MANAGEMENT',
  Cancer = 'CANCER',
  ChronicKidneyDisease = 'CHRONIC_KIDNEY_DISEASE',
  ChronicObstructivePulmonaryDisease = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
  CongestiveHeartFailure = 'CONGESTIVE_HEART_FAILURE',
  LipidManagement = 'LIPID_MANAGEMENT',
  Underweight = 'UNDERWEIGHT',
  WeightReduction = 'WEIGHT_REDUCTION',
  Wellness = 'WELLNESS'
}

export type BillingReport = {
  __typename?: 'BillingReport';
  birthday?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  insuranceProvider?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  monthlyTimes?: Maybe<Array<Maybe<MonthlyTime>>>;
};

export enum BillingStatusType {
  Billable = 'BILLABLE',
  NonBillable = 'NON_BILLABLE'
}

export type BloodPressureMeasurement = {
  __typename?: 'BloodPressureMeasurement';
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export enum CalendarLength {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK'
}

export enum CareStatus {
  Closed = 'CLOSED',
  Handled = 'HANDLED',
  New = 'NEW'
}

export type CategoryOperatorsFilterFindManyTimeTrackerInput = {
  /** Main eligible tracking event category */
  gt?: InputMaybe<EnumTimeTrackerCategory>;
  /** Main eligible tracking event category */
  gte?: InputMaybe<EnumTimeTrackerCategory>;
  /** Main eligible tracking event category */
  in?: InputMaybe<Array<InputMaybe<EnumTimeTrackerCategory>>>;
  /** Main eligible tracking event category */
  lt?: InputMaybe<EnumTimeTrackerCategory>;
  /** Main eligible tracking event category */
  lte?: InputMaybe<EnumTimeTrackerCategory>;
  /** Main eligible tracking event category */
  ne?: InputMaybe<EnumTimeTrackerCategory>;
  /** Main eligible tracking event category */
  nin?: InputMaybe<Array<InputMaybe<EnumTimeTrackerCategory>>>;
};

export type CategoryOperatorsFilterTimeTrackerInput = {
  /** Main eligible tracking event category */
  gt?: InputMaybe<EnumTimeTrackerCategory>;
  /** Main eligible tracking event category */
  gte?: InputMaybe<EnumTimeTrackerCategory>;
  /** Main eligible tracking event category */
  in?: InputMaybe<Array<InputMaybe<EnumTimeTrackerCategory>>>;
  /** Main eligible tracking event category */
  lt?: InputMaybe<EnumTimeTrackerCategory>;
  /** Main eligible tracking event category */
  lte?: InputMaybe<EnumTimeTrackerCategory>;
  /** Main eligible tracking event category */
  ne?: InputMaybe<EnumTimeTrackerCategory>;
  /** Main eligible tracking event category */
  nin?: InputMaybe<Array<InputMaybe<EnumTimeTrackerCategory>>>;
};

export type CategoryOperatorsFilterUpdateManyTimeTrackerInput = {
  /** Main eligible tracking event category */
  gt?: InputMaybe<EnumTimeTrackerCategory>;
  /** Main eligible tracking event category */
  gte?: InputMaybe<EnumTimeTrackerCategory>;
  /** Main eligible tracking event category */
  in?: InputMaybe<Array<InputMaybe<EnumTimeTrackerCategory>>>;
  /** Main eligible tracking event category */
  lt?: InputMaybe<EnumTimeTrackerCategory>;
  /** Main eligible tracking event category */
  lte?: InputMaybe<EnumTimeTrackerCategory>;
  /** Main eligible tracking event category */
  ne?: InputMaybe<EnumTimeTrackerCategory>;
  /** Main eligible tracking event category */
  nin?: InputMaybe<Array<InputMaybe<EnumTimeTrackerCategory>>>;
};

export enum ChallengeBadgeTypes {
  GlucoseDefender = 'GLUCOSE_DEFENDER',
  Healthy_100 = 'HEALTHY_100',
  HealthyStreak = 'HEALTHY_STREAK',
  HealthPacesetter = 'HEALTH_PACESETTER',
  HeartMonitor = 'HEART_MONITOR'
}

export enum ChallengePointsTypes {
  MeasurementMilestone_4 = 'MEASUREMENT_MILESTONE_4',
  MeasurementMilestone_8 = 'MEASUREMENT_MILESTONE_8',
  MeasurementMilestone_12 = 'MEASUREMENT_MILESTONE_12',
  VitalsMeasurement = 'VITALS_MEASUREMENT'
}

export type ChallengeRewardDetails = {
  __typename?: 'ChallengeRewardDetails';
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  date?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['EID']>;
  memberId?: Maybe<Scalars['EID']>;
  points?: Maybe<Scalars['Int']>;
  rewardDate?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export enum ChallengeRewardDetailsCategory {
  Badge = 'BADGE',
  Points = 'POINTS',
  RewardRedeem = 'REWARD_REDEEM'
}

export type Change = {
  __typename?: 'Change';
  assessments?: Maybe<Scalars['String']>;
  careCoordination?: Maybe<Scalars['String']>;
  followupSchedule?: Maybe<Scalars['String']>;
  goals?: Maybe<Scalars['String']>;
  histories?: Maybe<Array<Maybe<History>>>;
  interventions?: Maybe<Scalars['String']>;
  manualInputEnable?: Maybe<Scalars['Boolean']>;
  problemList?: Maybe<Scalars['String']>;
  resource?: Maybe<Scalars['String']>;
  tasks?: Maybe<Array<Maybe<Task>>>;
};

export type ChannelAckTimestamp = {
  __typename?: 'ChannelAckTimestamp';
  channelName?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['EID']>;
  timestamp?: Maybe<Scalars['Date']>;
};

export type ChannelAckTimestampInput = {
  channelName: Scalars['String'];
  teamId?: InputMaybe<Scalars['EID']>;
  timestamp: Scalars['Date'];
};

export type ChatChannelGroup = {
  __typename?: 'ChatChannelGroup';
  channels?: Maybe<Array<Maybe<ChatMemberChannel>>>;
  name?: Maybe<Scalars['String']>;
};

export type ChatChannelType = {
  __typename?: 'ChatChannelType';
  adminProgramId?: Maybe<Scalars['EID']>;
  channelGroup?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  newChannelGroup?: Maybe<Scalars['Boolean']>;
  teamId?: Maybe<Scalars['EID']>;
};

export type ChatMemberChannel = {
  __typename?: 'ChatMemberChannel';
  ackTimestamp?: Maybe<Scalars['Date']>;
  member?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
};

export type ChatTeamChannelGroup = {
  __typename?: 'ChatTeamChannelGroup';
  channelGroups?: Maybe<Array<Maybe<ChatChannelGroup>>>;
  teamId?: Maybe<Scalars['EID']>;
};

export type ChronicDisease = {
  __typename?: 'ChronicDisease';
  comments?: Maybe<Scalars['String']>;
  freq?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  otherTreatment?: Maybe<Scalars['String']>;
  seenDoctor?: Maybe<Scalars['Boolean']>;
  specialistInfo?: Maybe<SpecialistInfo>;
  takingMedication?: Maybe<Scalars['Boolean']>;
  testRegularly?: Maybe<Scalars['Boolean']>;
  treatments?: Maybe<Array<Maybe<Scalars['String']>>>;
  unit?: Maybe<Scalars['String']>;
  validationFlag?: Maybe<Scalars['Boolean']>;
  yearsWithCondition?: Maybe<Scalars['String']>;
};

export type ClaimedTimeLog = {
  __typename?: 'ClaimedTimeLog';
  endTime?: Maybe<Scalars['Date']>;
  events?: Maybe<Array<Maybe<ClaimedTimeLogEvent>>>;
  idleTime?: Maybe<Scalars['Int']>;
  memberId?: Maybe<Scalars['EID']>;
  monthOfYear?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['EID']>;
  serviceDate?: Maybe<Scalars['Date']>;
  startTime?: Maybe<Scalars['Date']>;
  totalTime: Scalars['Int'];
  viewerId?: Maybe<Scalars['EID']>;
};

export type ClaimedTimeLogEvent = {
  __typename?: 'ClaimedTimeLogEvent';
  name?: Maybe<Scalars['String']>;
};

export type ClaimedTimeLogEventInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type ClinicGoal = {
  __typename?: 'ClinicGoal';
  billable?: Maybe<Scalars['Boolean']>;
  condition?: Maybe<Scalars['String']>;
  conditionEnum?: Maybe<ConditionEnum>;
  goalAssessment?: Maybe<Array<Maybe<GoalAssessment>>>;
  goalEnum?: Maybe<ClinicGoalEnum>;
  meetingStatus?: Maybe<GoalMeetingStatusEnum>;
  statusUpdatedAt?: Maybe<Scalars['Date']>;
  statusUpdatedBy?: Maybe<User>;
  value?: Maybe<Scalars['String']>;
};

export enum ClinicGoalEnum {
  BloodPressureManagement_1 = 'BLOOD_PRESSURE_MANAGEMENT_1',
  BloodPressureManagement_2 = 'BLOOD_PRESSURE_MANAGEMENT_2',
  BloodPressureManagement_3 = 'BLOOD_PRESSURE_MANAGEMENT_3',
  BloodPressureManagement_4 = 'BLOOD_PRESSURE_MANAGEMENT_4',
  BloodPressureManagement_5 = 'BLOOD_PRESSURE_MANAGEMENT_5',
  Cancer_1 = 'CANCER_1',
  Cancer_2 = 'CANCER_2',
  Cancer_3 = 'CANCER_3',
  ChronicKidneyDisease_1 = 'CHRONIC_KIDNEY_DISEASE_1',
  ChronicKidneyDisease_2 = 'CHRONIC_KIDNEY_DISEASE_2',
  ChronicKidneyDisease_3 = 'CHRONIC_KIDNEY_DISEASE_3',
  ChronicKidneyDisease_4 = 'CHRONIC_KIDNEY_DISEASE_4',
  ChronicKidneyDisease_5 = 'CHRONIC_KIDNEY_DISEASE_5',
  ChronicKidneyDisease_6 = 'CHRONIC_KIDNEY_DISEASE_6',
  ChronicObstructivePulmonaryDisease_1 = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_1',
  ChronicObstructivePulmonaryDisease_2 = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_2',
  ChronicObstructivePulmonaryDisease_3 = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_3',
  ChronicObstructivePulmonaryDisease_4 = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_4',
  ChronicObstructivePulmonaryDisease_5 = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_5',
  CongestiveHeartFailure_1 = 'CONGESTIVE_HEART_FAILURE_1',
  CongestiveHeartFailure_2 = 'CONGESTIVE_HEART_FAILURE_2',
  CongestiveHeartFailure_3 = 'CONGESTIVE_HEART_FAILURE_3',
  CongestiveHeartFailure_4 = 'CONGESTIVE_HEART_FAILURE_4',
  CongestiveHeartFailure_5 = 'CONGESTIVE_HEART_FAILURE_5',
  CongestiveHeartFailure_6 = 'CONGESTIVE_HEART_FAILURE_6',
  CongestiveHeartFailure_7 = 'CONGESTIVE_HEART_FAILURE_7',
  CongestiveHeartFailure_8 = 'CONGESTIVE_HEART_FAILURE_8',
  CongestiveHeartFailure_9 = 'CONGESTIVE_HEART_FAILURE_9',
  CongestiveHeartFailure_10 = 'CONGESTIVE_HEART_FAILURE_10',
  CongestiveHeartFailure_11 = 'CONGESTIVE_HEART_FAILURE_11',
  CongestiveHeartFailure_12 = 'CONGESTIVE_HEART_FAILURE_12',
  CongestiveHeartFailure_13 = 'CONGESTIVE_HEART_FAILURE_13',
  CongestiveHeartFailure_14 = 'CONGESTIVE_HEART_FAILURE_14',
  CongestiveHeartFailure_15 = 'CONGESTIVE_HEART_FAILURE_15',
  CongestiveHeartFailure_16 = 'CONGESTIVE_HEART_FAILURE_16',
  CongestiveHeartFailure_17 = 'CONGESTIVE_HEART_FAILURE_17',
  CongestiveHeartFailure_18 = 'CONGESTIVE_HEART_FAILURE_18',
  CongestiveHeartFailure_19 = 'CONGESTIVE_HEART_FAILURE_19',
  CongestiveHeartFailure_20 = 'CONGESTIVE_HEART_FAILURE_20',
  Covid_1 = 'COVID_1',
  GlucoseManagement_1 = 'GLUCOSE_MANAGEMENT_1',
  GlucoseManagement_2 = 'GLUCOSE_MANAGEMENT_2',
  GlucoseManagement_3 = 'GLUCOSE_MANAGEMENT_3',
  GlucoseManagement_4 = 'GLUCOSE_MANAGEMENT_4',
  GlucoseManagement_5 = 'GLUCOSE_MANAGEMENT_5',
  GlucoseManagement_6 = 'GLUCOSE_MANAGEMENT_6',
  LipidManagement_1 = 'LIPID_MANAGEMENT_1',
  LipidManagement_2 = 'LIPID_MANAGEMENT_2',
  LipidManagement_3 = 'LIPID_MANAGEMENT_3',
  LipidManagement_4 = 'LIPID_MANAGEMENT_4',
  LipidManagement_5 = 'LIPID_MANAGEMENT_5',
  LipidManagement_6 = 'LIPID_MANAGEMENT_6',
  LipidManagement_7 = 'LIPID_MANAGEMENT_7',
  LipidManagement_8 = 'LIPID_MANAGEMENT_8',
  LipidManagement_9 = 'LIPID_MANAGEMENT_9',
  Underweight_1 = 'UNDERWEIGHT_1',
  Underweight_2 = 'UNDERWEIGHT_2',
  Underweight_3 = 'UNDERWEIGHT_3',
  WeightReduction_1 = 'WEIGHT_REDUCTION_1',
  WeightReduction_2 = 'WEIGHT_REDUCTION_2',
  WeightReduction_3 = 'WEIGHT_REDUCTION_3',
  WeightReduction_4 = 'WEIGHT_REDUCTION_4',
  WeightReduction_5 = 'WEIGHT_REDUCTION_5'
}

/** ## ClinicGoalTemplate Type */
export type ClinicGoalTemplate = {
  __typename?: 'ClinicGoalTemplate';
  chronicDisease?: Maybe<Scalars['String']>;
  codes?: Maybe<Array<Maybe<Scalars['String']>>>;
  condition?: Maybe<ConditionEnum>;
  conditionCheckbox?: Maybe<Scalars['String']>;
  goals?: Maybe<Array<Maybe<ClinicGoalEnum>>>;
  id?: Maybe<Scalars['EID']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  vitals?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CodedValue = {
  __typename?: 'CodedValue';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export enum ConditionEnum {
  BloodPressureManagement = 'BLOOD_PRESSURE_MANAGEMENT',
  Cancer = 'CANCER',
  ChronicKidneyDisease = 'CHRONIC_KIDNEY_DISEASE',
  ChronicObstructivePulmonaryDisease = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
  CongestiveHeartFailure = 'CONGESTIVE_HEART_FAILURE',
  Covid = 'COVID',
  GlucoseManagement = 'GLUCOSE_MANAGEMENT',
  LipidManagement = 'LIPID_MANAGEMENT',
  Underweight = 'UNDERWEIGHT',
  Unmapped = 'UNMAPPED',
  WeightReduction = 'WEIGHT_REDUCTION'
}

export type ConditionsListType = {
  __typename?: 'ConditionsListType';
  CKD?: Maybe<Scalars['Boolean']>;
  COPD?: Maybe<Scalars['Boolean']>;
  DM?: Maybe<Scalars['Boolean']>;
  ESRD_Dialysis?: Maybe<Scalars['Boolean']>;
  HLD?: Maybe<Scalars['Boolean']>;
  HTN?: Maybe<Scalars['Boolean']>;
  Obesity?: Maybe<Scalars['Boolean']>;
  PreDM?: Maybe<Scalars['Boolean']>;
};

export type ConnectedDeviceInfo = {
  __typename?: 'ConnectedDeviceInfo';
  deviceType?: Maybe<Scalars['String']>;
  macAddress?: Maybe<Scalars['String']>;
};

export type ConsentTemplate = {
  __typename?: 'ConsentTemplate';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  groupId?: Maybe<Scalars['EID']>;
  id?: Maybe<Scalars['EID']>;
  language?: Maybe<LanguageCode>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export enum CountryCode {
  Ab = 'AB',
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  An = 'AN',
  Ao = 'AO',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bu = 'BU',
  Bw = 'BW',
  Bz = 'BZ',
  Ca = 'CA',
  Cb = 'CB',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Ct = 'CT',
  Cu = 'CU',
  Cv = 'CV',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  Dg = 'DG',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Eg = 'EG',
  Eh = 'EH',
  En = 'EN',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fo = 'FO',
  Fq = 'FQ',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Gf = 'GF',
  Gh = 'GH',
  Gi = 'GI',
  Gk = 'GK',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Go = 'GO',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Gz = 'GZ',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hs = 'HS',
  Ht = 'HT',
  Hu = 'HU',
  Hv = 'HV',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Jt = 'JT',
  Ka = 'KA',
  Ke = 'KE',
  Kg = 'KG',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Ks = 'KS',
  Kv = 'KV',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Lh = 'LH',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mb = 'MB',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mi = 'MI',
  Mj = 'MJ',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pb = 'PB',
  Pc = 'PC',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Pz = 'PZ',
  Qa = 'QA',
  Re = 'RE',
  Rf = 'RF',
  Ro = 'RO',
  Rs = 'RS',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sf = 'SF',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  St = 'ST',
  Sv = 'SV',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Te = 'TE',
  Tg = 'TG',
  Th = 'TH',
  Ti = 'TI',
  Tk = 'TK',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tp = 'TP',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ug = 'UG',
  Un = 'UN',
  Ur = 'UR',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Wk = 'WK',
  Wm = 'WM',
  Xx = 'XX',
  Ym = 'YM',
  Yu = 'YU',
  Za = 'ZA',
  Zm = 'ZM',
  Zr = 'ZR',
  Zw = 'ZW'
}

export type CoveredMember = {
  __typename?: 'CoveredMember';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['EID']>;
  memberId?: Maybe<Scalars['EID']>;
  members?: Maybe<Array<Maybe<MiniMember>>>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type CoveredOrganization = {
  __typename?: 'CoveredOrganization';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['EID']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  memberId?: Maybe<Scalars['EID']>;
  organizations?: Maybe<Array<Maybe<MiniOrg>>>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type CreateOneAuditRecordInput = {
  action?: InputMaybe<Scalars['String']>;
  apiKey?: InputMaybe<Scalars['String']>;
  appId?: InputMaybe<Scalars['String']>;
  args?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  docId?: InputMaybe<Scalars['MongoID']>;
  /** List of fields requested/accessed by viewer */
  fields?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  ip?: InputMaybe<Scalars['String']>;
  networkSpeed?: InputMaybe<Scalars['String']>;
  networkType?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  /** Organization ID of the logged-in user (viewer) */
  organizationId?: InputMaybe<Scalars['MongoID']>;
  phoneName?: InputMaybe<Scalars['String']>;
  /** Resource (collection) name */
  resource?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
  sessionInfo?: InputMaybe<AuditRecordSessionInfoInput>;
  sessionToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<EnumAuditRecordStatus>;
  success?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  userId?: InputMaybe<Scalars['MongoID']>;
  username?: InputMaybe<Scalars['String']>;
  viewerId?: InputMaybe<Scalars['MongoID']>;
};

export type CreateOneAuditRecordPayload = {
  __typename?: 'CreateOneAuditRecordPayload';
  /** Created document */
  record?: Maybe<AuditRecord>;
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
};

export type CreateOneLabResultTemplatesInput = {
  createdAt?: InputMaybe<Scalars['Date']>;
  items?: InputMaybe<Array<InputMaybe<LabResultTemplatesLabResultTemplatesItemsInput>>>;
  templateId?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  userId?: InputMaybe<Scalars['MongoID']>;
  viewerId?: InputMaybe<Scalars['MongoID']>;
};

export type CreateOneLabResultTemplatesPayload = {
  __typename?: 'CreateOneLabResultTemplatesPayload';
  /** Created document */
  record?: Maybe<LabResultTemplates>;
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
};

export type CreateOneLabResultsInput = {
  createdAt?: InputMaybe<Scalars['Date']>;
  dateCollected?: InputMaybe<Scalars['Date']>;
  dateReceived?: InputMaybe<Scalars['Date']>;
  dateReported?: InputMaybe<Scalars['Date']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  labRef?: InputMaybe<Scalars['String']>;
  requisition?: InputMaybe<Scalars['String']>;
  results?: InputMaybe<Array<InputMaybe<LabResultsLabResultsResultsInput>>>;
  specimen?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  userId?: InputMaybe<Scalars['MongoID']>;
  viewerId?: InputMaybe<Scalars['MongoID']>;
};

export type CreateOneLabResultsPayload = {
  __typename?: 'CreateOneLabResultsPayload';
  /** Created document */
  record?: Maybe<LabResults>;
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
};

export type CreateOneTimeTrackerInput = {
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  abortTime?: InputMaybe<Scalars['Date']>;
  /** Main eligible tracking event action */
  action?: InputMaybe<EnumTimeTrackerAction>;
  /** Main eligible tracking event category */
  category?: InputMaybe<EnumTimeTrackerCategory>;
  /** Collection of the client-side events, to provide more details of the breakdown of accumulated time. */
  clientEvents?: InputMaybe<Array<InputMaybe<TimeTrackerTimeTrackerClientEventsInput>>>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  /** Interventions items. (May be >= 1, e.g. rateFoodLog + addFoodLogComment) */
  interventionDetails?: InputMaybe<Array<InputMaybe<TimeTrackerTimeTrackerInterventionDetailsInput>>>;
  /** Reference to other related time tracking events performed during the main event. */
  relatedEventIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** *Client* session ID to group events */
  sessionId?: InputMaybe<Scalars['String']>;
  /** Start time of the main eligible tracking event. */
  startTime?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  stopTime?: InputMaybe<Scalars['Date']>;
  techCall?: InputMaybe<Scalars['Boolean']>;
  /** DEPRECATED, use totalTime instead. unit: seconds */
  timeSpent?: InputMaybe<Scalars['Float']>;
  /** Client-side timeout configuration, included in totalTime if applicable */
  timeout?: InputMaybe<Scalars['Float']>;
  /** Total "eligible" time accumulated on the main event (unit: miliseconds). Can be different from the (stopTime - startTime) */
  totalTime?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED, use category+action instead */
  type?: InputMaybe<Scalars['String']>;
  /** User ID as target of the tracking, usually a member. */
  userId?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  userOrganizationId?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  viewerId?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  viewerOrganizationId?: InputMaybe<Scalars['MongoID']>;
  /** Client-side component/path details. */
  viewingDetails?: InputMaybe<TimeTrackerViewingDetailsInput>;
};

export type CreateOneTimeTrackerPayload = {
  __typename?: 'CreateOneTimeTrackerPayload';
  /** Created document */
  record?: Maybe<TimeTracker>;
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
};

export type CreateOneWeeklyBgScheduleTemplatesInput = {
  createdAt?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<WeeklyBgScheduleTemplatesScheduleInput>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  userId?: InputMaybe<Scalars['MongoID']>;
  viewerId?: InputMaybe<Scalars['MongoID']>;
};

export type CreateOneWeeklyBgScheduleTemplatesPayload = {
  __typename?: 'CreateOneWeeklyBGScheduleTemplatesPayload';
  /** Created document */
  record?: Maybe<WeeklyBgScheduleTemplates>;
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
};

export type Credential = {
  __typename?: 'Credential';
  /** For Agora */
  agoraAppId?: Maybe<Scalars['String']>;
  agoraChannelName?: Maybe<Scalars['String']>;
  agoraToken?: Maybe<Scalars['String']>;
  agoraUid?: Maybe<Scalars['String']>;
  apikey?: Maybe<Scalars['String']>;
  joinUrl?: Maybe<Scalars['String']>;
  /** For zoom */
  meetingNumber?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  sdkToken?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  videoType?: Maybe<VirtualMeetingVideoType>;
};

export type CurrentMonthTimeSpent = {
  __typename?: 'CurrentMonthTimeSpent';
  monthOfYear?: Maybe<Scalars['Int']>;
  totalTime?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type CursorInfo = {
  __typename?: 'CursorInfo';
  end?: Maybe<Scalars['String']>;
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrevious?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['String']>;
};

export type CursorInfo_Deprecated = {
  __typename?: 'CursorInfo_DEPRECATED';
  cursor?: Maybe<Scalars['Ref']>;
  hasMore?: Maybe<Scalars['Boolean']>;
  total?: Maybe<Scalars['Int']>;
};

export type CursorPaginatedProviderNotes = {
  __typename?: 'CursorPaginatedProviderNotes';
  cursorInfo?: Maybe<CursorInfo_Deprecated>;
  data?: Maybe<Array<Maybe<ProviderNote>>>;
};

export enum CustomizedFunctionName {
  OnCall = 'ON_CALL'
}

export type DailyMealSchedule = {
  __typename?: 'DailyMealSchedule';
  after_breakfast?: Maybe<Scalars['Boolean']>;
  after_dinner?: Maybe<Scalars['Boolean']>;
  after_lunch?: Maybe<Scalars['Boolean']>;
  bedtime?: Maybe<Scalars['Boolean']>;
  before_breakfast?: Maybe<Scalars['Boolean']>;
  before_dinner?: Maybe<Scalars['Boolean']>;
  before_lunch?: Maybe<Scalars['Boolean']>;
  overnight?: Maybe<Scalars['Boolean']>;
};

export type DateSetting = {
  __typename?: 'DateSetting';
  expireTime?: Maybe<Scalars['Date']>;
  startTime?: Maybe<Scalars['Date']>;
};

export enum DayOfWeek {
  Fri = 'FRI',
  Mon = 'MON',
  Sat = 'SAT',
  Sun = 'SUN',
  Thu = 'THU',
  Tue = 'TUE',
  Wed = 'WED'
}

/** Delete Reason key */
export enum DeleteReasonKey {
  Manualinput = 'MANUALINPUT'
}

export type Device = {
  __typename?: 'Device';
  address?: Maybe<Scalars['String']>;
  model?: Maybe<AllDeviceModels>;
  other?: Maybe<Scalars['String']>;
  status?: Maybe<DeviceStatus>;
  type?: Maybe<DeviceType>;
};

export type DeviceAddressInput = {
  address?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
  status: DeviceStatusInput;
};

/** ## DeviceError Type */
export type DeviceError = {
  __typename?: 'DeviceError';
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  deviceType?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  member?: Maybe<User>;
};

/** ## DeviceError Input */
export type DeviceErrorInput = {
  description?: InputMaybe<Scalars['String']>;
  deviceType?: InputMaybe<Scalars['String']>;
  errorCode?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['EID']>;
};

/** # IoT hub filter */
export type DeviceHubListOpts = {
  hubStatusFilter?: InputMaybe<HubStatusFilterEnum>;
};

export type DeviceHubListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<DeviceHubListSortField>;
};

export enum DeviceHubListSortField {
  CreatedAt = 'CREATED_AT'
}

/** ## DeviceHubType type */
export type DeviceHubType = {
  __typename?: 'DeviceHubType';
  createdAt?: Maybe<Scalars['Date']>;
  devices?: Maybe<Array<Maybe<IoTDevice>>>;
  history?: Maybe<Array<Maybe<IoTDeviceHistoryType>>>;
  id?: Maybe<Scalars['EID']>;
  lastHeartbeat?: Maybe<Scalars['Date']>;
  member?: Maybe<User>;
  serialNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type DeviceInput = {
  address?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<AllDeviceModels>;
  other?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DeviceStatus>;
  type: DeviceType;
};

export enum DeviceStatus {
  Damaged = 'DAMAGED',
  PatientOwned = 'PATIENT_OWNED',
  Returned = 'RETURNED',
  VendorLoan = 'VENDOR_LOAN'
}

export enum DeviceStatusEnum {
  Available = 'AVAILABLE',
  InUse = 'IN_USE',
  Unavailable = 'UNAVAILABLE'
}

export enum DeviceStatusFilterEnum {
  All = 'ALL',
  Available = 'AVAILABLE',
  InUse = 'IN_USE'
}

export enum DeviceStatusInput {
  PatientOwned = 'PATIENT_OWNED',
  VendorLoan = 'VENDOR_LOAN'
}

export enum DeviceType {
  Bg = 'BG',
  Bp = 'BP',
  Exercise = 'EXERCISE',
  Hr = 'HR',
  Hs = 'HS',
  Po = 'PO',
  Tablet = 'TABLET',
  Tm = 'TM'
}

export enum Diets {
  Atkins = 'ATKINS',
  BloodType = 'BLOOD_TYPE',
  CalorieRestriction = 'CALORIE_RESTRICTION',
  DietPills = 'DIET_PILLS',
  Exercise = 'EXERCISE',
  HighProtein = 'HIGH_PROTEIN',
  Keto = 'KETO',
  LowCarb = 'LOW_CARB',
  LowFat = 'LOW_FAT',
  LowSodium = 'LOW_SODIUM',
  LowSugar = 'LOW_SUGAR',
  None = 'NONE',
  SouthBeach = 'SOUTH_BEACH',
  WeightWatchers = 'WEIGHT_WATCHERS',
  Whole_30 = 'WHOLE_30'
}

export type DischargedDetail = {
  __typename?: 'DischargedDetail';
  description?: Maybe<Scalars['String']>;
  dischargedAt?: Maybe<Scalars['Date']>;
  dischargedBy?: Maybe<User>;
  key?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type DischargedDetailInput = {
  description?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
};

export type DiscountCode = {
  __typename?: 'DiscountCode';
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type DiscountInfo = {
  __typename?: 'DiscountInfo';
  discount?: Maybe<Scalars['Int']>;
  productExclude?: Maybe<Scalars['String']>;
  productInclude?: Maybe<Scalars['String']>;
  storeLink?: Maybe<Scalars['String']>;
};

export type DocIdOperatorsFilterFindManyAuditRecordInput = {
  gt?: InputMaybe<Scalars['MongoID']>;
  gte?: InputMaybe<Scalars['MongoID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  lt?: InputMaybe<Scalars['MongoID']>;
  lte?: InputMaybe<Scalars['MongoID']>;
  ne?: InputMaybe<Scalars['MongoID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

/** ## DoctorReferral Type */
export type DoctorReferral = {
  __typename?: 'DoctorReferral';
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  member?: Maybe<User>;
  primaryDoctor?: Maybe<User>;
};

export type Dummy = {
  __typename?: 'Dummy';
  BGMeasure?: Maybe<BgMeasureAudit>;
  BPMeasure?: Maybe<BpMeasureAudit>;
  ECGMeasure?: Maybe<EcgMeasureAudit>;
  EXERCISEMeasure?: Maybe<ExerciseMeasureAudit>;
  HRMeasure?: Maybe<HrMeasureAudit>;
  HSMeasure?: Maybe<HsMeasureAudit>;
  POMeasure?: Maybe<PoMeasureAudit>;
  TMMeasure?: Maybe<TmMeasureAudit>;
};

export type Duration = {
  __typename?: 'Duration';
  calendar?: Maybe<CalendarLength>;
  length?: Maybe<Scalars['Int']>;
};

export type DurationInput = {
  calendar: CalendarLength;
  length: Scalars['Int'];
};

/** iHealth ECG device models. */
export enum EcgDeviceModel {
  Ecg3 = 'ECG3'
}

/** ECG measurement data. */
export type EcgMeasure = Measure & {
  __typename?: 'ECGMeasure';
  abnormalPointsNumber?: Maybe<Scalars['Int']>;
  averageHeartRate?: Maybe<Scalars['HRValue']>;
  date?: Maybe<Scalars['Date']>;
  detailsReferenceKey?: Maybe<Scalars['String']>;
  deviceAddress?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['Date']>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  /**
   * hadMedicine: Boolean added to support hadMedicine flag
   * hadActivity: Boolean added to support hadActivity flag
   * hadInsulin: Boolean added to support hadInsulin flag
   * tags:[String] added to support mood tags
   */
  id?: Maybe<Scalars['EID']>;
  imageUrl?: Maybe<Scalars['String']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  maxHeartRate?: Maybe<Scalars['HRValue']>;
  measureBy?: Maybe<User>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  resultId?: Maybe<Scalars['EID']>;
  startTime?: Maybe<Scalars['Date']>;
  timezone?: Maybe<Scalars['String']>;
  totalHeartBeats?: Maybe<Scalars['Int']>;
  type?: Maybe<Vital>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
};

/** ECG measurement data. */
export type EcgMeasureAudit = MeasureAudit & {
  __typename?: 'ECGMeasureAudit';
  abnormalPointsNumber?: Maybe<Scalars['Int']>;
  averageHeartRate?: Maybe<Scalars['HRValue']>;
  date?: Maybe<Scalars['Date']>;
  detailsReferenceKey?: Maybe<Scalars['String']>;
  deviceAddress?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['Date']>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  /**
   * hadMedicine: Boolean added to support hadMedicine flag
   * hadActivity: Boolean added to support hadActivity flag
   * hadInsulin: Boolean added to support hadInsulin flag
   * tags:[String] added to support mood tags
   */
  id?: Maybe<Scalars['EID']>;
  imageUrl?: Maybe<Scalars['String']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  maxHeartRate?: Maybe<Scalars['HRValue']>;
  measureBy?: Maybe<User>;
  measurementSession?: Maybe<AuditRecord>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  networkSpeed?: Maybe<Scalars['String']>;
  networkType?: Maybe<Scalars['String']>;
  phoneName?: Maybe<Scalars['String']>;
  phoneSessionRecord?: Maybe<AuditRecord>;
  resultId?: Maybe<Scalars['EID']>;
  sessionId?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Date']>;
  timezone?: Maybe<Scalars['String']>;
  totalHeartBeats?: Maybe<Scalars['Int']>;
  type?: Maybe<Vital>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
};

export type EmrList = {
  __typename?: 'EMRList';
  CCM?: Maybe<Scalars['Boolean']>;
  ChartA1C?: Maybe<Scalars['Boolean']>;
  EMR_Charting?: Maybe<Scalars['Boolean']>;
  pinnedToEMR?: Maybe<Scalars['Boolean']>;
};

/** ## EPChangeLog type */
export type EpChangeLog = {
  __typename?: 'EPChangeLog';
  change?: Maybe<Change>;
  createdAt?: Maybe<Scalars['Date']>;
  enrolledProgramId?: Maybe<Scalars['EID']>;
  id?: Maybe<Scalars['EID']>;
  modifiedBy?: Maybe<Scalars['EID']>;
  modifiedByUser?: Maybe<User>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type EpChangeLogListOpts = {
  enrolledProgramId?: InputMaybe<Scalars['EID']>;
};

export type EpChangeLogListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<EpChangeLogListSortField>;
};

export enum EpChangeLogListSortField {
  UpdatedAt = 'UPDATED_AT'
}

export type ExerciseMeasure = Measure & {
  __typename?: 'EXERCISEMeasure';
  date?: Maybe<Scalars['Date']>;
  details?: Maybe<Array<Maybe<StepDetail>>>;
  deviceAddress?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  /**
   * hadMedicine: Boolean added to support hadMedicine flag
   * hadActivity: Boolean added to support hadActivity flag
   * hadInsulin: Boolean added to support hadInsulin flag
   * tags:[String] added to support mood tags
   */
  id?: Maybe<Scalars['EID']>;
  imageUrl?: Maybe<Scalars['String']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measureBy?: Maybe<User>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  resultId?: Maybe<Scalars['EID']>;
  steps?: Maybe<Scalars['STEPValue']>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<Vital>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
};

export type ExerciseMeasureAudit = MeasureAudit & {
  __typename?: 'EXERCISEMeasureAudit';
  date?: Maybe<Scalars['Date']>;
  details?: Maybe<StepDetail>;
  deviceAddress?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  /**
   * hadMedicine: Boolean added to support hadMedicine flag
   * hadActivity: Boolean added to support hadActivity flag
   * hadInsulin: Boolean added to support hadInsulin flag
   * tags:[String] added to support mood tags
   */
  id?: Maybe<Scalars['EID']>;
  imageUrl?: Maybe<Scalars['String']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measureBy?: Maybe<User>;
  measurementSession?: Maybe<AuditRecord>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  networkSpeed?: Maybe<Scalars['String']>;
  networkType?: Maybe<Scalars['String']>;
  phoneName?: Maybe<Scalars['String']>;
  phoneSessionRecord?: Maybe<AuditRecord>;
  resultId?: Maybe<Scalars['EID']>;
  sessionId?: Maybe<Scalars['String']>;
  steps?: Maybe<Scalars['STEPValue']>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<Vital>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
};

export type ExerciseResult = Result & {
  __typename?: 'EXERCISEResult';
  assessmentFilled?: Maybe<AssessmentFilled>;
  careStatus?: Maybe<CareStatus>;
  caredBy?: Maybe<User>;
  closedAt?: Maybe<Scalars['Date']>;
  comments?: Maybe<Array<Maybe<ResultCommentType>>>;
  date?: Maybe<Scalars['Date']>;
  details?: Maybe<Array<Maybe<StepDetail>>>;
  dismissed?: Maybe<Scalars['Boolean']>;
  enrolledProgram?: Maybe<EnrolledProgram>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['EID']>;
  late?: Maybe<Scalars['Boolean']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measure?: Maybe<Measure>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  notes?: Maybe<CursorPaginatedProviderNotes>;
  notified?: Maybe<Scalars['Boolean']>;
  refId?: Maybe<Scalars['EID']>;
  /** # 08/18/2017 COCONUT ## */
  repeat?: Maybe<Scalars['String']>;
  resultUpdates?: Maybe<Array<Maybe<ResultUpdateType>>>;
  reviewedAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<TaskSchedule>;
  severity?: Maybe<AlertSeverity>;
  steps?: Maybe<Scalars['STEPValue']>;
  taskStatus?: Maybe<TaskStatus>;
  threshold?: Maybe<Array<Maybe<AlertThreshold>>>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<TaskType>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
  violators?: Maybe<Array<Maybe<MeasureType>>>;
};


export type ExerciseResultEnrolledProgramArgs = {
  relatedTaskOnly?: InputMaybe<Scalars['Boolean']>;
};


export type ExerciseResultNotesArgs = {
  after?: InputMaybe<Scalars['Ref']>;
  before?: InputMaybe<Scalars['Ref']>;
  count?: InputMaybe<Scalars['Int']>;
};

export type Education = {
  __typename?: 'Education';
  appReview?: Maybe<Scalars['Boolean']>;
  discussion?: Maybe<Scalars['Boolean']>;
  selfReflection?: Maybe<Scalars['Boolean']>;
};

export enum EducationLevel {
  College = 'COLLEGE',
  HighSchool = 'HIGH_SCHOOL',
  None = 'NONE',
  SixthGrade = 'SIXTH_GRADE',
  ThirdGrade = 'THIRD_GRADE'
}

export type EducationTips = {
  __typename?: 'EducationTips';
  category?: Maybe<EducationTipsCategory>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  helpful?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['EID']>;
  level?: Maybe<EducationTipsLevel>;
  marked?: Maybe<Scalars['Boolean']>;
  memberId?: Maybe<Scalars['EID']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export enum EducationTipsCategory {
  Diet = 'DIET',
  Exercise = 'EXERCISE',
  GeneralizedAwareness = 'GENERALIZED_AWARENESS',
  LifeStyle = 'LIFE_STYLE',
  Medications = 'MEDICATIONS'
}

export enum EducationTipsLevel {
  High = 'HIGH',
  Low = 'LOW',
  Normal = 'NORMAL'
}

export type EducationTipsTemplate = {
  __typename?: 'EducationTipsTemplate';
  category?: Maybe<EducationTipsCategory>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['EID']>;
  language?: Maybe<LanguageCode>;
  level?: Maybe<EducationTipsLevel>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type Email = {
  __typename?: 'Email';
  address?: Maybe<Scalars['EmailAddress']>;
  primary?: Maybe<Scalars['Boolean']>;
  verified?: Maybe<Scalars['Boolean']>;
};

/** Profile for hospital-level roles such as nurses, doctors and admins. */
export type EmployeeProfile = UserProfile & {
  __typename?: 'EmployeeProfile';
  NPI?: Maybe<Scalars['String']>;
  assignedPatientCount?: Maybe<Scalars['Int']>;
  avatar?: Maybe<ImageType>;
  department?: Maybe<Scalars['String']>;
  directAddress?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  excludeBilling?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullNameWithNickname?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Gender']>;
  hasCredential?: Maybe<Scalars['Boolean']>;
  healthCoach?: Maybe<User>;
  lastName?: Maybe<Scalars['String']>;
  mentor?: Maybe<User>;
  nickname?: Maybe<Scalars['String']>;
  orgAssignedPatientCount?: Maybe<Scalars['Int']>;
  providerLanguage?: Maybe<Array<Maybe<LanguageCode>>>;
  specialty?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  title?: Maybe<UserTitle>;
};

/** You can not use both the _programId_ and _memberId_ parameters at the same time. */
export type EnrolledListOpts = {
  adminProgramId?: InputMaybe<Scalars['EID']>;
  healthCondition?: InputMaybe<Array<InputMaybe<HealthCondition>>>;
  managedBy?: InputMaybe<Scalars['EID']>;
  memberId?: InputMaybe<Scalars['EID']>;
  organizationId?: InputMaybe<Scalars['EID']>;
  suspended?: InputMaybe<Scalars['Boolean']>;
};

export type EnrolledListSort = {
  direction?: InputMaybe<SortDirection>;
  field: EnrolledListSortField;
};

export enum EnrolledListSortField {
  EndDate = 'END_DATE',
  Name = 'NAME',
  StartDate = 'START_DATE'
}

export enum EnrolledOperationStatus {
  DeviceInit = 'DEVICE_INIT',
  Discharged = 'DISCHARGED',
  Enrolled = 'ENROLLED',
  Incompleted = 'INCOMPLETED',
  Init = 'INIT',
  Returned = 'RETURNED',
  Suspended = 'SUSPENDED'
}

/** ## Enrolled patient statistics return type */
export type EnrolledPatientStatistics = {
  __typename?: 'EnrolledPatientStatistics';
  ageGroups?: Maybe<AgeGroupsType>;
  female?: Maybe<Scalars['Int']>;
  male?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

/**
 * ## User-enrolled program
 *  A user-enrolled program with editable tasks/schedules/thresholds. Not all information is editable.
 *  The _token_ field is only available if the enrolled program is elligible to start and hasn't started yet.
 */
export type EnrolledProgram = {
  __typename?: 'EnrolledProgram';
  adminProgram?: Maybe<AdminProgram>;
  assessments?: Maybe<Scalars['String']>;
  assessmentsFlag?: Maybe<Scalars['String']>;
  behavioralGoals?: Maybe<Array<Maybe<BehavioralGoal>>>;
  careCoordination?: Maybe<Scalars['String']>;
  clinicGoals?: Maybe<Array<Maybe<ClinicGoal>>>;
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  deviceInputEnabledTaskTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  devices?: Maybe<Array<Maybe<Device>>>;
  dischargedDetail?: Maybe<DischargedDetail>;
  duration?: Maybe<Duration>;
  endDate?: Maybe<Scalars['Date']>;
  extraNote?: Maybe<Scalars['String']>;
  followupSchedule?: Maybe<Scalars['String']>;
  /** @deprecated Old field, never been used in Unified Care */
  forms?: Maybe<Array<Maybe<EnrolledProgramForm>>>;
  goals?: Maybe<Scalars['String']>;
  goalsFlag?: Maybe<Scalars['String']>;
  healthCondition?: Maybe<HealthCondition>;
  id?: Maybe<Scalars['EID']>;
  interventions?: Maybe<Scalars['String']>;
  lastReviewedAt?: Maybe<Scalars['Date']>;
  /** @deprecated Replace by team */
  manager?: Maybe<Array<Maybe<User>>>;
  manualInputEnable?: Maybe<Scalars['Boolean']>;
  manualInputEnabledTaskTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  oneTimeTasks?: Maybe<Array<Maybe<Task>>>;
  organization?: Maybe<Organization>;
  problemList?: Maybe<Scalars['String']>;
  qrcode?: Maybe<Scalars['Token']>;
  resource?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<EnrolledProgramStatus>;
  tasks?: Maybe<Array<Maybe<Task>>>;
  team?: Maybe<Array<Maybe<Team>>>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  user?: Maybe<User>;
  vendor?: Maybe<User>;
};


/**
 * ## User-enrolled program
 *  A user-enrolled program with editable tasks/schedules/thresholds. Not all information is editable.
 *  The _token_ field is only available if the enrolled program is elligible to start and hasn't started yet.
 */
export type EnrolledProgramOneTimeTasksArgs = {
  type?: InputMaybe<Array<InputMaybe<TaskType>>>;
};


/**
 * ## User-enrolled program
 *  A user-enrolled program with editable tasks/schedules/thresholds. Not all information is editable.
 *  The _token_ field is only available if the enrolled program is elligible to start and hasn't started yet.
 */
export type EnrolledProgramTasksArgs = {
  type?: InputMaybe<Array<InputMaybe<TaskType>>>;
};

export type EnrolledProgramForm = {
  __typename?: 'EnrolledProgramForm';
  date?: Maybe<Scalars['Date']>;
  type?: Maybe<FormName>;
  uri?: Maybe<Scalars['String']>;
};

export type EnrolledProgramFormInput = {
  type: FormName;
  uri: Scalars['String'];
};

export enum EnrolledProgramStatus {
  Created = 'CREATED',
  Discharged = 'DISCHARGED',
  Pending = 'PENDING',
  Ready = 'READY',
  Replacing = 'REPLACING',
  Returned = 'RETURNED',
  Started = 'STARTED',
  Suspended = 'SUSPENDED'
}

export type EnrolledUserStatus = {
  __typename?: 'EnrolledUserStatus';
  activeUsers?: Maybe<Array<Maybe<StatusUser>>>;
  inactiveUsers?: Maybe<Array<Maybe<StatusUser>>>;
};

export enum EnumAuditRecordStatus {
  Failed = 'FAILED',
  PartiallySucceeded = 'PARTIALLY_SUCCEEDED',
  Succeeded = 'SUCCEEDED'
}

/** Main eligible tracking event action */
export enum EnumTimeTrackerAction {
  Engage = 'ENGAGE',
  Enter = 'ENTER',
  Review = 'REVIEW',
  Update = 'UPDATE'
}

/** Main eligible tracking event category */
export enum EnumTimeTrackerCategory {
  Additionalinfo = 'ADDITIONALINFO',
  Alert = 'ALERT',
  CallLog = 'CALL_LOG',
  Conversation = 'CONVERSATION',
  EnrolledProgram = 'ENROLLED_PROGRAM',
  FoodLog = 'FOOD_LOG',
  LabResults = 'LAB_RESULTS',
  Logbook = 'LOGBOOK',
  Medicine = 'MEDICINE',
  PatientDetail = 'PATIENT_DETAIL',
  PatientProfile = 'PATIENT_PROFILE',
  Postit = 'POSTIT',
  ProviderNotes = 'PROVIDER_NOTES',
  TagPatient = 'TAG_PATIENT',
  TaskAssignment = 'TASK_ASSIGNMENT',
  Visit = 'VISIT',
  Vitals = 'VITALS'
}

/** Client (timer) action in respond to event */
export enum EnumTimeTrackerClientEventsAction {
  Abort = 'ABORT',
  PauseTimer = 'PAUSE_TIMER',
  ResumeTimer = 'RESUME_TIMER',
  StartTimer = 'START_TIMER',
  StopTimer = 'STOP_TIMER'
}

export type Evaluation = {
  __typename?: 'Evaluation';
  comments?: Maybe<Scalars['String']>;
  freq?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  otherTreatment?: Maybe<Scalars['String']>;
  seenDoctorInLastSixMonth?: Maybe<Yes_No_Not_Sure_Enums>;
  specialistInfo?: Maybe<SpecialistInfo>;
  testRegularly?: Maybe<Yes_No_Not_Sure_Enums>;
  treatments?: Maybe<Array<Maybe<Scalars['String']>>>;
  unit?: Maybe<Scalars['String']>;
  yearsWithCondition?: Maybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  accumulatedTime?: Maybe<Scalars['Int']>;
  endTime?: Maybe<Scalars['Date']>;
  interventions?: Maybe<Array<Maybe<InterventionType>>>;
  provider?: Maybe<User>;
  startTime?: Maybe<Scalars['Date']>;
};

export type ExerciseGoal = {
  __typename?: 'ExerciseGoal';
  target?: Maybe<Scalars['Int']>;
  timeUnit?: Maybe<ExerciseTimeUnit>;
  unit?: Maybe<ExerciseUnit>;
};

export type ExerciseGoalInput = {
  target?: InputMaybe<Scalars['Int']>;
  timeUnit?: InputMaybe<ExerciseTimeUnit>;
  unit?: InputMaybe<ExerciseUnit>;
};

export type ExerciseStatus = {
  __typename?: 'ExerciseStatus';
  isActive?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
};

export enum ExerciseTimeUnit {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export type ExerciseTrendsByTimeRange = {
  __typename?: 'ExerciseTrendsByTimeRange';
  data?: Maybe<Array<Maybe<UserVitalsByTimeRangeDataPoint>>>;
};

export enum ExerciseUnit {
  Step = 'step'
}

export type FastFoodFreq = {
  __typename?: 'FastFoodFreq';
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

/** ## FeatureUpload type */
export type FeatureUpload = {
  __typename?: 'FeatureUpload';
  fileInfos?: Maybe<Array<Maybe<FileInfo>>>;
  release?: Maybe<Scalars['Int']>;
};

/** # Feedback filter */
export type FeedbackListOpts = {
  rating?: InputMaybe<FeedbackRatingFilterEnum>;
};

export type FeedbackListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<FeedbackListSortField>;
};

export enum FeedbackListSortField {
  CreatedAt = 'CREATED_AT'
}

export enum FeedbackRatingFilterEnum {
  FiveOrLess = 'FIVE_OR_LESS',
  FourOrLess = 'FOUR_OR_LESS',
  One = 'ONE',
  ThreeOrLess = 'THREE_OR_LESS',
  TwoOrLess = 'TWO_OR_LESS'
}

/** ## FeedbackType type */
export type FeedbackType = {
  __typename?: 'FeedbackType';
  createdAt?: Maybe<Scalars['Date']>;
  feedback?: Maybe<Scalars['String']>;
  member?: Maybe<User>;
  rating?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export enum FileExtTypeEnum {
  Jpg = 'JPG',
  Pdf = 'PDF',
  Png = 'PNG'
}

export type FileInfo = {
  __typename?: 'FileInfo';
  fileKey: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  originalLink?: Maybe<Scalars['String']>;
  thumbnailLink?: Maybe<Scalars['String']>;
};

export type FileInfoInput = {
  fileKey: Scalars['String'];
};

export type FileInfoType = {
  __typename?: 'FileInfoType';
  expires?: Maybe<Scalars['String']>;
};

export type FileSignedType = {
  __typename?: 'FileSignedType';
  fields?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** ## FileUpload type */
export type FileUpload = {
  __typename?: 'FileUpload';
  createdAt?: Maybe<Scalars['Date']>;
  fileInfo?: Maybe<FileInfo>;
  id?: Maybe<Scalars['EID']>;
  member?: Maybe<User>;
  organization?: Maybe<Organization>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  uploadedAt?: Maybe<Scalars['Date']>;
};

export type FileUploadListOpts = {
  fileExts?: InputMaybe<Array<InputMaybe<FileExtTypeEnum>>>;
  memberId?: InputMaybe<Scalars['EID']>;
};

export type FileUploadListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<FileUploadListSortField>;
};

export enum FileUploadListSortField {
  CreatedAt = 'CREATED_AT'
}

export enum FileUploadTypeEnum {
  Original = 'ORIGINAL',
  Regular = 'REGULAR',
  Thumbnail = 'THUMBNAIL'
}

export type FilterFindManyAuditRecordInput = {
  _id?: InputMaybe<Scalars['MongoID']>;
  _ids?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** List of fields that can be filtered via operators */
  _operators?: InputMaybe<OperatorsFilterFindManyAuditRecordInput>;
  action?: InputMaybe<Scalars['String']>;
  apiKey?: InputMaybe<Scalars['String']>;
  appId?: InputMaybe<Scalars['String']>;
  args?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  docId?: InputMaybe<Scalars['MongoID']>;
  /** List of fields requested/accessed by viewer */
  fields?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  ip?: InputMaybe<Scalars['String']>;
  networkSpeed?: InputMaybe<Scalars['String']>;
  networkType?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  /** Organization ID of the logged-in user (viewer) */
  organizationId?: InputMaybe<Scalars['MongoID']>;
  phoneName?: InputMaybe<Scalars['String']>;
  /** Resource (collection) name */
  resource?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
  sessionInfo?: InputMaybe<AuditRecordSessionInfoInput>;
  sessionToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<EnumAuditRecordStatus>;
  success?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  userId?: InputMaybe<Scalars['MongoID']>;
  username?: InputMaybe<Scalars['String']>;
  viewerId?: InputMaybe<Scalars['MongoID']>;
};

export type FilterFindManyLabResultTemplatesInput = {
  _id?: InputMaybe<Scalars['MongoID']>;
  _ids?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** List of fields that can be filtered via operators */
  _operators?: InputMaybe<OperatorsFilterFindManyLabResultTemplatesInput>;
  createdAt?: InputMaybe<Scalars['Date']>;
  items?: InputMaybe<Array<InputMaybe<LabResultTemplatesLabResultTemplatesItemsInput>>>;
  templateId?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  userId?: InputMaybe<Scalars['MongoID']>;
  viewerId?: InputMaybe<Scalars['MongoID']>;
};

export type FilterFindManyLabResultsInput = {
  _id?: InputMaybe<Scalars['MongoID']>;
  _ids?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** List of fields that can be filtered via operators */
  _operators?: InputMaybe<OperatorsFilterFindManyLabResultsInput>;
  createdAt?: InputMaybe<Scalars['Date']>;
  dateCollected?: InputMaybe<Scalars['Date']>;
  dateReceived?: InputMaybe<Scalars['Date']>;
  dateReported?: InputMaybe<Scalars['Date']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  /** remove deleted labRes */
  filterDeleted?: InputMaybe<Scalars['Boolean']>;
  labRef?: InputMaybe<Scalars['String']>;
  requisition?: InputMaybe<Scalars['String']>;
  results?: InputMaybe<Array<InputMaybe<LabResultsLabResultsResultsInput>>>;
  specimen?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  userId?: InputMaybe<Scalars['MongoID']>;
  viewerId?: InputMaybe<Scalars['MongoID']>;
};

export type FilterFindManyTimeTrackerInput = {
  _id?: InputMaybe<Scalars['MongoID']>;
  _ids?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** List of fields that can be filtered via operators */
  _operators?: InputMaybe<OperatorsFilterFindManyTimeTrackerInput>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  abortTime?: InputMaybe<Scalars['Date']>;
  /** Main eligible tracking event action */
  action?: InputMaybe<EnumTimeTrackerAction>;
  /** Main eligible tracking event category */
  category?: InputMaybe<EnumTimeTrackerCategory>;
  /** Collection of the client-side events, to provide more details of the breakdown of accumulated time. */
  clientEvents?: InputMaybe<Array<InputMaybe<TimeTrackerTimeTrackerClientEventsInput>>>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  /** Interventions items. (May be >= 1, e.g. rateFoodLog + addFoodLogComment) */
  interventionDetails?: InputMaybe<Array<InputMaybe<TimeTrackerTimeTrackerInterventionDetailsInput>>>;
  /** Reference to other related time tracking events performed during the main event. */
  relatedEventIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** *Client* session ID to group events */
  sessionId?: InputMaybe<Scalars['String']>;
  /** Start time of the main eligible tracking event. */
  startTime?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  stopTime?: InputMaybe<Scalars['Date']>;
  techCall?: InputMaybe<Scalars['Boolean']>;
  /** DEPRECATED, use totalTime instead. unit: seconds */
  timeSpent?: InputMaybe<Scalars['Float']>;
  /** Client-side timeout configuration, included in totalTime if applicable */
  timeout?: InputMaybe<Scalars['Float']>;
  /** Total "eligible" time accumulated on the main event (unit: miliseconds). Can be different from the (stopTime - startTime) */
  totalTime?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED, use category+action instead */
  type?: InputMaybe<Scalars['String']>;
  /** User ID as target of the tracking, usually a member. */
  userId?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  userOrganizationId?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  viewerId?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  viewerOrganizationId?: InputMaybe<Scalars['MongoID']>;
  /** Client-side component/path details. */
  viewingDetails?: InputMaybe<TimeTrackerViewingDetailsInput>;
};

export type FilterFindManyWeeklyBgScheduleTemplatesInput = {
  _id?: InputMaybe<Scalars['MongoID']>;
  _ids?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** List of fields that can be filtered via operators */
  _operators?: InputMaybe<OperatorsFilterFindManyWeeklyBgScheduleTemplatesInput>;
  createdAt?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<WeeklyBgScheduleTemplatesScheduleInput>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  userId?: InputMaybe<Scalars['MongoID']>;
  viewerId?: InputMaybe<Scalars['MongoID']>;
};

export type FilterLabResultsInput = {
  _id?: InputMaybe<Scalars['MongoID']>;
  _ids?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** List of fields that can be filtered via operators */
  _operators?: InputMaybe<OperatorsFilterLabResultsInput>;
  createdAt?: InputMaybe<Scalars['Date']>;
  dateCollected?: InputMaybe<Scalars['Date']>;
  dateReceived?: InputMaybe<Scalars['Date']>;
  dateReported?: InputMaybe<Scalars['Date']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  labRef?: InputMaybe<Scalars['String']>;
  requisition?: InputMaybe<Scalars['String']>;
  results?: InputMaybe<Array<InputMaybe<LabResultsLabResultsResultsInput>>>;
  specimen?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  userId?: InputMaybe<Scalars['MongoID']>;
  viewerId?: InputMaybe<Scalars['MongoID']>;
};

export type FilterTimeTrackerInput = {
  _id?: InputMaybe<Scalars['MongoID']>;
  _ids?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** List of fields that can be filtered via operators */
  _operators?: InputMaybe<OperatorsFilterTimeTrackerInput>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  abortTime?: InputMaybe<Scalars['Date']>;
  /** Main eligible tracking event action */
  action?: InputMaybe<EnumTimeTrackerAction>;
  /** Main eligible tracking event category */
  category?: InputMaybe<EnumTimeTrackerCategory>;
  /** Collection of the client-side events, to provide more details of the breakdown of accumulated time. */
  clientEvents?: InputMaybe<Array<InputMaybe<TimeTrackerTimeTrackerClientEventsInput>>>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  /** Interventions items. (May be >= 1, e.g. rateFoodLog + addFoodLogComment) */
  interventionDetails?: InputMaybe<Array<InputMaybe<TimeTrackerTimeTrackerInterventionDetailsInput>>>;
  /** Reference to other related time tracking events performed during the main event. */
  relatedEventIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** *Client* session ID to group events */
  sessionId?: InputMaybe<Scalars['String']>;
  /** Start time of the main eligible tracking event. */
  startTime?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  stopTime?: InputMaybe<Scalars['Date']>;
  techCall?: InputMaybe<Scalars['Boolean']>;
  /** DEPRECATED, use totalTime instead. unit: seconds */
  timeSpent?: InputMaybe<Scalars['Float']>;
  /** Client-side timeout configuration, included in totalTime if applicable */
  timeout?: InputMaybe<Scalars['Float']>;
  /** Total "eligible" time accumulated on the main event (unit: miliseconds). Can be different from the (stopTime - startTime) */
  totalTime?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED, use category+action instead */
  type?: InputMaybe<Scalars['String']>;
  /** User ID as target of the tracking, usually a member. */
  userId?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  userOrganizationId?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  viewerId?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  viewerOrganizationId?: InputMaybe<Scalars['MongoID']>;
  /** Client-side component/path details. */
  viewingDetails?: InputMaybe<TimeTrackerViewingDetailsInput>;
};

export type FilterUpdateManyLabResultsInput = {
  _id?: InputMaybe<Scalars['MongoID']>;
  _ids?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** List of fields that can be filtered via operators */
  _operators?: InputMaybe<OperatorsFilterUpdateManyLabResultsInput>;
  createdAt?: InputMaybe<Scalars['Date']>;
  dateCollected?: InputMaybe<Scalars['Date']>;
  dateReceived?: InputMaybe<Scalars['Date']>;
  dateReported?: InputMaybe<Scalars['Date']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  labRef?: InputMaybe<Scalars['String']>;
  requisition?: InputMaybe<Scalars['String']>;
  results?: InputMaybe<Array<InputMaybe<LabResultsLabResultsResultsInput>>>;
  specimen?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  userId?: InputMaybe<Scalars['MongoID']>;
  viewerId?: InputMaybe<Scalars['MongoID']>;
};

export type FilterUpdateManyTimeTrackerInput = {
  _id?: InputMaybe<Scalars['MongoID']>;
  _ids?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** List of fields that can be filtered via operators */
  _operators?: InputMaybe<OperatorsFilterUpdateManyTimeTrackerInput>;
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  abortTime?: InputMaybe<Scalars['Date']>;
  /** Main eligible tracking event action */
  action?: InputMaybe<EnumTimeTrackerAction>;
  /** Main eligible tracking event category */
  category?: InputMaybe<EnumTimeTrackerCategory>;
  /** Collection of the client-side events, to provide more details of the breakdown of accumulated time. */
  clientEvents?: InputMaybe<Array<InputMaybe<TimeTrackerTimeTrackerClientEventsInput>>>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  /** Interventions items. (May be >= 1, e.g. rateFoodLog + addFoodLogComment) */
  interventionDetails?: InputMaybe<Array<InputMaybe<TimeTrackerTimeTrackerInterventionDetailsInput>>>;
  /** Reference to other related time tracking events performed during the main event. */
  relatedEventIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** *Client* session ID to group events */
  sessionId?: InputMaybe<Scalars['String']>;
  /** Start time of the main eligible tracking event. */
  startTime?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  stopTime?: InputMaybe<Scalars['Date']>;
  techCall?: InputMaybe<Scalars['Boolean']>;
  /** DEPRECATED, use totalTime instead. unit: seconds */
  timeSpent?: InputMaybe<Scalars['Float']>;
  /** Client-side timeout configuration, included in totalTime if applicable */
  timeout?: InputMaybe<Scalars['Float']>;
  /** Total "eligible" time accumulated on the main event (unit: miliseconds). Can be different from the (stopTime - startTime) */
  totalTime?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED, use category+action instead */
  type?: InputMaybe<Scalars['String']>;
  /** User ID as target of the tracking, usually a member. */
  userId?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  userOrganizationId?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  viewerId?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  viewerOrganizationId?: InputMaybe<Scalars['MongoID']>;
  /** Client-side component/path details. */
  viewingDetails?: InputMaybe<TimeTrackerViewingDetailsInput>;
};

export type FlagDetail = {
  __typename?: 'FlagDetail';
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  reason?: Maybe<Scalars['String']>;
};

/** Notification type for flagging member, currently on EMAIL is supported, other types such as SMS, PUSH_NOTIFICATION might be supported in the future */
export enum FlagNotification {
  Email = 'EMAIL'
}

export enum FoodAccessibility {
  Aid = 'AID',
  CookCare = 'COOK_CARE',
  CookNoCare = 'COOK_NO_CARE',
  Kitchen = 'KITCHEN',
  LackResources = 'LACK_RESOURCES',
  None = 'NONE',
  NoAid = 'NO_AID',
  ShopCare = 'SHOP_CARE',
  ShopNoCare = 'SHOP_NO_CARE'
}

/** ## Food Log type */
export type FoodLog = {
  __typename?: 'FoodLog';
  comments?: Maybe<Array<Maybe<FoodLogCommentType>>>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['EID']>;
  images?: Maybe<Array<Maybe<ImageType>>>;
  mealType?: Maybe<MealTypeEnum>;
  member?: Maybe<User>;
  needReview?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  rating?: Maybe<FoodLogRatingType>;
  responseStatus?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  uploadedAt?: Maybe<Scalars['Date']>;
  vitalTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Food Log Message Input */
export type FoodLogCommentInput = {
  commentedById?: InputMaybe<Scalars['EID']>;
  originalText?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** Food Log Message Type */
export type FoodLogCommentType = {
  __typename?: 'FoodLogCommentType';
  commentedAt?: Maybe<Scalars['Date']>;
  commentedBy?: Maybe<User>;
  originalText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export enum FoodLogImageEnum {
  Regular = 'REGULAR',
  Thumbnail = 'THUMBNAIL'
}

export type FoodLogImageType = {
  __typename?: 'FoodLogImageType';
  fileKey: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  thumbnailLink?: Maybe<Scalars['String']>;
};

/**
 * # Food Log filter
 *  if both providerId and memberId are provided, memberId is ignored
 */
export type FoodLogListOpts = {
  fromDate?: InputMaybe<Scalars['Date']>;
  mealType?: InputMaybe<MealTypeEnum>;
  memberId?: InputMaybe<Scalars['EID']>;
  providerId?: InputMaybe<Scalars['EID']>;
  reviewed?: InputMaybe<Scalars['Boolean']>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type FoodLogListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<FoodLogListSortField>;
};

export enum FoodLogListSortField {
  CreatedAt = 'CREATED_AT',
  RatingStar = 'RATING_STAR'
}

/** Rating Category Input */
export type FoodLogRatingCategoryInput = {
  carb?: InputMaybe<RatingCategoryEnum>;
  fat?: InputMaybe<RatingCategoryEnum>;
  fruit?: InputMaybe<RatingCategoryEnum>;
  protein?: InputMaybe<RatingCategoryEnum>;
  vegetable?: InputMaybe<RatingCategoryEnum>;
};

/** Rating Category Type */
export type FoodLogRatingCategoryType = {
  __typename?: 'FoodLogRatingCategoryType';
  carb?: Maybe<RatingCategoryEnum>;
  fat?: Maybe<RatingCategoryEnum>;
  fruit?: Maybe<RatingCategoryEnum>;
  protein?: Maybe<RatingCategoryEnum>;
  vegetable?: Maybe<RatingCategoryEnum>;
};

/** Rating input */
export type FoodLogRatingInput = {
  category?: InputMaybe<FoodLogRatingCategoryInput>;
  star?: InputMaybe<Scalars['Int']>;
};

/** Rating type */
export type FoodLogRatingType = {
  __typename?: 'FoodLogRatingType';
  category?: Maybe<FoodLogRatingCategoryType>;
  star?: Maybe<Scalars['Int']>;
};

export enum FormName {
  ConsentForm = 'CONSENT_FORM',
  LoanForm = 'LOAN_FORM'
}

export enum GenderType {
  F = 'F',
  M = 'M',
  U = 'U'
}

export type GoalAssessment = {
  __typename?: 'GoalAssessment';
  assessment?: Maybe<GoalAssessmentEnum>;
  params?: Maybe<Array<Maybe<GoalAssessmentParams>>>;
};

export enum GoalAssessmentEnum {
  ClinicalCautionBp_1 = 'CLINICAL_CAUTION_BP_1',
  ClinicalCautionBp_2 = 'CLINICAL_CAUTION_BP_2',
  ClinicalCautionDiabetes = 'CLINICAL_CAUTION_DIABETES',
  ClinicalCautionPreDiabetes = 'CLINICAL_CAUTION_PRE_DIABETES',
  GlucoseAvg = 'GLUCOSE_AVG',
  GoalStatusMeeting = 'GOAL_STATUS_MEETING',
  GoalStatusNotMeeting = 'GOAL_STATUS_NOT_MEETING',
  GoalStatusUnableToConclude = 'GOAL_STATUS_UNABLE_TO_CONCLUDE',
  Instruction = 'INSTRUCTION',
  MeasureGte_12 = 'MEASURE_GTE_12',
  MeasureLe_12 = 'MEASURE_LE_12',
  WeightCondition = 'WEIGHT_CONDITION',
  WeightGoalChf = 'WEIGHT_GOAL_CHF',
  WeightGoalStatusMeeting = 'WEIGHT_GOAL_STATUS_MEETING',
  WeightGoalStatusNotMeeting = 'WEIGHT_GOAL_STATUS_NOT_MEETING',
  WeightGoalStatusUnableToConclude = 'WEIGHT_GOAL_STATUS_UNABLE_TO_CONCLUDE',
  WeightGoalType = 'WEIGHT_GOAL_TYPE',
  WeightInstruction = 'WEIGHT_INSTRUCTION',
  WeightMaintain = 'WEIGHT_MAINTAIN',
  WeightMeasureGte_12 = 'WEIGHT_MEASURE_GTE_12',
  WeightMeasureLe_12 = 'WEIGHT_MEASURE_LE_12',
  WeightNotMaintain = 'WEIGHT_NOT_MAINTAIN',
  WeightNoData = 'WEIGHT_NO_DATA',
  WeightTrendingGain = 'WEIGHT_TRENDING_GAIN',
  WeightTrendingLose = 'WEIGHT_TRENDING_LOSE'
}

export enum GoalAssessmentParamEnum {
  A1CLevel = 'A1C_LEVEL',
  Condition = 'CONDITION',
  WeightGoalType = 'WEIGHT_GOAL_TYPE'
}

export type GoalAssessmentParams = {
  __typename?: 'GoalAssessmentParams';
  name?: Maybe<GoalAssessmentParamEnum>;
  value?: Maybe<Scalars['String']>;
};

export enum GoalMeetingStatusEnum {
  Meeting = 'MEETING',
  NotMeeting = 'NOT_MEETING',
  UnableToConclude = 'UNABLE_TO_CONCLUDE'
}

export type GroupOperatorsFilterFindManyAuditRecordInput = {
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum HrDeviceModel {
  Bp3L = 'BP3L',
  Bp5 = 'BP5',
  Po3 = 'PO3'
}

/** Heart Rate measurement data. */
export type HrMeasure = Measure & {
  __typename?: 'HRMeasure';
  date?: Maybe<Scalars['Date']>;
  deviceAddress?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  heart_rate?: Maybe<Scalars['HRValue']>;
  /**
   * hadMedicine: Boolean added to support hadMedicine flag
   * hadActivity: Boolean added to support hadActivity flag
   * hadInsulin: Boolean added to support hadInsulin flag
   * tags:[String] added to support mood tags
   */
  id?: Maybe<Scalars['EID']>;
  imageUrl?: Maybe<Scalars['String']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measureBy?: Maybe<User>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  resultId?: Maybe<Scalars['EID']>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<Vital>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
};

/** Heart Rate measurement data. */
export type HrMeasureAudit = MeasureAudit & {
  __typename?: 'HRMeasureAudit';
  date?: Maybe<Scalars['Date']>;
  deviceAddress?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  heart_rate?: Maybe<Scalars['HRValue']>;
  /**
   * hadMedicine: Boolean added to support hadMedicine flag
   * hadActivity: Boolean added to support hadActivity flag
   * hadInsulin: Boolean added to support hadInsulin flag
   * tags:[String] added to support mood tags
   */
  id?: Maybe<Scalars['EID']>;
  imageUrl?: Maybe<Scalars['String']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measureBy?: Maybe<User>;
  measurementSession?: Maybe<AuditRecord>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  networkSpeed?: Maybe<Scalars['String']>;
  networkType?: Maybe<Scalars['String']>;
  phoneName?: Maybe<Scalars['String']>;
  phoneSessionRecord?: Maybe<AuditRecord>;
  resultId?: Maybe<Scalars['EID']>;
  sessionId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<Vital>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
};

export type HrResult = Result & {
  __typename?: 'HRResult';
  assessmentFilled?: Maybe<AssessmentFilled>;
  careStatus?: Maybe<CareStatus>;
  caredBy?: Maybe<User>;
  closedAt?: Maybe<Scalars['Date']>;
  comments?: Maybe<Array<Maybe<ResultCommentType>>>;
  date?: Maybe<Scalars['Date']>;
  dismissed?: Maybe<Scalars['Boolean']>;
  enrolledProgram?: Maybe<EnrolledProgram>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  heart_rate?: Maybe<Scalars['HRValue']>;
  id?: Maybe<Scalars['EID']>;
  late?: Maybe<Scalars['Boolean']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measure?: Maybe<Measure>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  notes?: Maybe<CursorPaginatedProviderNotes>;
  notified?: Maybe<Scalars['Boolean']>;
  refId?: Maybe<Scalars['EID']>;
  /** # 08/18/2017 COCONUT ## */
  repeat?: Maybe<Scalars['String']>;
  resultUpdates?: Maybe<Array<Maybe<ResultUpdateType>>>;
  reviewedAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<TaskSchedule>;
  severity?: Maybe<AlertSeverity>;
  taskStatus?: Maybe<TaskStatus>;
  threshold?: Maybe<Array<Maybe<AlertThreshold>>>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<TaskType>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
  violators?: Maybe<Array<Maybe<MeasureType>>>;
};


export type HrResultEnrolledProgramArgs = {
  relatedTaskOnly?: InputMaybe<Scalars['Boolean']>;
};


export type HrResultNotesArgs = {
  after?: InputMaybe<Scalars['Ref']>;
  before?: InputMaybe<Scalars['Ref']>;
  count?: InputMaybe<Scalars['Int']>;
};

/** iHealth Weight Scale device models. */
export enum HsDeviceModel {
  Hs2 = 'HS2',
  Hs2S = 'HS2S',
  Hs4S = 'HS4S',
  Hs6 = 'HS6'
}

/** Weight measurement data. */
export type HsMeasure = Measure & {
  __typename?: 'HSMeasure';
  body_weight?: Maybe<Scalars['HSValue']>;
  body_weight_change?: Maybe<Scalars['HSValue']>;
  date?: Maybe<Scalars['Date']>;
  deviceAddress?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  /**
   * hadMedicine: Boolean added to support hadMedicine flag
   * hadActivity: Boolean added to support hadActivity flag
   * hadInsulin: Boolean added to support hadInsulin flag
   * tags:[String] added to support mood tags
   */
  id?: Maybe<Scalars['EID']>;
  imageUrl?: Maybe<Scalars['String']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measureBy?: Maybe<User>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  resultId?: Maybe<Scalars['EID']>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<Vital>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
};

/** Weight measurement data. */
export type HsMeasureAudit = MeasureAudit & {
  __typename?: 'HSMeasureAudit';
  body_weight?: Maybe<Scalars['HSValue']>;
  body_weight_change?: Maybe<Scalars['HSValue']>;
  date?: Maybe<Scalars['Date']>;
  deviceAddress?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  /**
   * hadMedicine: Boolean added to support hadMedicine flag
   * hadActivity: Boolean added to support hadActivity flag
   * hadInsulin: Boolean added to support hadInsulin flag
   * tags:[String] added to support mood tags
   */
  id?: Maybe<Scalars['EID']>;
  imageUrl?: Maybe<Scalars['String']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measureBy?: Maybe<User>;
  measurementSession?: Maybe<AuditRecord>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  networkSpeed?: Maybe<Scalars['String']>;
  networkType?: Maybe<Scalars['String']>;
  phoneName?: Maybe<Scalars['String']>;
  phoneSessionRecord?: Maybe<AuditRecord>;
  resultId?: Maybe<Scalars['EID']>;
  sessionId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<Vital>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
};

export type HsResult = Result & {
  __typename?: 'HSResult';
  assessmentFilled?: Maybe<AssessmentFilled>;
  body_weight?: Maybe<Scalars['HSValue']>;
  body_weight_change?: Maybe<Scalars['HSValue']>;
  body_weight_change_baseline?: Maybe<Scalars['HSValue']>;
  careStatus?: Maybe<CareStatus>;
  caredBy?: Maybe<User>;
  closedAt?: Maybe<Scalars['Date']>;
  comments?: Maybe<Array<Maybe<ResultCommentType>>>;
  date?: Maybe<Scalars['Date']>;
  dismissed?: Maybe<Scalars['Boolean']>;
  enrolledProgram?: Maybe<EnrolledProgram>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['EID']>;
  late?: Maybe<Scalars['Boolean']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measure?: Maybe<Measure>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  notes?: Maybe<CursorPaginatedProviderNotes>;
  notified?: Maybe<Scalars['Boolean']>;
  refId?: Maybe<Scalars['EID']>;
  /** # 08/18/2017 COCONUT ## */
  repeat?: Maybe<Scalars['String']>;
  resultUpdates?: Maybe<Array<Maybe<ResultUpdateType>>>;
  reviewedAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<TaskSchedule>;
  severity?: Maybe<AlertSeverity>;
  taskStatus?: Maybe<TaskStatus>;
  threshold?: Maybe<Array<Maybe<AlertThreshold>>>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<TaskType>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
  violators?: Maybe<Array<Maybe<MeasureType>>>;
};


export type HsResultEnrolledProgramArgs = {
  relatedTaskOnly?: InputMaybe<Scalars['Boolean']>;
};


export type HsResultNotesArgs = {
  after?: InputMaybe<Scalars['Ref']>;
  before?: InputMaybe<Scalars['Ref']>;
  count?: InputMaybe<Scalars['Int']>;
};

/** ## Handout Type */
export type Handout = {
  __typename?: 'Handout';
  category?: Maybe<HandoutType>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  language?: Maybe<Array<Maybe<HandoutLanguage>>>;
  title?: Maybe<Scalars['String']>;
  weblink?: Maybe<Scalars['String']>;
};

export enum HandoutLanguage {
  AllLanguages = 'ALL_LANGUAGES',
  Cantonese = 'CANTONESE',
  Chinese = 'CHINESE',
  English = 'ENGLISH',
  Mandarin = 'MANDARIN',
  Spanish = 'SPANISH',
  Tagalog = 'TAGALOG',
  Vietnamese = 'VIETNAMESE'
}

export enum HandoutType {
  ChronicKidneyDisease = 'CHRONIC_KIDNEY_DISEASE',
  ChronicObstructivePulmonaryDisease = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
  CongestiveHeartFailure = 'CONGESTIVE_HEART_FAILURE',
  DeviceEducation = 'DEVICE_EDUCATION',
  Diabetes = 'DIABETES',
  Htn = 'HTN',
  Hyperlipidemia = 'HYPERLIPIDEMIA',
  OtherConditions = 'OTHER_CONDITIONS',
  Resources = 'RESOURCES',
  WeightManagement = 'WEIGHT_MANAGEMENT',
  Wellness = 'WELLNESS'
}

/**
 * ## Health Condition
 *  Health conditions are more than just categories. They control which data types can control
 *  other data types by relationship association.
 */
export enum HealthCondition {
  Ami = 'AMI',
  Copd = 'COPD',
  Default = 'DEFAULT',
  Diabetes = 'DIABETES',
  GestationalDiabetes = 'GESTATIONAL_DIABETES',
  HeartFailure = 'HEART_FAILURE',
  Hypertension = 'HYPERTENSION',
  Stage_1Hypertension = 'STAGE_1_HYPERTENSION',
  Stage_2HypertensionControlled = 'STAGE_2_HYPERTENSION_CONTROLLED',
  Stage_2HypertensionUncontrolled = 'STAGE_2_HYPERTENSION_UNCONTROLLED',
  Type_1Diabetes = 'TYPE_1_DIABETES',
  Type_2Diabetes = 'TYPE_2_DIABETES'
}

export enum HealthConditionEnum {
  Ami = 'AMI',
  Copd = 'COPD',
  Default = 'DEFAULT',
  Diabetes = 'DIABETES',
  GestationalDiabetes = 'GESTATIONAL_DIABETES',
  HeartFailure = 'HEART_FAILURE',
  Hypertension = 'HYPERTENSION',
  Stage_1Hypertension = 'STAGE_1_HYPERTENSION',
  Stage_2HypertensionControlled = 'STAGE_2_HYPERTENSION_CONTROLLED',
  Stage_2HypertensionUncontrolled = 'STAGE_2_HYPERTENSION_UNCONTROLLED',
  Type_1Diabetes = 'TYPE_1_DIABETES',
  Type_2Diabetes = 'TYPE_2_DIABETES'
}

export enum HiatusCategoryStatus {
  Checked = 'CHECKED',
  Paused = 'PAUSED',
  Resumed = 'RESUMED',
  Unchecked = 'UNCHECKED'
}

export type History = {
  __typename?: 'History';
  action?: Maybe<Scalars['String']>;
  timeStamp?: Maybe<Scalars['Date']>;
};

export enum HubStatusFilterEnum {
  All = 'ALL',
  Available = 'AVAILABLE',
  InUse = 'IN_USE'
}

/**
 * ## ICDCode
 *  Billabe ICD-10 CM Code
 */
export type IcdCode = {
  __typename?: 'ICDCode';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  title?: Maybe<Scalars['String']>;
};

export type IcdCodeListOpts = {
  search?: InputMaybe<IcdCodeSearchInput>;
};

export type IcdCodeListSort = {
  code?: InputMaybe<IcdCodeListSortField>;
  title?: InputMaybe<SortDirection>;
};

export enum IcdCodeListSortField {
  Code = 'CODE',
  Title = 'TITLE'
}

export enum IcdCodeSearchFields {
  Code = 'CODE',
  Title = 'TITLE'
}

export type IcdCodeSearchInput = {
  fields?: InputMaybe<Array<InputMaybe<IcdCodeSearchFields>>>;
  match: Scalars['String'];
};

export type Identification = {
  __typename?: 'Identification';
  MRN?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CodedValue>;
  value?: Maybe<Scalars['String']>;
};

export enum IdentificationDocType {
  Ck = 'CK',
  Dl = 'DL',
  Dr = 'DR',
  Fd = 'FD',
  Fi = 'FI',
  Fp = 'FP',
  Mb = 'MB',
  Mc = 'MC',
  Mh = 'MH',
  Mp = 'MP',
  Mr = 'MR',
  Na = 'NA',
  Nb = 'NB',
  Nr = 'NR',
  Ob = 'OB',
  Ot = 'OT',
  Prn = 'PRN',
  Sb = 'SB',
  Sc = 'SC',
  Se = 'SE',
  Sh = 'SH',
  Si = 'SI',
  Sm = 'SM',
  So = 'SO',
  Sp = 'SP',
  Ss = 'SS',
  Tn = 'TN'
}

export type ImageInfoType = {
  __typename?: 'ImageInfoType';
  expires?: Maybe<Scalars['String']>;
};

export type ImageSignedType = {
  __typename?: 'ImageSignedType';
  fields?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ImageType = {
  __typename?: 'ImageType';
  fileKey: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  thumbnailLink?: Maybe<Scalars['String']>;
};

export type ImageTypeInput = {
  fileKey: Scalars['String'];
};

export type InputA1CObject = {
  dateCollected?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['EID']>;
  isInHouse?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['String']>;
};

export type InputAddress = {
  city: Scalars['String'];
  country: CountryCode;
  floor?: InputMaybe<Scalars['String']>;
  postCode: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  streetName: Scalars['String'];
  streetNumber: Scalars['String'];
  type?: InputMaybe<AddressType>;
  unit?: InputMaybe<Scalars['String']>;
};

/**
 * ## Assessment Answer
 *  content: takes JSON.stringify(originalContent), while originalContent is:
 *  'number' for TRUE_OR_FALSE, SINGLE_SELECT, MULTI_SELECT, SCALED, and INPUT_NUMBER type questions;
 *  'string' from INPUT_LONG, INPUT_SHORT questions;
 *  'date' from INPUT_DATE questions;
 *  'other' is to fill in the answer for 'other' for questions with an array of options.
 *  e.g. SINGLE_SELECT, MULTI_SELECT, and SCALED
 */
export type InputAssessmentAnswer = {
  /** type: AssessmentAnswerType! */
  content: Scalars['String'];
  other?: InputMaybe<Scalars['String']>;
};

export type InputAssessmentFilledFilters = {
  completed?: InputMaybe<Scalars['Boolean']>;
  memberId: Scalars['EID'];
  type?: InputMaybe<Array<InputMaybe<AssessmentType>>>;
};

/** ## Used in AssessmentFilled questions */
export type InputAssessmentFilledQuestion = {
  _id?: InputMaybe<Scalars['EID']>;
  answer?: InputMaybe<InputAssessmentAnswer>;
  category?: InputMaybe<AssessmentQuestionCategory>;
  options?: InputMaybe<Array<InputMaybe<InputAssessmentOption>>>;
  text: Scalars['String'];
  type: AssessmentQuestionType;
};

export type InputAssessmentFilledSearch = {
  fields: Array<InputMaybe<AssessmentFilledSearchFieldKey>>;
  match: Scalars['String'];
};

export type InputAssessmentFilters = {
  healthCondition?: InputMaybe<Array<InputMaybe<HealthCondition>>>;
  status?: InputMaybe<Array<InputMaybe<ProgramStatus>>>;
  type?: InputMaybe<Array<InputMaybe<AssessmentType>>>;
};

export type InputAssessmentOption = {
  label: Scalars['String'];
  meaning?: InputMaybe<Scalars['String']>;
};

/**
 * ## Used in Assessment questions
 *  NOTE: Currently only 5 types are supported: TRUE_OR_FALSE, SINGLE_SELECT, MULTI_SELECT, SCALED, INPUT_LONG
 */
export type InputAssessmentQuestion = {
  _id?: InputMaybe<Scalars['EID']>;
  options?: InputMaybe<Array<InputMaybe<InputAssessmentOption>>>;
  text: Scalars['String'];
  type: AssessmentQuestionType;
};

export type InputAssessmentSearch = {
  fields: Array<InputMaybe<AssessmentSearchFieldKey>>;
  match: Scalars['String'];
};

/**
 * ## Admin Program Assessment Task
 *  nameKey is the uuid for the assessment (all versions)
 */
export type InputAssessmentTask = {
  /** _id: EID */
  nameKey: Scalars['String'];
};

/**
 * ## Enrolled Program Assessment Task (As of 01/06/2017, it's the same as InputAssessmentTask)
 *  nameKey is the uuid for the assessment (all versions)
 *  _id and version are not required when adding a new task, it will be added by the server
 */
export type InputAssessmentTaskEnrolled = {
  _id?: InputMaybe<Scalars['EID']>;
  nameKey: Scalars['String'];
};

export type InputAuthorityInfo = {
  audioStatus?: InputMaybe<PhoneReportInfoPermissionStatus>;
  autoUpdateStatus?: InputMaybe<PhoneReportInfoPermissionStatus>;
  cameraStatus?: InputMaybe<PhoneReportInfoPermissionStatus>;
  coarseLocationStatus?: InputMaybe<PhoneReportInfoPermissionStatus>;
  contactsStatus?: InputMaybe<PhoneReportInfoPermissionStatus>;
  fineLocationStatus?: InputMaybe<PhoneReportInfoPermissionStatus>;
  locationAlwaysStatus?: InputMaybe<PhoneReportInfoPermissionStatus>;
  locationWhenInUseStatus?: InputMaybe<PhoneReportInfoPermissionStatus>;
  microphoneStatus?: InputMaybe<PhoneReportInfoPermissionStatus>;
  notificationStatus?: InputMaybe<PhoneReportInfoPermissionStatus>;
  openAppCountsAfterLogin?: InputMaybe<Scalars['Int']>;
  photoLibraryStatus?: InputMaybe<PhoneReportInfoPermissionStatus>;
  readContactsStatus?: InputMaybe<PhoneReportInfoPermissionStatus>;
  writeContactsStatus?: InputMaybe<PhoneReportInfoPermissionStatus>;
};

export type InputBgTest = {
  freq?: InputMaybe<Scalars['Int']>;
  testRegularly?: InputMaybe<Scalars['Boolean']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type InputBgThreshold = {
  range: Array<InputMaybe<Scalars['Float']>>;
  unit: Scalars['BGUnit'];
};

export type InputBpTest = {
  freq?: InputMaybe<Scalars['Int']>;
  testRegularly?: InputMaybe<Scalars['Boolean']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type InputBpThreshold = {
  range: Array<InputMaybe<Scalars['Float']>>;
  unit: Scalars['BPUnit'];
};

export type InputBehavioralGoal = {
  category?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<BehavioralGoalsType>;
  value?: InputMaybe<Scalars['String']>;
};

export type InputBillableHealthCondition = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
};

export type InputChronicDisease = {
  comments?: InputMaybe<Scalars['String']>;
  freq?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  otherTreatment?: InputMaybe<Scalars['String']>;
  seenDoctor?: InputMaybe<Scalars['Boolean']>;
  specialistInfo?: InputMaybe<InputSpecialistInfo>;
  takingMedication?: InputMaybe<Scalars['Boolean']>;
  testRegularly?: InputMaybe<Scalars['Boolean']>;
  treatments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  unit?: InputMaybe<Scalars['String']>;
  validationFlag?: InputMaybe<Scalars['Boolean']>;
  yearsWithCondition?: InputMaybe<Scalars['String']>;
};

export type InputClinicGoal = {
  condition?: InputMaybe<Scalars['String']>;
  conditionEnum?: InputMaybe<ConditionEnum>;
  goalEnum?: InputMaybe<ClinicGoalEnum>;
  meetingStatus?: InputMaybe<GoalMeetingStatusEnum>;
  statusUpdatedAt?: InputMaybe<Scalars['Date']>;
  statusUpdatedBy?: InputMaybe<Scalars['EID']>;
  value?: InputMaybe<Scalars['String']>;
};

export type InputCommonProfile = {
  avatar?: InputMaybe<ImageTypeInput>;
  firstName: Scalars['String'];
  gender?: InputMaybe<Scalars['Gender']>;
  lastName: Scalars['String'];
  nickname?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<UserTitle>;
};

export type InputCommonProfileEdit = {
  avatar?: InputMaybe<ImageTypeInput>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['Gender']>;
  lastName?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<UserTitle>;
};

export type InputConditionsListType = {
  CKD?: InputMaybe<Scalars['Boolean']>;
  COPD?: InputMaybe<Scalars['Boolean']>;
  DM?: InputMaybe<Scalars['Boolean']>;
  ESRD_Dialysis?: InputMaybe<Scalars['Boolean']>;
  HLD?: InputMaybe<Scalars['Boolean']>;
  HTN?: InputMaybe<Scalars['Boolean']>;
  Obesity?: InputMaybe<Scalars['Boolean']>;
  PreDM?: InputMaybe<Scalars['Boolean']>;
};

export type InputConnectedDeviceInfo = {
  deviceType?: InputMaybe<Scalars['String']>;
  macAddress?: InputMaybe<Scalars['String']>;
};

export type InputDailyMealSchedule = {
  after_breakfast?: InputMaybe<Scalars['Boolean']>;
  after_dinner?: InputMaybe<Scalars['Boolean']>;
  after_lunch?: InputMaybe<Scalars['Boolean']>;
  bedtime?: InputMaybe<Scalars['Boolean']>;
  before_breakfast?: InputMaybe<Scalars['Boolean']>;
  before_dinner?: InputMaybe<Scalars['Boolean']>;
  before_lunch?: InputMaybe<Scalars['Boolean']>;
  overnight?: InputMaybe<Scalars['Boolean']>;
};

export type InputDeleteReason = {
  key?: InputMaybe<DeleteReasonKey>;
  value: Scalars['String'];
};

export type InputDietHabits = {
  dietProperties?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  other?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type InputDrinkingHistory = {
  amount?: InputMaybe<Scalars['String']>;
  drinkType?: InputMaybe<Scalars['String']>;
  freq?: InputMaybe<InputDrinkingHistoryFreq>;
  quitYear?: InputMaybe<Scalars['String']>;
  startSinceYear?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Boolean']>;
};

export type InputDrinkingHistoryFreq = {
  unit?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type InputEmrList = {
  CCM?: InputMaybe<Scalars['Boolean']>;
  ChartA1C?: InputMaybe<Scalars['Boolean']>;
  EMR_Charting?: InputMaybe<Scalars['Boolean']>;
  pinnedToEMR?: InputMaybe<Scalars['Boolean']>;
};

export type InputEducation = {
  appReview?: InputMaybe<Scalars['Boolean']>;
  discussion?: InputMaybe<Scalars['Boolean']>;
  selfReflection?: InputMaybe<Scalars['Boolean']>;
};

export type InputEmployeeProfile = {
  NPI?: InputMaybe<Scalars['String']>;
  department?: InputMaybe<Scalars['String']>;
  directAddress?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['String']>;
  excludeBilling?: InputMaybe<Scalars['Boolean']>;
  hasCredential?: InputMaybe<Scalars['Boolean']>;
  mentorId?: InputMaybe<Scalars['EID']>;
  providerLanguage?: InputMaybe<Array<InputMaybe<Language>>>;
  specialty?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type InputEnrolledProgram = {
  assessments?: InputMaybe<Scalars['String']>;
  careCoordination?: InputMaybe<Scalars['String']>;
  deviceInputEnabledTaskTypes?: InputMaybe<Array<InputMaybe<TaskType>>>;
  followupSchedule?: InputMaybe<Scalars['String']>;
  goals?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['EID']>;
  interventions?: InputMaybe<Scalars['String']>;
  manualInputEnable?: InputMaybe<Scalars['Boolean']>;
  manualInputEnabledTaskTypes?: InputMaybe<Array<InputMaybe<TaskType>>>;
  problemList?: InputMaybe<Scalars['String']>;
  resource?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<Array<InputMaybe<InputTaskEnrolled>>>;
  teamId?: InputMaybe<Scalars['EID']>;
};

export type InputEvaluation = {
  comments?: InputMaybe<Scalars['String']>;
  freq?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  otherTreatment?: InputMaybe<Scalars['String']>;
  seenDoctorInLastSixMonth?: InputMaybe<Yes_No_Not_Sure_Enums>;
  specialistInfo?: InputMaybe<InputSpecialistInfo>;
  testRegularly?: InputMaybe<Yes_No_Not_Sure_Enums>;
  treatments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  unit?: InputMaybe<Scalars['String']>;
  yearsWithCondition?: InputMaybe<Scalars['String']>;
};

export type InputExerciseFreq = {
  unit?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type InputExerciseHabits = {
  duration?: InputMaybe<Scalars['Float']>;
  freq?: InputMaybe<InputExerciseFreq>;
  value?: InputMaybe<Scalars['Boolean']>;
};

export type InputExerciseStatus = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  reason?: InputMaybe<Scalars['String']>;
};

export type InputFastFoodFreq = {
  unit?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type InputHrThreshold = {
  range: Array<InputMaybe<Scalars['Float']>>;
  unit: Scalars['HRUnit'];
};

export type InputHsThreshold = {
  range: Array<InputMaybe<Scalars['Float']>>;
  unit: Scalars['HSUnit'];
};

export type InputHeartAttack = {
  hasHeartAttack?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['String']>;
};

/**
 * ## Identification
 *  An identification number to link for a user (such as government ID)
 *  TODO: add provider professional id type when available
 */
export type InputIdentification = {
  type?: InputMaybe<IdentificationDocType>;
  value?: InputMaybe<Scalars['String']>;
};

export type InputInsuranceProvider = {
  id?: InputMaybe<Scalars['EID']>;
  primary?: InputMaybe<Scalars['Boolean']>;
};

export type InputInsuranceProviderSearch = {
  match?: InputMaybe<Scalars['String']>;
};

export type InputMealIntake = {
  comments?: InputMaybe<Scalars['String']>;
  foodTypeAmount?: InputMaybe<Scalars['String']>;
  meal?: InputMaybe<Scalars['String']>;
  mealFreq?: InputMaybe<Scalars['String']>;
  skipFreq?: InputMaybe<Scalars['String']>;
  timeRange?: InputMaybe<Scalars['String']>;
};

export type InputMedicationManagement = {
  extraNote?: InputMaybe<Scalars['String']>;
  otherInfo?: InputMaybe<Scalars['String']>;
  reconcilationCompliance?: InputMaybe<Scalars['String']>;
};

/**
 * ## Profile input for normal members/patients.
 *    Billable health Conditions: selected health conditions for billing
 */
export type InputMemberProfile = {
  BGMedicine?: InputMaybe<Scalars['Boolean']>;
  BGOther?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  BGSpecialistInfo?: InputMaybe<InputSpecialistInfo>;
  BGTest?: InputMaybe<InputBgTest>;
  BGTreatments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  BGValidationFlag?: InputMaybe<Scalars['Boolean']>;
  BGVisits?: InputMaybe<Scalars['Boolean']>;
  BPSpecialistInfo?: InputMaybe<InputSpecialistInfo>;
  BPTest?: InputMaybe<InputBpTest>;
  BPTreatments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  BPValidationFlag?: InputMaybe<Scalars['Boolean']>;
  BPVisits?: InputMaybe<Scalars['Boolean']>;
  activityLevel?: InputMaybe<ActivityLevel>;
  appLanguage?: InputMaybe<Language>;
  bgDailyMeasureTimes?: InputMaybe<Scalars['BGDailyMeasureTimes']>;
  billableHealthConditions?: InputMaybe<Array<InputMaybe<InputBillableHealthCondition>>>;
  billingStatus?: InputMaybe<BillingStatusType>;
  birthday: Scalars['CalDate'];
  cardiovascularDisease?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  chartingNote?: InputMaybe<Scalars['String']>;
  chronicDiseaseHistory?: InputMaybe<Array<InputMaybe<InputChronicDisease>>>;
  chronicDiseaseNote?: InputMaybe<Scalars['String']>;
  conditionsList?: InputMaybe<InputConditionsListType>;
  diagnosis?: InputMaybe<Scalars['String']>;
  dietHabits?: InputMaybe<InputDietHabits>;
  doctorId?: InputMaybe<Scalars['EID']>;
  drinkingHistory?: InputMaybe<InputDrinkingHistory>;
  eatingRoutine?: InputMaybe<EatingRoutine>;
  economicStatus?: InputMaybe<Scalars['String']>;
  education?: InputMaybe<InputEducation>;
  educationLevel?: InputMaybe<EducationLevel>;
  emrList?: InputMaybe<InputEmrList>;
  enrollmentNote?: InputMaybe<Scalars['String']>;
  ethnicity?: InputMaybe<Scalars['String']>;
  exerciseHabits?: InputMaybe<InputExerciseHabits>;
  fatherMedicalHistory?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  foodAccessibility?: InputMaybe<FoodAccessibility>;
  handouts?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  hasSmartPhone?: InputMaybe<Scalars['Boolean']>;
  healthConditions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  heartAttack?: InputMaybe<InputHeartAttack>;
  height?: InputMaybe<Scalars['HTValue']>;
  insulinDependent?: InputMaybe<Scalars['Boolean']>;
  insuranceProvider?: InputMaybe<Scalars['String']>;
  insuranceProvider2?: InputMaybe<Scalars['String']>;
  insuranceProviders?: InputMaybe<Array<InputMaybe<InputInsuranceProvider>>>;
  isSelfEnrolled?: InputMaybe<Scalars['Boolean']>;
  kidneyDisease?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Language>;
  lifestyleNote?: InputMaybe<Scalars['String']>;
  lifestylePattern?: InputMaybe<Scalars['String']>;
  liverDisease?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  maritalStatus?: InputMaybe<MaritalStatus>;
  maternalFatherMedicalHistory?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  maternalMotherMedicalHistory?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  maternalUnclesOrAuntsMedicalHistory?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  medicationManagement?: InputMaybe<InputMedicationManagement>;
  mntHealthConditions?: InputMaybe<Array<InputMaybe<InputBillableHealthCondition>>>;
  motherMedicalHistory?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  motivation?: InputMaybe<Motivation>;
  nationality?: InputMaybe<Nationality>;
  nutrition?: InputMaybe<InputNutrition>;
  occupation?: InputMaybe<Scalars['String']>;
  paternalFatherMedicalHistory?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  paternalMotherMedicalHistory?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  paternalUnclesOrAuntsMedicalHistory?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  programCategories?: InputMaybe<Array<InputMaybe<InputProgramCategories>>>;
  race?: InputMaybe<Race>;
  race1?: InputMaybe<Race1>;
  retinopathy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  routine?: InputMaybe<Routine>;
  siblingMedicalHistory?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sleepFreq?: InputMaybe<SleepFreq>;
  sleepingRoutine?: InputMaybe<SleepingRoutine>;
  smokingHistory?: InputMaybe<InputSmokingHistory>;
  socialSupport?: InputMaybe<InputSocialSupport>;
  spouseMedicalHistory?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  stroke?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  techLevel?: InputMaybe<TechLevel>;
  weight?: InputMaybe<Scalars['HSValue']>;
  workDuties?: InputMaybe<Scalars['String']>;
  yearOfDiagnose?: InputMaybe<Array<InputMaybe<InputYearOfDiagnose>>>;
};

export type InputMemberResidency = {
  admissionDate?: InputMaybe<Scalars['Date']>;
  building: Scalars['String'];
  floor?: InputMaybe<Scalars['String']>;
  room?: InputMaybe<Scalars['String']>;
};

export type InputMiniProvider = {
  name?: InputMaybe<Scalars['String']>;
  providerId?: InputMaybe<Scalars['EID']>;
};

export type InputMobileInfo = {
  appVersion?: InputMaybe<Scalars['String']>;
  latestAppVersion?: InputMaybe<Scalars['String']>;
  phoneId: Scalars['String'];
  phoneModel?: InputMaybe<Scalars['String']>;
  phoneName?: InputMaybe<Scalars['String']>;
  phoneOSVersion?: InputMaybe<Scalars['String']>;
};

/** ## Input for Notification Templates */
export type InputNotificationTmpl = {
  body?: InputMaybe<Scalars['String']>;
  person: NotificationPerson;
  subject?: InputMaybe<Scalars['String']>;
};

export type InputNutrition = {
  additionalComments?: InputMaybe<Scalars['String']>;
  behaviorChange?: InputMaybe<Scalars['String']>;
  fastFoodFreq?: InputMaybe<InputFastFoodFreq>;
  intake?: InputMaybe<Array<InputMaybe<InputMealIntake>>>;
  intakeNote?: InputMaybe<Scalars['String']>;
  interpretationNote?: InputMaybe<Scalars['String']>;
  nutritionUnderstanding?: InputMaybe<Scalars['String']>;
  previousDiets?: InputMaybe<Array<InputMaybe<Diets>>>;
  sweetBeverageFreq?: InputMaybe<InputSweetBeverageFreq>;
};

/** ## InputPESStatement Type */
export type InputPesStatement = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  etiology?: InputMaybe<Array<InputMaybe<InputStatement>>>;
  id?: InputMaybe<Scalars['EID']>;
  memberId?: InputMaybe<Scalars['EID']>;
  problem?: InputMaybe<InputStatement>;
  resolution?: InputMaybe<InputResolution>;
  signSymptom?: InputMaybe<Array<InputMaybe<InputStatement>>>;
  visitId?: InputMaybe<Scalars['EID']>;
};

export type InputPoThreshold = {
  range: Array<InputMaybe<Scalars['Float']>>;
  unit: Scalars['POUnit'];
};

export type InputPageInfo = {
  count?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<UserListSort>;
};

export type InputPatientFilters = {
  adminProgramId?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  age?: InputMaybe<InputUserAge>;
  assignedToCA?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  assignedToCANotNecessary?: InputMaybe<Scalars['Boolean']>;
  assignedToRD?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  assignedToRDNotNecessary?: InputMaybe<Scalars['Boolean']>;
  crossClinic?: InputMaybe<Scalars['Boolean']>;
  daysRemaining?: InputMaybe<Scalars['Int']>;
  doctorId?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  gender?: InputMaybe<Array<InputMaybe<Scalars['Gender']>>>;
  generalPatient?: InputMaybe<Scalars['Boolean']>;
  hasCallLog?: InputMaybe<Scalars['Boolean']>;
  hasMntCodes?: InputMaybe<Scalars['Boolean']>;
  hasMntProgram?: InputMaybe<Scalars['Boolean']>;
  mntProgramStatus?: InputMaybe<Array<InputMaybe<MntProgramStatusEnum>>>;
  monthlyMeasurementDaysRange?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  programsEnrolled?: InputMaybe<Array<InputMaybe<ProgramEnrolled>>>;
  showAll?: InputMaybe<Scalars['Boolean']>;
  starred?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Array<InputMaybe<PatientListStatus>>>;
  subscription?: InputMaybe<Array<InputMaybe<Subscribes_Status>>>;
  timeSpent?: InputMaybe<Array<InputMaybe<InputTimeSpent>>>;
  vitals?: InputMaybe<Array<InputMaybe<VitalTypes>>>;
};

export type InputPhone = {
  areaCode?: InputMaybe<Scalars['String']>;
  canUseForLogin?: InputMaybe<Scalars['Boolean']>;
  countryCode: Scalars['String'];
  number: Scalars['String'];
  private?: InputMaybe<Scalars['Boolean']>;
  type: PhoneNumberType;
};

export type InputProgramCategories = {
  enrolled?: InputMaybe<Scalars['Boolean']>;
  enrolledDate?: InputMaybe<Scalars['Date']>;
  name?: InputMaybe<Scalars['String']>;
};

export type InputRedeemSurveySchema = {
  question: Scalars['String'];
  rating: Scalars['Int'];
};

export type InputReferralStatus = {
  basicInfoEnroll?: InputMaybe<StatusEnum>;
  clinicalGoalsEnroll?: InputMaybe<StatusEnum>;
  confirmDevicesEnroll?: InputMaybe<StatusEnum>;
  enrollStatus?: InputMaybe<StatusEnum>;
  mntReferralEnroll?: InputMaybe<StatusEnum>;
  patientConsentEnroll?: InputMaybe<StatusEnum>;
  prgramEligibilityEnroll?: InputMaybe<StatusEnum>;
  referStatus?: InputMaybe<StatusEnum>;
  scheduleInitVisitEnroll?: InputMaybe<StatusEnum>;
  screeningStatus?: InputMaybe<StatusEnum>;
};

export type InputRemoteEnrollmentCallRecord = {
  callDate: Scalars['Date'];
  isPickedUp?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  providerId: Scalars['EID'];
};

export type InputRemoteTask = {
  onBoardFinished?: InputMaybe<Scalars['Boolean']>;
};

/** ## InputResolution Type */
export type InputResolution = {
  status?: InputMaybe<PesResolution>;
  visitId?: InputMaybe<Scalars['EID']>;
};

/** signedOffBy and signedOffTime are auto-populated and don't need to be passed in */
export type InputSignOffStatus = {
  signOff?: InputMaybe<Scalars['Boolean']>;
  signedOffBy?: InputMaybe<Scalars['EID']>;
  signedOffTime?: InputMaybe<Scalars['Date']>;
  timeSpent?: InputMaybe<Scalars['Int']>;
};

export type InputSmartAlertConfig = {
  alertDisplay?: InputMaybe<Scalars['String']>;
  endDate: Scalars['Date'];
  isValid?: InputMaybe<Scalars['Boolean']>;
  snoozeInterval?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Date'];
  taskType: SmartAlertTaskType;
};

export type InputSmokingHistory = {
  amount?: InputMaybe<Scalars['String']>;
  freq?: InputMaybe<InputSmokingHistoryFreq>;
  quitYear?: InputMaybe<Scalars['String']>;
  startSinceYear?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Boolean']>;
};

export type InputSmokingHistoryFreq = {
  unit?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type InputSocialSupport = {
  communitySupport?: InputMaybe<Scalars['String']>;
  contactInfo?: InputMaybe<Scalars['String']>;
  extraNote?: InputMaybe<Scalars['String']>;
  otherProviders?: InputMaybe<Scalars['String']>;
};

export type InputSpecialistInfo = {
  contact?: InputMaybe<Scalars['String']>;
  seenSpecialist?: InputMaybe<Scalars['Boolean']>;
};

/** ## InputStatement Type */
export type InputStatement = {
  groupId?: InputMaybe<Scalars['EID']>;
  optionId?: InputMaybe<Scalars['EID']>;
  value?: InputMaybe<Scalars['String']>;
};

export type InputSweetBeverageFreq = {
  unit?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type InputTmThreshold = {
  range: Array<InputMaybe<Scalars['Float']>>;
  unit: Scalars['TMUnit'];
};

/**
 * ## Task Input
 *  Add a new task or edit an existing task for a program. EID is optional. If no EID is passed then a new
 *  task will be added. If an EID is passed, the task that matches this EID will be updated.
 */
export type InputTask = {
  assessment?: InputMaybe<InputAssessmentTask>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['EID']>;
  notification?: InputMaybe<Array<InputMaybe<NotificationSettingInput>>>;
  repeat?: InputMaybe<TaskRepeat>;
  schedule?: InputMaybe<Array<InputMaybe<InputTaskSchedule>>>;
  scheduleType?: InputMaybe<Scalars['String']>;
  templateDetails?: InputMaybe<InputTemplateDetails>;
  threshold?: InputMaybe<Array<InputMaybe<AlertThresholdInput>>>;
  type?: InputMaybe<TaskType>;
};

/**
 * Input for changing an enrolled-program's task. For enrolled-programs only; not all task information is configurable.
 * EID is optional because  editEnrolledProgram can add task
 * type is optional only required for add task
 */
export type InputTaskEnrolled = {
  activated?: InputMaybe<Scalars['Boolean']>;
  assessment?: InputMaybe<InputAssessmentTaskEnrolled>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['EID']>;
  notification?: InputMaybe<Array<InputMaybe<NotificationSettingInput>>>;
  repeat?: InputMaybe<TaskRepeat>;
  schedule?: InputMaybe<Array<InputMaybe<InputTaskSchedule>>>;
  scheduleType?: InputMaybe<Scalars['String']>;
  templateDetails?: InputMaybe<InputTemplateDetails>;
  threshold?: InputMaybe<Array<InputMaybe<AlertThresholdInput>>>;
  type?: InputMaybe<TaskType>;
};

export type InputTaskSchedule = {
  beforeMeal?: InputMaybe<TaskScheduleBeforeAfter>;
  calendar?: InputMaybe<Scalars['RepeatFrequency']>;
  end?: InputMaybe<Scalars['Time']>;
  mealType?: InputMaybe<BgMealType>;
  start?: InputMaybe<Scalars['Time']>;
  target?: InputMaybe<Scalars['Int']>;
};

export type InputTemplateDetails = {
  _id?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<InputWeeklyMealSchedule>;
};

export type InputTimeSpent = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type InputUser = {
  address?: InputMaybe<Array<InputMaybe<InputAddress>>>;
  defaultRoleId?: InputMaybe<Scalars['EID']>;
  email?: InputMaybe<Array<InputMaybe<Scalars['EmailAddress']>>>;
  id?: InputMaybe<Scalars['EID']>;
  identification?: InputMaybe<InputIdentification>;
  phone?: InputMaybe<Array<InputMaybe<InputPhone>>>;
  profile?: InputMaybe<InputMemberProfile>;
  residency?: InputMaybe<InputMemberResidency>;
  role?: InputMaybe<Array<InputMaybe<Scalars['Ref']>>>;
  shipAddresses?: InputMaybe<Array<InputMaybe<InputAddress>>>;
  userSettings?: InputMaybe<InputUserSettings>;
  username?: InputMaybe<Scalars['String']>;
};

export type InputUserAge = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type InputUserDeviceRecordCount = {
  Align?: InputMaybe<Scalars['Int']>;
  BG5S?: InputMaybe<Scalars['Int']>;
  Ease?: InputMaybe<Scalars['Int']>;
  Lancets?: InputMaybe<Scalars['Int']>;
  Lina?: InputMaybe<Scalars['Int']>;
  Nexus?: InputMaybe<Scalars['Int']>;
  Oximeter?: InputMaybe<Scalars['Int']>;
  Strips?: InputMaybe<Scalars['Int']>;
  Thermometer?: InputMaybe<Scalars['Int']>;
  TrackSet?: InputMaybe<Scalars['Int']>;
  iPhone?: InputMaybe<Scalars['Int']>;
};

export type InputUserDeviceRecords = {
  Align?: InputMaybe<VisitDeviceRecordStatusEnum>;
  BG5S?: InputMaybe<VisitDeviceRecordStatusEnum>;
  Ease?: InputMaybe<VisitDeviceRecordStatusEnum>;
  Lancets?: InputMaybe<Scalars['Int']>;
  Lina?: InputMaybe<VisitDeviceRecordStatusEnum>;
  Nexus?: InputMaybe<VisitDeviceRecordStatusEnum>;
  Oximeter?: InputMaybe<VisitDeviceRecordStatusEnum>;
  Strips?: InputMaybe<Scalars['Int']>;
  Thermometer?: InputMaybe<VisitDeviceRecordStatusEnum>;
  TrackSet?: InputMaybe<Scalars['Boolean']>;
  iPhone?: InputMaybe<Scalars['Boolean']>;
};

export type InputUserSearch = {
  fields: Array<InputMaybe<InputUserSearchFieldKey>>;
  match: Array<Scalars['String']>;
};

export enum InputUserSearchFieldKey {
  Birthday = 'BIRTHDAY',
  Email = 'EMAIL',
  Employeeid = 'EMPLOYEEID',
  First = 'FIRST',
  FirstLast = 'FIRST_LAST',
  Identification = 'IDENTIFICATION',
  Last = 'LAST',
  Name = 'NAME',
  Phone = 'PHONE'
}

export type InputUserSettings = {
  listeningOrgs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notification?: InputMaybe<Array<InputMaybe<Notification>>>;
  notificationFreq?: InputMaybe<NotificationFreq>;
  preferredUnit?: InputMaybe<PreferredUnits>;
  sendHeartbeatLog?: InputMaybe<Scalars['Boolean']>;
  snapshotInterval?: InputMaybe<Scalars['Int']>;
};

export type InputUserThreshold = {
  range: Array<InputMaybe<Scalars['Float']>>;
  unit: Scalars['String'];
};

export type InputUserThresholdObject = {
  afterMeal?: InputMaybe<InputBgThreshold>;
  beforeMeal?: InputMaybe<InputBgThreshold>;
  bloodOxygen?: InputMaybe<InputPoThreshold>;
  bodyTemperature?: InputMaybe<InputTmThreshold>;
  diastolic?: InputMaybe<InputBpThreshold>;
  heartRate?: InputMaybe<InputHrThreshold>;
  systolic?: InputMaybe<InputBpThreshold>;
  weight?: InputMaybe<InputHsThreshold>;
};

export type InputWeeklyMealSchedule = {
  fri?: InputMaybe<InputDailyMealSchedule>;
  mon?: InputMaybe<InputDailyMealSchedule>;
  sat?: InputMaybe<InputDailyMealSchedule>;
  sun?: InputMaybe<InputDailyMealSchedule>;
  thurs?: InputMaybe<InputDailyMealSchedule>;
  tues?: InputMaybe<InputDailyMealSchedule>;
  wed?: InputMaybe<InputDailyMealSchedule>;
};

export type InputYearOfDiagnose = {
  condition?: InputMaybe<Scalars['String']>;
  diagnosedDate?: InputMaybe<Scalars['Float']>;
};

export type Insurance = {
  __typename?: 'Insurance';
  createdAt?: Maybe<Scalars['Date']>;
  fileInfo?: Maybe<InsuranceFileType>;
  id?: Maybe<Scalars['EID']>;
  member?: Maybe<User>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type InsuranceFileType = {
  __typename?: 'InsuranceFileType';
  fileKey: Scalars['String'];
  link?: Maybe<Scalars['String']>;
};

export type InsuranceProvider = {
  __typename?: 'InsuranceProvider';
  clearingHouse?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  payerId?: Maybe<Scalars['String']>;
  payerName?: Maybe<Scalars['String']>;
};

export type InsuranceProviderFilters = {
  serviceStates?: InputMaybe<Array<InputMaybe<InsuranceProviderServiceStateEnum>>>;
};

/** # default sort field is PAYER_ID, default direction is ASC */
export type InsuranceProviderListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<InsuranceProviderListSortField>;
};

export enum InsuranceProviderListSortField {
  PayerId = 'PAYER_ID',
  PayerName = 'PAYER_NAME'
}

export enum InsuranceProviderServiceStateEnum {
  Ak = 'AK',
  Al = 'AL',
  All = 'ALL',
  Ar = 'AR',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY'
}

/** ## Intervention Note Presets */
export type InterventionPreset = {
  __typename?: 'InterventionPreset';
  category: ProviderNoteCategory;
  display?: Maybe<Scalars['String']>;
  subCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum InventoryHistoryDateRangeEnum {
  BiWeek = 'BI_WEEK',
  Day = 'DAY',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK'
}

/** # Inventory history filter */
export type InventoryHistoryListOpts = {
  dateRange?: InputMaybe<InventoryHistoryDateRangeEnum>;
  fromDate?: InputMaybe<Scalars['Date']>;
  memberId?: InputMaybe<Scalars['EID']>;
  organizationId?: InputMaybe<Scalars['EID']>;
  toDate?: InputMaybe<Scalars['Date']>;
  visitId?: InputMaybe<Scalars['EID']>;
};

export type InventoryHistoryListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<InventoryHistoryListSortField>;
};

export enum InventoryHistoryListSortField {
  CreatedAt = 'CREATED_AT'
}

/** ## InventoryHistoryType type */
export type InventoryHistoryType = {
  __typename?: 'InventoryHistoryType';
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  member?: Maybe<User>;
  organization?: Maybe<Organization>;
  product?: Maybe<InventoryProduct>;
  visit?: Maybe<Visit>;
};

/** ## InventoryProduct type */
export type InventoryProduct = {
  __typename?: 'InventoryProduct';
  description?: Maybe<Scalars['String']>;
  productType?: Maybe<ProductTypeEnum>;
  quantity?: Maybe<Scalars['Int']>;
};

/** ## Inventory product input type */
export type InventoryProductInput = {
  description?: InputMaybe<Scalars['String']>;
  productType: ProductTypeEnum;
  quantity: Scalars['Int'];
};

export enum InventoryReportGroupByEnum {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK'
}

/** ## InventoryReportType type */
export type InventoryReportType = {
  __typename?: 'InventoryReportType';
  date?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<ProductReportType>>>;
};

/** # Inventory transaction filter */
export type InventoryTransactionListOpts = {
  fromDate?: InputMaybe<Scalars['Date']>;
  memberId?: InputMaybe<Scalars['EID']>;
  organizationId?: InputMaybe<Scalars['EID']>;
  toDate?: InputMaybe<Scalars['Date']>;
  visitId?: InputMaybe<Scalars['EID']>;
};

export type InventoryTransactionListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<InventoryTransactionListSortField>;
};

export enum InventoryTransactionListSortField {
  CreatedAt = 'CREATED_AT'
}

/** ## InventoryTransactionType type */
export type InventoryTransactionType = {
  __typename?: 'InventoryTransactionType';
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  inventoryHistories?: Maybe<Array<Maybe<InventoryHistoryType>>>;
  member?: Maybe<User>;
  note?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  visit?: Maybe<Visit>;
};

/** ## InventoryType type */
export type InventoryType = {
  __typename?: 'InventoryType';
  id?: Maybe<Scalars['EID']>;
  organization?: Maybe<Organization>;
  products?: Maybe<Array<Maybe<InventoryProduct>>>;
};

export enum IoTBpStandardEnum {
  J = 'J',
  W = 'W'
}

export enum IoTBpUnitEnum {
  M = 'm',
  P = 'p'
}

export enum IoTDateFormatEnum {
  C = 'C',
  E = 'E',
  U = 'U'
}

/**
 * ## Device types
 *  Supported types are: BP and HS
 */
export type IoTDevice = {
  __typename?: 'IoTDevice';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<IoTDeviceTypeEnum>;
  unit?: Maybe<Scalars['String']>;
};

/** ## IoTDeviceConfigType type */
export type IoTDeviceConfigType = {
  __typename?: 'IoTDeviceConfigType';
  battery?: Maybe<Scalars['Float']>;
  bpStandard?: Maybe<Scalars['String']>;
  bpUnit?: Maybe<IoTBpUnitEnum>;
  countryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  dateFormat?: Maybe<IoTDateFormatEnum>;
  deviceId?: Maybe<Scalars['String']>;
  firmwareVersion?: Maybe<Scalars['String']>;
  hardwareVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  lastSeenAt?: Maybe<Scalars['Date']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  protocol?: Maybe<Scalars['String']>;
  temperatureUnit?: Maybe<IoTTemperatureUnitEnum>;
  timbre?: Maybe<Scalars['Int']>;
  timeFormat?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  volume?: Maybe<Scalars['Int']>;
};

/** ## IoTDeviceHistoryType type */
export type IoTDeviceHistoryType = {
  __typename?: 'IoTDeviceHistoryType';
  action?: Maybe<Scalars['String']>;
  member?: Maybe<User>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
};

/**
 * ## Device types
 *  Supported types are: BP and HS
 *  For unit, kg or lb can be used for HS, default is lb (pound)
 */
export type IoTDeviceInput = {
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<IoTDeviceTypeEnum>;
  unit?: InputMaybe<Scalars['String']>;
};

/** # IoT Device Config filter */
export type IoTDeviceListConfigOpts = {
  deviceStatusFilter?: InputMaybe<DeviceStatusFilterEnum>;
};

export type IoTDeviceListConfigSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<IoTDeviceListConfigSortField>;
};

export enum IoTDeviceListConfigSortField {
  CreatedAt = 'CREATED_AT'
}

/** # IoT Device filter */
export type IoTDeviceListOpts = {
  deviceStatusFilter?: InputMaybe<DeviceStatusFilterEnum>;
  memberId?: InputMaybe<Scalars['EID']>;
};

export type IoTDeviceListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<IoTDeviceListSortField>;
};

export enum IoTDeviceListSortField {
  CreatedAt = 'CREATED_AT'
}

/** ## IoTDeviceType type */
export type IoTDeviceType = {
  __typename?: 'IoTDeviceType';
  accessedAt?: Maybe<Scalars['Date']>;
  config?: Maybe<IoTDeviceConfigType>;
  createdAt?: Maybe<Scalars['Date']>;
  deviceId?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<IoTDeviceHistoryType>>>;
  id?: Maybe<Scalars['EID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  member?: Maybe<User>;
  position?: Maybe<Scalars['Int']>;
  quittedAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export enum IoTDeviceTypeEnum {
  Bp = 'BP',
  Hs = 'HS'
}

export enum IoTTemperatureUnitEnum {
  C = 'c',
  F = 'f'
}

export type LabResultTemplates = {
  __typename?: 'LabResultTemplates';
  _id?: Maybe<Scalars['MongoID']>;
  createdAt?: Maybe<Scalars['Date']>;
  items?: Maybe<Array<Maybe<LabResultTemplatesItems>>>;
  templateId?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['MongoID']>;
  viewerId?: Maybe<Scalars['MongoID']>;
};

export type LabResultTemplatesItems = {
  __typename?: 'LabResultTemplatesItems';
  _id?: Maybe<Scalars['MongoID']>;
  interval?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type LabResultTemplatesLabResultTemplatesItemsInput = {
  _id?: InputMaybe<Scalars['MongoID']>;
  interval?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type LabResults = {
  __typename?: 'LabResults';
  _id?: Maybe<Scalars['MongoID']>;
  createdAt?: Maybe<Scalars['Date']>;
  dateCollected?: Maybe<Scalars['Date']>;
  dateReceived?: Maybe<Scalars['Date']>;
  dateReported?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  labRef?: Maybe<Scalars['String']>;
  requisition?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<LabResultsResults>>>;
  specimen?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['MongoID']>;
  viewerId?: Maybe<Scalars['MongoID']>;
};

/** A connection to a list of items. */
export type LabResultsConnection = {
  __typename?: 'LabResultsConnection';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<LabResultsEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LabResultsEdge = {
  __typename?: 'LabResultsEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['ConnectionCursor'];
  /** The item at the end of the edge */
  node?: Maybe<LabResults>;
};

export type LabResultsLabResultsResultsInput = {
  _id?: InputMaybe<Scalars['MongoID']>;
  dbKey?: InputMaybe<Scalars['String']>;
  flag?: InputMaybe<Scalars['String']>;
  interval?: InputMaybe<Scalars['String']>;
  isInHouse?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** List of items with pagination. */
export type LabResultsPagination = {
  __typename?: 'LabResultsPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<LabResults>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type LabResultsResults = {
  __typename?: 'LabResultsResults';
  _id?: Maybe<Scalars['MongoID']>;
  dbKey?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['String']>;
  interval?: Maybe<Scalars['String']>;
  isInHouse?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type LabTestResultType = {
  __typename?: 'LabTestResultType';
  dbKey?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  interval?: Maybe<Scalars['String']>;
  isInHouse?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type LabTestType = {
  __typename?: 'LabTestType';
  createdAt?: Maybe<Scalars['Date']>;
  dateCollected?: Maybe<Scalars['Date']>;
  dateReceived?: Maybe<Scalars['Date']>;
  dateReported?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['EID']>;
  labRef?: Maybe<Scalars['String']>;
  member?: Maybe<User>;
  requisition?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<LabTestResultType>>>;
  specimen?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  viewer?: Maybe<User>;
};

export enum Language {
  Ah = 'AH',
  Ak = 'AK',
  Al = 'AL',
  Am = 'AM',
  An = 'AN',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Bg = 'BG',
  Bi = 'BI',
  Bj = 'BJ',
  Bn = 'BN',
  Bt = 'BT',
  Bu = 'BU',
  By = 'BY',
  Ca = 'CA',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cr = 'CR',
  Ct = 'CT',
  Cw = 'CW',
  Cz = 'CZ',
  Da = 'DA',
  Ds = 'DS',
  Du = 'DU',
  Dy = 'DY',
  El = 'EL',
  Fa = 'FA',
  Fi = 'FI',
  Fl = 'FL',
  Fo = 'FO',
  Fr = 'FR',
  Ge = 'GE',
  Gh = 'GH',
  Gm = 'GM',
  Go = 'GO',
  Gr = 'GR',
  Gu = 'GU',
  Ha = 'HA',
  Hb = 'HB',
  Hc = 'HC',
  Hd = 'HD',
  He = 'HE',
  Hh = 'HH',
  Hi = 'HI',
  Hk = 'HK',
  Hn = 'HN',
  Ht = 'HT',
  Hu = 'HU',
  Ib = 'IB',
  Ic = 'IC',
  Id = 'ID',
  Ig = 'IG',
  Ih = 'IH',
  Ir = 'IR',
  It = 'IT',
  Ja = 'JA',
  Jf = 'JF',
  Jp = 'JP',
  Ka = 'KA',
  Kb = 'KB',
  Ke = 'KE',
  Kh = 'KH',
  Ki = 'KI',
  Kk = 'KK',
  Kl = 'KL',
  Km = 'KM',
  Kn = 'KN',
  Ko = 'KO',
  Kr = 'KR',
  Ks = 'KS',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lh = 'LH',
  Lu = 'LU',
  Ma = 'MA',
  Mb = 'MB',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mi = 'MI',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mr = 'MR',
  Mt = 'MT',
  My = 'MY',
  Mz = 'MZ',
  Ne = 'NE',
  No = 'NO',
  Np = 'NP',
  Nw = 'NW',
  Or = 'OR',
  Pe = 'PE',
  Ph = 'PH',
  Pi = 'PI',
  Pj = 'PJ',
  Pl = 'PL',
  Pn = 'PN',
  Po = 'PO',
  Pp = 'PP',
  Pu = 'PU',
  Rk = 'RK',
  Ro = 'RO',
  Ru = 'RU',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sl = 'SL',
  Sn = 'SN',
  Sp = 'SP',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sw = 'SW',
  Ta = 'TA',
  Tc = 'TC',
  Te = 'TE',
  Th = 'TH',
  Ti = 'TI',
  Tm = 'TM',
  To = 'TO',
  Tu = 'TU',
  Tw = 'TW',
  Un = 'UN',
  Ur = 'UR',
  Vn = 'VN',
  Vs = 'VS',
  Wc = 'WC',
  We = 'WE',
  Xx = 'XX',
  Yi = 'YI'
}

export type LanguageCode = {
  __typename?: 'LanguageCode';
  code?: Maybe<Language>;
  description?: Maybe<Scalars['String']>;
};

export type LimitedOrg = {
  __typename?: 'LimitedOrg';
  id?: Maybe<Scalars['EID']>;
  internalName?: Maybe<Scalars['String']>;
};

/** ## Limited (mini) provider note */
export type LimitedProviderNote = {
  __typename?: 'LimitedProviderNote';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<LimitedUser>;
  id?: Maybe<Scalars['EID']>;
};

export type LimitedUser = {
  __typename?: 'LimitedUser';
  avatar?: Maybe<ImageType>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  lastName?: Maybe<Scalars['String']>;
};

/** ## LoanDeviceEditInput input type for editting loan device */
export type LoanDeviceEditInput = {
  appVersion?: InputMaybe<Scalars['String']>;
  deviceType?: InputMaybe<LoanDeviceTypeEnum>;
  osVersion?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DeviceStatusEnum>;
};

/** ## LoanDeviceHistoryType type */
export type LoanDeviceHistoryType = {
  __typename?: 'LoanDeviceHistoryType';
  action?: Maybe<Scalars['String']>;
  member?: Maybe<User>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
};

/** ## LoanDeviceInput input type for creating loan device */
export type LoanDeviceInput = {
  appVersion?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  deviceType?: InputMaybe<LoanDeviceTypeEnum>;
  memberId?: InputMaybe<Scalars['EID']>;
  osVersion?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DeviceStatusEnum>;
};

/** # Loan device filter */
export type LoanDeviceListOpts = {
  status?: InputMaybe<LoanStatusFilterEnum>;
};

export type LoanDeviceListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<LoanDeviceListSortField>;
};

export enum LoanDeviceListSortField {
  CreatedAt = 'CREATED_AT'
}

/** ## LoanDeviceType type */
export type LoanDeviceType = {
  __typename?: 'LoanDeviceType';
  appVersion?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  deviceId?: Maybe<Scalars['String']>;
  deviceType?: Maybe<LoanDeviceTypeEnum>;
  history?: Maybe<Array<Maybe<LoanDeviceHistoryType>>>;
  member?: Maybe<User>;
  osVersion?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  status?: Maybe<DeviceStatusEnum>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export enum LoanDeviceTypeEnum {
  IPad = 'iPad',
  IPhone = 'iPhone'
}

export enum LoanStatusFilterEnum {
  All = 'ALL',
  Available = 'AVAILABLE',
  InUse = 'IN_USE'
}

export enum MvpAnswers {
  BetterOption = 'BETTER_OPTION',
  NotHelpful = 'NOT_HELPFUL',
  NoNeed = 'NO_NEED',
  TooPricy = 'TOO_PRICY'
}

export enum MaritalStatus {
  Divorced = 'DIVORCED',
  Married = 'MARRIED',
  Not = 'NOT',
  Reported = 'REPORTED',
  Separated = 'SEPARATED',
  Single = 'SINGLE',
  Widowed = 'WIDOWED'
}

export type MaritalStatusCode = {
  __typename?: 'MaritalStatusCode';
  code?: Maybe<MaritalStatus>;
  description?: Maybe<Scalars['String']>;
};

export type MealIntake = {
  __typename?: 'MealIntake';
  comments?: Maybe<Scalars['String']>;
  foodTypeAmount?: Maybe<Scalars['String']>;
  meal?: Maybe<Scalars['String']>;
  mealFreq?: Maybe<Scalars['String']>;
  skipFreq?: Maybe<Scalars['String']>;
  timeRange?: Maybe<Scalars['String']>;
};

export enum MealTypeEnum {
  Bedtime = 'BEDTIME',
  Breakfast = 'BREAKFAST',
  Dinner = 'DINNER',
  Lunch = 'LUNCH',
  Other = 'OTHER',
  Overnight = 'OVERNIGHT',
  Snack = 'SNACK'
}

/** Interface for all measurement data. */
export type Measure = {
  date?: Maybe<Scalars['Date']>;
  deviceAddress?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  /**
   * hadMedicine: Boolean added to support hadMedicine flag
   * hadActivity: Boolean added to support hadActivity flag
   * hadInsulin: Boolean added to support hadInsulin flag
   * tags:[String] added to support mood tags
   */
  id?: Maybe<Scalars['EID']>;
  imageUrl?: Maybe<Scalars['String']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measureBy?: Maybe<User>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  resultId?: Maybe<Scalars['EID']>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<Vital>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
};

/** Interface for all measurement data. */
export type MeasureAudit = {
  date?: Maybe<Scalars['Date']>;
  deviceAddress?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  /**
   * hadMedicine: Boolean added to support hadMedicine flag
   * hadActivity: Boolean added to support hadActivity flag
   * hadInsulin: Boolean added to support hadInsulin flag
   * tags:[String] added to support mood tags
   */
  id?: Maybe<Scalars['EID']>;
  imageUrl?: Maybe<Scalars['String']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measureBy?: Maybe<User>;
  measurementSession?: Maybe<AuditRecord>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  networkSpeed?: Maybe<Scalars['String']>;
  networkType?: Maybe<Scalars['String']>;
  phoneName?: Maybe<Scalars['String']>;
  phoneSessionRecord?: Maybe<AuditRecord>;
  resultId?: Maybe<Scalars['EID']>;
  sessionId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<Vital>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
};

export type MeasureCounts = {
  __typename?: 'MeasureCounts';
  BG?: Maybe<Scalars['Int']>;
  BP?: Maybe<Scalars['Int']>;
  ECG?: Maybe<Scalars['Int']>;
  HS?: Maybe<Scalars['Int']>;
  PO?: Maybe<Scalars['Int']>;
};

export type MeasureCursors = {
  __typename?: 'MeasureCursors';
  edges?: Maybe<Array<Maybe<MeasureEdge>>>;
  pageInfo?: Maybe<CursorInfo>;
};

export type MeasureEdge = {
  __typename?: 'MeasureEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Measure>;
};

export type MeasureListFilters = {
  memberId?: InputMaybe<Scalars['EID']>;
  type?: InputMaybe<Array<InputMaybe<Vital>>>;
};

export type MeasureListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<MeasureListSortField>;
};

export enum MeasureListSortField {
  Date = 'DATE'
}

export type MeasureTrend = {
  __typename?: 'MeasureTrend';
  data?: Maybe<Array<Maybe<TrendDataGroup>>>;
  groupBy?: Maybe<TrendGroupBy>;
  nextDate?: Maybe<Scalars['Date']>;
  previousDate?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
};

export type MeasureTrendsFilters = {
  fromLastMeasure?: InputMaybe<Scalars['Boolean']>;
  memberId: Scalars['EID'];
  timePeriod?: InputMaybe<TrendLength>;
  type: Vital;
};

export enum MeasureType {
  AfterMeal = 'AFTER_MEAL',
  BeforeMeal = 'BEFORE_MEAL',
  BloodOxygen = 'BLOOD_OXYGEN',
  BodyTemperature = 'BODY_TEMPERATURE',
  Diastolic = 'DIASTOLIC',
  EcgHeartRate = 'ECG_HEART_RATE',
  Exercise = 'EXERCISE',
  HeartRate = 'HEART_RATE',
  Systolic = 'SYSTOLIC',
  Weight = 'WEIGHT',
  WeightChange = 'WEIGHT_CHANGE',
  WeightChangeBaseline = 'WEIGHT_CHANGE_BASELINE'
}

/** ## Medication type */
export type Medication = {
  __typename?: 'Medication';
  active?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  dosage?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  healthCondition?: Maybe<HealthConditionEnum>;
  histories?: Maybe<Array<Maybe<MedicationHistory>>>;
  id?: Maybe<Scalars['EID']>;
  instruction?: Maybe<Scalars['String']>;
  member?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  otherMD?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<MedicationStatus>;
  type?: Maybe<MedicationTypeEnum>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type MedicationHistory = {
  __typename?: 'MedicationHistory';
  active?: Maybe<Scalars['Boolean']>;
  dosage?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  newValue?: Maybe<Scalars['String']>;
  originalValue?: Maybe<Scalars['String']>;
};

export type MedicationListOpts = {
  active?: InputMaybe<Scalars['Boolean']>;
  memberId?: InputMaybe<Scalars['EID']>;
  types?: InputMaybe<Array<InputMaybe<MedicationTypeEnum>>>;
};

export type MedicationListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<MedicationListSortField>;
};

export enum MedicationListSortField {
  CreatedAt = 'CREATED_AT',
  EndDate = 'END_DATE',
  Name = 'NAME',
  StartDate = 'START_DATE'
}

export type MedicationManagement = {
  __typename?: 'MedicationManagement';
  extraNote?: Maybe<Scalars['String']>;
  otherInfo?: Maybe<Scalars['String']>;
  reconcilationCompliance?: Maybe<Scalars['String']>;
};

export enum MedicationStatus {
  NotSure = 'NOT_SURE',
  NotTaking = 'NOT_TAKING',
  Taking = 'TAKING',
  TakingIrregularly = 'TAKING_IRREGULARLY'
}

export enum MedicationTypeEnum {
  External = 'EXTERNAL',
  Inhaled = 'INHALED',
  Injectable = 'INJECTABLE',
  Oral = 'ORAL'
}

export type MemberAvailability = {
  __typename?: 'MemberAvailability';
  id?: Maybe<Scalars['String']>;
  overlapVisits?: Maybe<Array<Maybe<OverlapVisit>>>;
};

/**
 * ## Profile for normal members/patients.
 *    Billable health Conditions: selected health conditions for billing
 */
export type MemberProfile = UserProfile & {
  __typename?: 'MemberProfile';
  BGMedicine?: Maybe<Scalars['Boolean']>;
  BGOther?: Maybe<Array<Maybe<Scalars['String']>>>;
  BGSpecialistInfo?: Maybe<SpecialistInfo>;
  BGTest?: Maybe<BgTest>;
  BGTreatments?: Maybe<Array<Maybe<Scalars['String']>>>;
  BGValidationFlag?: Maybe<Scalars['Boolean']>;
  BGVisits?: Maybe<Scalars['Boolean']>;
  BPSpecialistInfo?: Maybe<SpecialistInfo>;
  BPTest?: Maybe<BpTest>;
  BPTreatments?: Maybe<Array<Maybe<Scalars['String']>>>;
  BPValidationFlag?: Maybe<Scalars['Boolean']>;
  BPVisits?: Maybe<Scalars['Boolean']>;
  activityLevel?: Maybe<ActivityLevel>;
  appLanguage?: Maybe<LanguageCode>;
  avatar?: Maybe<ImageType>;
  bgDailyMeasureTimes?: Maybe<Scalars['BGDailyMeasureTimes']>;
  billableHealthConditions?: Maybe<Array<Maybe<BillableHealthCondition>>>;
  billingStatus?: Maybe<BillingStatusType>;
  birthday?: Maybe<Scalars['CalDate']>;
  callLogFlag?: Maybe<Scalars['Boolean']>;
  cardiovascularDisease?: Maybe<Array<Maybe<Scalars['String']>>>;
  chartingNote?: Maybe<Scalars['String']>;
  chronicDiseaseHistory?: Maybe<Array<Maybe<ChronicDisease>>>;
  chronicDiseaseNote?: Maybe<Scalars['String']>;
  claimedTimeLogFlag?: Maybe<Scalars['Boolean']>;
  conditionsList?: Maybe<ConditionsListType>;
  deviceRecords?: Maybe<UserDeviceRecordsType>;
  diagnosis?: Maybe<Scalars['String']>;
  dietHabits?: Maybe<DietHabits>;
  doctor?: Maybe<User>;
  doctorReferral?: Maybe<DoctorReferral>;
  drinkingHistory?: Maybe<DrinkingHistory>;
  eatingRoutine?: Maybe<EatingRoutine>;
  economicStatus?: Maybe<Scalars['String']>;
  education?: Maybe<Education>;
  educationLevel?: Maybe<EducationLevel>;
  emrList?: Maybe<EmrList>;
  enrollmentNote?: Maybe<Scalars['String']>;
  exerciseHabits?: Maybe<ExerciseHabits>;
  fatherMedicalHistory?: Maybe<Array<Maybe<Scalars['String']>>>;
  firstName?: Maybe<Scalars['String']>;
  foodAccessibility?: Maybe<FoodAccessibility>;
  fullName?: Maybe<Scalars['String']>;
  fullNameWithNickname?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Gender']>;
  handouts?: Maybe<Array<Maybe<Handout>>>;
  hasSmartPhone?: Maybe<Scalars['Boolean']>;
  healthConditions?: Maybe<Array<Maybe<Scalars['String']>>>;
  heartAttack?: Maybe<HeartAttack>;
  height?: Maybe<Scalars['HTValue']>;
  insulinDependent?: Maybe<Scalars['Boolean']>;
  insuranceProvider?: Maybe<Scalars['String']>;
  insuranceProvider2?: Maybe<Scalars['String']>;
  insuranceProviders?: Maybe<Array<Maybe<PatientInsuranceProvider>>>;
  insuranceUpdatedAt?: Maybe<Scalars['Date']>;
  isSelfEnrolled?: Maybe<Scalars['Boolean']>;
  kidneyDisease?: Maybe<Scalars['String']>;
  language?: Maybe<LanguageCode>;
  lastFoodLogAt?: Maybe<Scalars['Date']>;
  lastMeasuredAt?: Maybe<Scalars['Date']>;
  lastMeasuredByType?: Maybe<LastMeasuredByType>;
  lastName?: Maybe<Scalars['String']>;
  latestWeight?: Maybe<Scalars['HSValue']>;
  lifestyleNote?: Maybe<Scalars['String']>;
  lifestylePattern?: Maybe<Scalars['String']>;
  liverDisease?: Maybe<Array<Maybe<Scalars['String']>>>;
  maritalStatus?: Maybe<MaritalStatusCode>;
  maternalFatherMedicalHistory?: Maybe<Array<Maybe<Scalars['String']>>>;
  maternalMotherMedicalHistory?: Maybe<Array<Maybe<Scalars['String']>>>;
  maternalUnclesOrAuntsMedicalHistory?: Maybe<Array<Maybe<Scalars['String']>>>;
  medicationManagement?: Maybe<MedicationManagement>;
  mntHealthConditions?: Maybe<Array<Maybe<BillableHealthCondition>>>;
  motherMedicalHistory?: Maybe<Array<Maybe<Scalars['String']>>>;
  motivation?: Maybe<Motivation>;
  nationality?: Maybe<NationalityCode>;
  nickname?: Maybe<Scalars['String']>;
  nutrition?: Maybe<Nutrition>;
  occupation?: Maybe<Scalars['String']>;
  paternalFatherMedicalHistory?: Maybe<Array<Maybe<Scalars['String']>>>;
  paternalMotherMedicalHistory?: Maybe<Array<Maybe<Scalars['String']>>>;
  paternalUnclesOrAuntsMedicalHistory?: Maybe<Array<Maybe<Scalars['String']>>>;
  programCategories?: Maybe<Array<Maybe<ProgramCategories>>>;
  providerSummary?: Maybe<Scalars['String']>;
  race?: Maybe<RaceCode>;
  race1?: Maybe<Race1Code>;
  retinopathy?: Maybe<Array<Maybe<Scalars['String']>>>;
  routine?: Maybe<Routine>;
  siblingMedicalHistory?: Maybe<Array<Maybe<Scalars['String']>>>;
  sleepFreq?: Maybe<SleepFreq>;
  sleepingRoutine?: Maybe<SleepingRoutine>;
  smokingHistory?: Maybe<SmokingHistory>;
  socialSupport?: Maybe<SocialSupport>;
  spouseMedicalHistory?: Maybe<Array<Maybe<Scalars['String']>>>;
  stroke?: Maybe<Array<Maybe<Scalars['String']>>>;
  subscription?: Maybe<SubscriptionType>;
  techLevel?: Maybe<TechLevel>;
  title?: Maybe<UserTitle>;
  totalFoodlogs?: Maybe<Scalars['Int']>;
  totalMeasurements?: Maybe<Scalars['Int']>;
  visitCheckinFlag?: Maybe<Scalars['Boolean']>;
  weight?: Maybe<Scalars['HSValue']>;
  workDuties?: Maybe<Scalars['String']>;
  yearOfDiagnose?: Maybe<Array<Maybe<YearOfDiagnose>>>;
};

export type MemberResidency = {
  __typename?: 'MemberResidency';
  admissionDate?: Maybe<Scalars['Date']>;
  building?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
};

export type MemberSchedule = {
  __typename?: 'MemberSchedule';
  id?: Maybe<Scalars['String']>;
  visits?: Maybe<Array<Maybe<MemberVisit>>>;
};

/**
 * ## Program Count
 *  Summary numbers for a program.
 */
export type MemberSummary = {
  __typename?: 'MemberSummary';
  discharged?: Maybe<Scalars['Int']>;
  enrolled?: Maybe<Scalars['Int']>;
  nearDischarge?: Maybe<Scalars['Int']>;
  suspended?: Maybe<Scalars['Int']>;
};

export type MemberVisit = {
  __typename?: 'MemberVisit';
  appointmentAt?: Maybe<Scalars['Date']>;
  appointmentTo?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['EID']>;
  organization?: Maybe<LimitedOrg>;
  type?: Maybe<VisitTypeEnum>;
};

export type Mentor = {
  __typename?: 'Mentor';
  healthCoach?: Maybe<User>;
  id?: Maybe<Scalars['EID']>;
  mentor?: Maybe<User>;
};

export type MicroClassInfo = {
  __typename?: 'MicroClassInfo';
  content?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  fileUrl?: Maybe<Scalars['String']>;
  previewDuration?: Maybe<Scalars['Int']>;
};

export type MiniDoctor = {
  __typename?: 'MiniDoctor';
  id?: Maybe<Scalars['EID']>;
  name?: Maybe<Scalars['String']>;
};

/** Minimal data for a user-enrolled program */
export type MiniEnrolledProgram = {
  __typename?: 'MiniEnrolledProgram';
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  healthCondition?: Maybe<HealthCondition>;
  id?: Maybe<Scalars['EID']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<EnrolledProgramStatus>;
  taskAssignmentCount?: Maybe<Scalars['Int']>;
  tasks?: Maybe<Array<Maybe<Task>>>;
  team?: Maybe<Team>;
};

export type MissingInfo = {
  __typename?: 'MissingInfo';
  id?: Maybe<Scalars['EID']>;
  memberId?: Maybe<Scalars['EID']>;
  missingTasks?: Maybe<Array<Maybe<MissingInfoType>>>;
  status?: Maybe<MissingInfoStatus>;
};

export type MissingInfoListOpts = {
  memberId: Scalars['EID'];
  status?: InputMaybe<Scalars['String']>;
};

export enum MissingInfoStatus {
  Done = 'DONE',
  Init = 'INIT'
}

export enum MissingInfoType {
  DuplicateMrn = 'DUPLICATE_MRN',
  IcdNotMatch = 'ICD_NOT_MATCH',
  MissingInsurance = 'MISSING_INSURANCE',
  MissingMrn = 'MISSING_MRN',
  NoIcdCode = 'NO_ICD_CODE',
  NoProgramSelection = 'NO_PROGRAM_SELECTION',
  SipInsurance = 'SIP_INSURANCE'
}

export enum MntProgramStatusEnum {
  Agreed = 'AGREED',
  Ineligible = 'INELIGIBLE',
  Potential = 'POTENTIAL',
  Referred = 'REFERRED',
  Rejected = 'REJECTED'
}

export type MobileInfo = {
  __typename?: 'MobileInfo';
  appVersion?: Maybe<Scalars['String']>;
  latestAppVersion?: Maybe<Scalars['String']>;
  phoneId: Scalars['String'];
  phoneModel?: Maybe<Scalars['String']>;
  phoneName?: Maybe<Scalars['String']>;
  phoneOSVersion?: Maybe<Scalars['String']>;
};

export type ModifiedVersionHistory = {
  __typename?: 'ModifiedVersionHistory';
  date?: Maybe<Scalars['Date']>;
  itemsUpdated?: Maybe<Scalars['String']>;
  updateType?: Maybe<UpdateTypeEnum>;
  user?: Maybe<User>;
};

/** ## MonthlyReview type */
export type MonthlyReview = {
  __typename?: 'MonthlyReview';
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  dueDate?: Maybe<Scalars['Date']>;
  goals?: Maybe<Array<Maybe<MonthlyReviewGoal>>>;
  id?: Maybe<Scalars['EID']>;
  member?: Maybe<User>;
  memberId?: Maybe<Scalars['EID']>;
  monthOfYear?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['EID']>;
  preSummary?: Maybe<MonthlyReviewSummary>;
  previousGoals?: Maybe<Array<Maybe<ClinicGoal>>>;
  previousVitals?: Maybe<Array<Maybe<VitalCount>>>;
  reviewedBy?: Maybe<User>;
  signedOffAt?: Maybe<Scalars['Date']>;
  signedOffBy?: Maybe<User>;
  status?: Maybe<MonthlyReviewStatus>;
  teamId?: Maybe<Scalars['EID']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

/** ## MonthlyReviewContent */
export type MonthlyReviewContent = {
  __typename?: 'MonthlyReviewContent';
  condition?: Maybe<ConditionEnum>;
  goals?: Maybe<Array<Maybe<ClinicGoal>>>;
  vitals?: Maybe<Array<Maybe<VitalCount>>>;
};

export type MonthlyReviewGoalMeeting = {
  __typename?: 'MonthlyReviewGoalMeeting';
  conditionEnum?: Maybe<ConditionEnum>;
  goalAssessment?: Maybe<Array<Maybe<GoalAssessment>>>;
  goalEnum?: Maybe<ClinicGoalEnum>;
  meetingStatus?: Maybe<GoalMeetingStatusEnum>;
};

export enum MonthlyReviewGoalStatusType {
  Meeting = 'MEETING',
  NotMeeting = 'NOT_MEETING',
  UnableToConclude = 'UNABLE_TO_CONCLUDE'
}

export type MonthlyReviewListForDoctorOpts = {
  monthOfYear?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<MonthlyReviewStatus>;
  type?: InputMaybe<MonthlyReviewType>;
};

export type MonthlyReviewListOpts = {
  includeDraft?: InputMaybe<Scalars['Boolean']>;
  memberId: Scalars['EID'];
  monthOfYear?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<MonthlyReviewStatus>;
  type?: InputMaybe<MonthlyReviewType>;
};

export type MonthlyReviewListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<MonthlyReviewListSortField>;
};

export enum MonthlyReviewListSortField {
  CreatedAt = 'CREATED_AT'
}

export type MonthlyReviewRecommendation = {
  __typename?: 'MonthlyReviewRecommendation';
  count?: Maybe<Scalars['Int']>;
  recommend?: Maybe<Scalars['String']>;
  vital?: Maybe<Scalars['String']>;
};

export enum MonthlyReviewStatus {
  Done = 'DONE',
  Draft = 'DRAFT',
  Init = 'INIT'
}

export type MonthlyReviewSummary = {
  __typename?: 'MonthlyReviewSummary';
  BG?: Maybe<Array<Maybe<MonthlyReviewSummaryType>>>;
  BP?: Maybe<Array<Maybe<MonthlyReviewSummaryType>>>;
  EXERCISE?: Maybe<Array<Maybe<MonthlyReviewSummaryType>>>;
  HS?: Maybe<Array<Maybe<MonthlyReviewSummaryType>>>;
  PO?: Maybe<Array<Maybe<MonthlyReviewSummaryType>>>;
  TM?: Maybe<Array<Maybe<MonthlyReviewSummaryType>>>;
};

export type MonthlyReviewSummaryAverageInput = {
  average?: InputMaybe<Scalars['String']>;
  diastolic?: InputMaybe<Scalars['String']>;
  systolic?: InputMaybe<Scalars['String']>;
};

export type MonthlyReviewSummaryAverageType = {
  __typename?: 'MonthlyReviewSummaryAverageType';
  average?: Maybe<Scalars['String']>;
  diastolic?: Maybe<Scalars['String']>;
  systolic?: Maybe<Scalars['String']>;
};

export type MonthlyReviewSummaryInput = {
  BG?: InputMaybe<Array<InputMaybe<MonthlyReviewSummaryTypeInput>>>;
  BP?: InputMaybe<Array<InputMaybe<MonthlyReviewSummaryTypeInput>>>;
  EXERCISE?: InputMaybe<Array<InputMaybe<MonthlyReviewSummaryTypeInput>>>;
  HS?: InputMaybe<Array<InputMaybe<MonthlyReviewSummaryTypeInput>>>;
  PO?: InputMaybe<Array<InputMaybe<MonthlyReviewSummaryTypeInput>>>;
  TM?: InputMaybe<Array<InputMaybe<MonthlyReviewSummaryTypeInput>>>;
};

export type MonthlyReviewSummaryScheduleType = {
  __typename?: 'MonthlyReviewSummaryScheduleType';
  target?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
};

export type MonthlyReviewSummaryScheduleTypeInput = {
  target?: InputMaybe<Scalars['Int']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type MonthlyReviewSummaryType = {
  __typename?: 'MonthlyReviewSummaryType';
  beforeMeal?: Maybe<Scalars['Boolean']>;
  mealType?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Int']>;
  popUp?: Maybe<Scalars['Boolean']>;
  previous2To4Week?: Maybe<MonthlyReviewSummaryAverageType>;
  previous4To8Week?: Maybe<MonthlyReviewSummaryAverageType>;
  recent2Week?: Maybe<MonthlyReviewSummaryAverageType>;
  schedule?: Maybe<MonthlyReviewSummaryScheduleType>;
  timeSlots?: Maybe<Scalars['String']>;
  type?: Maybe<VitalSummary>;
  unit?: Maybe<Scalars['String']>;
  vital?: Maybe<Vital>;
};

export type MonthlyReviewSummaryTypeInput = {
  beforeMeal?: InputMaybe<Scalars['Boolean']>;
  mealType?: InputMaybe<Scalars['String']>;
  percentage?: InputMaybe<Scalars['Int']>;
  popUp?: InputMaybe<Scalars['Boolean']>;
  previous2To4Week?: InputMaybe<MonthlyReviewSummaryAverageInput>;
  previous4To8Week?: InputMaybe<MonthlyReviewSummaryAverageInput>;
  recent2Week?: InputMaybe<MonthlyReviewSummaryAverageInput>;
  schedule?: InputMaybe<MonthlyReviewSummaryScheduleTypeInput>;
  timeSlots?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  vital?: InputMaybe<Vital>;
};

export enum MonthlyReviewType {
  Mre = 'MRE',
  MreAdditional = 'MRE_ADDITIONAL',
  Mtpr = 'MTPR',
  MtprAdditional = 'MTPR_ADDITIONAL'
}

export type MonthlyTime = {
  __typename?: 'MonthlyTime';
  codes?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthOfYear?: Maybe<Scalars['Int']>;
  totalTime?: Maybe<Scalars['Int']>;
};

export type MonthlyTimeSpent = {
  __typename?: 'MonthlyTimeSpent';
  billed?: Maybe<Scalars['Boolean']>;
  events?: Maybe<Array<Maybe<Event>>>;
  id?: Maybe<Scalars['EID']>;
  member?: Maybe<User>;
  monthOfYear?: Maybe<Scalars['Int']>;
  organization?: Maybe<Organization>;
  totalTime?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type MonthlyTimeSpentListOpts = {
  memberId?: InputMaybe<Scalars['EID']>;
  months?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type MonthlyTimeSpentListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<MonthlyTimeSpentListSortField>;
};

export enum MonthlyTimeSpentListSortField {
  CreatedAt = 'CREATED_AT',
  MonthOfYear = 'MONTH_OF_YEAR',
  Name = 'NAME'
}

export enum Motivation {
  Action = 'ACTION',
  Contemplation = 'CONTEMPLATION',
  High = 'HIGH',
  Low = 'LOW',
  Maintenance = 'MAINTENANCE',
  Normal = 'NORMAL',
  Precontemplation = 'PRECONTEMPLATION',
  Preparation = 'PREPARATION'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** ## Activate assessment by nameKey, returns nModified */
  activateAssessment?: Maybe<Scalars['Int']>;
  activateOrganization?: Maybe<Array<Maybe<Scalars['EID']>>>;
  /**
   * ## Activate a User
   *  Activate user has its own API in case we may need additional cascading logic, etc.
   */
  activateUser?: Maybe<UserAuthentication>;
  /**
   * ## Add a device to EP
   *  Add a device to EP.  This replaces existing device with same type.
   */
  addDeviceToEnrolledProgram?: Maybe<EnrolledProgram>;
  /**
   * ## Add devices to a hub
   *  either id or serialNumber can be passed in to identify a hub
   */
  addDeviceToHub?: Maybe<DeviceHubType>;
  addFoodLogComment?: Maybe<FoodLog>;
  /**
   * ## Add an One-time-task to EP
   *  Add an One time task to EP
   */
  addOneTimeTaskToEnrolledProgram?: Maybe<EnrolledProgram>;
  /** ## Add organization managers */
  addOrgManagers?: Maybe<Array<Maybe<User>>>;
  addRedeemViews?: Maybe<RewardRedeemTemplate>;
  /**
   * ## Add team members to a team
   *  both teamId and memberIds are required, returns number of members added to a team
   */
  addTeamMembers?: Maybe<Scalars['Int']>;
  /**
   * ## Add Product to Inventory
   *  Add products to the organization's inventory
   */
  addToInventory?: Maybe<InventoryType>;
  /**
   * ## Assign Loan Device
   *  Assign a member to a loan device.  It takes either deviceId or serialNumer to look up for device
   */
  assignLoanDevice?: Maybe<LoanDeviceType>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  auditTrailCreate?: Maybe<CreateOneAuditRecordPayload>;
  /** Check if the program meets all the requirements, then change the status. */
  changeAdminProgramStatus?: Maybe<AdminProgram>;
  changePassword?: Maybe<UserAuthentication>;
  /**
   * ## Choose before Log In
   *  Login and choose organization.
   */
  chooseLoginOrganization?: Maybe<UserAuthentication>;
  /** ## Close an alert (careStatus NEW/HANDLED -> CLOSED) */
  closeAlert?: Maybe<Result>;
  /** ## Close Smart Alert */
  closeSmartAlert?: Maybe<SmartAlert>;
  createAccountEventHistory?: Maybe<AccountEventHistory>;
  /**
   * ## Create Program (Template)
   *  Create a new program that can be enrolled to a patient.
   */
  createAdminProgram?: Maybe<AdminProgram>;
  createAndSignConsent?: Maybe<PatientConsent>;
  /** ## Create Assessment. Only name is required (must be unique). */
  createAssessment?: Maybe<Assessment>;
  /**
   * ## createAutoMsgConfig
   *  subjectId is for online Class;
   *  iosMinAppVersion and androidMinAppVersion are for app update (type = APP_UPDATE)
   */
  createAutoMsgConfig?: Maybe<AutoMsgConfig>;
  createChatChannel?: Maybe<ChatChannelType>;
  createClaimedTimeLog?: Maybe<ClaimedTimeLog>;
  /** ## submit patient consent */
  createConsentTemplate?: Maybe<ConsentTemplate>;
  createDeviceError?: Maybe<DeviceError>;
  createDoctorReferral?: Maybe<DoctorReferral>;
  createEducationTips?: Maybe<EducationTips>;
  createEducationTipsTemplate?: Maybe<EducationTipsTemplate>;
  /**
   * ## Create feedback
   *  create feedback entry
   */
  createFeedback?: Maybe<FeedbackType>;
  createFileUpload?: Maybe<FileUpload>;
  createFoodLog?: Maybe<FoodLog>;
  createHeartbeatLog?: Maybe<HeartbeatLog>;
  /**
   * ## Create insurance provider
   *  returns a newly created insurance provider
   */
  createInsuranceProvider?: Maybe<InsuranceProvider>;
  /**
   * ## Create IoT Device
   *  combination of deviceId and position is the unique key to identify device
   */
  createIoTDevice?: Maybe<Array<Maybe<IoTDeviceType>>>;
  /**
   * ## Create IoT Device Config
   *
   */
  createIoTDeviceConfig?: Maybe<IoTDeviceConfigType>;
  /**
   * ## Create IoT Hub
   *  combination of deviceId and position is the unique key to identify device
   */
  createIoTHub?: Maybe<DeviceHubType>;
  /**
   * ## Create Loan Device
   *  create a loan device entry
   */
  createLoanDevice?: Maybe<LoanDeviceType>;
  createMedication?: Maybe<Medication>;
  createMissingInfo?: Maybe<MissingInfo>;
  createMonthlyReview?: Maybe<MonthlyReview>;
  /** ## Create multiple PESStatements */
  createMultiPESStatements?: Maybe<Array<Maybe<PesStatement>>>;
  createOneClickMeeting?: Maybe<Scalars['JSON']>;
  /**
   * ## Create Prododuct Order
   *  Create an order
   */
  createOrder?: Maybe<OrderType>;
  /** ## Create an organization schedule */
  createOrgSchedule?: Maybe<OrgSchedule>;
  /**
   * ## Create an Organization
   *  Create an organization; if no parentId is passed, it will become a new tree on its own (i.e. a sole, non-dependant parent).
   */
  createOrganization?: Maybe<Organization>;
  createPaperConsent?: Maybe<PatientConsent>;
  createPostIt?: Maybe<PostIt>;
  /** ## Create a ProviderNote */
  createProviderNote?: Maybe<ProviderNote>;
  createRemoteEnrollment?: Maybe<RemoteEnrollment>;
  createSelfEvaluation?: Maybe<SelfEvaluation>;
  createSubscribe?: Maybe<Subscribes>;
  createSurvey?: Maybe<SurveyType>;
  createSystemDynamicConfig?: Maybe<SystemDynamicConfig>;
  createTaskAssignment?: Maybe<TaskAssignment>;
  /**
   * ## Create a Team
   *  Create a (care) team of nurses, doctors or equivalent roles.
   *  Note that providerId is no longer use in this API.  Team members are now added through addTeamMembers API
   *  providerId is deprecated but for backward compatible until front end no longer uses it, then it will be removed
   */
  createTeam?: Maybe<Team>;
  createTemplateGroup?: Maybe<TemplateGroup>;
  /** ## Create a new tracked event */
  createTimeTrackingEvent?: Maybe<TimeTracker>;
  /**
   * ## User Registration (login required)
   *  Create a new account on someone else's behalf.
   */
  createUser?: Maybe<User>;
  createVisit?: Maybe<Visit>;
  /** ## Deactivate assessment by nameKey, returns nModified */
  deactivateAssessment?: Maybe<Scalars['Int']>;
  deactivateOrganization?: Maybe<Array<Maybe<Scalars['EID']>>>;
  /**
   * ## Deactivate a User
   *  Deactivate user has its own API in case we may need additional cascading logic, etc.
   */
  deactivateUser?: Maybe<UserAuthentication>;
  /**
   * ## Delete Admin Program
   *  Delete a program. Allowed only if the program has never been used.
   */
  deleteAdminProgram?: Maybe<Scalars['Boolean']>;
  /** ## Delete assessment by nameKey, returns nDeleted */
  deleteAssessment?: Maybe<Scalars['Int']>;
  deleteAutoMsgConfig?: Maybe<Scalars['Boolean']>;
  deleteConsentTemplate?: Maybe<Scalars['Boolean']>;
  deleteDoctorReferral?: Maybe<Scalars['Boolean']>;
  deleteEnrolledProgram?: Maybe<Scalars['Boolean']>;
  deleteInsurance?: Maybe<Scalars['Boolean']>;
  /**
   * ## Delete insurance provider
   *  returns true if successfully deleted, false otherwise
   */
  deleteInsuranceProvider?: Maybe<Scalars['Boolean']>;
  deleteOrder?: Maybe<Scalars['Boolean']>;
  /** ## Delete an organization schedule */
  deleteOrgSchedule?: Maybe<Scalars['Boolean']>;
  deletePatientConsent?: Maybe<Scalars['Boolean']>;
  deletePatientReferral?: Maybe<Scalars['Boolean']>;
  deletePostIt?: Maybe<Scalars['Boolean']>;
  deleteProviderNote?: Maybe<Scalars['Boolean']>;
  deleteSystemDynamicConfig?: Maybe<Scalars['Boolean']>;
  /**
   * ## Delete TaskAssignment
   *  Only createdBy can delete it
   */
  deleteTaskAssignment?: Maybe<Scalars['Boolean']>;
  /**
   * ## Delete Team
   *  Delete a team. Allowed only if organization matches, level is allowed and teamId not exist in EnrolledProgram
   */
  deleteTeam?: Maybe<Scalars['Boolean']>;
  deleteTemplateGroup?: Maybe<Scalars['Boolean']>;
  /**
   * ## Delete a user account
   * # Note: This CANNOT be undone!!!
   *  returns new document or an error
   */
  deleteUser?: Maybe<User>;
  deleteVisit?: Maybe<Scalars['Boolean']>;
  /**
   * ## Disable a Task in an EP
   *  Disable a task in a _user-enrolled_ program.
   */
  disableEnrolledProgramTask?: Maybe<Task>;
  /**
   * ## Discharge from an EP
   *  Discharge a member from the enrolled-program.
   */
  dischargeFromEnrolledProgram?: Maybe<EnrolledProgram>;
  editAccountRoleCapacity?: Maybe<AccountRoleCapacity>;
  /**
   * ## Edit Admin Program
   *  Edit an existing program and its tasks. Its organizationId cannot be changed. If you submit an incomplete form, other fields that you did not pass will not change.
   */
  editAdminProgram?: Maybe<AdminProgram>;
  /** ## Edit Assessment. Only nameKey is required. */
  editAssessment?: Maybe<Assessment>;
  /**
   * ## editAutoMsgConfig
   *  subjectId is for online Class;
   *  iosMinAppVersion and androidMinAppVersion are for app update (type = APP_UPDATE)
   */
  editAutoMsgConfig?: Maybe<AutoMsgConfig>;
  editConditionsList?: Maybe<User>;
  editConsentTemplate?: Maybe<ConsentTemplate>;
  editEXERCISE?: Maybe<Measure>;
  editEducationTips?: Maybe<EducationTips>;
  editEducationTipsTemplate?: Maybe<EducationTipsTemplate>;
  /**
   * ## Edit an EP
   *  Edit a user-enrolled program (tasks). Any nested input fields for tasks will
   *  replace the existing field. This means if you pass only _diastolic_ thresholds
   *  to the input, it will remove _systolic_ thresholds if there was one.
   */
  editEnrolledProgram?: Maybe<EnrolledProgram>;
  /**
   * ## Edit feedback
   *  Edit feedback entry
   */
  editFeedback?: Maybe<FeedbackType>;
  editFileUpload?: Maybe<FileUpload>;
  /**
   * ## Edit insurance provider
   *  returns a newly updated insurance provider
   */
  editInsuranceProvider?: Maybe<InsuranceProvider>;
  /**
   * ## Edit IoT Device
   *  combination of deviceId and position is the unique key to identify device
   */
  editIoTDevice?: Maybe<Array<Maybe<IoTDeviceType>>>;
  /**
   * ## Edit IoT Device
   *  combination of deviceId and position is the unique key to identify device
   */
  editIoTDeviceConfig?: Maybe<IoTDeviceConfigType>;
  /**
   * ## Edit Loan Device
   *  Edit a loan device
   */
  editLoanDevice?: Maybe<LoanDeviceType>;
  /** ## Edit user notes of a measurement (only allowed in a time range) */
  editMeasurementNotes?: Maybe<Measure>;
  editMedication?: Maybe<Medication>;
  editMentor?: Maybe<Mentor>;
  editMissingInfo?: Maybe<MissingInfo>;
  editMonthlyReview?: Maybe<MonthlyReview>;
  /**
   * ## Edit monthly time spent
   *  returns the edited monthly time spent
   */
  editMonthlyTimeSpent?: Maybe<MonthlyTimeSpent>;
  /** ## Edit multiple PESStatements */
  editMultiPESStatements?: Maybe<Array<Maybe<PesStatement>>>;
  /**
   * ## Edit Prododuct Order
   *  Edit an order
   */
  editOrder?: Maybe<OrderType>;
  /** ## Edit an organization schedule */
  editOrgSchedule?: Maybe<OrgSchedule>;
  /**
   * ## Edit an Organization
   *  Edit an organization.
   */
  editOrganization?: Maybe<Organization>;
  /** ## Edit a PESStatement */
  editPESStatement?: Maybe<PesStatement>;
  editPostIt?: Maybe<PostIt>;
  /** #edit ProvideNote */
  editProviderNote?: Maybe<ProviderNote>;
  /**
   * ## List of remote enrollment
   *  returns list of visits of base on filters
   */
  editRemoteEnrollment?: Maybe<RemoteEnrollment>;
  /**
   * ## Edit some Result properties.
   *  This API can be used to add comment, add reviewedAt and closedAt timestamp.  Passed in the properties to make changes
   *    comment: if commentedById is not passed in, viewer._id is used
   *    reviewed: if true, reviewedAt is set, else if false, reviewedAt is cleared
   *    closed: if true, closedAt is set, else if false, closedAt is cleared
   *    changed since 1/23/2019 : can edit beforeMeal and mealType, server will also update measurement collection.
   *    updated by Adam 03/11/2019 : add hadMedicine(boolean) , hadActivity(boolean), hadInsulin(boolean),tags
   */
  editResult?: Maybe<Result>;
  editSelfEvaluation?: Maybe<SelfEvaluation>;
  editTaskAssignment?: Maybe<TaskAssignment>;
  /**
   * ## Edit a Team include edit a team, add/delete member to a team
   *  Edit a (care) team
   *  Note that providerId is no longer use in this API.  Team members are now added through addTeamMembers/removeTeamMembers API
   *  providerId is deprecated but for backward compatible until front end no longer uses it, then it will be removed
   */
  editTeam?: Maybe<Team>;
  editTemplateGroup?: Maybe<TemplateGroup>;
  editUser?: Maybe<User>;
  editUserHandouts?: Maybe<User>;
  editUserMedicationManagement?: Maybe<User>;
  editUserNutrition?: Maybe<User>;
  editUserSocialSupport?: Maybe<User>;
  editVisit?: Maybe<Visit>;
  /**
   * ## Enable a Task in an EP
   *  Enable a task in a _user-enrolled_ program.
   */
  enableEnrolledProgramTask?: Maybe<Task>;
  /**
   * ## Enroll a Member to a Program
   *  Create a user-enrolled program.
   */
  enrollMember?: Maybe<EnrolledProgram>;
  enrollPatientConsent?: Maybe<PatientConsent>;
  /**
   * ## Extend EP Dates
   *  Extend an _already started_ user-enrolled program.
   */
  extendEnrolledProgram?: Maybe<EnrolledProgram>;
  /**
   * ## Flag Member
   *  Set the flag status of MEMBER user. Reason to flag can be optionally provided.
   */
  flagMember?: Maybe<User>;
  /** ## Handle an alert (careStatus NEW -> HANDLED) */
  handleAlert?: Maybe<Result>;
  /**
   * ## Scan & Initiate EP
   *  Scan a QR code and initiate the user enrollment process to program.
   */
  initiateEnrolledProgram?: Maybe<NewEnrolledProgram>;
  insertAssessmentFilled?: Maybe<AssessmentFilled>;
  /**
   * ## Insert BG
   *  Insert a blood glucose measurement.
   */
  insertBG?: Maybe<Measure>;
  /**
   * ## Insert BP
   *  Insert a blood pressure measurement. If this is _not_ a manual input reading, _heartrate and arrhythmia_ is required.
   */
  insertBP?: Maybe<Measure>;
  /**
   * ## Insert ECG
   *  Insert a ECG measurement. If this is _not_ a manual input reading, _bloodOxygen is required.
   */
  insertECG?: Maybe<Measure>;
  insertEXERCISE?: Maybe<Measure>;
  /**
   * ## Insert HR
   *  Insert a heart rate measurement.
   */
  insertHR?: Maybe<Measure>;
  /**
   * ## Insert HS
   *  Insert a weight scale measurement.
   */
  insertHS?: Maybe<Measure>;
  /**
   * ## Insert PO
   *  Insert a blood pressure measurement. If this is _not_ a manual input reading, _bloodOxygen is required.
   */
  insertPO?: Maybe<Measure>;
  insertRewardRedeemSurvey?: Maybe<RewardRedeemSurvey>;
  /**
   * ## Insert TM
   *  Insert a body temperature measurement. If this is _not_ a manual input reading, _bodyTemperature is required.
   */
  insertTM?: Maybe<Measure>;
  /**
   * ## Insert Task Result
   *  Insert measurement or assessment into a task using _[refId]_.
   */
  insertTaskResult?: Maybe<Array<Maybe<Result>>>;
  /**
   * ## Insert Task Result Relaxed
   *  Insert measurement or assessment into a task using _[refId]_.
   *  Added on 03/08/2018, accepting results of already completed tasks.
   */
  insertTaskResultRelaxed?: Maybe<Array<Maybe<Result>>>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  labResultCreate?: Maybe<CreateOneLabResultsPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  labResultTemplateCreate?: Maybe<CreateOneLabResultTemplatesPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  labResultTemplateRemove?: Maybe<RemoveByIdLabResultTemplatesPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  labResultUpdateById?: Maybe<UpdateByIdLabResultsPayload>;
  /** Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  labResultUpdateMany?: Maybe<UpdateManyLabResultsPayload>;
  /**
   * ## Identification Login
   *  Login using identification
   */
  loginWithIdentification?: Maybe<UserAuthentication>;
  loginWithOneClickMeeting?: Maybe<UserAuthentication>;
  /**
   * ## Otp Login
   *  Login with phone number and one time password (OTP)
   */
  loginWithOtp?: Maybe<UserAuthentication>;
  /**
   * ## Token Login
   *  Login with a token
   */
  loginWithToken?: Maybe<UserAuthentication>;
  /**
   * ## Username ~~/E-mail~~ Login
   * ### NOTE:
   * ### Only username is checked, as email is not unique
   *  As of 12/26/2017, only username login is allowed
   */
  loginWithUsernameOrEmail?: Maybe<UserAuthentication>;
  /**
   * ## Signout
   *  Signout and invalidate the session token
   */
  logoutAndClearToken?: Maybe<Scalars['Boolean']>;
  /**
   * ## Assign/Unassign Team from EP
   *  Assign or unassign a team and members from an enrolled-program.
   *  It is not possible to set nobody/team as a manager for an enrolled-program.
   */
  manageTeamForEnrolledProgram?: Maybe<EnrolledProgram>;
  markOnCallDoctor?: Maybe<OnCallDoctor>;
  /** #set as patient as reviewed */
  markPatientAsReviewed?: Maybe<ProviderNote>;
  /** ## Mark a ProviderNote as error */
  markProviderNoteError?: Maybe<ProviderNote>;
  muteMissingInfo?: Maybe<Scalars['Boolean']>;
  postVirtualMeetingEvent?: Maybe<Scalars['JSON']>;
  /**
   * ## Change Assessment status from 'DRAFT' to 'ACTIVE'.
   * ## Will throw error if status is already 'ACTIVE', or if there is any missing field.
   * ## Required fields: name, description, healthCondition, type, questions
   */
  publishAssessment?: Maybe<Assessment>;
  rateFoodLog?: Maybe<FoodLog>;
  readSignOffComment?: Maybe<SignOffTask>;
  refreshSpecialMembers?: Maybe<Scalars['Boolean']>;
  removeChatChannel?: Maybe<Scalars['Boolean']>;
  /**
   * ## Remove a device from EP
   *  Remove a device from EP.  This returns null if not device exists
   */
  removeDeviceFromEnrolledProgram?: Maybe<EnrolledProgram>;
  /**
   * ## Remove devices to a hub
   *  Either id or serialNumber can be passed in to identify a hub
   */
  removeDeviceFromHub?: Maybe<DeviceHubType>;
  /**
   * ## Remove Product from Inventory
   *  Remove products from the organization's inventory
   */
  removeFromInventory?: Maybe<InventoryType>;
  /**
   * ## Remove IoT Device
   *  remove the IoT device from the system
   */
  removeIoTDevice?: Maybe<Scalars['Boolean']>;
  /**
   * ## Remove IoT Device
   *  remove the IoT device config from the system
   */
  removeIoTDeviceConfig?: Maybe<Scalars['Boolean']>;
  /**
   * ## Remove IoT hub
   *  Either id or serialNumber can be passed in to identify a hub
   */
  removeIoTHub?: Maybe<Scalars['Boolean']>;
  removeMentorRelation?: Maybe<Scalars['Boolean']>;
  /** ## Remove organization managers */
  removeOrgManagers?: Maybe<Array<Maybe<User>>>;
  /**
   * ## Remove Task
   *  Delete a task from program by ID.
   */
  removeTaskFromAdminProgram?: Maybe<AdminProgram>;
  /** Delete a task from EP, the task need to has id */
  removeTaskFromEnrolledProgram?: Maybe<EnrolledProgram>;
  /**
   * ## Remove team members from a team
   *  both teamId and memberIds are required, returns number of members removed from a team
   */
  removeTeamMembers?: Maybe<Scalars['Int']>;
  removeUserRoles?: Maybe<User>;
  /** Request for reset password with either username or email */
  requestResetPassword?: Maybe<Scalars['Boolean']>;
  resendOnBoardEmail?: Maybe<Scalars['Boolean']>;
  /**
   * ## Reset all the current task completion status for a member (DEMO purpose only)
   *  **Removes results of all active tasks in the current schedule time range,   and returns the number of removed results.**   e.g If the task is on DAILY schedule, remove current day's task results.   Or if the task is on WEEKLY schedule, remove current week's task results.
   */
  resetCurrentTaskCompletionStatus?: Maybe<Scalars['Int']>;
  resetPassword?: Maybe<UserAuthentication>;
  resolveTaskAssignment?: Maybe<TaskAssignment>;
  /**
   * ## Resume an EP
   *  Resume an enrolled-program once it has started.
   */
  resumeEnrolledProgram?: Maybe<EnrolledProgram>;
  resumePatientHiatus?: Maybe<Scalars['Boolean']>;
  rewardRedeem?: Maybe<RewardRedeem>;
  /**
   * ## Add a new strip bottle, or set the bottle as latest
   * ## As of Nov 21, 2017, only members are allowed to use this API and memberId is assumed
   */
  scanStrip?: Maybe<Supply>;
  sendInvitationLink?: Maybe<NotificationResult>;
  /** ## Email Notification */
  sendMail?: Maybe<NotificationResult>;
  /** ## SMS Notification */
  sendMemberSms?: Maybe<NotificationResult>;
  /** Send a one time passcode through SMS */
  sendOtpCode?: Maybe<Scalars['Boolean']>;
  /**
   * This mutation takes an email address and will generate a random string which
   * will then be correlated to the address. After that it will send an email to
   * that email address with the generated string.
   */
  sendVerificationEmail?: Maybe<Scalars['Boolean']>;
  setCoveredMembers?: Maybe<CoveredMember>;
  setCoveredOrganizations?: Maybe<CoveredOrganization>;
  /**
   * ## Edit Date of enrolled-program
   *  set startDate and endDate to an EP
   */
  setDateForEnrolledProgram?: Maybe<EnrolledProgram>;
  /**
   * ## Set Device Model
   *  After finishing the program (template) creation process, set the device models for each device type.
   */
  setDevicesForAdminProgram?: Maybe<AdminProgram>;
  setPatientAlertConfig?: Maybe<SmartAlertTasksConfig>;
  setPatientHiatusStatus?: Maybe<PatientHiatusStatus>;
  setSelectedClinic?: Maybe<SelectedClinic>;
  setSystemDynamicConfig?: Maybe<SystemDynamicConfig>;
  setTaskAssignmentAsRead?: Maybe<TaskAssignment>;
  /**
   * ## Set (other) user's password
   *  Will reject if user id is the same as viewer's, so that we don't introduct a bypass to "changePassword"
   */
  setUserPassword?: Maybe<UserAuthentication>;
  signOffByMentor?: Maybe<SignOffTask>;
  signOffMonthlyReview?: Maybe<MonthlyReview>;
  signOffVisit?: Maybe<Visit>;
  /**
   * ## Star Member
   *  Set the star status of MEMBER user. Reason to star can be optionally provided.
   */
  starMember?: Maybe<User>;
  /** ## submit patient consent */
  submitPatientConsent?: Maybe<PatientConsent>;
  /**
   * ## Suspend an EP
   *  Suspend an enrolled-program once it has started.
   */
  suspendEnrolledProgram?: Maybe<EnrolledProgram>;
  /**
   * ## Terminate an organization
   *  This API will discharge all patients for this organization and is irreversible.
   *  User must have super admin role to use this API
   */
  terminateOrganization?: Maybe<Scalars['Boolean']>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  timeTrackerCreate?: Maybe<CreateOneTimeTrackerPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  timeTrackerUpdateById?: Maybe<UpdateByIdTimeTrackerPayload>;
  /** Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  timeTrackerUpdateMany?: Maybe<UpdateManyTimeTrackerPayload>;
  /**
   * ## UnFlag Member
   *  Set the flag status of MEMBER user
   *  Reason to unflag can be optionally provided
   */
  unFlagMember?: Maybe<User>;
  /**
   * ## Unassign Loan Device
   *  Unassign a member from a loan device.  It takes either deviceId or serialNumer to look up for device
   */
  unassignLoanDevice?: Maybe<LoanDeviceType>;
  /**
   * ## Unstar Member
   *  Set the star status of MEMBER user
   */
  unstarMember?: Maybe<User>;
  updateAssessmentFilled?: Maybe<AssessmentFilled>;
  updateChannelAckTimestamps?: Maybe<Array<Maybe<ChannelAckTimestamp>>>;
  /**
   * ## Assign/remove a member to/from a hub
   *  Either id or serialNumber can be passed in to identify a hub. If devices is passed in, it will overwrite the existing devices in the database
   */
  updateHubMember?: Maybe<DeviceHubType>;
  /**
   * ## Update IoT Device member
   *  This API assigns or unassigns a member to/from an IoT device
   */
  updateIoTDeviceMember?: Maybe<Array<Maybe<IoTDeviceType>>>;
  /**
   * ## Update lastReviewedAt field of a member based on program(s)
   *  Update the date of lastReviewedAt to current date and returns number of updated entries
   */
  updateLastReviewedAt?: Maybe<Scalars['Int']>;
  updatePatientAssignee?: Maybe<PatientAssignee>;
  updatePatientComplexity?: Maybe<PatientComplexity>;
  updateVisitConfirm?: Maybe<Visit>;
  updateVisitWorkingList?: Maybe<Visit>;
  uploadChallengeBadge?: Maybe<ChallengeRewardDetails>;
  uploadChallengeReward?: Maybe<ChallengeRewardDetails>;
  uploadInsurance?: Maybe<Insurance>;
  uploadPhoneReport?: Maybe<PhoneReportInfo>;
  uploadUserDevicesInfo?: Maybe<UserDevicesInfo>;
  /**
   * ## insert/update push notification
   *  insert or update member's push notification info
   */
  upsertDeviceToken?: Maybe<PushNotificationType>;
  upsertFeatureUpload?: Maybe<FeatureUpload>;
  upsertPatientMntProgram?: Maybe<PatientMntProgram>;
  upsertPatientReferral?: Maybe<PatientReferral>;
  upsertUserPreference?: Maybe<UserPreference>;
  upsertUserRoles?: Maybe<User>;
  /**
   * ## Upsert Version History
   *  must pass either a combination of enrolledProgramId && visitId OR enrolledProgramId && date
   */
  upsertVersionHistory?: Maybe<VersionHistory>;
  /** ## Use one strip from the bottle */
  useStrip?: Maybe<Supply>;
  /**
   * This mutation takes an email address and verification string.  It will compare the string
   * to the email address and if they match the email address will be verified
   */
  verifyEmail?: Maybe<Scalars['Boolean']>;
  verifyOnBoardToken?: Maybe<UserAuthentication>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  weeklyBGScheduleTemplateCreate?: Maybe<CreateOneWeeklyBgScheduleTemplatesPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  weeklyBGScheduleTemplateRemove?: Maybe<RemoveByIdWeeklyBgScheduleTemplatesPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  weeklyBGScheduleTemplateUpdate?: Maybe<UpdateByIdWeeklyBgScheduleTemplatesPayload>;
};


export type MutationActivateAssessmentArgs = {
  nameKey: Scalars['String'];
};


export type MutationActivateOrganizationArgs = {
  id?: InputMaybe<Scalars['EID']>;
};


export type MutationActivateUserArgs = {
  id: Scalars['EID'];
  roles: Array<InputMaybe<Scalars['Ref']>>;
};


export type MutationAddDeviceToEnrolledProgramArgs = {
  device: DeviceInput;
  id: Scalars['EID'];
};


export type MutationAddDeviceToHubArgs = {
  devices?: InputMaybe<Array<InputMaybe<IoTDeviceInput>>>;
  id?: InputMaybe<Scalars['EID']>;
  serialNumber?: InputMaybe<Scalars['String']>;
};


export type MutationAddFoodLogCommentArgs = {
  comment: FoodLogCommentInput;
  id?: InputMaybe<Scalars['EID']>;
};


export type MutationAddOneTimeTaskToEnrolledProgramArgs = {
  assessment?: InputMaybe<InputAssessmentTaskEnrolled>;
  id: Scalars['EID'];
  type: TaskType;
};


export type MutationAddOrgManagersArgs = {
  managerIds: Array<Scalars['EID']>;
  organizationId: Scalars['EID'];
};


export type MutationAddRedeemViewsArgs = {
  parentTemplateId: Scalars['EID'];
};


export type MutationAddTeamMembersArgs = {
  memberIds: Array<Scalars['EID']>;
  teamId: Scalars['EID'];
};


export type MutationAddToInventoryArgs = {
  note?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['EID'];
  products: Array<InventoryProductInput>;
  visitId?: InputMaybe<Scalars['EID']>;
};


export type MutationAssignLoanDeviceArgs = {
  deviceId?: InputMaybe<Scalars['String']>;
  memberId: Scalars['EID'];
  serialNumber?: InputMaybe<Scalars['String']>;
};


export type MutationAuditTrailCreateArgs = {
  record: CreateOneAuditRecordInput;
};


export type MutationChangeAdminProgramStatusArgs = {
  id: Scalars['EID'];
  status: ProgramStatus;
};


export type MutationChangePasswordArgs = {
  newPass: Scalars['String'];
  oldPass: Scalars['String'];
};


export type MutationChooseLoginOrganizationArgs = {
  ref: Scalars['Ref'];
};


export type MutationCloseAlertArgs = {
  id?: InputMaybe<Scalars['EID']>;
};


export type MutationCloseSmartAlertArgs = {
  taskId: Scalars['EID'];
};


export type MutationCreateAccountEventHistoryArgs = {
  event: Account_Event_Enum;
};


export type MutationCreateAdminProgramArgs = {
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<DurationInput>;
  healthCondition?: InputMaybe<HealthCondition>;
  name?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<Array<InputMaybe<InputTask>>>;
};


export type MutationCreateAndSignConsentArgs = {
  consentProviderName?: InputMaybe<Scalars['String']>;
  consentType: Scalars['String'];
  effectiveDate: Scalars['Date'];
  fileContent: Scalars['String'];
  memberId: Scalars['EID'];
  providers?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  timezone: Scalars['String'];
};


export type MutationCreateAssessmentArgs = {
  description?: InputMaybe<Scalars['String']>;
  healthCondition?: InputMaybe<HealthCondition>;
  name: Scalars['String'];
  publish?: InputMaybe<Scalars['Boolean']>;
  questions?: InputMaybe<Array<InputMaybe<InputAssessmentQuestion>>>;
  type: AssessmentType;
};


export type MutationCreateAutoMsgConfigArgs = {
  androidMinAppVersion?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<AutoMsgCategoryEnum>;
  excludeOrganizations?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  fileKey?: InputMaybe<Scalars['String']>;
  iosMinAppVersion?: InputMaybe<Scalars['String']>;
  languages?: InputMaybe<Array<InputMaybe<Language>>>;
  members?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  messages?: InputMaybe<Array<InputMaybe<AutoMsgMessageInput>>>;
  organizations?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  sendDateTime: Scalars['Date'];
  subjectId?: InputMaybe<Scalars['String']>;
  type: AutoMsgTypeEnum;
};


export type MutationCreateChatChannelArgs = {
  enrolledProgramId: Scalars['EID'];
  patientId: Scalars['EID'];
  teamId: Scalars['EID'];
};


export type MutationCreateClaimedTimeLogArgs = {
  duringVisit?: InputMaybe<Scalars['Boolean']>;
  endTime?: InputMaybe<Scalars['Date']>;
  events: Array<InputMaybe<ClaimedTimeLogEventInput>>;
  idleTime?: InputMaybe<Scalars['Int']>;
  manualInput?: InputMaybe<Scalars['Boolean']>;
  memberId: Scalars['EID'];
  monthOfYear: Scalars['Int'];
  serviceDate: Scalars['Date'];
  startTime?: InputMaybe<Scalars['Date']>;
  totalTime: Scalars['Int'];
};


export type MutationCreateConsentTemplateArgs = {
  content: Scalars['String'];
  groupId?: InputMaybe<Scalars['EID']>;
  language?: InputMaybe<Language>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationCreateDeviceErrorArgs = {
  description?: InputMaybe<Scalars['String']>;
  deviceType?: InputMaybe<Scalars['String']>;
  errorCode?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['EID']>;
};


export type MutationCreateDoctorReferralArgs = {
  memberId: Scalars['EID'];
  primaryDoctorId: Scalars['EID'];
};


export type MutationCreateEducationTipsArgs = {
  category?: InputMaybe<EducationTipsCategory>;
  level?: InputMaybe<EducationTipsLevel>;
  memberId: Scalars['EID'];
};


export type MutationCreateEducationTipsTemplateArgs = {
  category?: InputMaybe<EducationTipsCategory>;
  content?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Language>;
  level?: InputMaybe<EducationTipsLevel>;
};


export type MutationCreateFeedbackArgs = {
  feedback?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['EID']>;
  rating?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateFileUploadArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  fileInfo?: InputMaybe<FileInfoInput>;
  memberId: Scalars['EID'];
  organizationId?: InputMaybe<Scalars['EID']>;
  timezone?: InputMaybe<Scalars['String']>;
};


export type MutationCreateFoodLogArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  images?: InputMaybe<Array<InputMaybe<ImageTypeInput>>>;
  mealType?: InputMaybe<MealTypeEnum>;
  memberId: Scalars['EID'];
  needReview?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['EID']>;
  timezone?: InputMaybe<Scalars['String']>;
};


export type MutationCreateHeartbeatLogArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  heartbeatInterval?: InputMaybe<Scalars['Int']>;
  snapshots: Array<InputMaybe<SnapshotInput>>;
};


export type MutationCreateInsuranceProviderArgs = {
  clearingHouse?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  payerId: Scalars['String'];
  payerName: Scalars['String'];
};


export type MutationCreateIoTDeviceArgs = {
  deviceId: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  memberId?: InputMaybe<Scalars['EID']>;
};


export type MutationCreateIoTDeviceConfigArgs = {
  bpUnit?: InputMaybe<IoTBpUnitEnum>;
  countryId?: InputMaybe<Scalars['String']>;
  dateFormat?: InputMaybe<IoTDateFormatEnum>;
  deviceId: Scalars['String'];
  firmwareVersion?: InputMaybe<Scalars['String']>;
  hardwareVersion?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  protocol?: InputMaybe<Scalars['String']>;
  temperatureUnit?: InputMaybe<IoTTemperatureUnitEnum>;
  timbre?: InputMaybe<Scalars['Int']>;
  timeFormat?: InputMaybe<Scalars['Int']>;
  timezone?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateIoTHubArgs = {
  devices?: InputMaybe<Array<InputMaybe<IoTDeviceInput>>>;
  memberId?: InputMaybe<Scalars['EID']>;
  serialNumber: Scalars['String'];
};


export type MutationCreateLoanDeviceArgs = {
  appVersion?: InputMaybe<Scalars['String']>;
  deviceId: Scalars['String'];
  deviceType?: InputMaybe<LoanDeviceTypeEnum>;
  memberId?: InputMaybe<Scalars['EID']>;
  osVersion?: InputMaybe<Scalars['String']>;
  serialNumber: Scalars['String'];
};


export type MutationCreateMedicationArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  dosage?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  healthCondition?: InputMaybe<HealthConditionEnum>;
  instruction?: InputMaybe<Scalars['String']>;
  memberId: Scalars['EID'];
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['EID']>;
  otherMD?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<MedicationStatus>;
  type?: InputMaybe<MedicationTypeEnum>;
};


export type MutationCreateMissingInfoArgs = {
  memberId: Scalars['EID'];
};


export type MutationCreateMonthlyReviewArgs = {
  dueDate?: InputMaybe<Scalars['Date']>;
  goals?: InputMaybe<Array<InputMaybe<MonthlyReviewGoalInput>>>;
  memberId: Scalars['EID'];
  monthOfYear: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
  preSummary?: InputMaybe<MonthlyReviewSummaryInput>;
  previousGoals?: InputMaybe<Array<InputMaybe<PreviousGoalsInput>>>;
  previousVitals?: InputMaybe<Array<InputMaybe<PreviousVitalsInput>>>;
  status?: InputMaybe<MonthlyReviewStatus>;
  teamId: Scalars['EID'];
  type?: InputMaybe<MonthlyReviewType>;
};


export type MutationCreateMultiPesStatementsArgs = {
  memberId: Scalars['EID'];
  pesStatements?: InputMaybe<Array<InputMaybe<InputPesStatement>>>;
  visitId?: InputMaybe<Scalars['EID']>;
};


export type MutationCreateOneClickMeetingArgs = {
  phoneNumber: Scalars['String'];
  visitId: Scalars['EID'];
};


export type MutationCreateOrderArgs = {
  memberId?: InputMaybe<Scalars['EID']>;
  orderPlanType?: InputMaybe<OrderPlanEnum>;
  phone?: InputMaybe<InputPhone>;
  pickup?: InputMaybe<Scalars['Boolean']>;
  pickupLocation?: InputMaybe<Scalars['String']>;
  shipTo?: InputMaybe<OrderShipToInput>;
};


export type MutationCreateOrgScheduleArgs = {
  affectiveDays?: InputMaybe<Array<InputMaybe<DayOfWeek>>>;
  blockingRoles?: InputMaybe<Array<InputMaybe<RoleType>>>;
  endTime?: InputMaybe<Scalars['Date']>;
  organizationId: Scalars['EID'];
  startTime?: InputMaybe<Scalars['Date']>;
};


export type MutationCreateOrganizationArgs = {
  EHR?: InputMaybe<Scalars['String']>;
  accessTmpl: AccessTmpl;
  address?: InputMaybe<InputAddress>;
  brandedName?: InputMaybe<Scalars['String']>;
  consentTemplateIds?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  description?: InputMaybe<Scalars['String']>;
  excludeBilling?: InputMaybe<Scalars['Boolean']>;
  identifier?: InputMaybe<Scalars['String']>;
  internalName?: InputMaybe<Scalars['String']>;
  leaf?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  operationModel?: InputMaybe<OrgOperationModelEnum>;
  orgPermissions?: InputMaybe<OrgPermissionsInput>;
  parentId: Scalars['EID'];
  phone?: InputMaybe<InputPhone>;
  programParticipation?: InputMaybe<Array<InputMaybe<ProgramParticipationEnum>>>;
  startDate?: InputMaybe<Scalars['Date']>;
  timezone?: InputMaybe<Scalars['String']>;
};


export type MutationCreatePaperConsentArgs = {
  consentProviderName?: InputMaybe<Scalars['String']>;
  consentType: Scalars['String'];
  effectiveDate: Scalars['Date'];
  fileContent: Scalars['String'];
  memberId: Scalars['EID'];
  providers?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  signatureAt: Scalars['Date'];
  timezone: Scalars['String'];
};


export type MutationCreatePostItArgs = {
  memberId: Scalars['EID'];
  note: Scalars['String'];
  organizationId: Scalars['EID'];
};


export type MutationCreateProviderNoteArgs = {
  category: ProviderNoteCategory;
  content: Scalars['String'];
  enrolledProgramId?: InputMaybe<Scalars['EID']>;
  hiatusAction?: InputMaybe<ProviderHiatusAction>;
  hiatusCategories?: InputMaybe<Array<InputMaybe<PatientHiatusCategory>>>;
  hiatusStartDate?: InputMaybe<Scalars['Date']>;
  memberId?: InputMaybe<Scalars['EID']>;
  notBillableReason?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['EID']>;
  patientHiatusStatusId?: InputMaybe<Scalars['EID']>;
  pauseDuration?: InputMaybe<Scalars['Int']>;
  resultId?: InputMaybe<Scalars['EID']>;
  resumeDate?: InputMaybe<Scalars['Date']>;
  smartAlertId?: InputMaybe<Scalars['EID']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  techCall?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateRemoteEnrollmentArgs = {
  conditionsList?: InputMaybe<InputConditionsListType>;
  doctorReferral?: InputMaybe<Scalars['Boolean']>;
  doctorReferralDate?: InputMaybe<Scalars['Date']>;
  memberId: Scalars['EID'];
  remoteStatus?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateSelfEvaluationArgs = {
  CPODAns?: InputMaybe<Scalars['String']>;
  areasOfImprovement?: InputMaybe<Array<InputMaybe<AreasOfImprovementEnums>>>;
  bloodPressureAns?: InputMaybe<Yes_No_Not_Sure_Enums>;
  diabetesAns?: InputMaybe<DiabetesAnsEnums>;
  evaluations?: InputMaybe<Array<InputMaybe<InputEvaluation>>>;
  memberId: Scalars['EID'];
  otherAreaAns?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  visitId: Scalars['EID'];
};


export type MutationCreateSubscribeArgs = {
  memberId: Scalars['EID'];
  password?: InputMaybe<Scalars['String']>;
  platform: Subscribes_Platform;
  purchaseToken?: InputMaybe<Scalars['String']>;
  receiptData?: InputMaybe<Scalars['String']>;
  subscriptionId?: InputMaybe<Scalars['String']>;
  transactionDate?: InputMaybe<Scalars['Date']>;
  transactionId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateSurveyArgs = {
  answers?: InputMaybe<Array<InputMaybe<MvpAnswers>>>;
  feedback?: InputMaybe<Scalars['String']>;
  from: SurveyFrom;
  memberId?: InputMaybe<Scalars['EID']>;
};


export type MutationCreateSystemDynamicConfigArgs = {
  key: Scalars['String'];
  value: Scalars['String'];
};


export type MutationCreateTaskAssignmentArgs = {
  assignedTo?: InputMaybe<Scalars['EID']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  memberId: Scalars['EID'];
  priorityLevel: TaskPriorityLevel;
  reason: Scalars['String'];
  teamId?: InputMaybe<Scalars['EID']>;
};


export type MutationCreateTeamArgs = {
  consentProviderName?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['EmailAddress']>;
  forwardingPhone?: InputMaybe<InputPhone>;
  healthCondition?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['EID']>;
  phone?: InputMaybe<InputPhone>;
  primary?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  providerId?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  visibleMembers?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
};


export type MutationCreateTemplateGroupArgs = {
  name: Scalars['String'];
};


export type MutationCreateTimeTrackingEventArgs = {
  abortTime?: InputMaybe<Scalars['Date']>;
  action: TimeTrackerActionEnum;
  category: TimeTrackerCategoryEnum;
  clientEvents?: InputMaybe<Array<InputMaybe<TimeTrackerClientEventInput>>>;
  interventionDetails?: InputMaybe<Array<InputMaybe<TimeTrackerInterventionDetailsInput>>>;
  relatedEventIds?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  sessionId?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Date'];
  stopTime?: InputMaybe<Scalars['Date']>;
  techCall?: InputMaybe<Scalars['Boolean']>;
  timeout?: InputMaybe<Scalars['Int']>;
  totalTime?: InputMaybe<Scalars['Int']>;
  userId: Scalars['EID'];
  version?: InputMaybe<Scalars['Int']>;
  viewerId: Scalars['EID'];
  viewingDetails?: InputMaybe<TimeTrackerViewingDetailsInput>;
};


export type MutationCreateUserArgs = {
  address?: InputMaybe<Array<InputMaybe<InputAddress>>>;
  capacitys?: InputMaybe<Array<InputMaybe<RoleCapacityInput>>>;
  commonProfile?: InputMaybe<InputCommonProfile>;
  email?: InputMaybe<Array<InputMaybe<Scalars['EmailAddress']>>>;
  employeeProfile?: InputMaybe<InputEmployeeProfile>;
  id?: InputMaybe<Scalars['EID']>;
  identification?: InputMaybe<InputIdentification>;
  isTestUser?: InputMaybe<Scalars['Boolean']>;
  memberProfile?: InputMaybe<InputMemberProfile>;
  phone?: InputMaybe<Array<InputMaybe<InputPhone>>>;
  residency?: InputMaybe<InputMemberResidency>;
  role: Array<InputMaybe<Scalars['Ref']>>;
  username?: InputMaybe<Scalars['String']>;
};


export type MutationCreateVisitArgs = {
  A1C?: InputMaybe<InputA1CObject>;
  appointmentAt?: InputMaybe<Scalars['Date']>;
  appointmentTo?: InputMaybe<Scalars['Date']>;
  assignees?: InputMaybe<AssigneeInput>;
  associatedVisitId?: InputMaybe<Scalars['EID']>;
  category?: InputMaybe<VisitCategoryEnum>;
  checkinAt?: InputMaybe<Scalars['Date']>;
  checkoutAt?: InputMaybe<Scalars['Date']>;
  conditionsList?: InputMaybe<InputConditionsListType>;
  declineToCheckVital?: InputMaybe<Scalars['Boolean']>;
  details?: InputMaybe<VisitDetailsInput>;
  doctorNotes?: InputMaybe<Scalars['String']>;
  memberId: Scalars['EID'];
  needInHouseA1C?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['EID']>;
  programIds?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  providerIds?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  reason?: InputMaybe<Scalars['String']>;
  remoteStatus?: InputMaybe<Scalars['Boolean']>;
  reviewBillable?: InputMaybe<Scalars['Boolean']>;
  shouldSeeDoctor?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<VisitTypeEnum>;
  unableToCheckVital?: InputMaybe<Scalars['Boolean']>;
  userDeviceRecords?: InputMaybe<InputUserDeviceRecords>;
  visitRoles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationDeactivateAssessmentArgs = {
  nameKey: Scalars['String'];
};


export type MutationDeactivateOrganizationArgs = {
  id?: InputMaybe<Scalars['EID']>;
};


export type MutationDeactivateUserArgs = {
  id: Scalars['EID'];
  roles: Array<InputMaybe<Scalars['Ref']>>;
};


export type MutationDeleteAdminProgramArgs = {
  id: Scalars['EID'];
};


export type MutationDeleteAssessmentArgs = {
  nameKey: Scalars['String'];
};


export type MutationDeleteAutoMsgConfigArgs = {
  id: Scalars['EID'];
};


export type MutationDeleteConsentTemplateArgs = {
  id: Scalars['EID'];
};


export type MutationDeleteDoctorReferralArgs = {
  memberId: Scalars['EID'];
};


export type MutationDeleteEnrolledProgramArgs = {
  id: Scalars['EID'];
};


export type MutationDeleteInsuranceArgs = {
  id: Scalars['EID'];
};


export type MutationDeleteInsuranceProviderArgs = {
  id: Scalars['EID'];
};


export type MutationDeleteOrderArgs = {
  id: Scalars['EID'];
};


export type MutationDeleteOrgScheduleArgs = {
  id: Scalars['EID'];
};


export type MutationDeletePatientConsentArgs = {
  id: Scalars['EID'];
};


export type MutationDeletePatientReferralArgs = {
  patientId?: InputMaybe<Scalars['EID']>;
};


export type MutationDeletePostItArgs = {
  id: Scalars['EID'];
};


export type MutationDeleteProviderNoteArgs = {
  id: Scalars['EID'];
};


export type MutationDeleteSystemDynamicConfigArgs = {
  id: Scalars['EID'];
};


export type MutationDeleteTaskAssignmentArgs = {
  id: Scalars['EID'];
};


export type MutationDeleteTeamArgs = {
  id: Scalars['EID'];
};


export type MutationDeleteTemplateGroupArgs = {
  id: Scalars['EID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['EID'];
  reason?: InputMaybe<InputDeleteReason>;
};


export type MutationDeleteVisitArgs = {
  id: Scalars['EID'];
};


export type MutationDisableEnrolledProgramTaskArgs = {
  id: Scalars['EID'];
  taskId: Scalars['EID'];
};


export type MutationDischargeFromEnrolledProgramArgs = {
  dischargedDetail?: InputMaybe<DischargedDetailInput>;
  id: Scalars['EID'];
};


export type MutationEditAccountRoleCapacityArgs = {
  capacitys?: InputMaybe<Array<InputMaybe<RoleCapacityInput>>>;
  id: Scalars['EID'];
};


export type MutationEditAdminProgramArgs = {
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<DurationInput>;
  healthCondition?: InputMaybe<HealthCondition>;
  id: Scalars['EID'];
  name?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<Array<InputMaybe<InputTask>>>;
};


export type MutationEditAssessmentArgs = {
  description?: InputMaybe<Scalars['String']>;
  healthCondition?: InputMaybe<HealthCondition>;
  name?: InputMaybe<Scalars['String']>;
  nameKey: Scalars['String'];
  publish?: InputMaybe<Scalars['Boolean']>;
  questions?: InputMaybe<Array<InputMaybe<InputAssessmentQuestion>>>;
};


export type MutationEditAutoMsgConfigArgs = {
  androidMinAppVersion?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<AutoMsgCategoryEnum>;
  excludeOrganizations?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  fileKey?: InputMaybe<Scalars['String']>;
  id: Scalars['EID'];
  iosMinAppVersion?: InputMaybe<Scalars['String']>;
  languages?: InputMaybe<Array<InputMaybe<Language>>>;
  members?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  messages?: InputMaybe<Array<AutoMsgMessageInput>>;
  organizations?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  sendDateTime?: InputMaybe<Scalars['Date']>;
  subjectId?: InputMaybe<Scalars['String']>;
};


export type MutationEditConditionsListArgs = {
  conditionsList: InputConditionsListType;
  id: Scalars['EID'];
};


export type MutationEditConsentTemplateArgs = {
  content?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['EID']>;
  id: Scalars['EID'];
  language?: InputMaybe<Language>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationEditExerciseArgs = {
  details?: InputMaybe<Array<InputMaybe<StepDetailInput>>>;
  id: Scalars['EID'];
  steps: Scalars['STEPValue'];
};


export type MutationEditEducationTipsArgs = {
  helpful?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['EID'];
  marked?: InputMaybe<Scalars['Boolean']>;
};


export type MutationEditEducationTipsTemplateArgs = {
  category?: InputMaybe<EducationTipsCategory>;
  content?: InputMaybe<Scalars['String']>;
  id: Scalars['EID'];
  language?: InputMaybe<Language>;
  level?: InputMaybe<EducationTipsLevel>;
};


export type MutationEditEnrolledProgramArgs = {
  assessments?: InputMaybe<Scalars['String']>;
  assessmentsFlag?: InputMaybe<Scalars['String']>;
  behavioralGoals?: InputMaybe<Array<InputMaybe<InputBehavioralGoal>>>;
  careCoordination?: InputMaybe<Scalars['String']>;
  clinicGoals?: InputMaybe<Array<InputMaybe<InputClinicGoal>>>;
  deviceInputEnabledTaskTypes?: InputMaybe<Array<InputMaybe<TaskType>>>;
  extraNote?: InputMaybe<Scalars['String']>;
  followupSchedule?: InputMaybe<Scalars['String']>;
  goals?: InputMaybe<Scalars['String']>;
  goalsFlag?: InputMaybe<Scalars['String']>;
  id: Scalars['EID'];
  interventions?: InputMaybe<Scalars['String']>;
  manualInputEnable?: InputMaybe<Scalars['Boolean']>;
  manualInputEnabledTaskTypes?: InputMaybe<Array<InputMaybe<TaskType>>>;
  problemList?: InputMaybe<Scalars['String']>;
  resource?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<Array<InputMaybe<InputTaskEnrolled>>>;
  teamId?: InputMaybe<Scalars['EID']>;
};


export type MutationEditFeedbackArgs = {
  feedback?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['EID']>;
  rating?: InputMaybe<Scalars['Int']>;
};


export type MutationEditFileUploadArgs = {
  fileInfo?: InputMaybe<FileInfoInput>;
  id: Scalars['EID'];
};


export type MutationEditInsuranceProviderArgs = {
  clearingHouse?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['EID'];
  payerId?: InputMaybe<Scalars['String']>;
  payerName?: InputMaybe<Scalars['String']>;
};


export type MutationEditIoTDeviceArgs = {
  accessedAt?: InputMaybe<Scalars['Date']>;
  deviceId: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['Int']>;
  quittedAt?: InputMaybe<Scalars['Date']>;
};


export type MutationEditIoTDeviceConfigArgs = {
  bpUnit?: InputMaybe<IoTBpUnitEnum>;
  countryId?: InputMaybe<Scalars['String']>;
  dateFormat?: InputMaybe<IoTDateFormatEnum>;
  deviceId: Scalars['String'];
  firmwareVersion?: InputMaybe<Scalars['String']>;
  hardwareVersion?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  protocol?: InputMaybe<Scalars['String']>;
  temperatureUnit?: InputMaybe<IoTTemperatureUnitEnum>;
  timbre?: InputMaybe<Scalars['Int']>;
  timeFormat?: InputMaybe<Scalars['Int']>;
  timezone?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['Int']>;
};


export type MutationEditLoanDeviceArgs = {
  appVersion?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  deviceType?: InputMaybe<LoanDeviceTypeEnum>;
  osVersion?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
};


export type MutationEditMeasurementNotesArgs = {
  id: Scalars['EID'];
  user_notes: Scalars['String'];
};


export type MutationEditMedicationArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  dosage?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  healthCondition?: InputMaybe<HealthConditionEnum>;
  id: Scalars['EID'];
  instruction?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['EID']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['EID']>;
  otherMD?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<MedicationStatus>;
  type?: InputMaybe<MedicationTypeEnum>;
};


export type MutationEditMentorArgs = {
  healthCoachId: Scalars['EID'];
  mentorId: Scalars['EID'];
};


export type MutationEditMissingInfoArgs = {
  id: Scalars['EID'];
  status?: InputMaybe<Scalars['String']>;
};


export type MutationEditMonthlyReviewArgs = {
  goals?: InputMaybe<Array<InputMaybe<MonthlyReviewGoalInput>>>;
  id: Scalars['EID'];
  note?: InputMaybe<Scalars['String']>;
  preSummary?: InputMaybe<MonthlyReviewSummaryInput>;
  previousGoals?: InputMaybe<Array<InputMaybe<PreviousGoalsInput>>>;
  previousVitals?: InputMaybe<Array<InputMaybe<PreviousVitalsInput>>>;
  status?: InputMaybe<MonthlyReviewStatus>;
};


export type MutationEditMonthlyTimeSpentArgs = {
  billed: Scalars['Boolean'];
  id: Scalars['EID'];
};


export type MutationEditMultiPesStatementsArgs = {
  pesStatements?: InputMaybe<Array<InputMaybe<InputPesStatement>>>;
};


export type MutationEditOrderArgs = {
  id?: InputMaybe<Scalars['EID']>;
  phone?: InputMaybe<InputPhone>;
  pickup?: InputMaybe<Scalars['Boolean']>;
  pickupLocation?: InputMaybe<Scalars['String']>;
  shipTo?: InputMaybe<OrderShipToInput>;
  status?: InputMaybe<OrderStatusEnum>;
};


export type MutationEditOrgScheduleArgs = {
  affectiveDays?: InputMaybe<Array<InputMaybe<DayOfWeek>>>;
  blockingRoles?: InputMaybe<Array<InputMaybe<RoleType>>>;
  endTime?: InputMaybe<Scalars['Date']>;
  id: Scalars['EID'];
  startTime?: InputMaybe<Scalars['Date']>;
};


export type MutationEditOrganizationArgs = {
  EHR?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<InputAddress>;
  brandedName?: InputMaybe<Scalars['String']>;
  consentTemplateIds?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  description?: InputMaybe<Scalars['String']>;
  excludeBilling?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['EID'];
  identifier?: InputMaybe<Scalars['String']>;
  internalName?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  monthlyEnrollGoalCount?: InputMaybe<Scalars['Int']>;
  monthlyEnrollGoalMonth?: InputMaybe<Scalars['Int']>;
  monthlyEnrollGoalYear?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  operationModel?: InputMaybe<OrgOperationModelEnum>;
  orgPermissions?: InputMaybe<OrgPermissionsInput>;
  parentId?: InputMaybe<Scalars['EID']>;
  phone?: InputMaybe<InputPhone>;
  potentialPatientCount?: InputMaybe<Scalars['Int']>;
  programParticipation?: InputMaybe<Array<InputMaybe<ProgramParticipationEnum>>>;
  startDate?: InputMaybe<Scalars['Date']>;
  templateGroupIds?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  timezone?: InputMaybe<Scalars['String']>;
};


export type MutationEditPesStatementArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  etiology?: InputMaybe<Array<InputMaybe<InputStatement>>>;
  id: Scalars['EID'];
  problem?: InputMaybe<InputStatement>;
  resolution?: InputMaybe<InputResolution>;
  signSymptom?: InputMaybe<Array<InputMaybe<InputStatement>>>;
};


export type MutationEditPostItArgs = {
  id: Scalars['EID'];
  memberId?: InputMaybe<Scalars['EID']>;
  note?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['EID']>;
};


export type MutationEditProviderNoteArgs = {
  category?: InputMaybe<ProviderNoteCategory>;
  content?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['EID'];
  pauseDuration?: InputMaybe<Scalars['Int']>;
  resumeDate?: InputMaybe<Scalars['Date']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationEditRemoteEnrollmentArgs = {
  billingCodeAttached?: InputMaybe<Scalars['Boolean']>;
  carePlanFiled?: InputMaybe<Scalars['Boolean']>;
  conditionsList?: InputMaybe<InputConditionsListType>;
  consentFiled?: InputMaybe<Scalars['Boolean']>;
  deviceDelivered?: InputMaybe<Scalars['Boolean']>;
  doctorReferral?: InputMaybe<Scalars['Boolean']>;
  excludedReason?: InputMaybe<RemoteEnrollmentExcludedReasonEnums>;
  lastCallBy?: InputMaybe<InputRemoteEnrollmentCallRecord>;
  memberId: Scalars['EID'];
  nutritionAssessment?: InputMaybe<Scalars['Boolean']>;
  pinnedToEMR?: InputMaybe<Scalars['Boolean']>;
  shippingNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<RemoteEnrollmentStatus>;
  techOnBoard?: InputMaybe<Scalars['Boolean']>;
};


export type MutationEditResultArgs = {
  beforeMeal?: InputMaybe<Scalars['Boolean']>;
  closed?: InputMaybe<Scalars['Boolean']>;
  comment?: InputMaybe<ResultCommentInput>;
  date?: InputMaybe<Scalars['Date']>;
  hadActivity?: InputMaybe<Scalars['Boolean']>;
  hadInsulin?: InputMaybe<Scalars['Boolean']>;
  hadMedicine?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['EID']>;
  mealType?: InputMaybe<Scalars['String']>;
  measureIndex?: InputMaybe<Scalars['Int']>;
  moodTags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  refMeasurementId?: InputMaybe<Scalars['EID']>;
  reviewed?: InputMaybe<Scalars['Boolean']>;
  triageLevel?: InputMaybe<TriageLevel>;
  weight?: InputMaybe<Scalars['HSValue']>;
};


export type MutationEditSelfEvaluationArgs = {
  CPODAns?: InputMaybe<Scalars['String']>;
  areasOfImprovement?: InputMaybe<Array<InputMaybe<AreasOfImprovementEnums>>>;
  bloodPressureAns?: InputMaybe<Yes_No_Not_Sure_Enums>;
  diabetesAns?: InputMaybe<DiabetesAnsEnums>;
  evaluations?: InputMaybe<Array<InputMaybe<InputEvaluation>>>;
  id: Scalars['EID'];
  otherAreaAns?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationEditTaskAssignmentArgs = {
  assignedTo?: InputMaybe<Scalars['EID']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['EID'];
  priorityLevel?: InputMaybe<TaskPriorityLevel>;
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationEditTeamArgs = {
  consentProviderName?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['EmailAddress']>;
  forwardingPhone?: InputMaybe<InputPhone>;
  healthCondition?: InputMaybe<Scalars['String']>;
  id: Scalars['EID'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<InputPhone>;
  primary?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  providerId?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  visibleMembers?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
};


export type MutationEditTemplateGroupArgs = {
  id: Scalars['EID'];
  name: Scalars['String'];
};


export type MutationEditUserArgs = {
  address?: InputMaybe<Array<InputMaybe<InputAddress>>>;
  commonProfile?: InputMaybe<InputCommonProfileEdit>;
  email?: InputMaybe<Array<InputMaybe<Scalars['EmailAddress']>>>;
  employeeProfile?: InputMaybe<InputEmployeeProfile>;
  id: Scalars['EID'];
  identification?: InputMaybe<InputIdentification>;
  isTestUser?: InputMaybe<Scalars['Boolean']>;
  lastReleaseRead?: InputMaybe<Scalars['Int']>;
  memberProfile?: InputMaybe<InputMemberProfile>;
  phone?: InputMaybe<Array<InputMaybe<InputPhone>>>;
  residency?: InputMaybe<InputMemberResidency>;
  shipAddresses?: InputMaybe<Array<InputMaybe<InputAddress>>>;
  userSettings?: InputMaybe<InputUserSettings>;
  username?: InputMaybe<Scalars['String']>;
};


export type MutationEditUserHandoutsArgs = {
  handouts?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  id: Scalars['EID'];
};


export type MutationEditUserMedicationManagementArgs = {
  id: Scalars['EID'];
  medicationManagement?: InputMaybe<InputMedicationManagement>;
};


export type MutationEditUserNutritionArgs = {
  id: Scalars['EID'];
  nutrition?: InputMaybe<InputNutrition>;
};


export type MutationEditUserSocialSupportArgs = {
  id: Scalars['EID'];
  socialSupport?: InputMaybe<InputSocialSupport>;
};


export type MutationEditVisitArgs = {
  A1C?: InputMaybe<InputA1CObject>;
  appointmentAt?: InputMaybe<Scalars['Date']>;
  appointmentTo?: InputMaybe<Scalars['Date']>;
  assignees?: InputMaybe<AssigneeInput>;
  associatedVisitId?: InputMaybe<Scalars['EID']>;
  category?: InputMaybe<VisitCategoryEnum>;
  checkinAt?: InputMaybe<Scalars['Date']>;
  checkoutAt?: InputMaybe<Scalars['Date']>;
  conditionsList?: InputMaybe<InputConditionsListType>;
  declineToCheckVital?: InputMaybe<Scalars['Boolean']>;
  details?: InputMaybe<VisitDetailsInput>;
  deviceRecordCount?: InputMaybe<InputUserDeviceRecordCount>;
  doctorNotes?: InputMaybe<Scalars['String']>;
  id: Scalars['EID'];
  inventoryCount?: InputMaybe<InputUserDeviceRecordCount>;
  needInHouseA1C?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['EID']>;
  programIds?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  providerIds?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  reason?: InputMaybe<Scalars['String']>;
  remoteStatus?: InputMaybe<Scalars['Boolean']>;
  remoteTask?: InputMaybe<InputRemoteTask>;
  reviewBillable?: InputMaybe<Scalars['Boolean']>;
  shouldSeeDoctor?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<VisitTypeEnum>;
  unableToCheckVital?: InputMaybe<Scalars['Boolean']>;
  userAppLanguage?: InputMaybe<Language>;
  userDeviceRecords?: InputMaybe<InputUserDeviceRecords>;
  userLanguage?: InputMaybe<Language>;
  userTechLevel?: InputMaybe<TechLevel>;
  visitRoles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationEnableEnrolledProgramTaskArgs = {
  id: Scalars['EID'];
  taskId: Scalars['EID'];
};


export type MutationEnrollMemberArgs = {
  adminProgramId: Scalars['EID'];
  customizedTasks?: InputMaybe<Array<InputMaybe<InputTask>>>;
  endDate?: InputMaybe<Scalars['Date']>;
  managerId?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  memberId: Scalars['EID'];
  products?: InputMaybe<Array<InputMaybe<InventoryProductInput>>>;
  startDate: Scalars['Date'];
  teamId: Array<InputMaybe<Scalars['EID']>>;
};


export type MutationEnrollPatientConsentArgs = {
  consentProviderName?: InputMaybe<Scalars['String']>;
  consentType?: InputMaybe<Scalars['String']>;
  effectiveDate: Scalars['Date'];
  memberId: Scalars['EID'];
  providers?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  timezone?: InputMaybe<Scalars['String']>;
};


export type MutationExtendEnrolledProgramArgs = {
  endDate: Scalars['Date'];
  id: Scalars['EID'];
};


export type MutationFlagMemberArgs = {
  id: Scalars['EID'];
  notifiedProviders?: InputMaybe<Array<InputMaybe<NotifiedProviderInput>>>;
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationHandleAlertArgs = {
  id?: InputMaybe<Scalars['EID']>;
};


export type MutationInitiateEnrolledProgramArgs = {
  token: Scalars['Token'];
};


export type MutationInsertAssessmentFilledArgs = {
  assessmentId: Scalars['EID'];
  date: Scalars['Date'];
  memberId: Scalars['EID'];
  questionResults?: InputMaybe<Array<InputMaybe<InputAssessmentFilledQuestion>>>;
  submit?: InputMaybe<Scalars['Boolean']>;
  timezone: Scalars['String'];
};


export type MutationInsertBgArgs = {
  auditInfo?: InputMaybe<AuditInfo>;
  beforeMeal?: InputMaybe<Scalars['Boolean']>;
  date: Scalars['Date'];
  deviceAddress?: InputMaybe<Scalars['String']>;
  deviceModel?: InputMaybe<BgDeviceModel>;
  glucose: Scalars['BGValue'];
  hadActivity?: InputMaybe<Scalars['Boolean']>;
  hadInsulin?: InputMaybe<Scalars['Boolean']>;
  hadMedicine?: InputMaybe<Scalars['Boolean']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  manualInput?: InputMaybe<Scalars['Boolean']>;
  mealTimePassed?: InputMaybe<Scalars['Int']>;
  mealType?: InputMaybe<BgMealType>;
  memberId?: InputMaybe<Scalars['EID']>;
  moodTags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notes?: InputMaybe<Scalars['String']>;
  point?: InputMaybe<Scalars['Boolean']>;
  timezone: Scalars['String'];
};


export type MutationInsertBpArgs = {
  arrhythmia?: InputMaybe<Scalars['Boolean']>;
  auditInfo?: InputMaybe<AuditInfo>;
  date: Scalars['Date'];
  deviceAddress?: InputMaybe<Scalars['String']>;
  deviceModel?: InputMaybe<BpDeviceModel>;
  diastolic: Scalars['BPValue'];
  hadActivity?: InputMaybe<Scalars['Boolean']>;
  hadInsulin?: InputMaybe<Scalars['Boolean']>;
  hadMedicine?: InputMaybe<Scalars['Boolean']>;
  heartrate?: InputMaybe<Scalars['HRValue']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  manualInput?: InputMaybe<Scalars['Boolean']>;
  measureIndex?: InputMaybe<Scalars['Int']>;
  memberId?: InputMaybe<Scalars['EID']>;
  moodTags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notes?: InputMaybe<Scalars['String']>;
  point?: InputMaybe<Scalars['Boolean']>;
  posture?: InputMaybe<Scalars['BPPosture']>;
  refMeasurementId?: InputMaybe<Scalars['EID']>;
  systolic: Scalars['BPValue'];
  timezone: Scalars['String'];
  triageLevel?: InputMaybe<TriageLevel>;
  wavelet?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type MutationInsertEcgArgs = {
  abnormalPointCount?: InputMaybe<Scalars['Int']>;
  auditInfo?: InputMaybe<AuditInfo>;
  averageHeartRate?: InputMaybe<Scalars['HRValue']>;
  date: Scalars['Date'];
  detailsReferenceKey?: InputMaybe<Scalars['String']>;
  deviceAddress?: InputMaybe<Scalars['String']>;
  deviceModel?: InputMaybe<EcgDeviceModel>;
  endTime?: InputMaybe<Scalars['Date']>;
  hadActivity?: InputMaybe<Scalars['Boolean']>;
  hadInsulin?: InputMaybe<Scalars['Boolean']>;
  hadMedicine?: InputMaybe<Scalars['Boolean']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  manualInput?: InputMaybe<Scalars['Boolean']>;
  maxHeartRate?: InputMaybe<Scalars['HRValue']>;
  memberId?: InputMaybe<Scalars['EID']>;
  moodTags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notes?: InputMaybe<Scalars['String']>;
  point?: InputMaybe<Scalars['Boolean']>;
  startTime?: InputMaybe<Scalars['Date']>;
  timezone: Scalars['String'];
  totalHeartBeats?: InputMaybe<Scalars['Int']>;
};


export type MutationInsertExerciseArgs = {
  auditInfo?: InputMaybe<AuditInfo>;
  date: Scalars['Date'];
  details?: InputMaybe<Array<InputMaybe<StepDetailInput>>>;
  deviceAddress?: InputMaybe<Scalars['String']>;
  deviceModel?: InputMaybe<Scalars['String']>;
  hadActivity?: InputMaybe<Scalars['Boolean']>;
  hadInsulin?: InputMaybe<Scalars['Boolean']>;
  hadMedicine?: InputMaybe<Scalars['Boolean']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['EID']>;
  moodTags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notes?: InputMaybe<Scalars['String']>;
  point?: InputMaybe<Scalars['Boolean']>;
  steps: Scalars['STEPValue'];
  timezone: Scalars['String'];
};


export type MutationInsertHrArgs = {
  date: Scalars['Date'];
  hadActivity?: InputMaybe<Scalars['Boolean']>;
  hadInsulin?: InputMaybe<Scalars['Boolean']>;
  hadMedicine?: InputMaybe<Scalars['Boolean']>;
  heartrate: Scalars['HRValue'];
  imageUrl?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['EID']>;
  moodTags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notes?: InputMaybe<Scalars['String']>;
  point?: InputMaybe<Scalars['Boolean']>;
  timezone: Scalars['String'];
};


export type MutationInsertHsArgs = {
  auditInfo?: InputMaybe<AuditInfo>;
  date: Scalars['Date'];
  deviceAddress?: InputMaybe<Scalars['String']>;
  deviceModel?: InputMaybe<HsDeviceModel>;
  hadActivity?: InputMaybe<Scalars['Boolean']>;
  hadInsulin?: InputMaybe<Scalars['Boolean']>;
  hadMedicine?: InputMaybe<Scalars['Boolean']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  manualInput?: InputMaybe<Scalars['Boolean']>;
  memberId?: InputMaybe<Scalars['EID']>;
  moodTags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notes?: InputMaybe<Scalars['String']>;
  point?: InputMaybe<Scalars['Boolean']>;
  timezone: Scalars['String'];
  weight: Scalars['HSValue'];
};


export type MutationInsertPoArgs = {
  auditInfo?: InputMaybe<AuditInfo>;
  bloodOxygen: Scalars['POValue'];
  date: Scalars['Date'];
  details?: InputMaybe<Array<InputMaybe<PoItemInput>>>;
  deviceAddress?: InputMaybe<Scalars['String']>;
  deviceModel?: InputMaybe<PoDeviceModel>;
  hadActivity?: InputMaybe<Scalars['Boolean']>;
  hadInsulin?: InputMaybe<Scalars['Boolean']>;
  hadMedicine?: InputMaybe<Scalars['Boolean']>;
  heartrate?: InputMaybe<Scalars['HRValue']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  manualInput?: InputMaybe<Scalars['Boolean']>;
  memberId?: InputMaybe<Scalars['EID']>;
  moodTags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notes?: InputMaybe<Scalars['String']>;
  perfusionIndex?: InputMaybe<Scalars['PIValue']>;
  point?: InputMaybe<Scalars['Boolean']>;
  timezone: Scalars['String'];
};


export type MutationInsertRewardRedeemSurveyArgs = {
  comment?: InputMaybe<Scalars['String']>;
  memberId: Scalars['EID'];
  rewardRedeemTemplateId: Scalars['EID'];
  status: RedeemSurveyStatus;
  surveys?: InputMaybe<Array<InputMaybe<InputRedeemSurveySchema>>>;
};


export type MutationInsertTmArgs = {
  auditInfo?: InputMaybe<AuditInfo>;
  bodyTemperature: Scalars['TMValue'];
  date: Scalars['Date'];
  deviceAddress?: InputMaybe<Scalars['String']>;
  deviceModel?: InputMaybe<TmDeviceModel>;
  hadActivity?: InputMaybe<Scalars['Boolean']>;
  hadInsulin?: InputMaybe<Scalars['Boolean']>;
  hadMedicine?: InputMaybe<Scalars['Boolean']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  manualInput?: InputMaybe<Scalars['Boolean']>;
  memberId?: InputMaybe<Scalars['EID']>;
  moodTags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notes?: InputMaybe<Scalars['String']>;
  point?: InputMaybe<Scalars['Boolean']>;
  timezone: Scalars['String'];
};


export type MutationInsertTaskResultArgs = {
  assessmentFilledId?: InputMaybe<Scalars['EID']>;
  measureId?: InputMaybe<Scalars['EID']>;
  refId: Array<InputMaybe<Scalars['Ref']>>;
};


export type MutationInsertTaskResultRelaxedArgs = {
  afterTaskComplete?: InputMaybe<Scalars['Boolean']>;
  assessmentFilledId?: InputMaybe<Scalars['EID']>;
  hadActivity?: InputMaybe<Scalars['Boolean']>;
  hadInsulin?: InputMaybe<Scalars['Boolean']>;
  hadMedicine?: InputMaybe<Scalars['Boolean']>;
  measureId?: InputMaybe<Scalars['EID']>;
  moodTags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  refId: Array<InputMaybe<Scalars['Ref']>>;
};


export type MutationLabResultCreateArgs = {
  record: CreateOneLabResultsInput;
};


export type MutationLabResultTemplateCreateArgs = {
  record: CreateOneLabResultTemplatesInput;
};


export type MutationLabResultTemplateRemoveArgs = {
  _id: Scalars['MongoID'];
};


export type MutationLabResultUpdateByIdArgs = {
  record: UpdateByIdLabResultsInput;
};


export type MutationLabResultUpdateManyArgs = {
  filter?: InputMaybe<FilterUpdateManyLabResultsInput>;
  limit?: InputMaybe<Scalars['Int']>;
  record: UpdateManyLabResultsInput;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortUpdateManyLabResultsInput>;
};


export type MutationLoginWithIdentificationArgs = {
  identification: Scalars['String'];
  password: Scalars['String'];
};


export type MutationLoginWithOneClickMeetingArgs = {
  code: Scalars['String'];
  verification?: InputMaybe<Scalars['String']>;
};


export type MutationLoginWithOtpArgs = {
  countryCode?: InputMaybe<Scalars['String']>;
  number: Scalars['String'];
  otpCode: Scalars['String'];
};


export type MutationLoginWithTokenArgs = {
  token: Scalars['Token'];
};


export type MutationLoginWithUsernameOrEmailArgs = {
  auditInfo?: InputMaybe<AuditInfo>;
  password: Scalars['String'];
  usernameOrEmail: Scalars['String'];
};


export type MutationLogoutAndClearTokenArgs = {
  token: Scalars['Token'];
};


export type MutationManageTeamForEnrolledProgramArgs = {
  id: Scalars['EID'];
  managerId?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  teamId: Array<InputMaybe<Scalars['EID']>>;
};


export type MutationMarkOnCallDoctorArgs = {
  memberId: Scalars['EID'];
  onCall: Scalars['Boolean'];
};


export type MutationMarkPatientAsReviewedArgs = {
  userId: Scalars['EID'];
};


export type MutationMarkProviderNoteErrorArgs = {
  id: Scalars['EID'];
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationMuteMissingInfoArgs = {
  memberId: Scalars['EID'];
  mute: Scalars['Boolean'];
  note: Scalars['String'];
};


export type MutationPostVirtualMeetingEventArgs = {
  eventType?: InputMaybe<VirtualMeetingEventType>;
  params?: InputMaybe<VirtualMeetingventParams>;
  visitId: Scalars['EID'];
};


export type MutationPublishAssessmentArgs = {
  nameKey: Scalars['String'];
};


export type MutationRateFoodLogArgs = {
  id?: InputMaybe<Scalars['EID']>;
  rating: FoodLogRatingInput;
};


export type MutationReadSignOffCommentArgs = {
  id: Scalars['EID'];
};


export type MutationRemoveChatChannelArgs = {
  adminProgramId: Scalars['EID'];
  patientId: Scalars['EID'];
  teamId: Scalars['EID'];
};


export type MutationRemoveDeviceFromEnrolledProgramArgs = {
  device: DeviceInput;
  id: Scalars['EID'];
};


export type MutationRemoveDeviceFromHubArgs = {
  devices?: InputMaybe<Array<InputMaybe<IoTDeviceInput>>>;
  id?: InputMaybe<Scalars['EID']>;
  serialNumber?: InputMaybe<Scalars['String']>;
};


export type MutationRemoveFromInventoryArgs = {
  memberId: Scalars['EID'];
  note?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['EID'];
  products: Array<InventoryProductInput>;
  visitId?: InputMaybe<Scalars['EID']>;
};


export type MutationRemoveIoTDeviceArgs = {
  deviceId: Scalars['String'];
};


export type MutationRemoveIoTDeviceConfigArgs = {
  deviceId: Scalars['String'];
};


export type MutationRemoveIoTHubArgs = {
  id?: InputMaybe<Scalars['EID']>;
  serialNumber?: InputMaybe<Scalars['String']>;
};


export type MutationRemoveMentorRelationArgs = {
  healthCoachId?: InputMaybe<Scalars['EID']>;
  mentorId?: InputMaybe<Scalars['EID']>;
};


export type MutationRemoveOrgManagersArgs = {
  managerIds: Array<Scalars['EID']>;
  organizationId: Scalars['EID'];
};


export type MutationRemoveTaskFromAdminProgramArgs = {
  id: Scalars['EID'];
  taskId?: InputMaybe<Array<Scalars['EID']>>;
};


export type MutationRemoveTaskFromEnrolledProgramArgs = {
  id: Scalars['EID'];
  taskId: Array<InputMaybe<Scalars['EID']>>;
};


export type MutationRemoveTeamMembersArgs = {
  memberIds: Array<Scalars['EID']>;
  teamId: Scalars['EID'];
};


export type MutationRemoveUserRolesArgs = {
  defaultRole?: InputMaybe<Scalars['Ref']>;
  id: Scalars['EID'];
  roles?: InputMaybe<Array<InputMaybe<Scalars['Ref']>>>;
};


export type MutationRequestResetPasswordArgs = {
  email?: InputMaybe<Scalars['EmailAddress']>;
  username?: InputMaybe<Scalars['String']>;
};


export type MutationResendOnBoardEmailArgs = {
  email?: InputMaybe<Scalars['EmailAddress']>;
  id: Scalars['EID'];
};


export type MutationResetCurrentTaskCompletionStatusArgs = {
  memberId?: InputMaybe<Scalars['EID']>;
};


export type MutationResetPasswordArgs = {
  newPass: Scalars['String'];
  token?: InputMaybe<Scalars['String']>;
};


export type MutationResolveTaskAssignmentArgs = {
  id: Scalars['EID'];
  resolvedNote: Scalars['String'];
};


export type MutationResumeEnrolledProgramArgs = {
  id: Scalars['EID'];
};


export type MutationResumePatientHiatusArgs = {
  memberId: Scalars['EID'];
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationRewardRedeemArgs = {
  memberId: Scalars['EID'];
  rewardRedeemTemplateId: Scalars['EID'];
  timezone: Scalars['String'];
};


export type MutationScanStripArgs = {
  bottleId: Scalars['String'];
  expireAt: Scalars['Date'];
  maxUsage: Scalars['Int'];
  memberId?: InputMaybe<Scalars['EID']>;
};


export type MutationSendInvitationLinkArgs = {
  memberId: Scalars['EID'];
};


export type MutationSendMailArgs = {
  bcc?: InputMaybe<Scalars['String']>;
  cc?: InputMaybe<Scalars['String']>;
  html?: InputMaybe<Scalars['String']>;
  subject: Scalars['String'];
  text?: InputMaybe<Scalars['String']>;
  to: Scalars['String'];
};


export type MutationSendMemberSmsArgs = {
  countryCode?: InputMaybe<Scalars['String']>;
  memberId: Scalars['EID'];
  number: Scalars['String'];
  type: SmsMsgTypeEnum;
};


export type MutationSendOtpCodeArgs = {
  countryCode?: InputMaybe<Scalars['String']>;
  number: Scalars['String'];
};


export type MutationSendVerificationEmailArgs = {
  email: Scalars['EmailAddress'];
};


export type MutationSetCoveredMembersArgs = {
  members?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
};


export type MutationSetCoveredOrganizationsArgs = {
  isDefault?: InputMaybe<Scalars['Boolean']>;
  memberId?: InputMaybe<Scalars['EID']>;
  organizations?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
};


export type MutationSetDateForEnrolledProgramArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['EID'];
  startDate?: InputMaybe<Scalars['Date']>;
};


export type MutationSetDevicesForAdminProgramArgs = {
  devices: AdminProgramDevicesInput;
  id: Scalars['EID'];
};


export type MutationSetPatientAlertConfigArgs = {
  config?: InputMaybe<Array<InputMaybe<InputSmartAlertConfig>>>;
  memberId: Scalars['EID'];
};


export type MutationSetPatientHiatusStatusArgs = {
  endDate: Scalars['Date'];
  memberId: Scalars['EID'];
  note?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Date'];
  timezone: Scalars['String'];
  visitHiatus?: InputMaybe<Scalars['Boolean']>;
  vitalsMonitoringHiatus?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSetSelectedClinicArgs = {
  organizations?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
};


export type MutationSetSystemDynamicConfigArgs = {
  id: Scalars['EID'];
  value: Scalars['String'];
};


export type MutationSetTaskAssignmentAsReadArgs = {
  id: Scalars['EID'];
};


export type MutationSetUserPasswordArgs = {
  id: Scalars['EID'];
  newPass: Scalars['String'];
};


export type MutationSignOffByMentorArgs = {
  comment: Scalars['String'];
  id: Scalars['EID'];
};


export type MutationSignOffMonthlyReviewArgs = {
  id: Scalars['EID'];
};


export type MutationSignOffVisitArgs = {
  id: Scalars['EID'];
  signOffStatus?: InputMaybe<InputSignOffStatus>;
};


export type MutationStarMemberArgs = {
  id: Scalars['EID'];
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationSubmitPatientConsentArgs = {
  fileContent: Scalars['String'];
  memberId: Scalars['EID'];
};


export type MutationSuspendEnrolledProgramArgs = {
  id: Scalars['EID'];
};


export type MutationTerminateOrganizationArgs = {
  id: Scalars['EID'];
};


export type MutationTimeTrackerCreateArgs = {
  record: CreateOneTimeTrackerInput;
};


export type MutationTimeTrackerUpdateByIdArgs = {
  record: UpdateByIdTimeTrackerInput;
};


export type MutationTimeTrackerUpdateManyArgs = {
  filter?: InputMaybe<FilterUpdateManyTimeTrackerInput>;
  limit?: InputMaybe<Scalars['Int']>;
  record: UpdateManyTimeTrackerInput;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortUpdateManyTimeTrackerInput>;
};


export type MutationUnFlagMemberArgs = {
  id: Scalars['EID'];
  notifiedProviders?: InputMaybe<Array<InputMaybe<NotifiedProviderInput>>>;
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationUnassignLoanDeviceArgs = {
  deviceId?: InputMaybe<Scalars['String']>;
  memberId: Scalars['EID'];
  serialNumber?: InputMaybe<Scalars['String']>;
};


export type MutationUnstarMemberArgs = {
  id: Scalars['EID'];
};


export type MutationUpdateAssessmentFilledArgs = {
  date: Scalars['Date'];
  id: Scalars['EID'];
  questionResults?: InputMaybe<Array<InputMaybe<InputAssessmentFilledQuestion>>>;
  submit?: InputMaybe<Scalars['Boolean']>;
  timezone: Scalars['String'];
};


export type MutationUpdateChannelAckTimestampsArgs = {
  channelAckTimestamps?: InputMaybe<Array<InputMaybe<ChannelAckTimestampInput>>>;
};


export type MutationUpdateHubMemberArgs = {
  devices?: InputMaybe<Array<InputMaybe<IoTDeviceInput>>>;
  id?: InputMaybe<Scalars['EID']>;
  memberId: Scalars['EID'];
  serialNumber?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateIoTDeviceMemberArgs = {
  deviceId: Scalars['String'];
  memberId?: InputMaybe<Scalars['EID']>;
  position?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateLastReviewedAtArgs = {
  adminProgramIds: Array<Scalars['EID']>;
  memberId: Scalars['EID'];
};


export type MutationUpdatePatientAssigneeArgs = {
  assignedToCA?: InputMaybe<Scalars['EID']>;
  assignedToCANotNecessary?: InputMaybe<Scalars['Boolean']>;
  assignedToRD?: InputMaybe<Scalars['EID']>;
  assignedToRDNotNecessary?: InputMaybe<Scalars['Boolean']>;
  memberId: Scalars['EID'];
};


export type MutationUpdatePatientComplexityArgs = {
  level: PatientComplexityLevel;
  memberId: Scalars['EID'];
  source?: InputMaybe<PatientComplexitySource>;
};


export type MutationUpdateVisitConfirmArgs = {
  confirm?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['EID'];
};


export type MutationUpdateVisitWorkingListArgs = {
  id: Scalars['EID'];
  maWorkingList?: InputMaybe<VisitMaWorkingListInput>;
  rdWorkingList?: InputMaybe<VisitRdWorkingListInput>;
};


export type MutationUploadChallengeBadgeArgs = {
  date: Scalars['Date'];
  timezone: Scalars['String'];
  type: ChallengeBadgeTypes;
};


export type MutationUploadChallengeRewardArgs = {
  date: Scalars['Date'];
  points?: InputMaybe<Scalars['Int']>;
  timezone: Scalars['String'];
  type: ChallengePointsTypes;
};


export type MutationUploadInsuranceArgs = {
  fileKey: Scalars['String'];
  memberId: Scalars['EID'];
};


export type MutationUploadPhoneReportArgs = {
  addToContactsStatus?: InputMaybe<PhoneReportInfoContactsStatus>;
  authorityInfo?: InputMaybe<InputAuthorityInfo>;
  connectedDeviceInfo?: InputMaybe<Array<InputMaybe<InputConnectedDeviceInfo>>>;
  exerciseStatus?: InputMaybe<InputExerciseStatus>;
  memberId: Scalars['EID'];
  mobileInfo?: InputMaybe<InputMobileInfo>;
};


export type MutationUploadUserDevicesInfoArgs = {
  devices?: InputMaybe<Array<InputMaybe<InputConnectedDeviceInfo>>>;
  fixTag?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['EID'];
};


export type MutationUpsertDeviceTokenArgs = {
  memberId: Scalars['EID'];
  platform: PushNotificationPlatformEnum;
  token: Scalars['String'];
};


export type MutationUpsertFeatureUploadArgs = {
  fileKeys: Array<InputMaybe<Scalars['String']>>;
  release: Scalars['Int'];
};


export type MutationUpsertPatientMntProgramArgs = {
  description?: InputMaybe<Scalars['String']>;
  ineligibledBy?: InputMaybe<Scalars['EID']>;
  mntConditions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  patientId: Scalars['EID'];
  referredBy?: InputMaybe<Scalars['EID']>;
  status?: InputMaybe<MntProgramStatusEnum>;
  visitId?: InputMaybe<Scalars['EID']>;
};


export type MutationUpsertPatientReferralArgs = {
  billableHealthConditions?: InputMaybe<Array<InputMaybe<InputBillableHealthCondition>>>;
  clinicGoals?: InputMaybe<Array<InputMaybe<InputClinicGoal>>>;
  conditionsToMonnitor?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  deviceAccessbility?: InputMaybe<Scalars['Boolean']>;
  devicePickup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  deviceShip?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  discussedWithPatient?: InputMaybe<Scalars['Boolean']>;
  doctorReferral?: InputMaybe<Scalars['Boolean']>;
  eligiblePrgorams?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  enrolledAnother?: InputMaybe<Scalars['Boolean']>;
  enrolledBy?: InputMaybe<Scalars['EID']>;
  enrolledByRole?: InputMaybe<Scalars['String']>;
  exclusionOtherReason?: InputMaybe<Scalars['String']>;
  haveSmartphone?: InputMaybe<Scalars['Boolean']>;
  helpedSetupAndUseDevice?: InputMaybe<Scalars['Boolean']>;
  initialVisitId?: InputMaybe<Scalars['EID']>;
  mntConditions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  patientDescribe?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['EID']>;
  recommendedVitals?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  referredBy?: InputMaybe<Scalars['EID']>;
  referredByRole?: InputMaybe<Scalars['String']>;
  shipAddresses?: InputMaybe<InputAddress>;
  status?: InputMaybe<InputReferralStatus>;
  techLevel?: InputMaybe<ReferTechLevelEnum>;
  wantMNTReferral?: InputMaybe<Scalars['Boolean']>;
  wouldJoin?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpsertUserPreferenceArgs = {
  followupVisits?: InputMaybe<UserPreferenceInput>;
  memberId: Scalars['EID'];
  nutritionCoaching?: InputMaybe<UserPreferenceInput>;
  sendOnlineClassNotice?: InputMaybe<UserPreferenceInput>;
  sendVitalReminder?: InputMaybe<UserPreferenceInput>;
};


export type MutationUpsertUserRolesArgs = {
  defaultRole?: InputMaybe<Scalars['Ref']>;
  id: Scalars['EID'];
  roles?: InputMaybe<Array<InputMaybe<Scalars['Ref']>>>;
};


export type MutationUpsertVersionHistoryArgs = {
  careplanData?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['CalDate']>;
  enrolledProgramId?: InputMaybe<Scalars['EID']>;
  itemsUpdated?: InputMaybe<Scalars['String']>;
  visitId?: InputMaybe<Scalars['EID']>;
};


export type MutationVerifyEmailArgs = {
  email: Scalars['EmailAddress'];
  verificationString: Scalars['String'];
};


export type MutationVerifyOnBoardTokenArgs = {
  token: Scalars['String'];
};


export type MutationWeeklyBgScheduleTemplateCreateArgs = {
  record: CreateOneWeeklyBgScheduleTemplatesInput;
};


export type MutationWeeklyBgScheduleTemplateRemoveArgs = {
  _id: Scalars['MongoID'];
};


export type MutationWeeklyBgScheduleTemplateUpdateArgs = {
  record: UpdateByIdWeeklyBgScheduleTemplatesInput;
};

export enum Nationality {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Al = 'AL',
  Am = 'AM',
  Ao = 'AO',
  Ar = 'AR',
  At = 'AT',
  Au = 'AU',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bn = 'BN',
  Bo = 'BO',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bu = 'BU',
  Bw = 'BW',
  Bz = 'BZ',
  Ca = 'CA',
  Cb = 'CB',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cy = 'CY',
  Cz = 'CZ',
  Dg = 'DG',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Eg = 'EG',
  En = 'EN',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gc = 'GC',
  Gd = 'GD',
  Ge = 'GE',
  Gg = 'GG',
  Gh = 'GH',
  Gj = 'GJ',
  Gm = 'GM',
  Gn = 'GN',
  Go = 'GO',
  Gq = 'GQ',
  Gr = 'GR',
  Gt = 'GT',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hn = 'HN',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  In = 'IN',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ka = 'KA',
  Ke = 'KE',
  Ki = 'KI',
  Km = 'KM',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Lh = 'LH',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mb = 'MB',
  Mc = 'MC',
  Md = 'MD',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mj = 'MJ',
  Ml = 'ML',
  Mn = 'MN',
  Mo = 'MO',
  Mr = 'MR',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Ne = 'NE',
  Ng = 'NG',
  Ni = 'NI',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nt = 'NT',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pn = 'PN',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Rf = 'RF',
  Ro = 'RO',
  Rs = 'RS',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Si = 'SI',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sw = 'SW',
  Sy = 'SY',
  Sz = 'SZ',
  Td = 'TD',
  Tg = 'TG',
  Th = 'TH',
  Ti = 'TI',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tp = 'TP',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ug = 'UG',
  Uk = 'UK',
  Un = 'UN',
  Ur = 'UR',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vn = 'VN',
  Vu = 'VU',
  Ws = 'WS',
  Ym = 'YM',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW'
}

export type NationalityCode = {
  __typename?: 'NationalityCode';
  code?: Maybe<Nationality>;
  description?: Maybe<Scalars['String']>;
};

/**
 * ## New Enrolled Program
 *  Result a new enrolled-program creation. Contains the newly started program.
 */
export type NewEnrolledProgram = {
  __typename?: 'NewEnrolledProgram';
  enrolledProgram?: Maybe<EnrolledProgram>;
  secret?: Maybe<Scalars['Token']>;
};

export type NoteStatsType = {
  __typename?: 'NoteStatsType';
  count?: Maybe<Scalars['Int']>;
  lastSeen?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
};

/** Various methods of notification */
export enum Notification {
  Email = 'EMAIL',
  PushNotification = 'PUSH_NOTIFICATION',
  Sms = 'SMS'
}

/** Notification frequency settings */
export enum NotificationFreq {
  NotificationsOff = 'NOTIFICATIONS_OFF',
  NotifyAlways = 'NOTIFY_ALWAYS',
  NotifyLess = 'NOTIFY_LESS'
}

export enum NotificationPerson {
  CareTeam = 'CARE_TEAM',
  Member = 'MEMBER'
}

export type NotificationResult = {
  __typename?: 'NotificationResult';
  message?: Maybe<Scalars['String']>;
  notified?: Maybe<Scalars['Boolean']>;
};

/** ## Notification Settings */
export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  chance?: Maybe<Scalars['Int']>;
  formula?: Maybe<AlertFormula>;
  tmpl?: Maybe<Array<Maybe<NotificationTmpl>>>;
  trigger?: Maybe<Array<Maybe<AlertTrigger>>>;
  type?: Maybe<Array<Maybe<Notification>>>;
};

/**
 * ## Notification Settings Input
 *  One of trigger or chance is required but not both. Input _trigger_ will create severity alerts
 *  and input _chance_ will create missed alerts.
 */
export type NotificationSettingInput = {
  chance?: InputMaybe<Scalars['Int']>;
  formula?: InputMaybe<AlertFormula>;
  tmpl: Array<InputNotificationTmpl>;
  trigger?: InputMaybe<Array<InputMaybe<AlertTrigger>>>;
  type: Array<InputMaybe<Notification>>;
};

/** ## Template for Notifications */
export type NotificationTmpl = {
  __typename?: 'NotificationTmpl';
  body?: Maybe<Scalars['String']>;
  person?: Maybe<NotificationPerson>;
  subject?: Maybe<Scalars['String']>;
};

export type NotifiedProviderInput = {
  id?: InputMaybe<Scalars['EID']>;
  types?: InputMaybe<Array<InputMaybe<FlagNotification>>>;
};

export type Nutrition = {
  __typename?: 'Nutrition';
  additionalComments?: Maybe<Scalars['String']>;
  behaviorChange?: Maybe<Scalars['String']>;
  extraNote?: Maybe<Scalars['String']>;
  fastFoodFreq?: Maybe<FastFoodFreq>;
  intake?: Maybe<Array<Maybe<MealIntake>>>;
  intakeNote?: Maybe<Scalars['String']>;
  interpretationNote?: Maybe<Scalars['String']>;
  nutritionUnderstanding?: Maybe<Scalars['String']>;
  previousDiets?: Maybe<Array<Maybe<Diets>>>;
  sweetBeverageFreq?: Maybe<SweetBeverageFreq>;
};

export type OnCallDoctor = {
  __typename?: 'OnCallDoctor';
  doctor?: Maybe<User>;
  onCall?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['EID']>;
};

export type OnCalleeOption = {
  __typename?: 'OnCalleeOption';
  fullName?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['EID']>;
};

export type OperatorsFilterFindManyAuditRecordInput = {
  /** Filter value by operator(s) */
  _id?: InputMaybe<_IdOperatorsFilterFindManyAuditRecordInput>;
  /** Filter value by operator(s) */
  docId?: InputMaybe<DocIdOperatorsFilterFindManyAuditRecordInput>;
  /** Filter value by operator(s) */
  group?: InputMaybe<GroupOperatorsFilterFindManyAuditRecordInput>;
  /** Filter value by operator(s) */
  sessionId?: InputMaybe<SessionIdOperatorsFilterFindManyAuditRecordInput>;
};

export type OperatorsFilterFindManyLabResultTemplatesInput = {
  /** Filter value by operator(s) */
  _id?: InputMaybe<_IdOperatorsFilterFindManyLabResultTemplatesInput>;
};

export type OperatorsFilterFindManyLabResultsInput = {
  /** Filter value by operator(s) */
  _id?: InputMaybe<_IdOperatorsFilterFindManyLabResultsInput>;
  /** Filter value by operator(s) */
  templateId?: InputMaybe<TemplateIdOperatorsFilterFindManyLabResultsInput>;
  /** Filter value by operator(s) */
  userId?: InputMaybe<UserIdOperatorsFilterFindManyLabResultsInput>;
};

export type OperatorsFilterFindManyTimeTrackerInput = {
  /** Filter value by operator(s) */
  _id?: InputMaybe<_IdOperatorsFilterFindManyTimeTrackerInput>;
  /** Filter value by operator(s) */
  abortTime?: InputMaybe<AbortTimeOperatorsFilterFindManyTimeTrackerInput>;
  /** Filter value by operator(s) */
  category?: InputMaybe<CategoryOperatorsFilterFindManyTimeTrackerInput>;
  /** Filter value by operator(s) */
  sessionId?: InputMaybe<SessionIdOperatorsFilterFindManyTimeTrackerInput>;
  /** Filter value by operator(s) */
  startTime?: InputMaybe<StartTimeOperatorsFilterFindManyTimeTrackerInput>;
  /** Filter value by operator(s) */
  stopTime?: InputMaybe<StopTimeOperatorsFilterFindManyTimeTrackerInput>;
  /** Filter value by operator(s) */
  userId?: InputMaybe<UserIdOperatorsFilterFindManyTimeTrackerInput>;
  /** Filter value by operator(s) */
  userOrganizationId?: InputMaybe<UserOrganizationIdOperatorsFilterFindManyTimeTrackerInput>;
  /** Filter value by operator(s) */
  viewerId?: InputMaybe<ViewerIdOperatorsFilterFindManyTimeTrackerInput>;
  /** Filter value by operator(s) */
  viewerOrganizationId?: InputMaybe<ViewerOrganizationIdOperatorsFilterFindManyTimeTrackerInput>;
};

export type OperatorsFilterFindManyWeeklyBgScheduleTemplatesInput = {
  /** Filter value by operator(s) */
  _id?: InputMaybe<_IdOperatorsFilterFindManyWeeklyBgScheduleTemplatesInput>;
};

export type OperatorsFilterLabResultsInput = {
  /** Filter value by operator(s) */
  _id?: InputMaybe<_IdOperatorsFilterLabResultsInput>;
  /** Filter value by operator(s) */
  templateId?: InputMaybe<TemplateIdOperatorsFilterLabResultsInput>;
  /** Filter value by operator(s) */
  userId?: InputMaybe<UserIdOperatorsFilterLabResultsInput>;
};

export type OperatorsFilterTimeTrackerInput = {
  /** Filter value by operator(s) */
  _id?: InputMaybe<_IdOperatorsFilterTimeTrackerInput>;
  /** Filter value by operator(s) */
  abortTime?: InputMaybe<AbortTimeOperatorsFilterTimeTrackerInput>;
  /** Filter value by operator(s) */
  category?: InputMaybe<CategoryOperatorsFilterTimeTrackerInput>;
  /** Filter value by operator(s) */
  sessionId?: InputMaybe<SessionIdOperatorsFilterTimeTrackerInput>;
  /** Filter value by operator(s) */
  startTime?: InputMaybe<StartTimeOperatorsFilterTimeTrackerInput>;
  /** Filter value by operator(s) */
  stopTime?: InputMaybe<StopTimeOperatorsFilterTimeTrackerInput>;
  /** Filter value by operator(s) */
  userId?: InputMaybe<UserIdOperatorsFilterTimeTrackerInput>;
  /** Filter value by operator(s) */
  userOrganizationId?: InputMaybe<UserOrganizationIdOperatorsFilterTimeTrackerInput>;
  /** Filter value by operator(s) */
  viewerId?: InputMaybe<ViewerIdOperatorsFilterTimeTrackerInput>;
  /** Filter value by operator(s) */
  viewerOrganizationId?: InputMaybe<ViewerOrganizationIdOperatorsFilterTimeTrackerInput>;
};

export type OperatorsFilterUpdateManyLabResultsInput = {
  /** Filter value by operator(s) */
  _id?: InputMaybe<_IdOperatorsFilterUpdateManyLabResultsInput>;
  /** Filter value by operator(s) */
  templateId?: InputMaybe<TemplateIdOperatorsFilterUpdateManyLabResultsInput>;
  /** Filter value by operator(s) */
  userId?: InputMaybe<UserIdOperatorsFilterUpdateManyLabResultsInput>;
};

export type OperatorsFilterUpdateManyTimeTrackerInput = {
  /** Filter value by operator(s) */
  _id?: InputMaybe<_IdOperatorsFilterUpdateManyTimeTrackerInput>;
  /** Filter value by operator(s) */
  abortTime?: InputMaybe<AbortTimeOperatorsFilterUpdateManyTimeTrackerInput>;
  /** Filter value by operator(s) */
  category?: InputMaybe<CategoryOperatorsFilterUpdateManyTimeTrackerInput>;
  /** Filter value by operator(s) */
  sessionId?: InputMaybe<SessionIdOperatorsFilterUpdateManyTimeTrackerInput>;
  /** Filter value by operator(s) */
  startTime?: InputMaybe<StartTimeOperatorsFilterUpdateManyTimeTrackerInput>;
  /** Filter value by operator(s) */
  stopTime?: InputMaybe<StopTimeOperatorsFilterUpdateManyTimeTrackerInput>;
  /** Filter value by operator(s) */
  userId?: InputMaybe<UserIdOperatorsFilterUpdateManyTimeTrackerInput>;
  /** Filter value by operator(s) */
  userOrganizationId?: InputMaybe<UserOrganizationIdOperatorsFilterUpdateManyTimeTrackerInput>;
  /** Filter value by operator(s) */
  viewerId?: InputMaybe<ViewerIdOperatorsFilterUpdateManyTimeTrackerInput>;
  /** Filter value by operator(s) */
  viewerOrganizationId?: InputMaybe<ViewerOrganizationIdOperatorsFilterUpdateManyTimeTrackerInput>;
};

/** ## Order input */
export type OrderInput = {
  memberId?: InputMaybe<Scalars['EID']>;
  orderPlanType?: InputMaybe<OrderPlanEnum>;
  phone?: InputMaybe<InputPhone>;
  pickup?: InputMaybe<Scalars['Boolean']>;
  pickupLocation?: InputMaybe<Scalars['String']>;
  shipTo?: InputMaybe<OrderShipToInput>;
};

export type OrderListOpts = {
  memberId?: InputMaybe<Scalars['EID']>;
  status?: InputMaybe<Scalars['String']>;
};

export type OrderListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<OrderListSortField>;
};

export enum OrderListSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

export enum OrderPlanEnum {
  StripLens_100 = 'STRIP_LENS_100'
}

export type OrderShipToInput = {
  address?: InputMaybe<InputAddress>;
  name?: InputMaybe<Scalars['String']>;
};

export type OrderShipToType = {
  __typename?: 'OrderShipToType';
  address?: Maybe<Address>;
  name?: Maybe<Scalars['String']>;
};

export enum OrderStatusEnum {
  Approved = 'APPROVED',
  PickedUp = 'PICKED_UP',
  Rejected = 'REJECTED',
  Shipped = 'SHIPPED'
}

/** ## Order type */
export type OrderType = {
  __typename?: 'OrderType';
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  id?: Maybe<Scalars['EID']>;
  member?: Maybe<User>;
  orderNumber?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  phone?: Maybe<Phone>;
  pickup?: Maybe<Scalars['Boolean']>;
  pickupLocation?: Maybe<Scalars['String']>;
  shipTo?: Maybe<OrderShipToType>;
  shipment?: Maybe<Shipment>;
  status?: Maybe<OrderStatusEnum>;
  team?: Maybe<Team>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type OrgAppCustomization = {
  __typename?: 'OrgAppCustomization';
  preferredUnit?: Maybe<PreferredUnits>;
  statusThreshold?: Maybe<OrgAppStatusThresholdGroup>;
  supportedDeviceTypes?: Maybe<Array<Maybe<DeviceType>>>;
};

export type OrgAppStatusThreshold = {
  __typename?: 'OrgAppStatusThreshold';
  alert?: Maybe<Scalars['Int']>;
};

export type OrgAppStatusThresholdGroup = {
  __typename?: 'OrgAppStatusThresholdGroup';
  BG?: Maybe<OrgAppStatusThreshold>;
  BP?: Maybe<OrgAppStatusThreshold>;
  EXERCISE?: Maybe<OrgAppStatusThreshold>;
  HR?: Maybe<OrgAppStatusThreshold>;
  HS?: Maybe<OrgAppStatusThreshold>;
  PO?: Maybe<OrgAppStatusThreshold>;
  TABLET?: Maybe<OrgAppStatusThreshold>;
  TM?: Maybe<OrgAppStatusThreshold>;
};

export type OrgCustomization = {
  __typename?: 'OrgCustomization';
  app?: Maybe<OrgAppCustomization>;
};

export type OrgFoodLogFilters = {
  withinLast?: InputMaybe<OrgNotificationTimeRange>;
};

export type OrgManager = {
  __typename?: 'OrgManager';
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
};

export type OrgNotificationAlertCount = {
  __typename?: 'OrgNotificationAlertCount';
  count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  level1Count?: Maybe<Scalars['Int']>;
  nonLevel1Count?: Maybe<Scalars['Int']>;
};

export type OrgNotificationCount = {
  __typename?: 'OrgNotificationCount';
  count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
};

/** Default values:  task = ONE_DAY, alert = ONE_DAY, foodLog = ONE_WEEK */
export enum OrgNotificationTimeRange {
  OneDay = 'ONE_DAY',
  OneMonth = 'ONE_MONTH',
  OneWeek = 'ONE_WEEK',
  TwelveHours = 'TWELVE_HOURS',
  TwoDays = 'TWO_DAYS',
  TwoWeeks = 'TWO_WEEKS'
}

export type OrgNotifications = {
  __typename?: 'OrgNotifications';
  alerts?: Maybe<Array<Maybe<OrgNotificationAlertCount>>>;
  foodLog?: Maybe<Array<Maybe<OrgNotificationCount>>>;
  tasks?: Maybe<Array<Maybe<OrgNotificationCount>>>;
};

export enum OrgOperationModelEnum {
  Hybrid = 'HYBRID',
  OnlineOffline = 'ONLINE_OFFLINE',
  Remote = 'REMOTE',
  Saas = 'SAAS'
}

/**
 * ## OrgPermissions
 *  Organization permissions
 */
export type OrgPermissions = {
  __typename?: 'OrgPermissions';
  BG5STrail?: Maybe<Scalars['Boolean']>;
  analyticsAPI?: Maybe<Scalars['Boolean']>;
  enableABTesting?: Maybe<Scalars['Boolean']>;
  enableAutoMessage?: Maybe<Scalars['Boolean']>;
  keepOwnDevices?: Maybe<Scalars['Boolean']>;
  otherCCMProvider?: Maybe<Scalars['Boolean']>;
  pinnedToEMRRequired?: Maybe<Scalars['Boolean']>;
  supportClear?: Maybe<Scalars['Boolean']>;
  supportLoanerPhone?: Maybe<Scalars['Boolean']>;
  useDefaultMobile?: Maybe<Scalars['Boolean']>;
};

/**
 * ## OrgPermissionsInput
 *  Organization permissions
 */
export type OrgPermissionsInput = {
  BG5STrail?: InputMaybe<Scalars['Boolean']>;
  analyticsAPI?: InputMaybe<Scalars['Boolean']>;
  enableABTesting?: InputMaybe<Scalars['Boolean']>;
  enableAutoMessage?: InputMaybe<Scalars['Boolean']>;
  keepOwnDevices?: InputMaybe<Scalars['Boolean']>;
  otherCCMProvider?: InputMaybe<Scalars['Boolean']>;
  pinnedToEMRRequired?: InputMaybe<Scalars['Boolean']>;
  supportClear?: InputMaybe<Scalars['Boolean']>;
  supportLoanerPhone?: InputMaybe<Scalars['Boolean']>;
  useDefaultMobile?: InputMaybe<Scalars['Boolean']>;
};

/** ## Organization schedule type */
export type OrgSchedule = {
  __typename?: 'OrgSchedule';
  affectiveDays?: Maybe<Array<Maybe<DayOfWeek>>>;
  blockingRoles?: Maybe<Array<Maybe<RoleType>>>;
  endTime?: Maybe<Scalars['Date']>;
  id: Scalars['EID'];
  startTime?: Maybe<Scalars['Date']>;
};

export enum OrgSearchFields {
  Description = 'DESCRIPTION',
  InternalName = 'INTERNAL_NAME',
  Name = 'NAME'
}

export type OrgSmartAlertFilters = {
  alertLevel?: InputMaybe<Array<InputMaybe<SmartAlertLevel>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  withinLast?: InputMaybe<OrgNotificationTimeRange>;
};

export type OrgTaskFilters = {
  withinLast?: InputMaybe<OrgNotificationTimeRange>;
};

export type OrgTeams = {
  __typename?: 'OrgTeams';
  organizationId?: Maybe<Scalars['EID']>;
  teamIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Organization = {
  __typename?: 'Organization';
  EHR?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  brandedName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  customization?: Maybe<OrgCustomization>;
  customizedFunctions?: Maybe<Array<Maybe<CustomizedFunctionType>>>;
  departments?: Maybe<Array<Maybe<OrganizationDepartment>>>;
  description?: Maybe<Scalars['String']>;
  excludeBilling?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['EID']>;
  identifier?: Maybe<Scalars['String']>;
  internalName?: Maybe<Scalars['String']>;
  leaf?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  managers?: Maybe<Array<Maybe<OrgManager>>>;
  monthlyEnrollGoalCount?: Maybe<Scalars['Int']>;
  monthlyEnrollGoalCountHistory?: Maybe<Array<Maybe<MonthlyEnrollGoalCountHistoryItem>>>;
  name?: Maybe<Scalars['String']>;
  notePreset?: Maybe<Array<Maybe<InterventionPreset>>>;
  operationModel?: Maybe<OrgOperationModelEnum>;
  orgPermissions?: Maybe<OrgPermissions>;
  orgSchedules?: Maybe<Array<Maybe<OrgSchedule>>>;
  orgSummary?: Maybe<OrganizationSummary>;
  parent?: Maybe<ParentOrganization>;
  phone?: Maybe<Phone>;
  potentialPatientCount?: Maybe<Scalars['Int']>;
  premiumClinic?: Maybe<Scalars['Boolean']>;
  programParticipation?: Maybe<Array<Maybe<ProgramParticipationEnum>>>;
  roles?: Maybe<Array<Maybe<UserRole>>>;
  specialties?: Maybe<Array<Maybe<OrganizationSpeciality>>>;
  startDate?: Maybe<Scalars['Date']>;
  templateGroups?: Maybe<Array<Maybe<TemplateGroup>>>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type OrganizationCursors = {
  __typename?: 'OrganizationCursors';
  edges?: Maybe<Array<Maybe<OrganizationEdge>>>;
  pageInfo?: Maybe<CursorInfo>;
};

export type OrganizationDepartment = {
  __typename?: 'OrganizationDepartment';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type OrganizationEdge = {
  __typename?: 'OrganizationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Organization>;
};

export type OrganizationFilter = {
  active?: InputMaybe<Scalars['Boolean']>;
  leafOnly?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['EID']>;
  parentOnly?: InputMaybe<Scalars['Boolean']>;
  rootId?: InputMaybe<Scalars['EID']>;
  search?: InputMaybe<Scalars['String']>;
  skipOwn?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationSearch = {
  fields: Array<InputMaybe<OrgSearchFields>>;
  match: Scalars['String'];
};

export type OrganizationSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<OrganizationSortField>;
};

export enum OrganizationSortField {
  Created = 'CREATED',
  InternalName = 'INTERNAL_NAME',
  Name = 'NAME',
  Updated = 'UPDATED'
}

export type OrganizationSpeciality = {
  __typename?: 'OrganizationSpeciality';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type OrganizationSummary = {
  __typename?: 'OrganizationSummary';
  areaCount?: Maybe<Scalars['Int']>;
  clinicCount?: Maybe<Scalars['Int']>;
  roleCounts?: Maybe<Array<Maybe<RoleCount>>>;
};

export type OverlapVisit = {
  __typename?: 'OverlapVisit';
  appointmentAt?: Maybe<Scalars['Date']>;
  appointmentTo?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['EID']>;
  organization?: Maybe<LimitedOrg>;
};

export enum PesCategory {
  Etiology = 'ETIOLOGY',
  Problem = 'PROBLEM',
  SignSymptom = 'SIGN_SYMPTOM'
}

/** ## PES Resolution Enum  */
export enum PesResolution {
  ImprovementShown = 'IMPROVEMENT_SHOWN',
  NoLongerAppropriate = 'NO_LONGER_APPROPRIATE',
  Resolved = 'RESOLVED',
  Unresolved = 'UNRESOLVED'
}

/** ## PESStatement Type */
export type PesStatement = {
  __typename?: 'PESStatement';
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  deleted?: Maybe<Scalars['Boolean']>;
  etiology?: Maybe<Array<Maybe<Statement>>>;
  id?: Maybe<Scalars['EID']>;
  member?: Maybe<User>;
  problem?: Maybe<Statement>;
  resolution?: Maybe<Resolution>;
  signSymptom?: Maybe<Array<Maybe<Statement>>>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  visit?: Maybe<Visit>;
};

/** ## PESStatementTemplate Type */
export type PesStatementTemplate = {
  __typename?: 'PESStatementTemplate';
  category?: Maybe<PesCategory>;
  id?: Maybe<Scalars['EID']>;
  options?: Maybe<Array<Maybe<PesStatementTemplate>>>;
  value?: Maybe<Scalars['String']>;
};

/** iHealth Pulse Oximeter device models. */
export enum PoDeviceModel {
  Po3 = 'PO3'
}

/** PO details' item data. */
export type PoItem = {
  __typename?: 'POItem';
  blood_oxygen?: Maybe<Scalars['POValue']>;
  date?: Maybe<Scalars['Date']>;
  heart_rate?: Maybe<Scalars['HRValue']>;
  perfusion_index?: Maybe<Scalars['PIValue']>;
};

/** PO details' item input */
export type PoItemInput = {
  blood_oxygen?: InputMaybe<Scalars['POValue']>;
  date?: InputMaybe<Scalars['Date']>;
  heart_rate?: InputMaybe<Scalars['HRValue']>;
  perfusion_index?: InputMaybe<Scalars['PIValue']>;
};

/** Blood Oxygen & Heart Rate measurement data. */
export type PoMeasure = Measure & {
  __typename?: 'POMeasure';
  blood_oxygen?: Maybe<Scalars['POValue']>;
  date?: Maybe<Scalars['Date']>;
  details?: Maybe<Array<Maybe<PoItem>>>;
  deviceAddress?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  heart_rate?: Maybe<Scalars['HRValue']>;
  /**
   * hadMedicine: Boolean added to support hadMedicine flag
   * hadActivity: Boolean added to support hadActivity flag
   * hadInsulin: Boolean added to support hadInsulin flag
   * tags:[String] added to support mood tags
   */
  id?: Maybe<Scalars['EID']>;
  imageUrl?: Maybe<Scalars['String']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measureBy?: Maybe<User>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  perfusion_index?: Maybe<Scalars['PIValue']>;
  resultId?: Maybe<Scalars['EID']>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<Vital>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
};

/** Blood Oxygen & Heart Rate measurement data. */
export type PoMeasureAudit = MeasureAudit & {
  __typename?: 'POMeasureAudit';
  blood_oxygen?: Maybe<Scalars['POValue']>;
  date?: Maybe<Scalars['Date']>;
  /** PO details' item data. */
  details?: Maybe<PoItem>;
  deviceAddress?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  heart_rate?: Maybe<Scalars['HRValue']>;
  /**
   * hadMedicine: Boolean added to support hadMedicine flag
   * hadActivity: Boolean added to support hadActivity flag
   * hadInsulin: Boolean added to support hadInsulin flag
   * tags:[String] added to support mood tags
   */
  id?: Maybe<Scalars['EID']>;
  imageUrl?: Maybe<Scalars['String']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measureBy?: Maybe<User>;
  measurementSession?: Maybe<AuditRecord>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  networkSpeed?: Maybe<Scalars['String']>;
  networkType?: Maybe<Scalars['String']>;
  perfusion_index?: Maybe<Scalars['PIValue']>;
  phoneName?: Maybe<Scalars['String']>;
  phoneSessionRecord?: Maybe<AuditRecord>;
  resultId?: Maybe<Scalars['EID']>;
  sessionId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<Vital>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
};

export type PoResult = Result & {
  __typename?: 'POResult';
  assessmentFilled?: Maybe<AssessmentFilled>;
  blood_oxygen?: Maybe<Scalars['POValue']>;
  careStatus?: Maybe<CareStatus>;
  caredBy?: Maybe<User>;
  closedAt?: Maybe<Scalars['Date']>;
  comments?: Maybe<Array<Maybe<ResultCommentType>>>;
  date?: Maybe<Scalars['Date']>;
  details?: Maybe<Array<Maybe<PoItem>>>;
  dismissed?: Maybe<Scalars['Boolean']>;
  enrolledProgram?: Maybe<EnrolledProgram>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  heart_rate?: Maybe<Scalars['HRValue']>;
  id?: Maybe<Scalars['EID']>;
  late?: Maybe<Scalars['Boolean']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measure?: Maybe<Measure>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  notes?: Maybe<CursorPaginatedProviderNotes>;
  notified?: Maybe<Scalars['Boolean']>;
  perfusion_index?: Maybe<Scalars['PIValue']>;
  refId?: Maybe<Scalars['EID']>;
  /** # 08/18/2017 COCONUT ## */
  repeat?: Maybe<Scalars['String']>;
  resultUpdates?: Maybe<Array<Maybe<ResultUpdateType>>>;
  reviewedAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<TaskSchedule>;
  severity?: Maybe<AlertSeverity>;
  taskStatus?: Maybe<TaskStatus>;
  threshold?: Maybe<Array<Maybe<AlertThreshold>>>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<TaskType>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
  violators?: Maybe<Array<Maybe<MeasureType>>>;
};


export type PoResultEnrolledProgramArgs = {
  relatedTaskOnly?: InputMaybe<Scalars['Boolean']>;
};


export type PoResultNotesArgs = {
  after?: InputMaybe<Scalars['Ref']>;
  before?: InputMaybe<Scalars['Ref']>;
  count?: InputMaybe<Scalars['Int']>;
};

export type PoStatType = {
  __typename?: 'POStatType';
  afternoon?: Maybe<PoVitalStatDetailType>;
  all?: Maybe<PoVitalStatDetailType>;
  criticalLow?: Maybe<PoVitalStatDetailType>;
  evening?: Maybe<PoVitalStatDetailType>;
  highestPO?: Maybe<Scalars['Int']>;
  low?: Maybe<PoVitalStatDetailType>;
  morning?: Maybe<PoVitalStatDetailType>;
  normal?: Maybe<PoVitalStatDetailType>;
  overnight?: Maybe<PoVitalStatDetailType>;
};

export type PoVitalStatDetailType = {
  __typename?: 'POVitalStatDetailType';
  average?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  perfusionIndexAverage?: Maybe<Scalars['Float']>;
  pulseRateAverage?: Maybe<Scalars['Float']>;
  range?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PagePaginatedEducationTips = {
  __typename?: 'PagePaginatedEducationTips';
  data?: Maybe<Array<Maybe<EducationTips>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** ## Pagination and data list for assessment. */
export type PaginatedAssessment = {
  __typename?: 'PaginatedAssessment';
  data?: Maybe<Array<Maybe<Assessment>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** ## Assessment Filled Pagination */
export type PaginatedAssessmentFilled = {
  __typename?: 'PaginatedAssessmentFilled';
  data?: Maybe<Array<Maybe<AssessmentFilled>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedAudits = {
  __typename?: 'PaginatedAudits';
  data?: Maybe<Array<Maybe<Audit>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedChallengeReward = {
  __typename?: 'PaginatedChallengeReward';
  data?: Maybe<Array<Maybe<ChallengeRewardDetails>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** Pagination and data (list) for a DeviceHub's results */
export type PaginatedDeviceHubs = {
  __typename?: 'PaginatedDeviceHubs';
  data?: Maybe<Array<Maybe<DeviceHubType>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** Pagination and data (list) for epChangeLogs results */
export type PaginatedEpChangeLogs = {
  __typename?: 'PaginatedEPChangeLogs';
  data?: Maybe<Array<Maybe<EpChangeLog>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** Pagination and data list for enrolled-programs. */
export type PaginatedEnrolled = {
  __typename?: 'PaginatedEnrolled';
  data?: Maybe<Array<Maybe<EnrolledProgram>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** Pagination and data (list) for a feedback's results */
export type PaginatedFeedbacks = {
  __typename?: 'PaginatedFeedbacks';
  data?: Maybe<Array<Maybe<FeedbackType>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** Pagination and data (list) for a file upload's results */
export type PaginatedFileUploads = {
  __typename?: 'PaginatedFileUploads';
  data?: Maybe<Array<Maybe<FileUpload>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** Pagination and data (list) for a food log's results */
export type PaginatedFoodLogs = {
  __typename?: 'PaginatedFoodLogs';
  data?: Maybe<Array<Maybe<FoodLog>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedIcdCodes = {
  __typename?: 'PaginatedICDCodes';
  data?: Maybe<Array<Maybe<IcdCode>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** Pagination and data (list) for a InsuranceProvider's results */
export type PaginatedInsuranceProvider = {
  __typename?: 'PaginatedInsuranceProvider';
  data?: Maybe<Array<Maybe<InsuranceProvider>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** Pagination and data (list) for a InventoryHistory's results */
export type PaginatedInventoryHistories = {
  __typename?: 'PaginatedInventoryHistories';
  data?: Maybe<Array<Maybe<InventoryHistoryType>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** Pagination and data (list) for a InventoryTransaction's results */
export type PaginatedInventoryTransactions = {
  __typename?: 'PaginatedInventoryTransactions';
  data?: Maybe<Array<Maybe<InventoryTransactionType>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** Pagination and data (list) for a IoT Device List Config's results */
export type PaginatedIoTDeviceConfigs = {
  __typename?: 'PaginatedIoTDeviceConfigs';
  data?: Maybe<Array<Maybe<IoTDeviceConfigType>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** Pagination and data (list) for a IoT Device List's results */
export type PaginatedIoTDevices = {
  __typename?: 'PaginatedIoTDevices';
  data?: Maybe<Array<Maybe<IoTDeviceType>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** Pagination and data (list) for a LoanDevice's results */
export type PaginatedLoanDevices = {
  __typename?: 'PaginatedLoanDevices';
  data?: Maybe<Array<Maybe<LoanDeviceType>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedMeasures = {
  __typename?: 'PaginatedMeasures';
  cursorInfo?: Maybe<CursorInfo_Deprecated>;
  data?: Maybe<Array<Maybe<Measure>>>;
};

/** Pagination and data (list) for a medication's results */
export type PaginatedMedications = {
  __typename?: 'PaginatedMedications';
  data?: Maybe<Array<Maybe<Medication>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedMonthlyReview = {
  __typename?: 'PaginatedMonthlyReview';
  data?: Maybe<Array<Maybe<MonthlyReview>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedMonthlyReviewForDoctor = {
  __typename?: 'PaginatedMonthlyReviewForDoctor';
  data?: Maybe<Array<Maybe<MonthlyReview>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** Pagination and data (list) for a monthly time spent's results */
export type PaginatedMonthlyTimes = {
  __typename?: 'PaginatedMonthlyTimes';
  data?: Maybe<Array<Maybe<MonthlyTimeSpent>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** Pagination and data (list) for an order's results */
export type PaginatedOrders = {
  __typename?: 'PaginatedOrders';
  data?: Maybe<Array<Maybe<OrderType>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedOrganizations = {
  __typename?: 'PaginatedOrganizations';
  data?: Maybe<Array<Maybe<Organization>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedPatientSmartAlert = {
  __typename?: 'PaginatedPatientSmartAlert';
  data?: Maybe<Array<Maybe<SmartAlert>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedPatients = {
  __typename?: 'PaginatedPatients';
  data?: Maybe<Array<Maybe<PatientAggregated>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedPrograms = {
  __typename?: 'PaginatedPrograms';
  data?: Maybe<Array<Maybe<AdminProgram>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** ## ProviderNote paginated */
export type PaginatedProviderNotes = {
  __typename?: 'PaginatedProviderNotes';
  data?: Maybe<Array<Maybe<ProviderNote>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedRemoteEnrollments = {
  __typename?: 'PaginatedRemoteEnrollments';
  data?: Maybe<Array<Maybe<RemoteEnrollment>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** Pagination and data (list) for an enrolled-program's results. */
export type PaginatedResults = {
  __typename?: 'PaginatedResults';
  data?: Maybe<Array<Maybe<Result>>>;
  pageInfo?: Maybe<PaginationInfo>;
  stat?: Maybe<ResultStatType>;
};

export type PaginatedRewardRedeem = {
  __typename?: 'PaginatedRewardRedeem';
  data?: Maybe<Array<Maybe<RewardRedeem>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedRewardRedeemTemplate = {
  __typename?: 'PaginatedRewardRedeemTemplate';
  data?: Maybe<Array<Maybe<RewardRedeemTemplate>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedSmartAlertTasks = {
  __typename?: 'PaginatedSmartAlertTasks';
  data?: Maybe<Array<Maybe<PatientWithTasks>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedSubscribes = {
  __typename?: 'PaginatedSubscribes';
  data?: Maybe<Array<Maybe<Subscribes>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedSystemDynamicConfigs = {
  __typename?: 'PaginatedSystemDynamicConfigs';
  data?: Maybe<Array<Maybe<SystemDynamicConfig>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedTaskAssignments = {
  __typename?: 'PaginatedTaskAssignments';
  data?: Maybe<Array<Maybe<UserAllTask>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedTeams = {
  __typename?: 'PaginatedTeams';
  data?: Maybe<Array<Maybe<Team>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedTemplateGroupOrganizations = {
  __typename?: 'PaginatedTemplateGroupOrganizations';
  data?: Maybe<Array<Maybe<TemplateGroupOrganizations>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedTemplateGroups = {
  __typename?: 'PaginatedTemplateGroups';
  data?: Maybe<Array<Maybe<TemplateGroup>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** Pagination and data (list) for a UsageHeartbeat's results */
export type PaginatedUsageHeartbeats = {
  __typename?: 'PaginatedUsageHeartbeats';
  data?: Maybe<Array<Maybe<UsageHeartbeatType>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginatedUsers = {
  __typename?: 'PaginatedUsers';
  data?: Maybe<Array<Maybe<User>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

/** Pagination and data (list) for a visit's results */
export type PaginatedVisits = {
  __typename?: 'PaginatedVisits';
  data?: Maybe<Array<Maybe<Visit>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  lastPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type ParentOrganization = {
  __typename?: 'ParentOrganization';
  id?: Maybe<Scalars['EID']>;
  internalName?: Maybe<Scalars['String']>;
  leaf?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type PatientAggregated = {
  __typename?: 'PatientAggregated';
  enrolledPrograms?: Maybe<Array<Maybe<MiniEnrolledProgram>>>;
  user?: Maybe<User>;
};

export type PatientAssignee = {
  __typename?: 'PatientAssignee';
  assignedToCA?: Maybe<User>;
  assignedToCANotNecessary?: Maybe<Scalars['Boolean']>;
  assignedToRD?: Maybe<User>;
  assignedToRDNotNecessary?: Maybe<Scalars['Boolean']>;
  memberId?: Maybe<Scalars['EID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
};

export type PatientComplexity = {
  __typename?: 'PatientComplexity';
  level?: Maybe<PatientComplexityLevel>;
  memberId?: Maybe<Scalars['EID']>;
  source?: Maybe<PatientComplexitySource>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
};

export enum PatientComplexityLevel {
  Complex = 'COMPLEX',
  NonComplex = 'NON_COMPLEX',
  NullCodes = 'NULL_CODES'
}

export enum PatientComplexitySource {
  Condition = 'CONDITION',
  IcdCode = 'ICD_CODE',
  ManualInput = 'MANUAL_INPUT'
}

export type PatientConsent = {
  __typename?: 'PatientConsent';
  consent?: Maybe<Scalars['Boolean']>;
  consentType?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['Date']>;
  fileUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  memberId?: Maybe<Scalars['EID']>;
  providers?: Maybe<Array<Maybe<MiniDoctor>>>;
  signatureAt?: Maybe<Scalars['Date']>;
};

export enum PatientHiatusCategory {
  VisitHiatus = 'VISIT_HIATUS',
  VitalsMonitoringHiatus = 'VITALS_MONITORING_HIATUS'
}

export type PatientHiatusStatus = {
  __typename?: 'PatientHiatusStatus';
  createdAt?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['EID']>;
  memberId?: Maybe<Scalars['EID']>;
  note?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  visitHiatus?: Maybe<Scalars['Boolean']>;
  vitalsMonitoringHiatus?: Maybe<Scalars['Boolean']>;
};

export enum PatientHiatusStatusEnum {
  Paused = 'PAUSED',
  Resumed = 'RESUMED'
}

export type PatientInsuranceProvider = {
  __typename?: 'PatientInsuranceProvider';
  insuranceProvider?: Maybe<InsuranceProvider>;
  primary?: Maybe<Scalars['Boolean']>;
};

export enum PatientListStatus {
  DeviceInit = 'DEVICE_INIT',
  Discharged = 'DISCHARGED',
  Enrolled = 'ENROLLED',
  Excluded = 'EXCLUDED',
  Init = 'INIT',
  Referred = 'REFERRED',
  Returned = 'RETURNED',
  Suspended = 'SUSPENDED',
  Tbd = 'TBD'
}

export type PatientMntCode = {
  __typename?: 'PatientMntCode';
  code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PatientMntProgram = {
  __typename?: 'PatientMntProgram';
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  ineligibledAt?: Maybe<Scalars['Date']>;
  ineligibledBy?: Maybe<User>;
  mntConditions?: Maybe<Array<Maybe<Scalars['String']>>>;
  modifiedAt?: Maybe<Scalars['Date']>;
  modifiedBy?: Maybe<User>;
  patient?: Maybe<User>;
  referredAt?: Maybe<Scalars['Date']>;
  referredBy?: Maybe<User>;
  status?: Maybe<MntProgramStatusEnum>;
  visit?: Maybe<Visit>;
};

export type PatientReferral = {
  __typename?: 'PatientReferral';
  billableHealthConditions?: Maybe<Array<Maybe<BillableHealthCondition>>>;
  clinicGoals?: Maybe<Array<Maybe<ClinicGoal>>>;
  conditionsToMonnitor?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Date']>;
  deviceAccessbility?: Maybe<Scalars['Boolean']>;
  devicePickup?: Maybe<Array<Maybe<Scalars['String']>>>;
  deviceShip?: Maybe<Array<Maybe<Scalars['String']>>>;
  discussedWithPatient?: Maybe<Scalars['Boolean']>;
  doctorReferral?: Maybe<Scalars['Boolean']>;
  eligiblePrgorams?: Maybe<Array<Maybe<Scalars['String']>>>;
  enrolledAnother?: Maybe<Scalars['Boolean']>;
  enrolledAt?: Maybe<Scalars['Date']>;
  enrolledBy?: Maybe<User>;
  enrolledByRole?: Maybe<Scalars['String']>;
  exclusionOtherReason?: Maybe<Scalars['String']>;
  haveSmartphone?: Maybe<Scalars['Boolean']>;
  helpedSetupAndUseDevice?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['EID']>;
  initialVisit?: Maybe<Visit>;
  mntConditions?: Maybe<Array<Maybe<Scalars['String']>>>;
  patientDescribe?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['EID']>;
  recommendedVitals?: Maybe<Array<Maybe<Scalars['String']>>>;
  referredAt?: Maybe<Scalars['Date']>;
  referredBy?: Maybe<User>;
  referredByRole?: Maybe<Scalars['String']>;
  referredDate?: Maybe<Scalars['Date']>;
  shipAddresses?: Maybe<Address>;
  status?: Maybe<ReferralStatus>;
  techLevel?: Maybe<ReferTechLevelEnum>;
  updatedAt?: Maybe<Scalars['Date']>;
  wantMNTReferral?: Maybe<Scalars['Boolean']>;
  wouldJoin?: Maybe<Scalars['Boolean']>;
};

export type PatientReward = {
  __typename?: 'PatientReward';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['EID']>;
  memberId?: Maybe<Scalars['EID']>;
  points?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PatientSmartAlertOpts = {
  alertLevel?: InputMaybe<Array<InputMaybe<SmartAlertLevel>>>;
  memberId: Scalars['EID'];
  status?: InputMaybe<SmartAlertStatus>;
  taskType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PatientTaskAssignments = {
  __typename?: 'PatientTaskAssignments';
  memberId?: Maybe<Scalars['EID']>;
  taskAssignments?: Maybe<Array<Maybe<TaskAssignment>>>;
};

export type PatientWithTasks = {
  __typename?: 'PatientWithTasks';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  lastName?: Maybe<Scalars['String']>;
  member?: Maybe<User>;
  memberId?: Maybe<Scalars['EID']>;
  tasks?: Maybe<Array<Maybe<SmartAlert>>>;
};

export type Phone = {
  __typename?: 'Phone';
  areaCode?: Maybe<Scalars['String']>;
  canUseForLogin?: Maybe<Scalars['Boolean']>;
  countryCode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  private?: Maybe<Scalars['Boolean']>;
  type?: Maybe<PhoneNumberType>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type PhoneDevice = {
  __typename?: 'PhoneDevice';
  appScheme?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  firstUsed?: Maybe<Scalars['Date']>;
  lastUsed?: Maybe<Scalars['Date']>;
  phoneId?: Maybe<Scalars['String']>;
  phoneModel?: Maybe<Scalars['String']>;
  phoneName?: Maybe<Scalars['String']>;
  phoneOSVersion?: Maybe<Scalars['String']>;
  serverUrl?: Maybe<Scalars['String']>;
};

export enum PhoneNumberType {
  Home = 'HOME',
  Mobile = 'MOBILE',
  Work = 'WORK'
}

export type PhoneReportInfo = {
  __typename?: 'PhoneReportInfo';
  FirstUsedAt?: Maybe<Scalars['Date']>;
  LastUsedAt?: Maybe<Scalars['Date']>;
  addToContactsStatus?: Maybe<PhoneReportInfoContactsStatus>;
  authorityInfo?: Maybe<AuthorityInfo>;
  connectedDeviceInfo?: Maybe<Array<Maybe<ConnectedDeviceInfo>>>;
  exerciseStatus?: Maybe<ExerciseStatus>;
  memberId?: Maybe<Scalars['EID']>;
  mobileInfo?: Maybe<MobileInfo>;
};

export enum PhoneReportInfoContactsStatus {
  Added = 'added',
  Unavailable = 'unavailable'
}

export enum PhoneReportInfoPermissionStatus {
  Blocked = 'blocked',
  Denied = 'denied',
  Granted = 'granted',
  Unavailable = 'unavailable'
}

export type PhoneReportList = {
  __typename?: 'PhoneReportList';
  data?: Maybe<Array<Maybe<PhoneReportInfo>>>;
};

/** ## PostIt type */
export type PostIt = {
  __typename?: 'PostIt';
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  id?: Maybe<Scalars['EID']>;
  member?: Maybe<User>;
  modifiedAt?: Maybe<Scalars['Date']>;
  modifiedBy?: Maybe<User>;
  note?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** ## PostIt Input */
export type PostItInput = {
  id?: InputMaybe<Scalars['EID']>;
  memberId?: InputMaybe<Scalars['EID']>;
  note?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['EID']>;
};

export type PreferenceType = {
  __typename?: 'PreferenceType';
  note?: Maybe<Scalars['String']>;
  value?: Maybe<UserPreferenceValueEnum>;
};

export enum PreferredUnits {
  Imperial = 'IMPERIAL',
  Metrics = 'METRICS'
}

/** File Presigned file upload type */
export type PresignedFileUploadType = {
  __typename?: 'PresignedFileUploadType';
  data?: Maybe<FileSignedType>;
  info?: Maybe<FileInfoType>;
};

/** Image Presigned Upload type */
export type PresignedUploadType = {
  __typename?: 'PresignedUploadType';
  data?: Maybe<ImageSignedType>;
  info?: Maybe<ImageInfoType>;
};

export type PreviousGoalAssesmentInput = {
  assessment?: InputMaybe<Scalars['String']>;
  params?: InputMaybe<Array<InputMaybe<PreviousGoalAssesmentParamsInput>>>;
};

export type PreviousGoalAssesmentParamsInput = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type PreviousGoalsInput = {
  billable?: InputMaybe<Scalars['Boolean']>;
  condition?: InputMaybe<Scalars['String']>;
  conditionEnum?: InputMaybe<Scalars['String']>;
  goalAssessment?: InputMaybe<Array<InputMaybe<PreviousGoalAssesmentInput>>>;
  goalEnum?: InputMaybe<Scalars['String']>;
  meetingStatus?: InputMaybe<Scalars['String']>;
  statusUpdatedAt?: InputMaybe<Scalars['Date']>;
  statusUpdatedBy?: InputMaybe<Scalars['EID']>;
  value?: InputMaybe<Scalars['String']>;
};

export type PreviousVitalsInput = {
  count?: InputMaybe<Scalars['Int']>;
  vital?: InputMaybe<Vital>;
};

export type PrimaryMember = {
  memberId: Scalars['EID'];
  type: PrimaryOperateType;
};

export enum PrimaryOperateType {
  Set = 'SET',
  Unset = 'UNSET'
}

export type ProductReportType = {
  __typename?: 'ProductReportType';
  description?: Maybe<Scalars['String']>;
  productType?: Maybe<ProductTypeEnum>;
  totalAdded?: Maybe<Scalars['Int']>;
  totalRemoved?: Maybe<Scalars['Int']>;
};

export enum ProductTypeEnum {
  A1C = 'A1C',
  Bg = 'BG',
  Bg5S = 'BG5S',
  Bp = 'BP',
  Clear = 'CLEAR',
  Hs = 'HS',
  Lancet = 'LANCET',
  Nexus = 'NEXUS',
  Oximeter = 'OXIMETER',
  Phone = 'PHONE',
  Strip = 'STRIP',
  Thermometer = 'THERMOMETER'
}

export type ProgramDevice = {
  __typename?: 'ProgramDevice';
  model?: Maybe<AllDeviceModels>;
  type?: Maybe<DeviceType>;
};

export enum ProgramEnrolled {
  Ccm = 'CCM',
  Mnt = 'MNT',
  None = 'NONE',
  NoMnt = 'NO_MNT',
  Rpm = 'RPM'
}

export type ProgramListFilters = {
  healthCondition?: InputMaybe<Array<InputMaybe<HealthCondition>>>;
  organizationId?: InputMaybe<Scalars['EID']>;
  status?: InputMaybe<ProgramStatus>;
};

export type ProgramListSearch = {
  fields?: InputMaybe<Array<InputMaybe<ProgramListSearchFields>>>;
  match: Scalars['String'];
};

/** # only 'name' is supported */
export enum ProgramListSearchFields {
  Name = 'NAME'
}

export type ProgramListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<ProgramListSortField>;
};

export enum ProgramListSortField {
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT'
}

export type ProgramMeasureTrend = {
  __typename?: 'ProgramMeasureTrend';
  data?: Maybe<Array<Maybe<ProgramTrendDataGroup>>>;
  groupBy?: Maybe<TrendGroupBy>;
};

export type ProgramMeasureTrendsFilters = {
  adminProgramIds: Array<Scalars['EID']>;
  timePeriod?: InputMaybe<ProgramTrendPeriod>;
};

export type ProgramMeasurementTrend = {
  __typename?: 'ProgramMeasurementTrend';
  data?: Maybe<Array<Maybe<ProgramMeasurementTrendDataPoint>>>;
};

export type ProgramMeasurementTrendDataPoint = {
  __typename?: 'ProgramMeasurementTrendDataPoint';
  createdAt?: Maybe<Scalars['Date']>;
  diastolic_blood_pressure?: Maybe<BloodPressureMeasurement>;
  systolic_blood_pressure?: Maybe<BloodPressureMeasurement>;
};

export type ProgramMeasurementTrendsFilters = {
  adminProgramIds: Array<Scalars['EID']>;
  timePeriod?: InputMaybe<TrendLength>;
};

export enum ProgramParticipationEnum {
  Ccm = 'CCM',
  Mnt = 'MNT',
  Rpm = 'RPM'
}

export enum ProgramStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT'
}

export type ProgramTrendDataGroup = {
  __typename?: 'ProgramTrendDataGroup';
  conditionCounts?: Maybe<ProgramTrendDataPoint>;
  group?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type ProgramTrendDataPoint = {
  __typename?: 'ProgramTrendDataPoint';
  elevated?: Maybe<Scalars['Int']>;
  hyperCrisis?: Maybe<Scalars['Int']>;
  hyperStage1?: Maybe<Scalars['Int']>;
  hyperStage2?: Maybe<Scalars['Int']>;
  normal?: Maybe<Scalars['Int']>;
};

export enum ProgramTrendPeriod {
  Month = 'MONTH',
  Week = 'WEEK'
}

export type ProviderBillableTime = {
  __typename?: 'ProviderBillableTime';
  fromTime?: Maybe<Scalars['Date']>;
  provider?: Maybe<LimitedUser>;
  toTime?: Maybe<Scalars['Date']>;
  totalEnrTime?: Maybe<Scalars['Int']>;
  totalUnenrTime?: Maybe<Scalars['Int']>;
};

export enum ProviderHiatusAction {
  Create = 'CREATE',
  Resumed = 'RESUMED',
  Update = 'UPDATE'
}

/** ## ProviderNote full data */
export type ProviderNote = {
  __typename?: 'ProviderNote';
  category?: Maybe<ProviderNoteCategory>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  deleted?: Maybe<Scalars['Boolean']>;
  enrolledProgram?: Maybe<EnrolledProgram>;
  error?: Maybe<ProviderNoteError>;
  hiatusAction?: Maybe<ProviderHiatusAction>;
  hiatusCategories?: Maybe<Array<Maybe<PatientHiatusCategory>>>;
  hiatusStartDate?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['EID']>;
  notBillableReason?: Maybe<Scalars['String']>;
  pauseDuration?: Maybe<Scalars['Int']>;
  result?: Maybe<Result>;
  resumeDate?: Maybe<Scalars['Date']>;
  smartAlert?: Maybe<SmartAlert>;
  smartAlertLevel?: Maybe<Scalars['String']>;
  smartAlertType?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  techCall?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ProviderNoteType>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
};

export enum ProviderNoteCategory {
  Alerts = 'ALERTS',
  CallLog = 'CALL_LOG',
  DoctorReviewed = 'DOCTOR_REVIEWED',
  FollowUpCounseling = 'FOLLOW_UP_COUNSELING',
  General = 'GENERAL',
  MedReconciliation = 'MED_RECONCILIATION',
  Mre = 'MRE',
  MreAdditional = 'MRE_ADDITIONAL',
  Mtpr = 'MTPR',
  MtprAdditional = 'MTPR_ADDITIONAL',
  Nursing = 'NURSING',
  OfflineTaskLog = 'OFFLINE_TASK_LOG',
  PauseFollowUp = 'PAUSE_FOLLOW_UP',
  ReEngagement = 'RE_ENGAGEMENT',
  SmartAlert = 'SMART_ALERT',
  TaskNotes = 'TASK_NOTES',
  Unflag = 'UNFLAG'
}

export type ProviderNoteError = {
  __typename?: 'ProviderNoteError';
  markedBy?: Maybe<User>;
  reason?: Maybe<Scalars['String']>;
};

export type ProviderNoteListCursorOpts = {
  category?: InputMaybe<Array<InputMaybe<ProviderNoteCategory>>>;
  enrolledProgramId?: InputMaybe<Scalars['EID']>;
  error?: InputMaybe<Scalars['Boolean']>;
  memberId?: InputMaybe<Scalars['EID']>;
  resultId?: InputMaybe<Scalars['EID']>;
};

/**
 * ## ProviderNote List Options
 *  Note: 'from' and 'to' are on 'createdAt', both inclusive
 */
export type ProviderNoteListOpts = {
  category?: InputMaybe<Array<InputMaybe<ProviderNoteCategory>>>;
  enrolledProgramId?: InputMaybe<Scalars['EID']>;
  error?: InputMaybe<Scalars['Boolean']>;
  from?: InputMaybe<Scalars['Date']>;
  memberId?: InputMaybe<Scalars['EID']>;
  resultId?: InputMaybe<Scalars['EID']>;
  to?: InputMaybe<Scalars['Date']>;
};

/** ## ProviderNote List Sorting */
export type ProviderNoteListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<ProviderNoteListSortField>;
};

export enum ProviderNoteListSortField {
  CreatedAt = 'CREATED_AT'
}

/** ## ProviderNoteTag list data */
export type ProviderNoteTag = {
  __typename?: 'ProviderNoteTag';
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  key?: Maybe<Scalars['String']>;
};

export type ProviderNoteTagList = {
  __typename?: 'ProviderNoteTagList';
  data?: Maybe<Array<Maybe<ProviderNoteTag>>>;
};

export enum ProviderNoteType {
  Member = 'MEMBER',
  MonthlyReview = 'MONTHLY_REVIEW',
  Program = 'PROGRAM',
  SmartAlert = 'SMART_ALERT',
  TaskResult = 'TASK_RESULT'
}

export enum PushNotificationPlatformEnum {
  Apns = 'APNS',
  ApnsProvider = 'APNS_PROVIDER',
  ApnsProviderEnterprise = 'APNS_PROVIDER_ENTERPRISE',
  ApnsProviderEnterpriseSandbox = 'APNS_PROVIDER_ENTERPRISE_SANDBOX',
  ApnsProviderSandbox = 'APNS_PROVIDER_SANDBOX',
  ApnsSandbox = 'APNS_SANDBOX',
  Gcm = 'GCM',
  GcmProvider = 'GCM_PROVIDER'
}

export type PushNotificationType = {
  __typename?: 'PushNotificationType';
  member?: Maybe<User>;
  platform?: Maybe<PushNotificationPlatformEnum>;
  token?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /**
   * ## ICDCode
   *  Find a ICDCode by ID.
   */
  ICDCode?: Maybe<IcdCode>;
  /**
   * ## ICDCode List
   *  Find a list of ICD codes using various search key words.
   */
  ICDCodeList?: Maybe<PaginatedIcdCodes>;
  /** Returns task assignments accountHistoryList */
  accountHistoryList?: Maybe<AccountEventHistoryList>;
  /** Return a list of active users in a given time frame. */
  activeUsersTrend?: Maybe<ActiveUsersTrend>;
  /**
   * ## Single Program (Template)
   *  Find a single program template by ID.
   */
  adminProgram?: Maybe<AdminProgram>;
  /**
   * ## List Programs (Template)
   *  Find a list of program templates.
   */
  adminProgramList?: Maybe<PaginatedPrograms>;
  /**
   * ## Search for Programs
   *  Not Done (09/27/2017 deprecating)
   */
  adminProgramSearch?: Maybe<PaginatedPrograms>;
  /**
   * ## Program Summary
   *  Find a program and its summary data.
   */
  adminProgramSummary?: Maybe<AdminProgramWithSummary>;
  /**
   * ## Return a list of enrolled-programs (universal)
   *  Find all enrolled-programs for a user across all organizations.
   */
  allEnrolledProgramList?: Maybe<PaginatedEnrolled>;
  assessment?: Maybe<Assessment>;
  /** ## Query single assessment filled */
  assessmentFilled?: Maybe<AssessmentFilled>;
  assessmentFilledList?: Maybe<PaginatedAssessmentFilled>;
  assessmentList?: Maybe<PaginatedAssessment>;
  auditTrailList?: Maybe<PaginatedAudits>;
  auditTrailMany?: Maybe<Array<Maybe<AuditRecord>>>;
  /**
   * ## A list of auto message records
   *  returns a list of auto message config documents
   */
  autoMsgConfigList?: Maybe<Array<Maybe<AutoMsgConfig>>>;
  batchChatHistory?: Maybe<Scalars['JSON']>;
  /**
   * ## Get RD/CA Scheduled Visits
   *  returns list of members' visits
   */
  careTeamMemberVisits?: Maybe<Array<Maybe<MemberSchedule>>>;
  challengeRewardList?: Maybe<PaginatedChallengeReward>;
  /**
   * ## List of device errors
   *  returns a list of device errors based on member ID
   */
  deviceErrorList?: Maybe<Array<Maybe<DeviceError>>>;
  /**
   * ## Get a list of devices from a hub
   *  The list can be filtered for AVAILABLE, IN_USE, or ALL devices
   */
  deviceHubList?: Maybe<PaginatedDeviceHubs>;
  dummyTypeResolver?: Maybe<Dummy>;
  educationTipsList?: Maybe<PagePaginatedEducationTips>;
  /**
   * ## Get enrolled patiient statistics
   *  This returns some statistics of 1 or more enrolled programs
   */
  enrolledPatientStatistics?: Maybe<EnrolledPatientStatistics>;
  /** Find a user-enrolled program by ID. */
  enrolledProgram?: Maybe<EnrolledProgram>;
  /**
   * ## Return a list of enrolled-programs
   *  Find all enrolled-programs for a given _programId_ or _memberId_. Additionally, you can also add _status_ and _healthCondition_ filters.
   */
  enrolledProgramList?: Maybe<PaginatedEnrolled>;
  enrolledUserStatus?: Maybe<EnrolledUserStatus>;
  /**
   * ## List of EPChangeLog
   *  returns list of EPChangeLog
   */
  epChangeLogList?: Maybe<PaginatedEpChangeLogs>;
  /**
   * ## Get a list of feedbacks
   *  The list can be filtered for FIVE_OR_LESS, FOUR_OR_LESS, THREE_OR_LESS, TWO_OR_LESS, ONE
   */
  feedbackList?: Maybe<PaginatedFeedbacks>;
  /**
   * ## List of file uploads
   *  Returns list of file uploads of an user
   */
  fileUploadList?: Maybe<PaginatedFileUploads>;
  /**
   * ## List of food logs
   *  Returns list of food logs of an user
   */
  foodLogList?: Maybe<PaginatedFoodLogs>;
  /** Returns patients missing Info */
  gPatientsWithMissingInfo?: Maybe<Scalars['Boolean']>;
  getAccountRoleCapacity?: Maybe<AccountRoleCapacity>;
  getAppUpgradeInfo?: Maybe<AppUpgradeInfo>;
  getAssigneeOptions?: Maybe<AssigneeOption>;
  getAutoCompletedAddress?: Maybe<Array<Maybe<AutoCompletedAddress>>>;
  /**
   * ## Single auto message record
   *  returns a single auto message config document
   */
  getAutoMsgConfig?: Maybe<AutoMsgConfig>;
  getAvatarUploadUrl?: Maybe<PresignedUploadType>;
  /**
   * ## List of behavioral goals by type
   *  returns a list of behavioral goals based on type
   * ## NOTE: types is DEPRECATED
   */
  getBehavioralGoalTemplates?: Maybe<Array<Maybe<BehavioralGoalTemplate>>>;
  getBillingReports?: Maybe<Array<Maybe<BillingReport>>>;
  /**
   * ## Get RD/CA AvailabilityType
   *  returns list of availabilities
   */
  getCareTeamMemberAvailability?: Maybe<Array<Maybe<MemberAvailability>>>;
  getChannelMeta?: Maybe<ChatMemberChannel>;
  getChatChannels?: Maybe<Array<Maybe<ChatMemberChannel>>>;
  getChatHistory?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  /**
   * ## List of clinic goal template by icdCodes
   *  returns a list of clinic goal templates based on icdCodes
   */
  getClinicGoalTemplates: Array<ClinicGoalTemplate>;
  /** ## get patient consent */
  getConsentTemplate?: Maybe<ConsentTemplate>;
  getConsentTemplateList?: Maybe<Array<Maybe<ConsentTemplate>>>;
  getCoveredMembers?: Maybe<CoveredMember>;
  getCoveredOrganizations?: Maybe<CoveredOrganization>;
  /**
   * ## Single deviceError
   *  returns a single device error based on ID
   */
  getDeviceError?: Maybe<DeviceError>;
  /**
   * ## Get an IoT hub
   *  Either id, memberId, or serialNumber can be passed in to find a hub if member is assigned to a hub in case of memberId is passed in
   */
  getDeviceHub?: Maybe<DeviceHubType>;
  getEducationTipsTemplate?: Maybe<EducationTipsTemplate>;
  getEducationTipsTemplateList?: Maybe<Array<Maybe<EducationTipsTemplate>>>;
  /**
   * ## Single Feature Upload
   *  returns a single feature upload based on release
   */
  getFeatureUpload?: Maybe<FeatureUpload>;
  /**
   * ## Get a feedback
   *  Either memberId can be passed in to find a feedback
   */
  getFeedback?: Maybe<FeedbackType>;
  /**
   * ## Single File Upload
   *  returns a single file upload based on ID
   */
  getFileUpload?: Maybe<FileUpload>;
  /**
   * ## Single Food Log
   *  returns a single food log based on ID
   */
  getFoodLog?: Maybe<FoodLog>;
  /**
   * ## List of handouts by type
   *  returns a list of handouts based on type
   * ## NOTE: category is DEPRECATED in favor of search
   */
  getHandouts?: Maybe<Array<Maybe<Handout>>>;
  /**
   * ## return the HbA1c percentage, -1 if there is no measurement
   *  retrieve the result based on data range, default is starting from today back to 3 month before
   */
  getHbA1c?: Maybe<Scalars['Float']>;
  /**
   * ## Get a single Insurance provider document
   *  return a document
   */
  getInsuranceProvider?: Maybe<InsuranceProvider>;
  /**
   * ## Get inventory
   *  get an organization's inventory
   */
  getInventory?: Maybe<InventoryType>;
  /**
   * ## get a IoT Device
   *  Pass in combination of deviceId and position to find an IoT device
   */
  getIoTDevice?: Maybe<Array<Maybe<IoTDeviceType>>>;
  /**
   * ## get a IoT Device Config
   *  Get IoT device configuration
   */
  getIoTDeviceConfig?: Maybe<IoTDeviceConfigType>;
  getLatestA1C?: Maybe<LabTestType>;
  /**
   * ## Get the last scanned strip info
   * ## If memberId is not provided, the logged-in viewer's id will be assumed
   */
  getLatestStrip?: Maybe<Supply>;
  /**
   * ## Get a loan device
   *  Either deviceId, memberId, or serialNumber can be passed in to find a device if member is assigned to a device in case of memberId is passed in
   */
  getLoanDevice?: Maybe<LoanDeviceType>;
  /**
   * ## Single medication
   *  returns a single medication base on ID
   */
  getMedication?: Maybe<Medication>;
  /**
   * ## Total member time spent
   *  returns member's total time spent since enrolled
   */
  getMemberTotalTimeSpent?: Maybe<Scalars['Int']>;
  getMentorRelation?: Maybe<Mentor>;
  getMissingInfo?: Maybe<MissingInfo>;
  getMissingInfoList?: Maybe<Array<Maybe<MissingInfo>>>;
  getMonthlyReview?: Maybe<MonthlyReview>;
  getMonthlyReviewContent?: Maybe<Array<Maybe<MonthlyReviewContent>>>;
  getMonthlyReviewGoalMeeting?: Maybe<MonthlyReviewGoalMeeting>;
  getMonthlyReviewRecommendation?: Maybe<Array<Maybe<MonthlyReviewRecommendation>>>;
  getMonthlyReviewSummary?: Maybe<MonthlyReviewSummary>;
  /**
   * ## Single monthly time spent
   *  returns a single monthly time spent
   */
  getMonthlyTimeSpent?: Maybe<MonthlyTimeSpent>;
  getNeedSignOffPatients?: Maybe<SignOffPatients>;
  getOnCalleeOptions?: Maybe<Array<Maybe<OnCalleeOption>>>;
  /**
   * ## Single Order
   *  Returns a single order
   */
  getOrder?: Maybe<OrderType>;
  /** ## Get organization blocking schedule */
  getOrgSchedule?: Maybe<OrgSchedule>;
  /**
   * ## getOrgTeams - Organization's teams
   *  Returns teams of requested organizations
   */
  getOrgTeams?: Maybe<Array<Maybe<OrgTeams>>>;
  /**
   * ## List of PESStatements by visitId and memberId
   *  returns a list of PESStatements createdAt BEFORE the visit.appointmentAt && contains the memberId
   */
  getPESStatements?: Maybe<Array<Maybe<PesStatement>>>;
  getPatientAlertConfig?: Maybe<SmartAlertTasksConfig>;
  getPatientAssignee?: Maybe<PatientAssignee>;
  /** ## get patient complexity */
  getPatientComplexity?: Maybe<PatientComplexity>;
  /** ## get patient complexity by codes */
  getPatientComplexityByCodes?: Maybe<PatientComplexityLevel>;
  /** ## get patient complexity by conditions */
  getPatientComplexityByConditions?: Maybe<PatientComplexityLevel>;
  /** ## get patient consent */
  getPatientConsent?: Maybe<PatientConsent>;
  getPatientHiatusStatus?: Maybe<PatientHiatusStatus>;
  /**
   * ## Single Patient MNT program
   *  returns a single patient MNT program document
   */
  getPatientMntProgram?: Maybe<PatientMntProgram>;
  getPatientReferral?: Maybe<PatientReferral>;
  getPatientReward?: Maybe<PatientReward>;
  getPatientSignOffTasks?: Maybe<SignOffTask>;
  getPatientSmartAlert?: Maybe<PatientWithTasks>;
  /**
   * ## PESStatementTemplate
   *  find and return a PESStatement by EID
   */
  getPesTemplate?: Maybe<PesStatementTemplate>;
  /** find and return a list of PESStatements by category */
  getPesTemplatesByCategory?: Maybe<Array<Maybe<PesStatementTemplate>>>;
  /**
   * ## Single postit
   *  returns a single postit based on ID
   */
  getPostIt?: Maybe<PostIt>;
  /** ## Get presigned feature upload credential */
  getPresignedFeatureUpload?: Maybe<PresignedFileUploadType>;
  /** ## Get presigned file upload credential */
  getPresignedFileUpload?: Maybe<PresignedFileUploadType>;
  /** ## Get presigned logo upload credential */
  getPresignedLogoUpload?: Maybe<PresignedUploadType>;
  /** ## Get presigned upload credential */
  getPresignedUpload?: Maybe<PresignedUploadType>;
  getProviderBillableTime?: Maybe<ProviderBillableTime>;
  getRemoteEnrollment?: Maybe<RemoteEnrollment>;
  getRewardRedeem?: Maybe<RewardRedeem>;
  getRewardRedeemList?: Maybe<PaginatedRewardRedeem>;
  getRewardRedeemTemplate?: Maybe<RewardRedeemTemplate>;
  getRewardRedeemTemplatesList?: Maybe<PaginatedRewardRedeemTemplate>;
  getSelectedClinic?: Maybe<SelectedClinic>;
  getSignOffTask?: Maybe<SignOffTask>;
  /** ## Get signed download url */
  getSignedDownload?: Maybe<SignedDownloadType>;
  /**
   * ## Get signed feature download url
   *  release is in format yyyymmdd, default is latest release
   */
  getSignedFeatureDownload?: Maybe<Array<Maybe<SignedDownloadType>>>;
  /** ## Get signed file download url */
  getSignedFileDownload?: Maybe<SignedFileDownloadType>;
  getSmartAlertTypes?: Maybe<Array<Maybe<SmartAlertTypes>>>;
  getSystemDynamicConfigs?: Maybe<PaginatedSystemDynamicConfigs>;
  /** returns one patient's task assignments */
  getTaskAssignmentsByPatientId?: Maybe<PatientTaskAssignments>;
  getTeamChannelGroups?: Maybe<Array<Maybe<ChatTeamChannelGroup>>>;
  getTeamMemberTotalChannels?: Maybe<Scalars['Int']>;
  getTeamTotalChannels?: Maybe<Scalars['Int']>;
  getTemplateGroup?: Maybe<TemplateGroup>;
  getUnreadSignOffComments?: Maybe<SignOffTasks>;
  /**
   * # Get User Chat Info
   *  Note that newAuthKey flag is disabled (ignored by server)
   */
  getUserChatInfo?: Maybe<UserChatInfo>;
  /**
   * ## Get user's all devices from all EP
   *  This returns empty array if not device exists
   */
  getUserDevices?: Maybe<UserDeviceList>;
  getUserDevicesInfo?: Maybe<UserDevicesInfo>;
  /**
   * ## List of version histories
   *  returns a list of version histories based on enrolled program ID
   */
  getVersionHistoryList?: Maybe<Array<Maybe<VersionHistory>>>;
  getVirtualVisitStatus?: Maybe<Array<Maybe<VirtualVisitStatus>>>;
  getVirtualVisitTeam?: Maybe<Array<Maybe<VirtualMeetingUserStatus>>>;
  getVirtualVisitToken?: Maybe<Credential>;
  /**
   * ## Single medication
   *  returns a single medication base on ID
   */
  getVisit?: Maybe<Visit>;
  getVisitMemberProfile?: Maybe<UserProfile>;
  insuranceList?: Maybe<Array<Maybe<Insurance>>>;
  /**
   * ## Insurance provider list
   *  returns a list of insurance providers
   */
  insuranceProviderList?: Maybe<PaginatedInsuranceProvider>;
  /**
   * ## Get the inventory history list
   *  The list can be filtered for WEEK, MONTH, or QUARTER
   */
  inventoryHistoryList?: Maybe<PaginatedInventoryHistories>;
  /**
   * ## Get the inventory report
   *  The list can be filtered for WEEK, BI_WEEK, MONTH, or QUARTER for starting date.  Also it can be grouped by DAY/WEEK/MONTH, default is DAY
   */
  inventoryReport?: Maybe<Array<Maybe<InventoryReportType>>>;
  /**
   * ## Get the inventory transaction list
   *  The list can be filtered for
   */
  inventoryTransactionList?: Maybe<PaginatedInventoryTransactions>;
  /**
   * ## Get a list of IoT device configurations
   *  The list can be filtered for AVAILABLE, IN_USE, or ALL devices
   */
  iotDeviceConfigList?: Maybe<PaginatedIoTDeviceConfigs>;
  /**
   * ## Get a list of IoT devices
   *  The list can be filtered for AVAILABLE, IN_USE, or ALL devices
   */
  iotDeviceList?: Maybe<PaginatedIoTDevices>;
  labResultConnection?: Maybe<LabResultsConnection>;
  labResultCount?: Maybe<Scalars['Int']>;
  labResultMany?: Maybe<Array<Maybe<LabResults>>>;
  labResultPagination?: Maybe<LabResultsPagination>;
  labResultTemplateMany?: Maybe<Array<Maybe<LabResultTemplates>>>;
  listChannelsFromGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  listPatientReferral?: Maybe<Array<Maybe<PatientReferral>>>;
  /**
   * ## Get a list of loan devices
   *  The list can be filtered for AVAILABLE, IN_USE, or ALL devices
   */
  loanDeviceList?: Maybe<PaginatedLoanDevices>;
  /** Find a single measurement data by a given ID. */
  measure?: Maybe<Measure>;
  /** Return a list of all measurements or list by a vital type (measure type). */
  measureCursors?: Maybe<MeasureCursors>;
  /** Return a list of all measurements or list by a vital type (measure type). */
  measureLatestByType?: Maybe<Array<Maybe<Measure>>>;
  /** Return a BP counts for each category */
  measureTrendsForProgram?: Maybe<ProgramMeasureTrend>;
  /**
   * ## Measurement Trends
   *  Vitals are always returned in imperial units. Conversions should happen in the front-end.
   *  If _fromLastMeasure_ is true and date is null, data from the latest measurement is returned.
   */
  measureTrendsForUser?: Maybe<MeasureTrend>;
  /** Return all BP measurements in specific */
  measurementTrendsForProgram?: Maybe<ProgramMeasurementTrend>;
  medNameList?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * ## List of medications
   *  returns list of medications of an user
   */
  medicationList?: Maybe<PaginatedMedications>;
  monthlyReviewList?: Maybe<PaginatedMonthlyReview>;
  monthlyReviewListForDoctor?: Maybe<PaginatedMonthlyReviewForDoctor>;
  /**
   * ## List of monthly time spent
   *  returns list of monthly time spent
   */
  monthlyTimeSpentList?: Maybe<PaginatedMonthlyTimes>;
  /**
   * ## List of user orders
   *  Returns list of orders from a user
   */
  orderList?: Maybe<PaginatedOrders>;
  /**
   * ## Get organization notifications
   *  current for alert task count, will add food log and tasks
   */
  orgNotifications?: Maybe<OrgNotifications>;
  /** ## Get organization blocking schedule list */
  orgScheduleList?: Maybe<Array<Maybe<OrgSchedule>>>;
  /**
   * ## Organization
   *  Return a single organization
   */
  organization?: Maybe<Organization>;
  /**
   * ## Organization Cursors
   *  List of organizations
   */
  organizationCursors?: Maybe<OrganizationCursors>;
  /**
   * ## Organization List
   *  List of organizations
   */
  organizationList?: Maybe<PaginatedOrganizations>;
  /** ## Providers Only: Query patient records with enrolled program information */
  patientList?: Maybe<PaginatedPatients>;
  patientSmartAlertList?: Maybe<PaginatedPatientSmartAlert>;
  phoneReportInfoList?: Maybe<PhoneReportList>;
  /**
   * ## List of postits
   *  returns a list of postits based on member ID
   */
  postItList?: Maybe<Array<Maybe<PostIt>>>;
  /** ## Returns one ProviderNote */
  providerNote?: Maybe<ProviderNote>;
  /** # Returns a list of all ProviderNote with cursor for Infinite Scrolling */
  providerNoteCursorList?: Maybe<CursorPaginatedProviderNotes>;
  /** ## Returns a list of all ProviderNote or list by a Result. */
  providerNoteList?: Maybe<PaginatedProviderNotes>;
  providerNoteTagList?: Maybe<ProviderNoteTagList>;
  /**
   * ## ### ###
   * ## Quicksight Dashboard Embedding URL Query
   * ## ### ###
   * ## Returns an URL
   */
  quicksightDashboard?: Maybe<Scalars['String']>;
  /**
   * ## List of remote enrollment
   *  returns list of visits of base on filters
   */
  remoteEnrollmentList?: Maybe<PaginatedRemoteEnrollments>;
  remoteEnrollmentSummary?: Maybe<RemoteEnrollmentSummary>;
  /**
   * ## Single result
   *  Return a single result/alert data.
   */
  result?: Maybe<Result>;
  /**
   * ## Return a list of results
   *  Retrieve results by memberId, programId, taskId or from a day. If you pass both taskId and programId, it will only return results from the matching task.
   */
  resultList?: Maybe<PaginatedResults>;
  /**
   * ## Return all results from today
   *  Retrieve all the results from user for today (including all the non-recorded upcoming and missed results for today). If you do not pass memberId, your own memberId will be used. There is no pagination support for this query.
   */
  resultsFromToday?: Maybe<Array<Maybe<Result>>>;
  /**
   * ## Search patient record from NEHR
   *  __identification__: __required__, can be NRIC, passport No., and other accepted ID;
   *  __dateOfBirth__: __required__, must be in YYYY-MM-DD;
   *  __[first|last]Name__: *optional*, case insensitive;
   *  __gender__: *optional*, CODED;
   */
  searchPatient?: Maybe<Array<Maybe<User>>>;
  smartAlertTasksList?: Maybe<PaginatedSmartAlertTasks>;
  subscribesList?: Maybe<PaginatedSubscribes>;
  /** Returns task assignments list of a provider */
  taskAssignmentList?: Maybe<PaginatedTaskAssignments>;
  /**
   * ## Team
   *  Find a team by ID.
   */
  team?: Maybe<Team>;
  /**
   * ## Team List
   *  Find a list of users using various filters. Only elligible accounts will be returned.
   *  i.e. existing, non-admin, non-member users such as nurses and doctors.
   */
  teamList?: Maybe<PaginatedTeams>;
  templateGroupOrganizationsList?: Maybe<PaginatedTemplateGroupOrganizations>;
  templateGroupsList?: Maybe<PaginatedTemplateGroups>;
  timeTrackerConnection?: Maybe<TimeTrackerConnection>;
  timeTrackerCount?: Maybe<Scalars['Int']>;
  timeTrackerMany?: Maybe<Array<Maybe<TimeTracker>>>;
  timeTrackerPagination?: Maybe<TimeTrackerPagination>;
  timeTrackerWithUsername?: Maybe<Array<Maybe<TimeTrackerWithUsername>>>;
  triggerSmartAlerts?: Maybe<TriggerSmartAlertsStatus>;
  /**
   * ## Get the usage heartbeat list
   *  The list can be filtered memberId, organizationId, and fromDate (based on last timestamp)
   */
  usageHeartbeatList?: Maybe<PaginatedUsageHeartbeats>;
  /**
   * ## User
   *  Find a User by EID. If EID is not provided, return currently logged in user.
   */
  user?: Maybe<User>;
  /**
   * ## User Cursor List
   *  Return a list of users.
   */
  userCursorList?: Maybe<UserCursors>;
  userExerciseTrendsByTimeRange?: Maybe<ExerciseTrendsByTimeRange>;
  userIPList?: Maybe<Array<Maybe<UserIp>>>;
  /**
   * ## User Paginated List
   *  Return a list of users.
   */
  userList?: Maybe<PaginatedUsers>;
  userMeasurements?: Maybe<Array<Maybe<MeasureAudit>>>;
  userMonthlyTimeSpent?: Maybe<Array<Maybe<UserMonthlyTimeSpent>>>;
  userPhoneList?: Maybe<Array<Maybe<PhoneDevice>>>;
  /** User vital readings (calling "vitals" as it can be from measurements or results) */
  userVitalTrendsByTimeRange?: Maybe<UserVitalsTrendsByTimeRange>;
  /**
   * ## List of visits
   *  returns list of visits of base on filters
   */
  visitList?: Maybe<PaginatedVisits>;
  weeklyBGScheduleTemplateMany?: Maybe<Array<Maybe<WeeklyBgScheduleTemplates>>>;
};


export type QueryIcdCodeArgs = {
  id?: InputMaybe<Scalars['EID']>;
};


export type QueryIcdCodeListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<IcdCodeSearchInput>;
  sort?: InputMaybe<IcdCodeListSort>;
};


export type QueryAccountHistoryListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<AccountEventHistoryListOpts>;
};


export type QueryActiveUsersTrendArgs = {
  after?: InputMaybe<Scalars['Date']>;
  before?: InputMaybe<Scalars['Date']>;
  filters?: InputMaybe<ActiveUsersTrendFilters>;
};


export type QueryAdminProgramArgs = {
  id: Scalars['EID'];
};


export type QueryAdminProgramListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<ProgramListFilters>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ProgramListSearch>;
  sort?: InputMaybe<ProgramListSort>;
};


export type QueryAdminProgramSearchArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ProgramListSort>;
};


export type QueryAdminProgramSummaryArgs = {
  id: Scalars['EID'];
};


export type QueryAllEnrolledProgramListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters: AllEnrolledListOpts;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<EnrolledListSort>;
};


export type QueryAssessmentArgs = {
  id: Scalars['EID'];
};


export type QueryAssessmentFilledArgs = {
  id: Scalars['EID'];
};


export type QueryAssessmentFilledListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<InputAssessmentFilledFilters>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<InputAssessmentFilledSearch>;
  sort?: InputMaybe<AssessmentFilledListSort>;
};


export type QueryAssessmentListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<InputAssessmentFilters>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<InputAssessmentSearch>;
  sort?: InputMaybe<AssessmentListSort>;
};


export type QueryAuditTrailListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<AuditListFilters>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<AuditListSort>;
};


export type QueryAuditTrailManyArgs = {
  filter?: InputMaybe<FilterFindManyAuditRecordInput>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortFindManyAuditRecordInput>;
};


export type QueryAutoMsgConfigListArgs = {
  filters?: InputMaybe<AutoMsgConfigListOpts>;
};


export type QueryBatchChatHistoryArgs = {
  channelNames: Array<InputMaybe<Scalars['String']>>;
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryCareTeamMemberVisitsArgs = {
  excludeOrgIds?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  from: Scalars['Date'];
  memberIds?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  organizationIds?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  to?: InputMaybe<Scalars['Date']>;
  types?: InputMaybe<Array<InputMaybe<VisitTypeEnum>>>;
};


export type QueryChallengeRewardListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<RewardListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<RewardListSort>;
};


export type QueryDeviceErrorListArgs = {
  memberId: Scalars['EID'];
};


export type QueryDeviceHubListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<DeviceHubListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<DeviceHubListSort>;
};


export type QueryEducationTipsListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['EID'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryEnrolledPatientStatisticsArgs = {
  adminProgramIds: Array<Scalars['EID']>;
};


export type QueryEnrolledProgramArgs = {
  id: Scalars['EID'];
};


export type QueryEnrolledProgramListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<EnrolledListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<EnrolledListSort>;
};


export type QueryEnrolledUserStatusArgs = {
  adminProgramIds?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type QueryEpChangeLogListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<EpChangeLogListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<EpChangeLogListSort>;
};


export type QueryFeedbackListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<FeedbackListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<FeedbackListSort>;
};


export type QueryFileUploadListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<FileUploadListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<FileUploadListSort>;
};


export type QueryFoodLogListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<FoodLogListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<FoodLogListSort>;
};


export type QueryGetAccountRoleCapacityArgs = {
  memberId: Scalars['EID'];
};


export type QueryGetAppUpgradeInfoArgs = {
  currentVersion?: InputMaybe<Scalars['String']>;
  memberId: Scalars['EID'];
};


export type QueryGetAutoCompletedAddressArgs = {
  str?: InputMaybe<Scalars['String']>;
};


export type QueryGetAutoMsgConfigArgs = {
  id: Scalars['EID'];
};


export type QueryGetAvatarUploadUrlArgs = {
  filename: Scalars['String'];
  userId: Scalars['EID'];
};


export type QueryGetBehavioralGoalTemplatesArgs = {
  search?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<InputMaybe<BehavioralGoalsType>>>;
};


export type QueryGetBillingReportsArgs = {
  end?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['EID'];
  start?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCareTeamMemberAvailabilityArgs = {
  excludeVisitId?: InputMaybe<Scalars['EID']>;
  from: Scalars['Date'];
  memberIds: Array<Scalars['EID']>;
  to: Scalars['Date'];
};


export type QueryGetChannelMetaArgs = {
  channelName: Scalars['String'];
};


export type QueryGetChatChannelsArgs = {
  count?: InputMaybe<Scalars['Int']>;
  fromChannel?: InputMaybe<Scalars['String']>;
  onlyUnread?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['EID'];
};


export type QueryGetChatHistoryArgs = {
  channelName: Scalars['String'];
  count?: InputMaybe<Scalars['Int']>;
  fromTimestamp?: InputMaybe<Scalars['String']>;
};


export type QueryGetClinicGoalTemplatesArgs = {
  codes?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryGetConsentTemplateArgs = {
  groupId?: InputMaybe<Scalars['EID']>;
  id?: InputMaybe<Scalars['EID']>;
  language: Language;
  organizationId?: InputMaybe<Scalars['EID']>;
};


export type QueryGetConsentTemplateListArgs = {
  groupId?: InputMaybe<Scalars['EID']>;
  language?: InputMaybe<Language>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryGetCoveredOrganizationsArgs = {
  memberId: Scalars['EID'];
};


export type QueryGetDeviceErrorArgs = {
  id: Scalars['EID'];
};


export type QueryGetDeviceHubArgs = {
  id?: InputMaybe<Scalars['EID']>;
  memberId?: InputMaybe<Scalars['EID']>;
  serialNumber?: InputMaybe<Scalars['String']>;
};


export type QueryGetEducationTipsTemplateArgs = {
  id?: InputMaybe<Scalars['EID']>;
};


export type QueryGetEducationTipsTemplateListArgs = {
  category?: InputMaybe<EducationTipsCategory>;
  language?: InputMaybe<Language>;
  level?: InputMaybe<EducationTipsLevel>;
};


export type QueryGetFeatureUploadArgs = {
  release: Scalars['Int'];
};


export type QueryGetFeedbackArgs = {
  memberId?: InputMaybe<Scalars['EID']>;
};


export type QueryGetFileUploadArgs = {
  id?: InputMaybe<Scalars['EID']>;
};


export type QueryGetFoodLogArgs = {
  id?: InputMaybe<Scalars['EID']>;
};


export type QueryGetHandoutsArgs = {
  category?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetHbA1cArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  memberId: Scalars['EID'];
  toDate?: InputMaybe<Scalars['Date']>;
};


export type QueryGetInsuranceProviderArgs = {
  id: Scalars['EID'];
};


export type QueryGetInventoryArgs = {
  organizationId?: InputMaybe<Scalars['EID']>;
};


export type QueryGetIoTDeviceArgs = {
  deviceId?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['EID']>;
  position?: InputMaybe<Scalars['Int']>;
};


export type QueryGetIoTDeviceConfigArgs = {
  deviceId: Scalars['String'];
};


export type QueryGetLatestA1CArgs = {
  memberId: Scalars['EID'];
};


export type QueryGetLatestStripArgs = {
  memberId?: InputMaybe<Scalars['EID']>;
};


export type QueryGetLoanDeviceArgs = {
  deviceId?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['EID']>;
  serialNumber?: InputMaybe<Scalars['String']>;
};


export type QueryGetMedicationArgs = {
  id: Scalars['EID'];
};


export type QueryGetMemberTotalTimeSpentArgs = {
  memberId: Scalars['EID'];
};


export type QueryGetMentorRelationArgs = {
  healthCoachId?: InputMaybe<Scalars['EID']>;
  mentorId?: InputMaybe<Scalars['EID']>;
};


export type QueryGetMissingInfoArgs = {
  id?: InputMaybe<Scalars['EID']>;
};


export type QueryGetMissingInfoListArgs = {
  filters?: InputMaybe<MissingInfoListOpts>;
};


export type QueryGetMonthlyReviewArgs = {
  id: Scalars['EID'];
};


export type QueryGetMonthlyReviewContentArgs = {
  memberId: Scalars['EID'];
};


export type QueryGetMonthlyReviewGoalMeetingArgs = {
  conditionEnum: ConditionEnum;
  goalEnum: ClinicGoalEnum;
  memberId: Scalars['EID'];
};


export type QueryGetMonthlyReviewRecommendationArgs = {
  memberId?: InputMaybe<Scalars['EID']>;
};


export type QueryGetMonthlyReviewSummaryArgs = {
  memberId?: InputMaybe<Scalars['EID']>;
};


export type QueryGetMonthlyTimeSpentArgs = {
  memberId: Scalars['EID'];
  monthOfYear: Scalars['Int'];
  organizationId?: InputMaybe<Scalars['EID']>;
};


export type QueryGetNeedSignOffPatientsArgs = {
  mentorId: Scalars['EID'];
};


export type QueryGetOrderArgs = {
  id?: InputMaybe<Scalars['EID']>;
};


export type QueryGetOrgScheduleArgs = {
  id: Scalars['EID'];
};


export type QueryGetPesStatementsArgs = {
  memberId: Scalars['EID'];
  visitId?: InputMaybe<Scalars['EID']>;
};


export type QueryGetPatientAlertConfigArgs = {
  memberId: Scalars['EID'];
};


export type QueryGetPatientAssigneeArgs = {
  memberId: Scalars['EID'];
};


export type QueryGetPatientComplexityArgs = {
  memberId: Scalars['EID'];
};


export type QueryGetPatientComplexityByCodesArgs = {
  billableCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nonBillableCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryGetPatientComplexityByConditionsArgs = {
  conditions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryGetPatientConsentArgs = {
  memberId: Scalars['EID'];
};


export type QueryGetPatientHiatusStatusArgs = {
  memberId: Scalars['EID'];
};


export type QueryGetPatientMntProgramArgs = {
  patientId: Scalars['EID'];
};


export type QueryGetPatientReferralArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  patientId?: InputMaybe<Scalars['EID']>;
  referredBy?: InputMaybe<Scalars['EID']>;
};


export type QueryGetPatientRewardArgs = {
  memberId: Scalars['EID'];
};


export type QueryGetPatientSignOffTasksArgs = {
  patientId: Scalars['EID'];
};


export type QueryGetPatientSmartAlertArgs = {
  memberId: Scalars['EID'];
};


export type QueryGetPesTemplateArgs = {
  id?: InputMaybe<Scalars['EID']>;
};


export type QueryGetPesTemplatesByCategoryArgs = {
  category?: InputMaybe<PesCategory>;
};


export type QueryGetPostItArgs = {
  id: Scalars['EID'];
};


export type QueryGetPresignedFeatureUploadArgs = {
  filename: Scalars['String'];
  release: Scalars['Int'];
};


export type QueryGetPresignedFileUploadArgs = {
  filename: Scalars['String'];
  userId: Scalars['EID'];
};


export type QueryGetPresignedLogoUploadArgs = {
  filename: Scalars['String'];
  organizationId: Scalars['EID'];
};


export type QueryGetPresignedUploadArgs = {
  filename: Scalars['String'];
  userId: Scalars['EID'];
};


export type QueryGetProviderBillableTimeArgs = {
  from?: InputMaybe<Scalars['Date']>;
  providerId?: InputMaybe<Scalars['EID']>;
  to?: InputMaybe<Scalars['Date']>;
};


export type QueryGetRemoteEnrollmentArgs = {
  memberId?: InputMaybe<Scalars['EID']>;
};


export type QueryGetRewardRedeemArgs = {
  id: Scalars['EID'];
};


export type QueryGetRewardRedeemListArgs = {
  category?: InputMaybe<RewardRedeemCategory>;
  count?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['EID'];
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<RewardRedeemSort>;
};


export type QueryGetRewardRedeemTemplateArgs = {
  id: Scalars['EID'];
};


export type QueryGetRewardRedeemTemplatesListArgs = {
  category?: InputMaybe<RewardRedeemCategory>;
  count?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['EID']>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<RewardRedeemTemplateSort>;
};


export type QueryGetSignOffTaskArgs = {
  id: Scalars['EID'];
};


export type QueryGetSignedDownloadArgs = {
  fileKey: Scalars['String'];
  type?: InputMaybe<FoodLogImageEnum>;
  userId: Scalars['EID'];
};


export type QueryGetSignedFeatureDownloadArgs = {
  release?: InputMaybe<Scalars['Int']>;
};


export type QueryGetSignedFileDownloadArgs = {
  fileKey: Scalars['String'];
  type?: InputMaybe<FileUploadTypeEnum>;
  userId: Scalars['EID'];
};


export type QueryGetSmartAlertTypesArgs = {
  inUse?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetSystemDynamicConfigsArgs = {
  count?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetTaskAssignmentsByPatientIdArgs = {
  memberId?: InputMaybe<Scalars['EID']>;
  sort?: InputMaybe<TaskAssignmentListSort>;
  status?: InputMaybe<Array<InputMaybe<TaskAssignmentStatus>>>;
};


export type QueryGetTeamChannelGroupsArgs = {
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  userId?: InputMaybe<Scalars['EID']>;
};


export type QueryGetTeamMemberTotalChannelsArgs = {
  teamMemberId: Scalars['EID'];
};


export type QueryGetTeamTotalChannelsArgs = {
  teamId: Scalars['EID'];
};


export type QueryGetTemplateGroupArgs = {
  id: Scalars['EID'];
};


export type QueryGetUnreadSignOffCommentsArgs = {
  healthCoachId: Scalars['EID'];
};


export type QueryGetUserChatInfoArgs = {
  newAuthKey?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['EID'];
};


export type QueryGetUserDevicesArgs = {
  userId: Scalars['EID'];
};


export type QueryGetUserDevicesInfoArgs = {
  memberId: Scalars['EID'];
};


export type QueryGetVersionHistoryListArgs = {
  enrolledProgramId: Scalars['EID'];
};


export type QueryGetVirtualVisitStatusArgs = {
  visitIds: Array<InputMaybe<Scalars['EID']>>;
};


export type QueryGetVirtualVisitTeamArgs = {
  visitId: Scalars['EID'];
};


export type QueryGetVirtualVisitTokenArgs = {
  visitId: Scalars['EID'];
};


export type QueryGetVisitArgs = {
  id: Scalars['EID'];
};


export type QueryGetVisitMemberProfileArgs = {
  memberId: Scalars['EID'];
  visitId: Scalars['EID'];
};


export type QueryInsuranceListArgs = {
  memberId: Scalars['EID'];
};


export type QueryInsuranceProviderListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<InsuranceProviderFilters>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<InputInsuranceProviderSearch>;
  sort?: InputMaybe<InsuranceProviderListSort>;
};


export type QueryInventoryHistoryListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<InventoryHistoryListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<InventoryHistoryListSort>;
};


export type QueryInventoryReportArgs = {
  dateRange?: InputMaybe<InventoryHistoryDateRangeEnum>;
  fromDate?: InputMaybe<Scalars['Date']>;
  groupBy?: InputMaybe<InventoryReportGroupByEnum>;
  organizationId?: InputMaybe<Scalars['EID']>;
  toDate?: InputMaybe<Scalars['Date']>;
};


export type QueryInventoryTransactionListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<InventoryTransactionListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<InventoryTransactionListSort>;
};


export type QueryIotDeviceConfigListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<IoTDeviceListConfigOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<IoTDeviceListConfigSort>;
};


export type QueryIotDeviceListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<IoTDeviceListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<IoTDeviceListSort>;
};


export type QueryLabResultConnectionArgs = {
  after?: InputMaybe<Scalars['ConnectionCursor']>;
  before?: InputMaybe<Scalars['ConnectionCursor']>;
  filter?: InputMaybe<FilterFindManyLabResultsInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortConnectionLabResultsEnum>;
};


export type QueryLabResultCountArgs = {
  filter?: InputMaybe<FilterLabResultsInput>;
};


export type QueryLabResultManyArgs = {
  filter?: InputMaybe<FilterFindManyLabResultsInput>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortFindManyLabResultsInput>;
};


export type QueryLabResultPaginationArgs = {
  filter?: InputMaybe<FilterFindManyLabResultsInput>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortFindManyLabResultsInput>;
};


export type QueryLabResultTemplateManyArgs = {
  filter?: InputMaybe<FilterFindManyLabResultTemplatesInput>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortFindManyLabResultTemplatesInput>;
};


export type QueryListChannelsFromGroupArgs = {
  channelGroup: Scalars['String'];
};


export type QueryListPatientReferralArgs = {
  referredBy?: InputMaybe<Scalars['EID']>;
};


export type QueryLoanDeviceListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<LoanDeviceListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<LoanDeviceListSort>;
};


export type QueryMeasureArgs = {
  id: Scalars['EID'];
};


export type QueryMeasureCursorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<MeasureListFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<MeasureListSort>;
};


export type QueryMeasureLatestByTypeArgs = {
  filters?: InputMaybe<MeasureListFilters>;
  sort?: InputMaybe<MeasureListSort>;
};


export type QueryMeasureTrendsForProgramArgs = {
  after?: InputMaybe<Scalars['Date']>;
  before?: InputMaybe<Scalars['Date']>;
  filters: ProgramMeasureTrendsFilters;
};


export type QueryMeasureTrendsForUserArgs = {
  after?: InputMaybe<Scalars['Date']>;
  before?: InputMaybe<Scalars['Date']>;
  filters: MeasureTrendsFilters;
};


export type QueryMeasurementTrendsForProgramArgs = {
  after?: InputMaybe<Scalars['Date']>;
  before?: InputMaybe<Scalars['Date']>;
  filters: ProgramMeasurementTrendsFilters;
};


export type QueryMedicationListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<MedicationListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<MedicationListSort>;
};


export type QueryMonthlyReviewListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<MonthlyReviewListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<MonthlyReviewListSort>;
};


export type QueryMonthlyReviewListForDoctorArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<MonthlyReviewListForDoctorOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<MonthlyReviewListSort>;
};


export type QueryMonthlyTimeSpentListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<MonthlyTimeSpentListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<MonthlyTimeSpentListSort>;
};


export type QueryOrderListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<OrderListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<OrderListSort>;
};


export type QueryOrgNotificationsArgs = {
  alert?: InputMaybe<OrgSmartAlertFilters>;
  foodLog?: InputMaybe<OrgFoodLogFilters>;
  task?: InputMaybe<OrgTaskFilters>;
};


export type QueryOrgScheduleListArgs = {
  organizationId: Scalars['EID'];
};


export type QueryOrganizationArgs = {
  id: Scalars['EID'];
};


export type QueryOrganizationCursorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<OrganizationSearch>;
  sort?: InputMaybe<OrganizationSort>;
};


export type QueryOrganizationListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<OrganizationFilter>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<OrganizationSearch>;
  sort?: InputMaybe<OrganizationSort>;
};


export type QueryPatientListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<InputPatientFilters>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<InputUserSearch>;
  sort?: InputMaybe<UserListSort>;
};


export type QueryPatientSmartAlertListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<PatientSmartAlertOpts>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPhoneReportInfoListArgs = {
  memberId?: InputMaybe<Scalars['EID']>;
  phoneId?: InputMaybe<Scalars['String']>;
};


export type QueryPostItListArgs = {
  memberId: Scalars['EID'];
};


export type QueryProviderNoteArgs = {
  id: Scalars['EID'];
};


export type QueryProviderNoteCursorListArgs = {
  after?: InputMaybe<Scalars['Ref']>;
  before?: InputMaybe<Scalars['Ref']>;
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<ProviderNoteListCursorOpts>;
  sort?: InputMaybe<ProviderNoteListSort>;
};


export type QueryProviderNoteListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<ProviderNoteListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ProviderNoteListSort>;
};


export type QueryQuicksightDashboardArgs = {
  dashboardId: Scalars['String'];
  organizationId?: InputMaybe<Scalars['EID']>;
  username: Scalars['String'];
};


export type QueryRemoteEnrollmentListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<RemoteEnrollmentListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<RemoteEnrollmentListSort>>>;
};


export type QueryRemoteEnrollmentSummaryArgs = {
  crossClinic?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['EID']>;
};


export type QueryResultArgs = {
  id?: InputMaybe<Scalars['EID']>;
};


export type QueryResultListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters: ResultListOpts;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ResultListSort>;
};


export type QueryResultsFromTodayArgs = {
  filters?: InputMaybe<ResultsFromTodayOpts>;
  sort?: InputMaybe<ResultListSort>;
};


export type QuerySearchPatientArgs = {
  dateOfBirth: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['Gender']>;
  identification: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
};


export type QuerySmartAlertTasksListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<SmartAlertOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SmartAlerttListSort>;
};


export type QuerySubscribesListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['EID'];
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SubscribesSort>;
};


export type QueryTaskAssignmentListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<TaskAssignmentListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<TaskAssignmentListSort>;
};


export type QueryTeamArgs = {
  id?: InputMaybe<Scalars['EID']>;
};


export type QueryTeamListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<TeamListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<TeamSearchInput>;
  sort?: InputMaybe<TeamListSort>;
};


export type QueryTemplateGroupOrganizationsListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryTemplateGroupsListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryTimeTrackerConnectionArgs = {
  after?: InputMaybe<Scalars['ConnectionCursor']>;
  before?: InputMaybe<Scalars['ConnectionCursor']>;
  filter?: InputMaybe<FilterFindManyTimeTrackerInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortConnectionTimeTrackerEnum>;
};


export type QueryTimeTrackerCountArgs = {
  filter?: InputMaybe<FilterTimeTrackerInput>;
};


export type QueryTimeTrackerManyArgs = {
  filter?: InputMaybe<FilterFindManyTimeTrackerInput>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortFindManyTimeTrackerInput>;
};


export type QueryTimeTrackerPaginationArgs = {
  filter?: InputMaybe<FilterFindManyTimeTrackerInput>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortFindManyTimeTrackerInput>;
};


export type QueryTimeTrackerWithUsernameArgs = {
  userId: Scalars['String'];
};


export type QueryTriggerSmartAlertsArgs = {
  memberId?: InputMaybe<Scalars['EID']>;
};


export type QueryUsageHeartbeatListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<UsageHeartbeatListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<UsageHeartbeatListSort>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['EID']>;
  identification?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['Ref']>;
};


export type QueryUserCursorListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters: UserListFilters;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<InputUserSearch>;
  sort?: InputMaybe<UserListSort>;
};


export type QueryUserExerciseTrendsByTimeRangeArgs = {
  memberId: Scalars['EID'];
  timeRange: UserVitalsTimeRangeEnum;
};


export type QueryUserIpListArgs = {
  userId: Scalars['String'];
};


export type QueryUserListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters: UserListFilters;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<InputUserSearch>;
  sort?: InputMaybe<UserListSort>;
};


export type QueryUserMeasurementsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};


export type QueryUserMonthlyTimeSpentArgs = {
  from?: InputMaybe<Scalars['Date']>;
  to?: InputMaybe<Scalars['Date']>;
  userId: Scalars['String'];
};


export type QueryUserPhoneListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortLastUsed>;
  userId: Scalars['String'];
};


export type QueryUserVitalTrendsByTimeRangeArgs = {
  enrolledProgramIds?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  memberId: Scalars['EID'];
  timeRange: UserVitalsTimeRangeEnum;
  vitalTypes: Array<InputMaybe<Vital>>;
};


export type QueryVisitListArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<VisitListOpts>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<VisitListSort>;
  sortByConfirm?: InputMaybe<Scalars['Boolean']>;
};


export type QueryWeeklyBgScheduleTemplateManyArgs = {
  filter?: InputMaybe<FilterFindManyWeeklyBgScheduleTemplatesInput>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortFindManyWeeklyBgScheduleTemplatesInput>;
};

export enum Race {
  Ab = 'AB',
  Ac = 'AC',
  Ad = 'AD',
  Af = 'AF',
  Ag = 'AG',
  Ah = 'AH',
  Ai = 'AI',
  Aj = 'AJ',
  Al = 'AL',
  Am = 'AM',
  An = 'AN',
  Ao = 'AO',
  Ap = 'AP',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Ax = 'AX',
  Ay = 'AY',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bc = 'BC',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bk = 'BK',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bu = 'BU',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cb = 'CB',
  Cc = 'CC',
  Cd = 'CD',
  Ce = 'CE',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Cj = 'CJ',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cs = 'CS',
  Cz = 'CZ',
  Da = 'DA',
  Db = 'DB',
  Dh = 'DH',
  Ds = 'DS',
  Du = 'DU',
  Dy = 'DY',
  El = 'EL',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Eu = 'EU',
  Ey = 'EY',
  Fi = 'FI',
  Fj = 'FJ',
  Fm = 'FM',
  Fr = 'FR',
  Ga = 'GA',
  Ge = 'GE',
  Gh = 'GH',
  Gk = 'GK',
  Gm = 'GM',
  Go = 'GO',
  Gr = 'GR',
  Hi = 'HI',
  Ho = 'HO',
  Ht = 'HT',
  Hu = 'HU',
  Hw = 'HW',
  Ia = 'IA',
  Ib = 'IB',
  Id = 'ID',
  Ik = 'IK',
  Il = 'IL',
  In = 'IN',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Iu = 'IU',
  Ja = 'JA',
  Jk = 'JK',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Jw = 'JW',
  Ka = 'KA',
  Kb = 'KB',
  Kc = 'KC',
  Kd = 'KD',
  Ke = 'KE',
  Kf = 'KF',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Kk = 'KK',
  Kl = 'KL',
  Km = 'KM',
  Kn = 'KN',
  Kr = 'KR',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Li = 'LI',
  Lk = 'LK',
  Lt = 'LT',
  Lx = 'LX',
  Ly = 'LY',
  Ma = 'MA',
  Mb = 'MB',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mi = 'MI',
  Mj = 'MJ',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nw = 'NW',
  Nz = 'NZ',
  Pa = 'PA',
  Pb = 'PB',
  Pc = 'PC',
  Pe = 'PE',
  Ph = 'PH',
  Pj = 'PJ',
  Pk = 'PK',
  Pl = 'PL',
  Pn = 'PN',
  Po = 'PO',
  Pr = 'PR',
  Ps = 'PS',
  Py = 'PY',
  Rj = 'RJ',
  Rk = 'RK',
  Ro = 'RO',
  Ru = 'RU',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sf = 'SF',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sq = 'SQ',
  Sr = 'SR',
  Ss = 'SS',
  Su = 'SU',
  Sw = 'SW',
  Sy = 'SY',
  Te = 'TE',
  Th = 'TH',
  Ti = 'TI',
  Tj = 'TJ',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tp = 'TP',
  Tr = 'TR',
  Un = 'UN',
  Ur = 'UR',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Ve = 'VE',
  Vn = 'VN',
  We = 'WE',
  Xd = 'XD',
  Xe = 'XE',
  Xi = 'XI',
  Xx = 'XX',
  Yu = 'YU',
  Zw = 'ZW'
}

export enum Race1 {
  Aian = 'AIAN',
  Asian = 'ASIAN',
  Baa = 'BAA',
  Hispanic = 'HISPANIC',
  Multiracial = 'MULTIRACIAL',
  Nhopi = 'NHOPI',
  NotToSay = 'NOT_TO_SAY',
  Other = 'OTHER',
  White = 'WHITE'
}

export type Race1Code = {
  __typename?: 'Race1Code';
  code?: Maybe<Race1>;
  description?: Maybe<Scalars['String']>;
};

export type RaceCode = {
  __typename?: 'RaceCode';
  code?: Maybe<Race>;
  description?: Maybe<Scalars['String']>;
};

export enum RatingCategoryEnum {
  Excellent = 'EXCELLENT',
  Poor = 'POOR'
}

export type RedeemSurveySchema = {
  __typename?: 'RedeemSurveySchema';
  question: Scalars['String'];
  rating: Scalars['Int'];
};

export enum RedeemSurveyStatus {
  Closed = 'CLOSED',
  Submitted = 'SUBMITTED'
}

export enum ReferTechLevelEnum {
  NotEligible = 'NOT_ELIGIBLE',
  SmartPhoneNotPreferred = 'SMART_PHONE_NOT_PREFERRED',
  SmartPhonePreferred = 'SMART_PHONE_PREFERRED'
}

export type ReferralStatus = {
  __typename?: 'ReferralStatus';
  basicInfoEnroll?: Maybe<StatusEnum>;
  clinicalGoalsEnroll?: Maybe<StatusEnum>;
  confirmDevicesEnroll?: Maybe<StatusEnum>;
  enrollStatus?: Maybe<StatusEnum>;
  mntReferralEnroll?: Maybe<StatusEnum>;
  patientConsentEnroll?: Maybe<StatusEnum>;
  prgramEligibilityEnroll?: Maybe<StatusEnum>;
  referStatus?: Maybe<StatusEnum>;
  scheduleInitVisitEnroll?: Maybe<StatusEnum>;
  screeningStatus?: Maybe<StatusEnum>;
};

export type RemoteEnrollment = {
  __typename?: 'RemoteEnrollment';
  billingCodeAttached?: Maybe<RemoteTaskStatus>;
  callHistories?: Maybe<Array<Maybe<RemoteEnrollmentCallRecord>>>;
  callers?: Maybe<Array<Maybe<MiniProvider>>>;
  carePlanFiled?: Maybe<RemoteTaskStatus>;
  conditionsList?: Maybe<ConditionsListType>;
  confirmedBy?: Maybe<MiniProvider>;
  consentFiled?: Maybe<RemoteTaskStatus>;
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<MiniProvider>;
  deviceDelivered?: Maybe<RemoteTaskStatus>;
  doctorReferral?: Maybe<Scalars['Boolean']>;
  doctorReferralBy?: Maybe<RemoteTaskStatus>;
  doctorReferralDate?: Maybe<Scalars['Date']>;
  excludedReason?: Maybe<RemoteEnrollmentExcludedReasonEnums>;
  language?: Maybe<LanguageCode>;
  latestStickyNote?: Maybe<PostIt>;
  member?: Maybe<User>;
  nextVisit?: Maybe<RemoteEnrollmentVisit>;
  nutritionAssessment?: Maybe<RemoteTaskStatus>;
  organization?: Maybe<Organization>;
  patientDoctorId?: Maybe<Scalars['EID']>;
  patientDoctorName?: Maybe<Scalars['String']>;
  patientRefer?: Maybe<PatientReferral>;
  pinnedToEMR?: Maybe<RemoteTaskStatus>;
  shippingInfo?: Maybe<ShippingInfo>;
  status?: Maybe<RemoteEnrollmentStatus>;
  stickyNotesCount?: Maybe<Scalars['Int']>;
  techOnBoard?: Maybe<RemoteTaskStatus>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<MiniProvider>;
};

export type RemoteEnrollmentCallRecord = {
  __typename?: 'RemoteEnrollmentCallRecord';
  callDate?: Maybe<Scalars['Date']>;
  isPickedUp?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['EID']>;
};

export enum RemoteEnrollmentExcludedReasonEnums {
  CallFailed = 'CALL_FAILED',
  ChangePcp = 'CHANGE_PCP',
  NeedToThinkAboutDoctorFirst = 'NEED_TO_THINK_ABOUT_DOCTOR_FIRST',
  NoAnswerLeftVoicemail = 'NO_ANSWER_LEFT_VOICEMAIL',
  NoSmartPhone = 'NO_SMART_PHONE',
  Other = 'OTHER',
  RejectedCopayConcern = 'REJECTED_COPAY_CONCERN',
  RejectedDoesNotTrustTheProgram = 'REJECTED_DOES_NOT_TRUST_THE_PROGRAM',
  RejectedLanguageBarrier = 'REJECTED_LANGUAGE_BARRIER',
  RejectedNotAppropriate = 'REJECTED_NOT_APPROPRIATE',
  RejectedNoInterest = 'REJECTED_NO_INTEREST',
  RejectedStrongTechBarrier = 'REJECTED_STRONG_TECH_BARRIER',
  VeryInterested = 'VERY_INTERESTED'
}

export enum RemoteEnrollmentFilterLanguage {
  Ct = 'CT',
  El = 'EL',
  Mn = 'MN',
  Others = 'OTHERS',
  Sp = 'SP',
  Vn = 'VN'
}

export type RemoteEnrollmentListOpts = {
  callerId?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  crossClinic?: InputMaybe<Scalars['Boolean']>;
  doctorReferral?: InputMaybe<Scalars['Boolean']>;
  doctorReferralDate?: InputMaybe<Scalars['Date']>;
  enrolledByMAMD?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Array<InputMaybe<RemoteEnrollmentFilterLanguage>>>;
  pinnedToEMR?: InputMaybe<Scalars['Boolean']>;
  providerId?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  referredByMAMD?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<RemoteEnrollmentStatus>;
};

export type RemoteEnrollmentListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<RemoteEnrollmentListSortField>;
};

export enum RemoteEnrollmentListSortField {
  CreateAt = 'CREATE_AT',
  DoctorReferral = 'DOCTOR_REFERRAL',
  Language = 'LANGUAGE',
  LastCallDate = 'LAST_CALL_DATE',
  LastName = 'LAST_NAME',
  NextVisitTime = 'NEXT_VISIT_TIME',
  PinnedToEmr = 'PINNED_TO_EMR'
}

export enum RemoteEnrollmentStatus {
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Excluded = 'EXCLUDED',
  Init = 'INIT',
  Referred = 'REFERRED',
  Tbd = 'TBD'
}

export type RemoteEnrollmentSummary = {
  __typename?: 'RemoteEnrollmentSummary';
  completed?: Maybe<Scalars['Int']>;
  confirmed?: Maybe<Scalars['Int']>;
  enrolledByMAMD?: Maybe<Scalars['Int']>;
  excluded?: Maybe<Scalars['Int']>;
  referred?: Maybe<Scalars['Int']>;
  referredByMAMD?: Maybe<Scalars['Int']>;
  screened?: Maybe<Scalars['Int']>;
  tbd?: Maybe<Scalars['Int']>;
};

export type RemoteEnrollmentVisit = {
  __typename?: 'RemoteEnrollmentVisit';
  appointmentAt?: Maybe<Scalars['Date']>;
  visitId?: Maybe<Scalars['EID']>;
};

export type RemoteTaskStatus = {
  __typename?: 'RemoteTaskStatus';
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<MiniProvider>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<MiniProvider>;
  value?: Maybe<Scalars['Boolean']>;
};

export type RemoveByIdLabResultTemplatesPayload = {
  __typename?: 'RemoveByIdLabResultTemplatesPayload';
  /** Removed document */
  record?: Maybe<LabResultTemplates>;
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
};

export type RemoveByIdWeeklyBgScheduleTemplatesPayload = {
  __typename?: 'RemoveByIdWeeklyBGScheduleTemplatesPayload';
  /** Removed document */
  record?: Maybe<WeeklyBgScheduleTemplates>;
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
};

/** ## Resolution Type */
export type Resolution = {
  __typename?: 'Resolution';
  status?: Maybe<PesResolution>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<Scalars['EID']>;
  visit?: Maybe<Visit>;
};

/**
 * ## Result for tasks & alerts
 *  Result data generated against pre-set threshold range and schedule. Measurement, assessment, threshold
 *  and schedule data inside this type is de-normalized so it does not come from the original source. Data in
 *  _measure_ and _assessment_ field is _not_ de-normalized. For inserting measurements/assessments to a specific
 *  result/task, use the _refId_ field.
 */
export type Result = {
  assessmentFilled?: Maybe<AssessmentFilled>;
  careStatus?: Maybe<CareStatus>;
  caredBy?: Maybe<User>;
  closedAt?: Maybe<Scalars['Date']>;
  comments?: Maybe<Array<Maybe<ResultCommentType>>>;
  date?: Maybe<Scalars['Date']>;
  dismissed?: Maybe<Scalars['Boolean']>;
  enrolledProgram?: Maybe<EnrolledProgram>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['EID']>;
  late?: Maybe<Scalars['Boolean']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measure?: Maybe<Measure>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  notes?: Maybe<CursorPaginatedProviderNotes>;
  notified?: Maybe<Scalars['Boolean']>;
  refId?: Maybe<Scalars['EID']>;
  /** # 08/18/2017 COCONUT ## */
  repeat?: Maybe<Scalars['String']>;
  resultUpdates?: Maybe<Array<Maybe<ResultUpdateType>>>;
  reviewedAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<TaskSchedule>;
  severity?: Maybe<AlertSeverity>;
  taskStatus?: Maybe<TaskStatus>;
  threshold?: Maybe<Array<Maybe<AlertThreshold>>>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<TaskType>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
  violators?: Maybe<Array<Maybe<MeasureType>>>;
};


/**
 * ## Result for tasks & alerts
 *  Result data generated against pre-set threshold range and schedule. Measurement, assessment, threshold
 *  and schedule data inside this type is de-normalized so it does not come from the original source. Data in
 *  _measure_ and _assessment_ field is _not_ de-normalized. For inserting measurements/assessments to a specific
 *  result/task, use the _refId_ field.
 */
export type ResultEnrolledProgramArgs = {
  relatedTaskOnly?: InputMaybe<Scalars['Boolean']>;
};


/**
 * ## Result for tasks & alerts
 *  Result data generated against pre-set threshold range and schedule. Measurement, assessment, threshold
 *  and schedule data inside this type is de-normalized so it does not come from the original source. Data in
 *  _measure_ and _assessment_ field is _not_ de-normalized. For inserting measurements/assessments to a specific
 *  result/task, use the _refId_ field.
 */
export type ResultNotesArgs = {
  after?: InputMaybe<Scalars['Ref']>;
  before?: InputMaybe<Scalars['Ref']>;
  count?: InputMaybe<Scalars['Int']>;
};

/** Result Comment Input */
export type ResultCommentInput = {
  commentedById?: InputMaybe<Scalars['EID']>;
  text?: InputMaybe<Scalars['String']>;
};

/** Result Comment Type */
export type ResultCommentType = {
  __typename?: 'ResultCommentType';
  commentedAt?: Maybe<Scalars['Date']>;
  commentedBy?: Maybe<User>;
  text?: Maybe<Scalars['String']>;
};

/**
 * _day_ option allows you to return all results from a specific day. If you pass both _taskId_ and
 * _programId_, it will only return results from the matching task. _programId_ and _memberId_
 * cannot be used together. _taskId_ cannot be used alone without _programId_.
 */
export type ResultListOpts = {
  adminProgramId?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  alertsOnly?: InputMaybe<Scalars['Boolean']>;
  careStatus?: InputMaybe<Array<InputMaybe<CareStatus>>>;
  day?: InputMaybe<Scalars['CalDate']>;
  enrolledProgramId?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  fromDate?: InputMaybe<Scalars['Date']>;
  memberId?: InputMaybe<Scalars['EID']>;
  severity?: InputMaybe<Array<InputMaybe<AlertSeverity>>>;
  taskId?: InputMaybe<Scalars['EID']>;
  timezone?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['Date']>;
  type?: InputMaybe<Array<InputMaybe<TaskType>>>;
};

export type ResultListSort = {
  direction?: InputMaybe<SortDirection>;
  field: ResultListSortField;
};

export enum ResultListSortField {
  Date = 'DATE'
}

export type ResultStatType = {
  __typename?: 'ResultStatType';
  bg?: Maybe<BgStatType>;
  bp?: Maybe<BpStatType>;
  po?: Maybe<PoStatType>;
};

/** Result Update Type */
export type ResultUpdateType = {
  __typename?: 'ResultUpdateType';
  updatedAt?: Maybe<Scalars['Date']>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** If memberId is not passed, it will default to your EID. If date is not passed, it will default to the current date. */
export type ResultsFromTodayOpts = {
  enrolledProgramId?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  memberId?: InputMaybe<Scalars['EID']>;
  timezone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Array<InputMaybe<TaskType>>>;
};

export enum ReturnReason {
  Damaged = 'DAMAGED',
  Lost = 'LOST',
  Normal = 'NORMAL'
}

export type RewardListOpts = {
  category?: InputMaybe<ChallengeRewardDetailsCategory>;
  memberId: Scalars['EID'];
};

export type RewardListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<RewardListSortField>;
};

export enum RewardListSortField {
  Date = 'DATE'
}

export type RewardRedeem = {
  __typename?: 'RewardRedeem';
  discountCode?: Maybe<DiscountCode>;
  id?: Maybe<Scalars['EID']>;
  redeemDate?: Maybe<Scalars['Date']>;
  redeemTemplate?: Maybe<RewardRedeemTemplate>;
  survey?: Maybe<Array<Maybe<RewardRedeemSurvey>>>;
  timezone?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export enum RewardRedeemCategory {
  Discount = 'DISCOUNT',
  VirtualClass = 'VIRTUAL_CLASS'
}

export type RewardRedeemSort = {
  direction: SortDirection;
  field: RewardRedeemSortField;
};

export enum RewardRedeemSortField {
  RedeemDate = 'REDEEM_DATE'
}

export type RewardRedeemSurvey = {
  __typename?: 'RewardRedeemSurvey';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  memberId?: Maybe<Scalars['EID']>;
  status?: Maybe<RedeemSurveyStatus>;
  surveys?: Maybe<Array<Maybe<RedeemSurveySchema>>>;
};

export type RewardRedeemTemplate = {
  __typename?: 'RewardRedeemTemplate';
  author?: Maybe<AuthorInfo>;
  category?: Maybe<Scalars['String']>;
  dateSetting?: Maybe<DateSetting>;
  discountInfo?: Maybe<DiscountInfo>;
  iconUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  microClass?: Maybe<MicroClassInfo>;
  organizationId?: Maybe<Scalars['EID']>;
  parentTemplateId?: Maybe<Scalars['EID']>;
  points?: Maybe<Scalars['Int']>;
  subTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
};

export type RewardRedeemTemplateSort = {
  direction: SortDirection;
  field: RewardRedeemTemplateSortField;
};

export enum RewardRedeemTemplateSortField {
  Createdat = 'CREATEDAT'
}

export type RoleCapacity = {
  __typename?: 'RoleCapacity';
  capacity: Scalars['Int'];
  role: Scalars['String'];
};

export type RoleCapacityInput = {
  capacity: Scalars['Int'];
  role: Scalars['String'];
};

/** Role Categories */
export enum RoleCategory {
  Admin = 'ADMIN',
  AreaManager = 'AREA_MANAGER',
  Biller = 'BILLER',
  CareGiver = 'CARE_GIVER',
  Member = 'MEMBER',
  Other = 'OTHER',
  PartnerBiller = 'PARTNER_BILLER',
  Provider = 'PROVIDER',
  SuperAdmin = 'SUPER_ADMIN'
}

export type RoleCount = {
  __typename?: 'RoleCount';
  count?: Maybe<Scalars['Int']>;
  roleType?: Maybe<RoleType>;
};

export enum RoleType {
  Admin = 'ADMIN',
  AreaManager = 'AREA_MANAGER',
  Biller = 'BILLER',
  Ca = 'CA',
  Hc = 'HC',
  Ma = 'MA',
  Md = 'MD',
  Member = 'MEMBER',
  PartnerBiller = 'PARTNER_BILLER',
  Rd = 'RD',
  SuperAdmin = 'SUPER_ADMIN'
}

export enum Routine {
  AtLeastOneMeal = 'AT_LEAST_ONE_MEAL',
  IrregularRoutine = 'IRREGULAR_ROUTINE',
  None = 'NONE',
  NoRoutine = 'NO_ROUTINE',
  RegularRoutine = 'REGULAR_ROUTINE'
}

export enum Subscribes_Platform {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export enum Subscribes_Status {
  Cancel = 'CANCEL',
  Expired = 'EXPIRED',
  Renew = 'RENEW',
  Subscribed = 'SUBSCRIBED',
  Unsubscribed = 'UNSUBSCRIBED'
}

export type SelectedClinic = {
  __typename?: 'SelectedClinic';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['EID']>;
  memberId?: Maybe<Scalars['EID']>;
  organizations?: Maybe<Array<Maybe<MiniClinic>>>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type SelfEvaluation = {
  __typename?: 'SelfEvaluation';
  CPODAns?: Maybe<Scalars['String']>;
  areasOfImprovement?: Maybe<Array<Maybe<AreasOfImprovementEnums>>>;
  bloodPressureAns?: Maybe<Yes_No_Not_Sure_Enums>;
  diabetesAns?: Maybe<DiabetesAnsEnums>;
  evaluations?: Maybe<Array<Maybe<Evaluation>>>;
  id?: Maybe<Scalars['EID']>;
  memberId?: Maybe<Scalars['EID']>;
  otherAreaAns?: Maybe<Array<Maybe<Scalars['String']>>>;
  visitId?: Maybe<Scalars['EID']>;
};

export type SessionIdOperatorsFilterFindManyAuditRecordInput = {
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SessionIdOperatorsFilterFindManyTimeTrackerInput = {
  /** *Client* session ID to group events */
  gt?: InputMaybe<Scalars['String']>;
  /** *Client* session ID to group events */
  gte?: InputMaybe<Scalars['String']>;
  /** *Client* session ID to group events */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** *Client* session ID to group events */
  lt?: InputMaybe<Scalars['String']>;
  /** *Client* session ID to group events */
  lte?: InputMaybe<Scalars['String']>;
  /** *Client* session ID to group events */
  ne?: InputMaybe<Scalars['String']>;
  /** *Client* session ID to group events */
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SessionIdOperatorsFilterTimeTrackerInput = {
  /** *Client* session ID to group events */
  gt?: InputMaybe<Scalars['String']>;
  /** *Client* session ID to group events */
  gte?: InputMaybe<Scalars['String']>;
  /** *Client* session ID to group events */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** *Client* session ID to group events */
  lt?: InputMaybe<Scalars['String']>;
  /** *Client* session ID to group events */
  lte?: InputMaybe<Scalars['String']>;
  /** *Client* session ID to group events */
  ne?: InputMaybe<Scalars['String']>;
  /** *Client* session ID to group events */
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SessionIdOperatorsFilterUpdateManyTimeTrackerInput = {
  /** *Client* session ID to group events */
  gt?: InputMaybe<Scalars['String']>;
  /** *Client* session ID to group events */
  gte?: InputMaybe<Scalars['String']>;
  /** *Client* session ID to group events */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** *Client* session ID to group events */
  lt?: InputMaybe<Scalars['String']>;
  /** *Client* session ID to group events */
  lte?: InputMaybe<Scalars['String']>;
  /** *Client* session ID to group events */
  ne?: InputMaybe<Scalars['String']>;
  /** *Client* session ID to group events */
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Shipment = {
  __typename?: 'Shipment';
  method?: Maybe<Scalars['String']>;
  tracking?: Maybe<Scalars['String']>;
};

export type SignOffChatHistory = {
  __typename?: 'SignOffChatHistory';
  channel?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
  type?: Maybe<Scalars['String']>;
};

export type SignOffPatientItem = FoodLog | MonthlyReview | ProviderNote | SignOffChatHistory | SignOffVersionHistory | SmartAlert | TaskAssignment | Visit;

export type SignOffPatients = {
  __typename?: 'SignOffPatients';
  healthCoach?: Maybe<User>;
  mentor?: Maybe<User>;
  patientIds?: Maybe<Array<Maybe<Scalars['EID']>>>;
};

export type SignOffStatus = {
  __typename?: 'SignOffStatus';
  signOff?: Maybe<Scalars['Boolean']>;
  signedOffBy?: Maybe<User>;
  signedOffTime?: Maybe<Scalars['Date']>;
  timeSpent?: Maybe<Scalars['Int']>;
};

export type SignOffTask = {
  __typename?: 'SignOffTask';
  comment?: Maybe<Scalars['String']>;
  healthCoach?: Maybe<User>;
  id?: Maybe<Scalars['EID']>;
  mentor?: Maybe<User>;
  organization?: Maybe<Organization>;
  patient?: Maybe<User>;
  readDate?: Maybe<Scalars['Date']>;
  signOffBy?: Maybe<User>;
  signOffDate?: Maybe<Scalars['Date']>;
  tasks?: Maybe<Array<Maybe<SignOffPatientItem>>>;
};

export type SignOffTasks = {
  __typename?: 'SignOffTasks';
  count?: Maybe<Scalars['Int']>;
  healthCoach?: Maybe<User>;
  mentor?: Maybe<User>;
  signOffTaskIds?: Maybe<Array<Maybe<Scalars['EID']>>>;
};

export type SignOffVersionHistory = {
  __typename?: 'SignOffVersionHistory';
  history?: Maybe<Array<Maybe<VersionHistory>>>;
  type?: Maybe<Scalars['String']>;
};

/** Image Presigned Upload type */
export type SignedDownloadType = {
  __typename?: 'SignedDownloadType';
  fileKey?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

/** File signed file download type */
export type SignedFileDownloadType = {
  __typename?: 'SignedFileDownloadType';
  fileKey?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export enum SleepFreq {
  Daily = 'DAILY',
  FourNights = 'FOUR_NIGHTS',
  IrregularRoutine = 'IRREGULAR_ROUTINE',
  Never = 'NEVER',
  TwoNights = 'TWO_NIGHTS'
}

export type SmartAlert = {
  __typename?: 'SmartAlert';
  alertLevel?: Maybe<SmartAlertLevel>;
  category?: Maybe<Scalars['String']>;
  closeAt?: Maybe<Scalars['Date']>;
  closeBy?: Maybe<User>;
  createdAt?: Maybe<Scalars['Date']>;
  display?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  /** @deprecated It is replaced by providerNotes array since there could be more than 1 provider note per alert. */
  providerNote?: Maybe<Scalars['String']>;
  providerNotes?: Maybe<Array<Maybe<LimitedProviderNote>>>;
  reason?: Maybe<Array<Maybe<Scalars['String']>>>;
  replyTime?: Maybe<Scalars['Int']>;
  status?: Maybe<SmartAlertStatus>;
  taskType?: Maybe<Scalars['String']>;
  visitWindow?: Maybe<Scalars['String']>;
};

export enum SmartAlertLevel {
  LevelFive = 'LEVEL_FIVE',
  LevelFour = 'LEVEL_FOUR',
  LevelOne = 'LEVEL_ONE',
  LevelThree = 'LEVEL_THREE',
  LevelTwo = 'LEVEL_TWO'
}

export type SmartAlertSort = {
  direction?: InputMaybe<SortDirection>;
  field: SmartAlertSortField;
};

export enum SmartAlertSortField {
  AlertLevel = 'ALERT_LEVEL',
  Closeedat = 'CLOSEEDAT',
  Createdat = 'CREATEDAT'
}

export enum SmartAlertStatus {
  Done = 'DONE',
  Mute = 'MUTE',
  Todo = 'TODO'
}

export enum SmartAlertTaskCategory {
  Cde = 'CDE',
  Ma = 'MA',
  Provider = 'PROVIDER'
}

export enum SmartAlertTaskType {
  LevelFiveBgMissingPair = 'LEVEL_FIVE_BG_MISSING_PAIR',
  LevelFiveHubUserMissingCallLog = 'LEVEL_FIVE_HUB_USER_MISSING_CALL_LOG',
  LevelFiveNoProvidernoteInSixWeeks = 'LEVEL_FIVE_NO_PROVIDERNOTE_IN_SIX_WEEKS',
  LevelFour_7DayHypertensionStageIi = 'LEVEL_FOUR_7_DAY_HYPERTENSION_STAGE_II',
  LevelFour_14DayHypertensionStageIi = 'LEVEL_FOUR_14_DAY_HYPERTENSION_STAGE_II',
  LevelFourA1COverdue = 'LEVEL_FOUR_A1C_OVERDUE',
  LevelFourBgTrendingDown = 'LEVEL_FOUR_BG_TRENDING_DOWN',
  LevelFourBgTrendingHigh = 'LEVEL_FOUR_BG_TRENDING_HIGH',
  LevelFourBpTrendingDown = 'LEVEL_FOUR_BP_TRENDING_DOWN',
  LevelFourBpTrendingHigh = 'LEVEL_FOUR_BP_TRENDING_HIGH',
  LevelFourHighAfterMealBg = 'LEVEL_FOUR_HIGH_AFTER_MEAL_BG',
  LevelFourHighAverageBg = 'LEVEL_FOUR_HIGH_AVERAGE_BG',
  LevelFourHighBg = 'LEVEL_FOUR_HIGH_BG',
  LevelFourHighFastingBg = 'LEVEL_FOUR_HIGH_FASTING_BG',
  LevelFourNoProvidernoteInFourWeeks = 'LEVEL_FOUR_NO_PROVIDERNOTE_IN_FOUR_WEEKS',
  LevelOneBgContinueHigh = 'LEVEL_ONE_BG_CONTINUE_HIGH',
  LevelOneBgCriticalHigh = 'LEVEL_ONE_BG_CRITICAL_HIGH',
  LevelOneBgCriticalLow = 'LEVEL_ONE_BG_CRITICAL_LOW',
  LevelOneBpCriticalHigh = 'LEVEL_ONE_BP_CRITICAL_HIGH',
  LevelOneBpCriticalLow = 'LEVEL_ONE_BP_CRITICAL_LOW',
  LevelOneSpo2CriticalLow = 'LEVEL_ONE_SPO2_CRITICAL_LOW',
  LevelOneSpo2HypoxiaCriticalLow = 'LEVEL_ONE_SPO2_HYPOXIA_CRITICAL_LOW',
  LevelOneVisitDue = 'LEVEL_ONE_VISIT_DUE',
  LevelThree_1DayAfterEnrollment = 'LEVEL_THREE_1_DAY_AFTER_ENROLLMENT',
  LevelThree_8DaysLowActivity = 'LEVEL_THREE_8_DAYS_LOW_ACTIVITY',
  LevelThree_14DayEnrollmentEngagement = 'LEVEL_THREE_14_DAY_ENROLLMENT_ENGAGEMENT',
  LevelThree_20DaysLowActivity = 'LEVEL_THREE_20_DAYS_LOW_ACTIVITY',
  LevelThreeBgPairReading = 'LEVEL_THREE_BG_PAIR_READING',
  LevelThreeBirthday = 'LEVEL_THREE_BIRTHDAY',
  LevelThreeHighAfterMealBg = 'LEVEL_THREE_HIGH_AFTER_MEAL_BG',
  LevelThreeHighFastingBg = 'LEVEL_THREE_HIGH_FASTING_BG',
  LevelThreeInactivePatient = 'LEVEL_THREE_INACTIVE_PATIENT',
  LevelThreePatientAppEngagement = 'LEVEL_THREE_PATIENT_APP_ENGAGEMENT',
  LevelThreePatientEngagement = 'LEVEL_THREE_PATIENT_ENGAGEMENT',
  LevelThreePatientNoAppEngagement = 'LEVEL_THREE_PATIENT_NO_APP_ENGAGEMENT',
  LevelThreeVisitOverdue = 'LEVEL_THREE_VISIT_OVERDUE',
  LevelTwoA1CDue = 'LEVEL_TWO_A1C_DUE',
  LevelTwoBgCriticalHighAfterMeal = 'LEVEL_TWO_BG_CRITICAL_HIGH_AFTER_MEAL',
  LevelTwoBgCriticalHighBeforeMeal = 'LEVEL_TWO_BG_CRITICAL_HIGH_BEFORE_MEAL',
  LevelTwoBgPairReading = 'LEVEL_TWO_BG_PAIR_READING',
  LevelTwoSpo2HypoxiaLow = 'LEVEL_TWO_SPO2_HYPOXIA_LOW',
  LevelTwoSpo2Low = 'LEVEL_TWO_SPO2_LOW'
}

export type SmartAlertTasksConfig = {
  __typename?: 'SmartAlertTasksConfig';
  configs?: Maybe<Array<Maybe<SmartAlertConfig>>>;
  id?: Maybe<Scalars['EID']>;
  memberId?: Maybe<Scalars['EID']>;
};

export type SmartAlertTypes = {
  __typename?: 'SmartAlertTypes';
  category?: Maybe<Scalars['String']>;
  inUse?: Maybe<Scalars['Boolean']>;
  taskType?: Maybe<Scalars['String']>;
};

export type SmartAlerttListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<SmartAlerttListSortField>;
};

export enum SmartAlerttListSortField {
  CreateDate = 'CREATE_DATE'
}

export enum SmsMsgTypeEnum {
  PromoteUnifiedCare = 'PROMOTE_UNIFIED_CARE'
}

/** snapshot Input  */
export type SnapshotInput = {
  componentPath?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  focused?: InputMaybe<Scalars['Boolean']>;
  patientId?: InputMaybe<Scalars['EID']>;
  snapshotInterval?: InputMaybe<Scalars['Int']>;
};

export type SocialSupport = {
  __typename?: 'SocialSupport';
  communitySupport?: Maybe<Scalars['String']>;
  contactInfo?: Maybe<Scalars['String']>;
  extraNote?: Maybe<Scalars['String']>;
  otherProviders?: Maybe<Scalars['String']>;
};

export enum SortConnectionLabResultsEnum {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortConnectionTimeTrackerEnum {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortFindManyAuditRecordInput {
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  DocidAsc = 'DOCID_ASC',
  DocidDesc = 'DOCID_DESC',
  GroupAsc = 'GROUP_ASC',
  GroupDesc = 'GROUP_DESC',
  GroupUseridAsc = 'GROUP__USERID_ASC',
  GroupUseridDesc = 'GROUP__USERID_DESC',
  GroupUseridSessioninfoPhoneidAsc = 'GROUP__USERID__SESSIONINFO__PHONEID_ASC',
  GroupUseridSessioninfoPhoneidDesc = 'GROUP__USERID__SESSIONINFO__PHONEID_DESC',
  SessionidAsc = 'SESSIONID_ASC',
  SessionidDesc = 'SESSIONID_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyLabResultTemplatesInput {
  TemplateNameAsc = 'TEMPLATE_NAME_ASC',
  TemplateNameDesc = 'TEMPLATE_NAME_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyLabResultsInput {
  TemplateidAsc = 'TEMPLATEID_ASC',
  TemplateidDesc = 'TEMPLATEID_DESC',
  UseridAsc = 'USERID_ASC',
  UseridDesc = 'USERID_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyTimeTrackerInput {
  AborttimeAsc = 'ABORTTIME_ASC',
  AborttimeDesc = 'ABORTTIME_DESC',
  CategoryAsc = 'CATEGORY_ASC',
  CategoryDesc = 'CATEGORY_DESC',
  CategoryActionAsc = 'CATEGORY__ACTION_ASC',
  CategoryActionDesc = 'CATEGORY__ACTION_DESC',
  SessionidAsc = 'SESSIONID_ASC',
  SessionidDesc = 'SESSIONID_DESC',
  StarttimeAsc = 'STARTTIME_ASC',
  StarttimeDesc = 'STARTTIME_DESC',
  StoptimeAsc = 'STOPTIME_ASC',
  StoptimeDesc = 'STOPTIME_DESC',
  UseridAsc = 'USERID_ASC',
  UseridDesc = 'USERID_DESC',
  UserorganizationidAsc = 'USERORGANIZATIONID_ASC',
  UserorganizationidDesc = 'USERORGANIZATIONID_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC',
  VieweridAsc = 'VIEWERID_ASC',
  VieweridDesc = 'VIEWERID_DESC',
  ViewerorganizationidAsc = 'VIEWERORGANIZATIONID_ASC',
  ViewerorganizationidDesc = 'VIEWERORGANIZATIONID_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyWeeklyBgScheduleTemplatesInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortLastUsed {
  LastusedAsc = 'LASTUSED_ASC',
  LastusedDesc = 'LASTUSED_DESC'
}

export enum SortUpdateManyLabResultsInput {
  TemplateidAsc = 'TEMPLATEID_ASC',
  TemplateidDesc = 'TEMPLATEID_DESC',
  UseridAsc = 'USERID_ASC',
  UseridDesc = 'USERID_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortUpdateManyTimeTrackerInput {
  AborttimeAsc = 'ABORTTIME_ASC',
  AborttimeDesc = 'ABORTTIME_DESC',
  CategoryAsc = 'CATEGORY_ASC',
  CategoryDesc = 'CATEGORY_DESC',
  CategoryActionAsc = 'CATEGORY__ACTION_ASC',
  CategoryActionDesc = 'CATEGORY__ACTION_DESC',
  SessionidAsc = 'SESSIONID_ASC',
  SessionidDesc = 'SESSIONID_DESC',
  StarttimeAsc = 'STARTTIME_ASC',
  StarttimeDesc = 'STARTTIME_DESC',
  StoptimeAsc = 'STOPTIME_ASC',
  StoptimeDesc = 'STOPTIME_DESC',
  UseridAsc = 'USERID_ASC',
  UseridDesc = 'USERID_DESC',
  UserorganizationidAsc = 'USERORGANIZATIONID_ASC',
  UserorganizationidDesc = 'USERORGANIZATIONID_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC',
  VieweridAsc = 'VIEWERID_ASC',
  VieweridDesc = 'VIEWERID_DESC',
  ViewerorganizationidAsc = 'VIEWERORGANIZATIONID_ASC',
  ViewerorganizationidDesc = 'VIEWERORGANIZATIONID_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export type SpecialistInfo = {
  __typename?: 'SpecialistInfo';
  contact?: Maybe<Scalars['String']>;
  seenSpecialist?: Maybe<Scalars['Boolean']>;
};

export type StartTimeOperatorsFilterFindManyTimeTrackerInput = {
  /** Start time of the main eligible tracking event. */
  gt?: InputMaybe<Scalars['Date']>;
  /** Start time of the main eligible tracking event. */
  gte?: InputMaybe<Scalars['Date']>;
  /** Start time of the main eligible tracking event. */
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** Start time of the main eligible tracking event. */
  lt?: InputMaybe<Scalars['Date']>;
  /** Start time of the main eligible tracking event. */
  lte?: InputMaybe<Scalars['Date']>;
  /** Start time of the main eligible tracking event. */
  ne?: InputMaybe<Scalars['Date']>;
  /** Start time of the main eligible tracking event. */
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
};

export type StartTimeOperatorsFilterTimeTrackerInput = {
  /** Start time of the main eligible tracking event. */
  gt?: InputMaybe<Scalars['Date']>;
  /** Start time of the main eligible tracking event. */
  gte?: InputMaybe<Scalars['Date']>;
  /** Start time of the main eligible tracking event. */
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** Start time of the main eligible tracking event. */
  lt?: InputMaybe<Scalars['Date']>;
  /** Start time of the main eligible tracking event. */
  lte?: InputMaybe<Scalars['Date']>;
  /** Start time of the main eligible tracking event. */
  ne?: InputMaybe<Scalars['Date']>;
  /** Start time of the main eligible tracking event. */
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
};

export type StartTimeOperatorsFilterUpdateManyTimeTrackerInput = {
  /** Start time of the main eligible tracking event. */
  gt?: InputMaybe<Scalars['Date']>;
  /** Start time of the main eligible tracking event. */
  gte?: InputMaybe<Scalars['Date']>;
  /** Start time of the main eligible tracking event. */
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** Start time of the main eligible tracking event. */
  lt?: InputMaybe<Scalars['Date']>;
  /** Start time of the main eligible tracking event. */
  lte?: InputMaybe<Scalars['Date']>;
  /** Start time of the main eligible tracking event. */
  ne?: InputMaybe<Scalars['Date']>;
  /** Start time of the main eligible tracking event. */
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
};

/** ## Statement Type */
export type Statement = {
  __typename?: 'Statement';
  groupId?: Maybe<Scalars['EID']>;
  optionId?: Maybe<Scalars['EID']>;
  value?: Maybe<Scalars['String']>;
};

export enum Status {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Finished = 'FINISHED',
  Inactive = 'INACTIVE'
}

export enum StatusEnum {
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Todo = 'TODO'
}

export type StatusUser = {
  __typename?: 'StatusUser';
  birthday?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  emails?: Maybe<Array<Maybe<Email>>>;
  enrolledSince?: Maybe<Scalars['Date']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  id?: Maybe<Scalars['EID']>;
  lastFoodlogTime?: Maybe<Scalars['Date']>;
  lastMeasuredAt?: Maybe<Scalars['Date']>;
  lastMeasurementTime?: Maybe<Scalars['Date']>;
  lastName?: Maybe<Scalars['String']>;
  lastProviderNote?: Maybe<ProviderNote>;
  lastReviewedAt?: Maybe<Scalars['Date']>;
  lastVisitTime?: Maybe<Scalars['Date']>;
  phones?: Maybe<Array<Maybe<Phone>>>;
  stats?: Maybe<Array<Maybe<NoteStatsType>>>;
  totalFoodlog?: Maybe<Scalars['Int']>;
  totalMeasurement?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  vitalTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type StepDetail = {
  __typename?: 'StepDetail';
  endTime: Scalars['Date'];
  startTime: Scalars['Date'];
  steps?: Maybe<Scalars['STEPValue']>;
};

export type StepDetailInput = {
  endTime: Scalars['Date'];
  startTime: Scalars['Date'];
  steps?: InputMaybe<Scalars['STEPValue']>;
};

export type StopTimeOperatorsFilterFindManyTimeTrackerInput = {
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  gt?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  gte?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  lt?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  lte?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  ne?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
};

export type StopTimeOperatorsFilterTimeTrackerInput = {
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  gt?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  gte?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  lt?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  lte?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  ne?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
};

export type StopTimeOperatorsFilterUpdateManyTimeTrackerInput = {
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  gt?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  gte?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  lt?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  lte?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  ne?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
};

export type Subscribes = {
  __typename?: 'Subscribes';
  createdAt?: Maybe<Scalars['Date']>;
  expiresDate?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['EID']>;
  memberId?: Maybe<Scalars['EID']>;
  originalPurchaseDate?: Maybe<Scalars['Date']>;
  originalTransactionId?: Maybe<Scalars['String']>;
  platform?: Maybe<Subscribes_Platform>;
  purchaseDate?: Maybe<Scalars['Date']>;
  status?: Maybe<Subscribes_Status>;
  transactionDate?: Maybe<Scalars['Date']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type SubscribesSort = {
  direction: SortDirection;
  field: SubscribesSortField;
};

export enum SubscribesSortField {
  Createdat = 'CREATEDAT'
}

export type Supply = {
  __typename?: 'Supply';
  bottleId?: Maybe<Scalars['String']>;
  category?: Maybe<SupplyCategory>;
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  currentUsage?: Maybe<Scalars['Int']>;
  expireAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['EID']>;
  maxUsage?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
};

export enum SupplyCategory {
  BgStrip = 'BG_STRIP'
}

export enum SurveyFrom {
  MvpProgram = 'MVP_PROGRAM',
  UnifiedCare = 'UNIFIED_CARE'
}

export type SurveyType = {
  __typename?: 'SurveyType';
  answers?: Maybe<Array<Maybe<MvpAnswers>>>;
  createdAt?: Maybe<Scalars['Date']>;
  feedback?: Maybe<Scalars['String']>;
  from?: Maybe<SurveyFrom>;
  id?: Maybe<Scalars['EID']>;
  memberId?: Maybe<Scalars['EID']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type SweetBeverageFreq = {
  __typename?: 'SweetBeverageFreq';
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type SystemDynamicConfig = {
  __typename?: 'SystemDynamicConfig';
  configKey?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  value?: Maybe<Scalars['String']>;
};

/** iHealth Pulse Oximeter device models. */
export enum TmDeviceModel {
  Tm3 = 'TM3',
  Ts28B = 'TS28B'
}

/** Body Temperature measurement data. */
export type TmMeasure = Measure & {
  __typename?: 'TMMeasure';
  body_temperature?: Maybe<Scalars['TMValue']>;
  date?: Maybe<Scalars['Date']>;
  deviceAddress?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  /**
   * hadMedicine: Boolean added to support hadMedicine flag
   * hadActivity: Boolean added to support hadActivity flag
   * hadInsulin: Boolean added to support hadInsulin flag
   * tags:[String] added to support mood tags
   */
  id?: Maybe<Scalars['EID']>;
  imageUrl?: Maybe<Scalars['String']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measureBy?: Maybe<User>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  resultId?: Maybe<Scalars['EID']>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<Vital>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
};

/** Body Temperature measurement data. */
export type TmMeasureAudit = MeasureAudit & {
  __typename?: 'TMMeasureAudit';
  body_temperature?: Maybe<Scalars['TMValue']>;
  date?: Maybe<Scalars['Date']>;
  deviceAddress?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  /**
   * hadMedicine: Boolean added to support hadMedicine flag
   * hadActivity: Boolean added to support hadActivity flag
   * hadInsulin: Boolean added to support hadInsulin flag
   * tags:[String] added to support mood tags
   */
  id?: Maybe<Scalars['EID']>;
  imageUrl?: Maybe<Scalars['String']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measureBy?: Maybe<User>;
  measurementSession?: Maybe<AuditRecord>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  networkSpeed?: Maybe<Scalars['String']>;
  networkType?: Maybe<Scalars['String']>;
  phoneName?: Maybe<Scalars['String']>;
  phoneSessionRecord?: Maybe<AuditRecord>;
  resultId?: Maybe<Scalars['EID']>;
  sessionId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<Vital>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
};

export type TmResult = Result & {
  __typename?: 'TMResult';
  assessmentFilled?: Maybe<AssessmentFilled>;
  body_temperature?: Maybe<Scalars['TMValue']>;
  careStatus?: Maybe<CareStatus>;
  caredBy?: Maybe<User>;
  closedAt?: Maybe<Scalars['Date']>;
  comments?: Maybe<Array<Maybe<ResultCommentType>>>;
  date?: Maybe<Scalars['Date']>;
  dismissed?: Maybe<Scalars['Boolean']>;
  enrolledProgram?: Maybe<EnrolledProgram>;
  hadActivity?: Maybe<Scalars['Boolean']>;
  hadInsulin?: Maybe<Scalars['Boolean']>;
  hadMedicine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['EID']>;
  late?: Maybe<Scalars['Boolean']>;
  manualInput?: Maybe<Scalars['Boolean']>;
  measure?: Maybe<Measure>;
  moodTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  notes?: Maybe<CursorPaginatedProviderNotes>;
  notified?: Maybe<Scalars['Boolean']>;
  refId?: Maybe<Scalars['EID']>;
  /** # 08/18/2017 COCONUT ## */
  repeat?: Maybe<Scalars['String']>;
  resultUpdates?: Maybe<Array<Maybe<ResultUpdateType>>>;
  reviewedAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<TaskSchedule>;
  severity?: Maybe<AlertSeverity>;
  taskStatus?: Maybe<TaskStatus>;
  threshold?: Maybe<Array<Maybe<AlertThreshold>>>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<TaskType>;
  user?: Maybe<User>;
  user_notes?: Maybe<Scalars['String']>;
  violators?: Maybe<Array<Maybe<MeasureType>>>;
};


export type TmResultEnrolledProgramArgs = {
  relatedTaskOnly?: InputMaybe<Scalars['Boolean']>;
};


export type TmResultNotesArgs = {
  after?: InputMaybe<Scalars['Ref']>;
  before?: InputMaybe<Scalars['Ref']>;
  count?: InputMaybe<Scalars['Int']>;
};

export enum TabletDeviceModel {
  IpadMini = 'IPAD_MINI'
}

/**
 * ## Task
 *  Details for an individual task belonging to a program including its schedule, repetition (daily/weekly),
 *  alert threshold, vital type, and chance (amount of times you can miss the task before an alert is triggered).
 *  In program task, assessment has _id only (questions is added as an empty array by DB);
 *  In enrolled task, assessment has name, description and questions fields;
 */
export type Task = {
  __typename?: 'Task';
  activated?: Maybe<Scalars['Boolean']>;
  assessment?: Maybe<TaskAssessment>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  notification?: Maybe<Array<Maybe<NotificationSetting>>>;
  repeat?: Maybe<TaskRepeat>;
  schedule?: Maybe<Array<Maybe<TaskSchedule>>>;
  scheduleType?: Maybe<Scalars['String']>;
  templateDetails?: Maybe<TemplateDetails>;
  threshold?: Maybe<Array<Maybe<AlertThreshold>>>;
  type?: Maybe<TaskType>;
};

/** ## Assessment data from a task */
export type TaskAssessment = {
  __typename?: 'TaskAssessment';
  _id?: Maybe<Scalars['EID']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  name?: Maybe<Scalars['String']>;
  nameKey?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<Maybe<AssessmentQuestion>>>;
  version?: Maybe<Scalars['Int']>;
};

/** ## Task Assignment type */
export type TaskAssignment = {
  __typename?: 'TaskAssignment';
  assignedTo?: Maybe<User>;
  assignedToDate?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  deleted?: Maybe<Scalars['Boolean']>;
  doctor?: Maybe<User>;
  dueDate?: Maybe<Scalars['Date']>;
  history?: Maybe<Array<Maybe<TaskAssignmentHistoryType>>>;
  id?: Maybe<Scalars['EID']>;
  isRead?: Maybe<IsReadType>;
  member?: Maybe<User>;
  organization?: Maybe<Organization>;
  priorityLevel?: Maybe<TaskPriorityLevel>;
  reason?: Maybe<Scalars['String']>;
  resolvedNote?: Maybe<TaskAssignmentResolveType>;
  status?: Maybe<TaskAssignmentStatus>;
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['EID']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

/** Task Assignment History Type */
export type TaskAssignmentHistoryType = {
  __typename?: 'TaskAssignmentHistoryType';
  assignedAt?: Maybe<Scalars['Date']>;
  assignedBy?: Maybe<User>;
  assignedTo?: Maybe<User>;
  note?: Maybe<Scalars['String']>;
};

/**
 * # Task Assignment filter
 *  if both providerId and memberId are provided, memberId is ignored
 */
export type TaskAssignmentListOpts = {
  assignedTo?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  createdBy?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  doctorId?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  dueFromDate?: InputMaybe<Scalars['Date']>;
  dueToDate?: InputMaybe<Scalars['Date']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  notAssignedByUser?: InputMaybe<Scalars['Boolean']>;
  priorityLevel?: InputMaybe<Array<InputMaybe<TaskPriorityLevel>>>;
  status?: InputMaybe<Array<InputMaybe<TaskAssignmentStatus>>>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type TaskAssignmentListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<TaskAssignmentListSortField>;
};

export enum TaskAssignmentListSortField {
  CreatedAt = 'CREATED_AT',
  DueDate = 'DUE_DATE'
}

/** Task Assignment Message Type */
export type TaskAssignmentResolveType = {
  __typename?: 'TaskAssignmentResolveType';
  note?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['Date']>;
  resolvedBy?: Maybe<User>;
};

export enum TaskAssignmentStatus {
  Open = 'OPEN',
  Resolved = 'RESOLVED'
}

export enum TaskPriorityLevel {
  Low = 'LOW',
  Medium = 'MEDIUM',
  Urgent = 'URGENT'
}

export enum TaskRepeat {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Scheduled = 'SCHEDULED',
  Weekly = 'WEEKLY',
  Weeklymeal = 'WEEKLYMEAL'
}

/** Schedule based on start and end time (HHMM string format) and before/after meal. */
export type TaskSchedule = {
  __typename?: 'TaskSchedule';
  beforeMeal?: Maybe<TaskScheduleBeforeAfter>;
  calendar?: Maybe<Scalars['RepeatFrequency']>;
  end?: Maybe<Scalars['Time']>;
  mealType?: Maybe<BgMealType>;
  start?: Maybe<Scalars['Time']>;
  target?: Maybe<Scalars['Int']>;
};

/** To distinguish whether the measurement should be before/after a meal or both. This enum is used only for program (task) management purposes. */
export enum TaskScheduleBeforeAfter {
  AfterMeal = 'AFTER_MEAL',
  AnyTime = 'ANY_TIME',
  BeforeMeal = 'BEFORE_MEAL'
}

export enum TaskStatus {
  Completed = 'COMPLETED',
  Current = 'CURRENT',
  Extra = 'EXTRA',
  Incomplete = 'INCOMPLETE',
  Missed = 'MISSED',
  Suspended = 'SUSPENDED',
  Upcoming = 'UPCOMING'
}

export enum TaskType {
  Assessment = 'ASSESSMENT',
  Bg = 'BG',
  Bp = 'BP',
  Ecg = 'ECG',
  Exercise = 'EXERCISE',
  Hr = 'HR',
  Hs = 'HS',
  Po = 'PO',
  Survey = 'SURVEY',
  Tm = 'TM'
}

/**
 * ## Team
 *  A group of hospital members (such as nurses and doctors).
 */
export type Team = {
  __typename?: 'Team';
  consentProviderName?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Email>;
  forwardingPhone?: Maybe<Phone>;
  healthCondition?: Maybe<HealthCondition>;
  id?: Maybe<Scalars['EID']>;
  members?: Maybe<Array<Maybe<TeamMember>>>;
  modifiedAt?: Maybe<Scalars['Date']>;
  modifiedBy?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  phone?: Maybe<Phone>;
  teamChannel?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};


/**
 * ## Team
 *  A group of hospital members (such as nurses and doctors).
 */
export type TeamMembersArgs = {
  primary?: InputMaybe<Scalars['Boolean']>;
};

export type TeamListOpts = {
  healthCondition?: InputMaybe<HealthCondition>;
  organizationId?: InputMaybe<Scalars['EID']>;
  /** search inside filters should be gone */
  search?: InputMaybe<TeamSearchInput>;
};

export type TeamListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<TeamListSortField>;
};

export enum TeamListSortField {
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT'
}

/**
 * ## Team Member
 *  Member within a team including information that relates to its parent team.
 */
export type TeamMember = {
  __typename?: 'TeamMember';
  primary?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Array<Maybe<UserRole>>>;
  user?: Maybe<User>;
  visible?: Maybe<Scalars['Boolean']>;
};

export enum TeamSearchFields {
  Description = 'DESCRIPTION',
  HealthCondition = 'HEALTH_CONDITION',
  Name = 'NAME'
}

export type TeamSearchInput = {
  fields?: InputMaybe<Array<InputMaybe<TeamSearchFields>>>;
  match: Scalars['String'];
};

export enum TechLevel {
  High = 'HIGH',
  Low = 'LOW',
  Normal = 'NORMAL'
}

export type TemplateDetails = {
  __typename?: 'TemplateDetails';
  _id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  schedule?: Maybe<WeeklyMealSchedule>;
};

export type TemplateGroup = {
  __typename?: 'TemplateGroup';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['EID']>;
  name?: Maybe<Scalars['String']>;
  templates?: Maybe<Array<Maybe<ConsentTemplate>>>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type TemplateGroupOrganizations = {
  __typename?: 'TemplateGroupOrganizations';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['EID']>;
  language?: Maybe<Array<Maybe<LanguageCode>>>;
  name?: Maybe<Scalars['String']>;
  organizations?: Maybe<Array<Maybe<MiniOrganization>>>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type TemplateIdOperatorsFilterFindManyLabResultsInput = {
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TemplateIdOperatorsFilterLabResultsInput = {
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TemplateIdOperatorsFilterUpdateManyLabResultsInput = {
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** ## TimeTracker */
export type TimeTracker = {
  __typename?: 'TimeTracker';
  _id?: Maybe<Scalars['String']>;
  abortTime?: Maybe<Scalars['Date']>;
  action?: Maybe<TimeTrackerActionEnum>;
  category?: Maybe<TimeTrackerCategoryEnum>;
  clientEvents?: Maybe<Array<Maybe<TimeTrackerClientEvent>>>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['EID']>;
  interventionDetails?: Maybe<Array<Maybe<TimeTrackerInterventionDetails>>>;
  relatedEvents?: Maybe<Array<Maybe<TimeTracker>>>;
  sessionId?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Date']>;
  stopTime?: Maybe<Scalars['Date']>;
  techCall?: Maybe<Scalars['Boolean']>;
  timeout?: Maybe<Scalars['Int']>;
  totalTime?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  version?: Maybe<Scalars['Int']>;
  viewer?: Maybe<User>;
};

export enum TimeTrackerActionEnum {
  Engage = 'ENGAGE',
  Enter = 'ENTER',
  Review = 'REVIEW',
  Update = 'UPDATE'
}

export enum TimeTrackerCategoryEnum {
  Additionalinfo = 'ADDITIONALINFO',
  Alert = 'ALERT',
  CallLog = 'CALL_LOG',
  Conversation = 'CONVERSATION',
  EnrolledProgram = 'ENROLLED_PROGRAM',
  FoodLog = 'FOOD_LOG',
  LabResults = 'LAB_RESULTS',
  Logbook = 'LOGBOOK',
  Medicine = 'MEDICINE',
  PatientDetail = 'PATIENT_DETAIL',
  PatientProfile = 'PATIENT_PROFILE',
  Postit = 'POSTIT',
  ProviderNotes = 'PROVIDER_NOTES',
  TagPatient = 'TAG_PATIENT',
  TaskAssignment = 'TASK_ASSIGNMENT',
  Visit = 'VISIT',
  Vitals = 'VITALS'
}

export type TimeTrackerClientEvent = {
  __typename?: 'TimeTrackerClientEvent';
  action?: Maybe<TimeTrackerClientEventActionEnum>;
  event?: Maybe<Scalars['String']>;
  ts?: Maybe<Scalars['Date']>;
};

export enum TimeTrackerClientEventActionEnum {
  Abort = 'ABORT',
  PauseTimer = 'PAUSE_TIMER',
  ResumeTimer = 'RESUME_TIMER',
  StartTimer = 'START_TIMER',
  StopTimer = 'STOP_TIMER'
}

export type TimeTrackerClientEventInput = {
  action: TimeTrackerClientEventActionEnum;
  event: Scalars['String'];
  ts: Scalars['Date'];
};

export type TimeTrackerClientEvents = {
  __typename?: 'TimeTrackerClientEvents';
  /** Client (timer) action in respond to event */
  action?: Maybe<EnumTimeTrackerClientEventsAction>;
  /** Client Event, e.g. OpenPopUp, Timeout */
  event?: Maybe<Scalars['String']>;
  /** Client Event timestamp */
  ts?: Maybe<Scalars['Date']>;
};

/** A connection to a list of items. */
export type TimeTrackerConnection = {
  __typename?: 'TimeTrackerConnection';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<TimeTrackerEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TimeTrackerEdge = {
  __typename?: 'TimeTrackerEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['ConnectionCursor'];
  /** The item at the end of the edge */
  node?: Maybe<TimeTracker>;
};

export type TimeTrackerInterventionDetails = {
  __typename?: 'TimeTrackerInterventionDetails';
  apiName?: Maybe<Scalars['String']>;
  docId?: Maybe<Scalars['EID']>;
  resource?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
};

export type TimeTrackerInterventionDetailsInput = {
  apiName: Scalars['String'];
  docId: Scalars['EID'];
  resource: Scalars['String'];
  summary: Scalars['String'];
};

/** List of items with pagination. */
export type TimeTrackerPagination = {
  __typename?: 'TimeTrackerPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<TimeTracker>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type TimeTrackerTimeTrackerClientEventsInput = {
  /** Client (timer) action in respond to event */
  action?: InputMaybe<EnumTimeTrackerClientEventsAction>;
  /** Client Event, e.g. OpenPopUp, Timeout */
  event?: InputMaybe<Scalars['String']>;
  /** Client Event timestamp */
  ts?: InputMaybe<Scalars['Date']>;
};

export type TimeTrackerTimeTrackerInterventionDetailsInput = {
  /** Intervention API called for intervention. e.g. createProviderNotes, editEnrolledProgram */
  apiName?: InputMaybe<Scalars['String']>;
  /** Document ID of the resource. Optional for engage conversation (chatting) */
  docId?: InputMaybe<Scalars['MongoID']>;
  /** Resource (data collection) affected by the event, prefix of EID. e.g. food_logs, results. */
  resource?: InputMaybe<Scalars['String']>;
  /** Summary notes of the interventions performed. */
  summary?: InputMaybe<Scalars['String']>;
};

export type TimeTrackerViewingDetails = {
  __typename?: 'TimeTrackerViewingDetails';
  /** Client-side component / container */
  component?: Maybe<Scalars['String']>;
  /** Client-side relative path */
  path?: Maybe<Scalars['String']>;
};

export type TimeTrackerViewingDetailsInput = {
  component?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
};

export type TimeTrackerWithUsername = {
  __typename?: 'TimeTrackerWithUsername';
  _id?: Maybe<Scalars['ID']>;
  component?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Date']>;
  stopTime?: Maybe<Scalars['Date']>;
  timeSpent?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  viewerId?: Maybe<Scalars['String']>;
  viewerUsername?: Maybe<Scalars['String']>;
};

/**
 * Each data point is designed to have an average/minimum/maximum value for
 * a single or group of data.
 */
export type TrendDataGroup = {
  __typename?: 'TrendDataGroup';
  average?: Maybe<Array<Maybe<TrendDataPoint>>>;
  group?: Maybe<Scalars['String']>;
  max?: Maybe<Array<Maybe<TrendDataPoint>>>;
  min?: Maybe<Array<Maybe<TrendDataPoint>>>;
  noData?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Vital>;
};

export type TrendDataPoint = {
  __typename?: 'TrendDataPoint';
  measureType?: Maybe<MeasureType>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export enum TrendGroupBy {
  Mmddyyyy = 'MMDDYYYY',
  Mmm = 'MMM',
  Monthly = 'MONTHLY',
  Timestamp = 'TIMESTAMP',
  Weekly = 'WEEKLY'
}

export enum TrendLength {
  BiAnnual = 'BI_ANNUAL',
  Month = 'MONTH',
  OneYear = 'ONE_YEAR',
  Quarter = 'QUARTER',
  Week = 'WEEK'
}

export enum TriageLevel {
  Triage_3Hour = 'TRIAGE_3_HOUR',
  Triage_10Min = 'TRIAGE_10_MIN',
  TriageImmediate = 'TRIAGE_IMMEDIATE',
  TriageNoop = 'TRIAGE_NOOP',
  TriageNoopIgnore = 'TRIAGE_NOOP_IGNORE'
}

export type UpdateByIdLabResultsInput = {
  _id: Scalars['MongoID'];
  createdAt?: InputMaybe<Scalars['Date']>;
  dateCollected?: InputMaybe<Scalars['Date']>;
  dateReceived?: InputMaybe<Scalars['Date']>;
  dateReported?: InputMaybe<Scalars['Date']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  labRef?: InputMaybe<Scalars['String']>;
  requisition?: InputMaybe<Scalars['String']>;
  results?: InputMaybe<Array<InputMaybe<LabResultsLabResultsResultsInput>>>;
  specimen?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  userId?: InputMaybe<Scalars['MongoID']>;
  viewerId?: InputMaybe<Scalars['MongoID']>;
};

export type UpdateByIdLabResultsPayload = {
  __typename?: 'UpdateByIdLabResultsPayload';
  /** Updated document */
  record?: Maybe<LabResults>;
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
};

export type UpdateByIdTimeTrackerInput = {
  _id: Scalars['MongoID'];
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  abortTime?: InputMaybe<Scalars['Date']>;
  /** Main eligible tracking event action */
  action?: InputMaybe<EnumTimeTrackerAction>;
  /** Main eligible tracking event category */
  category?: InputMaybe<EnumTimeTrackerCategory>;
  /** Collection of the client-side events, to provide more details of the breakdown of accumulated time. */
  clientEvents?: InputMaybe<Array<InputMaybe<TimeTrackerTimeTrackerClientEventsInput>>>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  /** Interventions items. (May be >= 1, e.g. rateFoodLog + addFoodLogComment) */
  interventionDetails?: InputMaybe<Array<InputMaybe<TimeTrackerTimeTrackerInterventionDetailsInput>>>;
  /** Reference to other related time tracking events performed during the main event. */
  relatedEventIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** *Client* session ID to group events */
  sessionId?: InputMaybe<Scalars['String']>;
  /** Start time of the main eligible tracking event. */
  startTime?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  stopTime?: InputMaybe<Scalars['Date']>;
  techCall?: InputMaybe<Scalars['Boolean']>;
  /** DEPRECATED, use totalTime instead. unit: seconds */
  timeSpent?: InputMaybe<Scalars['Float']>;
  /** Client-side timeout configuration, included in totalTime if applicable */
  timeout?: InputMaybe<Scalars['Float']>;
  /** Total "eligible" time accumulated on the main event (unit: miliseconds). Can be different from the (stopTime - startTime) */
  totalTime?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED, use category+action instead */
  type?: InputMaybe<Scalars['String']>;
  /** User ID as target of the tracking, usually a member. */
  userId?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  userOrganizationId?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  viewerId?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  viewerOrganizationId?: InputMaybe<Scalars['MongoID']>;
  /** Client-side component/path details. */
  viewingDetails?: InputMaybe<TimeTrackerViewingDetailsInput>;
};

export type UpdateByIdTimeTrackerPayload = {
  __typename?: 'UpdateByIdTimeTrackerPayload';
  /** Updated document */
  record?: Maybe<TimeTracker>;
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
};

export type UpdateByIdWeeklyBgScheduleTemplatesInput = {
  _id: Scalars['MongoID'];
  createdAt?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<WeeklyBgScheduleTemplatesScheduleInput>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  userId?: InputMaybe<Scalars['MongoID']>;
  viewerId?: InputMaybe<Scalars['MongoID']>;
};

export type UpdateByIdWeeklyBgScheduleTemplatesPayload = {
  __typename?: 'UpdateByIdWeeklyBGScheduleTemplatesPayload';
  /** Updated document */
  record?: Maybe<WeeklyBgScheduleTemplates>;
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
};

export type UpdateManyLabResultsInput = {
  createdAt?: InputMaybe<Scalars['Date']>;
  dateCollected?: InputMaybe<Scalars['Date']>;
  dateReceived?: InputMaybe<Scalars['Date']>;
  dateReported?: InputMaybe<Scalars['Date']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  labRef?: InputMaybe<Scalars['String']>;
  requisition?: InputMaybe<Scalars['String']>;
  results?: InputMaybe<Array<InputMaybe<LabResultsLabResultsResultsInput>>>;
  specimen?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  userId?: InputMaybe<Scalars['MongoID']>;
  viewerId?: InputMaybe<Scalars['MongoID']>;
};

export type UpdateManyLabResultsPayload = {
  __typename?: 'UpdateManyLabResultsPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
};

export type UpdateManyTimeTrackerInput = {
  /** Abort time of the main eligible tracking event, set to null if event is stopped (successful). */
  abortTime?: InputMaybe<Scalars['Date']>;
  /** Main eligible tracking event action */
  action?: InputMaybe<EnumTimeTrackerAction>;
  /** Main eligible tracking event category */
  category?: InputMaybe<EnumTimeTrackerCategory>;
  /** Collection of the client-side events, to provide more details of the breakdown of accumulated time. */
  clientEvents?: InputMaybe<Array<InputMaybe<TimeTrackerTimeTrackerClientEventsInput>>>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  /** Interventions items. (May be >= 1, e.g. rateFoodLog + addFoodLogComment) */
  interventionDetails?: InputMaybe<Array<InputMaybe<TimeTrackerTimeTrackerInterventionDetailsInput>>>;
  /** Reference to other related time tracking events performed during the main event. */
  relatedEventIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** *Client* session ID to group events */
  sessionId?: InputMaybe<Scalars['String']>;
  /** Start time of the main eligible tracking event. */
  startTime?: InputMaybe<Scalars['Date']>;
  /** Stop time of the main eligible tracking event, stop can be triggered by user action, or timeout */
  stopTime?: InputMaybe<Scalars['Date']>;
  techCall?: InputMaybe<Scalars['Boolean']>;
  /** DEPRECATED, use totalTime instead. unit: seconds */
  timeSpent?: InputMaybe<Scalars['Float']>;
  /** Client-side timeout configuration, included in totalTime if applicable */
  timeout?: InputMaybe<Scalars['Float']>;
  /** Total "eligible" time accumulated on the main event (unit: miliseconds). Can be different from the (stopTime - startTime) */
  totalTime?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED, use category+action instead */
  type?: InputMaybe<Scalars['String']>;
  /** User ID as target of the tracking, usually a member. */
  userId?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  userOrganizationId?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  viewerId?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  viewerOrganizationId?: InputMaybe<Scalars['MongoID']>;
  /** Client-side component/path details. */
  viewingDetails?: InputMaybe<TimeTrackerViewingDetailsInput>;
};

export type UpdateManyTimeTrackerPayload = {
  __typename?: 'UpdateManyTimeTrackerPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
};

export enum UpdateTypeEnum {
  Created = 'CREATED',
  Modified = 'MODIFIED'
}

/** # Usage heartbeat filter */
export type UsageHeartbeatListOpts = {
  fromDate?: InputMaybe<Scalars['Date']>;
  memberIds?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  organizationId?: InputMaybe<Scalars['EID']>;
};

export type UsageHeartbeatListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<UsageHeartbeatListSortField>;
};

export enum UsageHeartbeatListSortField {
  UpdatedAt = 'UPDATED_AT'
}

export type UsageHeartbeatType = {
  __typename?: 'UsageHeartbeatType';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['EID']>;
  member?: Maybe<User>;
  organization?: Maybe<Organization>;
  role?: Maybe<UserRole>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type User = {
  __typename?: 'User';
  address?: Maybe<Array<Maybe<Address>>>;
  allRoles?: Maybe<Array<Maybe<UserRole>>>;
  appMessageSent?: Maybe<Scalars['Boolean']>;
  assignees?: Maybe<PatientAssignee>;
  capacitys?: Maybe<Array<Maybe<RoleCapacity>>>;
  /** @deprecated caregiver is no longer used in this API.  care team members has been replaced for this old field. */
  caregiver?: Maybe<Array<Maybe<User>>>;
  consentInfo?: Maybe<PatientConsent>;
  createdAt?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  devices?: Maybe<Array<Maybe<Device>>>;
  doctorReferral?: Maybe<DoctorReferral>;
  email?: Maybe<Array<Maybe<Email>>>;
  enrollmentDate?: Maybe<Scalars['Date']>;
  flagDetails?: Maybe<Array<Maybe<FlagDetail>>>;
  hasFlag?: Maybe<Scalars['Boolean']>;
  hasPassword?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['EID']>;
  identification?: Maybe<Array<Maybe<Identification>>>;
  isDefaultPassword?: Maybe<Scalars['Boolean']>;
  isTestUser?: Maybe<Scalars['Boolean']>;
  lastLogIn?: Maybe<AccountEventHistory>;
  lastReleaseRead?: Maybe<Scalars['Int']>;
  latestVisit?: Maybe<Visit>;
  loginAuditList?: Maybe<Array<Maybe<AuditRecord>>>;
  measureAuditList?: Maybe<Array<Maybe<MeasureAudit>>>;
  measurements?: Maybe<Array<Maybe<Measure>>>;
  monthlyMeasurementDays?: Maybe<Scalars['Int']>;
  monthlyTimeSpent?: Maybe<CurrentMonthTimeSpent>;
  onCall?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Organization>;
  patientComplexity?: Maybe<PatientComplexity>;
  patientMntCodes?: Maybe<Array<Maybe<PatientMntCode>>>;
  patientMntProgram?: Maybe<PatientMntProgram>;
  phone?: Maybe<Array<Maybe<Phone>>>;
  /** @deprecated phoneAuditList is deprecated in favor of phoneReportInfos. */
  phoneAuditList?: Maybe<Array<Maybe<PhoneDevice>>>;
  phoneReportInfos?: Maybe<Array<Maybe<PhoneReportInfo>>>;
  profile?: Maybe<UserProfile>;
  providerTotalChannels?: Maybe<Scalars['Int']>;
  readLatestRelease?: Maybe<Scalars['Boolean']>;
  registeredAt?: Maybe<Scalars['Date']>;
  remoteEnrollment?: Maybe<RemoteEnrollment>;
  residency?: Maybe<MemberResidency>;
  selectedRole?: Maybe<UserRole>;
  starred?: Maybe<Scalars['Boolean']>;
  starredReason?: Maybe<Scalars['String']>;
  taskAssignmentCount?: Maybe<Scalars['Int']>;
  team?: Maybe<Array<Maybe<Team>>>;
  updatedAt?: Maybe<Scalars['Date']>;
  userIPList?: Maybe<Array<Maybe<UserIp>>>;
  userPreference?: Maybe<UserPreference>;
  userSettings?: Maybe<UserSettings>;
  username?: Maybe<Scalars['String']>;
};


export type UserProfileArgs = {
  interface?: InputMaybe<RoleCategory>;
};

/**
 * ## User Access
 *  Access rules for individual actions.
 */
export type UserAccess = {
  __typename?: 'UserAccess';
  action?: Maybe<Scalars['String']>;
  rule?: Maybe<Scalars['String']>;
};

/** Pagination and data (list)  */
export type UserAllTask = {
  __typename?: 'UserAllTask';
  assignedTo?: Maybe<User>;
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  doctor?: Maybe<User>;
  dueDate?: Maybe<Scalars['Date']>;
  member?: Maybe<User>;
  taskAssignments?: Maybe<Array<Maybe<TaskAssignment>>>;
};

/**
 * ## Authentication
 *  Authentication data after a successful login attempt.
 */
export type UserAuthentication = {
  __typename?: 'UserAuthentication';
  access?: Maybe<Array<Maybe<UserAccess>>>;
  active?: Maybe<Scalars['Boolean']>;
  allRoles?: Maybe<Array<Maybe<UserRole>>>;
  appSettings?: Maybe<AppSettings>;
  globalConfigs?: Maybe<Array<Maybe<SystemDynamicConfig>>>;
  id?: Maybe<Scalars['EID']>;
  identification?: Maybe<Array<Maybe<Identification>>>;
  isDefaultPassword?: Maybe<Scalars['Boolean']>;
  lastLogIn?: Maybe<AccountEventHistory>;
  mobile?: Maybe<Phone>;
  orgPermissions?: Maybe<OrgPermissions>;
  role?: Maybe<UserRole>;
  sessionToken?: Maybe<Scalars['Token']>;
  token?: Maybe<Scalars['String']>;
  translateKey?: Maybe<Scalars['String']>;
  userSettings?: Maybe<UserSettings>;
  username?: Maybe<Scalars['String']>;
  visitId?: Maybe<Scalars['EID']>;
};

/** ## User Chat Info */
export type UserChatInfo = {
  __typename?: 'UserChatInfo';
  authKey?: Maybe<Scalars['String']>;
  channelGroup?: Maybe<ChatChannelGroup>;
  channelGroupName?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<Maybe<ChatChannelType>>>;
  cipherKey?: Maybe<Scalars['String']>;
  messageSource?: Maybe<Scalars['String']>;
  publishKey?: Maybe<Scalars['String']>;
  subscribeKey?: Maybe<Scalars['String']>;
  userChatId?: Maybe<Scalars['String']>;
};

export type UserCursors = {
  __typename?: 'UserCursors';
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  pageInfo?: Maybe<CursorInfo>;
};

/** User devices */
export type UserDeviceList = {
  __typename?: 'UserDeviceList';
  devices?: Maybe<Array<Maybe<Device>>>;
  userId?: Maybe<Scalars['EID']>;
};

export type UserDeviceRecordCountType = {
  __typename?: 'UserDeviceRecordCountType';
  Align?: Maybe<Scalars['Int']>;
  BG5S?: Maybe<Scalars['Int']>;
  Ease?: Maybe<Scalars['Int']>;
  Lancets?: Maybe<Scalars['Int']>;
  Lina?: Maybe<Scalars['Int']>;
  Nexus?: Maybe<Scalars['Int']>;
  Oximeter?: Maybe<Scalars['Int']>;
  Strips?: Maybe<Scalars['Int']>;
  Thermometer?: Maybe<Scalars['Int']>;
  TrackSet?: Maybe<Scalars['Int']>;
  iPhone?: Maybe<Scalars['Int']>;
};

export type UserDeviceRecordsType = {
  __typename?: 'UserDeviceRecordsType';
  Align?: Maybe<VisitDeviceRecordStatusEnum>;
  BG5S?: Maybe<VisitDeviceRecordStatusEnum>;
  Ease?: Maybe<VisitDeviceRecordStatusEnum>;
  Lancets?: Maybe<Scalars['Int']>;
  Lina?: Maybe<VisitDeviceRecordStatusEnum>;
  Nexus?: Maybe<VisitDeviceRecordStatusEnum>;
  Oximeter?: Maybe<VisitDeviceRecordStatusEnum>;
  Strips?: Maybe<Scalars['Int']>;
  Thermometer?: Maybe<VisitDeviceRecordStatusEnum>;
  TrackSet?: Maybe<Scalars['Boolean']>;
  iPhone?: Maybe<Scalars['Boolean']>;
};

export type UserDevicesInfo = {
  __typename?: 'UserDevicesInfo';
  devices?: Maybe<Array<Maybe<ConnectedDeviceInfo>>>;
  fixTag?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['EID']>;
  memberId?: Maybe<Scalars['EID']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<User>;
};

export type UserIp = {
  __typename?: 'UserIP';
  count?: Maybe<Scalars['Int']>;
  firstUsed?: Maybe<Scalars['Date']>;
  ip?: Maybe<Scalars['String']>;
  lastUsed?: Maybe<Scalars['Date']>;
};

export type UserIdOperatorsFilterFindManyLabResultsInput = {
  gt?: InputMaybe<Scalars['MongoID']>;
  gte?: InputMaybe<Scalars['MongoID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  lt?: InputMaybe<Scalars['MongoID']>;
  lte?: InputMaybe<Scalars['MongoID']>;
  ne?: InputMaybe<Scalars['MongoID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type UserIdOperatorsFilterFindManyTimeTrackerInput = {
  /** User ID as target of the tracking, usually a member. */
  gt?: InputMaybe<Scalars['MongoID']>;
  /** User ID as target of the tracking, usually a member. */
  gte?: InputMaybe<Scalars['MongoID']>;
  /** User ID as target of the tracking, usually a member. */
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** User ID as target of the tracking, usually a member. */
  lt?: InputMaybe<Scalars['MongoID']>;
  /** User ID as target of the tracking, usually a member. */
  lte?: InputMaybe<Scalars['MongoID']>;
  /** User ID as target of the tracking, usually a member. */
  ne?: InputMaybe<Scalars['MongoID']>;
  /** User ID as target of the tracking, usually a member. */
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type UserIdOperatorsFilterLabResultsInput = {
  gt?: InputMaybe<Scalars['MongoID']>;
  gte?: InputMaybe<Scalars['MongoID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  lt?: InputMaybe<Scalars['MongoID']>;
  lte?: InputMaybe<Scalars['MongoID']>;
  ne?: InputMaybe<Scalars['MongoID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type UserIdOperatorsFilterTimeTrackerInput = {
  /** User ID as target of the tracking, usually a member. */
  gt?: InputMaybe<Scalars['MongoID']>;
  /** User ID as target of the tracking, usually a member. */
  gte?: InputMaybe<Scalars['MongoID']>;
  /** User ID as target of the tracking, usually a member. */
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** User ID as target of the tracking, usually a member. */
  lt?: InputMaybe<Scalars['MongoID']>;
  /** User ID as target of the tracking, usually a member. */
  lte?: InputMaybe<Scalars['MongoID']>;
  /** User ID as target of the tracking, usually a member. */
  ne?: InputMaybe<Scalars['MongoID']>;
  /** User ID as target of the tracking, usually a member. */
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type UserIdOperatorsFilterUpdateManyLabResultsInput = {
  gt?: InputMaybe<Scalars['MongoID']>;
  gte?: InputMaybe<Scalars['MongoID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  lt?: InputMaybe<Scalars['MongoID']>;
  lte?: InputMaybe<Scalars['MongoID']>;
  ne?: InputMaybe<Scalars['MongoID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type UserIdOperatorsFilterUpdateManyTimeTrackerInput = {
  /** User ID as target of the tracking, usually a member. */
  gt?: InputMaybe<Scalars['MongoID']>;
  /** User ID as target of the tracking, usually a member. */
  gte?: InputMaybe<Scalars['MongoID']>;
  /** User ID as target of the tracking, usually a member. */
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** User ID as target of the tracking, usually a member. */
  lt?: InputMaybe<Scalars['MongoID']>;
  /** User ID as target of the tracking, usually a member. */
  lte?: InputMaybe<Scalars['MongoID']>;
  /** User ID as target of the tracking, usually a member. */
  ne?: InputMaybe<Scalars['MongoID']>;
  /** User ID as target of the tracking, usually a member. */
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

/**
 * __role__: Roles of the user list to query;
 * __search__: User fields and string pattern to match. Currently supported field: NAME, EMAIL, IDENTIFICATION;
 * __filters__: Filters, currently supported: gender, age, memberIn;
 */
export type UserListFilters = {
  age?: InputMaybe<InputUserAge>;
  doctorId?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  gender?: InputMaybe<Array<InputMaybe<Scalars['Gender']>>>;
  phone?: InputMaybe<InputPhone>;
  roleName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['Ref']>>>;
  rootId?: InputMaybe<Scalars['EID']>;
  search?: InputMaybe<InputUserSearch>;
  starred?: InputMaybe<Scalars['Boolean']>;
};

export type UserListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<UserListSortField>;
};

export enum UserListSortField {
  Birthday = 'BIRTHDAY',
  EnrollmentDate = 'ENROLLMENT_DATE',
  LastName = 'LAST_NAME',
  MonthlyMeasurementDays = 'MONTHLY_MEASUREMENT_DAYS',
  Registered = 'REGISTERED',
  Subscription = 'SUBSCRIPTION',
  TimeSpent = 'TIME_SPENT'
}

export type UserMonthlyTimeSpent = {
  __typename?: 'UserMonthlyTimeSpent';
  component?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  timeSpent?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  viewerId?: Maybe<Scalars['String']>;
  viewerUsername?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type UserOrganizationIdOperatorsFilterFindManyTimeTrackerInput = {
  /** User Organization ID as target of the tracking, usually a member. */
  gt?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  gte?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** User Organization ID as target of the tracking, usually a member. */
  lt?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  lte?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  ne?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type UserOrganizationIdOperatorsFilterTimeTrackerInput = {
  /** User Organization ID as target of the tracking, usually a member. */
  gt?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  gte?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** User Organization ID as target of the tracking, usually a member. */
  lt?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  lte?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  ne?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type UserOrganizationIdOperatorsFilterUpdateManyTimeTrackerInput = {
  /** User Organization ID as target of the tracking, usually a member. */
  gt?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  gte?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** User Organization ID as target of the tracking, usually a member. */
  lt?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  lte?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  ne?: InputMaybe<Scalars['MongoID']>;
  /** User Organization ID as target of the tracking, usually a member. */
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type UserPreference = {
  __typename?: 'UserPreference';
  followupVisits?: Maybe<PreferenceType>;
  id?: Maybe<Scalars['EID']>;
  member?: Maybe<User>;
  nutritionCoaching?: Maybe<PreferenceType>;
  sendOnlineClassNotice?: Maybe<PreferenceType>;
  sendVitalReminder?: Maybe<PreferenceType>;
};

export type UserPreferenceInput = {
  note?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<UserPreferenceValueEnum>;
};

export enum UserPreferenceValueEnum {
  No = 'NO',
  Yes = 'YES'
}

/** Full profile interfaces for all user roles. */
export type UserProfile = {
  avatar?: Maybe<ImageType>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullNameWithNickname?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Gender']>;
  lastName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  title?: Maybe<UserTitle>;
};

/**
 * ## User Role
 *  Roles for users.
 */
export type UserRole = {
  __typename?: 'UserRole';
  access?: Maybe<Array<Maybe<UserAccess>>>;
  active?: Maybe<Scalars['Boolean']>;
  category?: Maybe<RoleCategory>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  refId?: Maybe<Scalars['Ref']>;
  roleType?: Maybe<RoleType>;
  startFrom?: Maybe<Scalars['Date']>;
};

/**
 * ## User Settings
 *  Personal preferences and settings information.
 */
export type UserSettings = {
  __typename?: 'UserSettings';
  /** Organization App Customization */
  appCustomization?: Maybe<OrgAppCustomization>;
  heartbeatInterval?: Maybe<Scalars['Int']>;
  listeningOrgs?: Maybe<Array<Maybe<Scalars['String']>>>;
  notification?: Maybe<Array<Maybe<Notification>>>;
  notificationFreq?: Maybe<NotificationFreq>;
  preferredUnit?: Maybe<PreferredUnits>;
  sendHeartbeatLog?: Maybe<Scalars['Boolean']>;
  snapshotInterval?: Maybe<Scalars['Int']>;
};

export enum UserTitle {
  Mr = 'MR',
  Mrs = 'MRS',
  Ms = 'MS'
}

export type UserVitalsByTimeRangeData = {
  __typename?: 'UserVitalsByTimeRangeData';
  BG_after_meal?: Maybe<Array<Maybe<UserVitalsByTimeRangeDataPoint>>>;
  BG_before_meal?: Maybe<Array<Maybe<UserVitalsByTimeRangeDataPoint>>>;
  BP_diastolic_blood_pressure?: Maybe<Array<Maybe<UserVitalsByTimeRangeDataPoint>>>;
  BP_systolic_blood_pressure?: Maybe<Array<Maybe<UserVitalsByTimeRangeDataPoint>>>;
  EXERCISE_steps?: Maybe<Array<Maybe<UserVitalsByTimeRangeDataPoint>>>;
  HR_heart_rate?: Maybe<Array<Maybe<UserVitalsByTimeRangeDataPoint>>>;
  HS_body_weight?: Maybe<Array<Maybe<UserVitalsByTimeRangeDataPoint>>>;
  HS_body_weight_change?: Maybe<Array<Maybe<UserVitalsByTimeRangeDataPoint>>>;
  PO_blood_oxygen?: Maybe<Array<Maybe<UserVitalsByTimeRangeDataPoint>>>;
  PO_heart_rate?: Maybe<Array<Maybe<UserVitalsByTimeRangeDataPoint>>>;
  PO_perfusion_index?: Maybe<Array<Maybe<UserVitalsByTimeRangeDataPoint>>>;
  TM_body_temperature?: Maybe<Array<Maybe<UserVitalsByTimeRangeDataPoint>>>;
};

export type UserVitalsByTimeRangeDataPoint = {
  __typename?: 'UserVitalsByTimeRangeDataPoint';
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  value?: Maybe<Scalars['Float']>;
};

export type UserVitalsByTimeRangeMeta = {
  __typename?: 'UserVitalsByTimeRangeMeta';
  aggregation?: Maybe<UserVitalsTrendsAggregationEnum>;
};

export enum UserVitalsTimeRangeEnum {
  OneMonth = 'ONE_MONTH',
  ThreeDay = 'THREE_DAY',
  ThreeMonth = 'THREE_MONTH',
  TwelveMonth = 'TWELVE_MONTH',
  TwoWeek = 'TWO_WEEK'
}

export enum UserVitalsTrendsAggregationEnum {
  Daily = 'DAILY',
  Raw = 'RAW'
}

export type UserVitalsTrendsByTimeRange = {
  __typename?: 'UserVitalsTrendsByTimeRange';
  meta?: Maybe<UserVitalsByTimeRangeMeta>;
  series?: Maybe<UserVitalsByTimeRangeData>;
};

/** ## VersionHistory Type */
export type VersionHistory = {
  __typename?: 'VersionHistory';
  careplanData?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['EID']>;
  modifiedHistory?: Maybe<Array<Maybe<ModifiedVersionHistory>>>;
  visit?: Maybe<Visit>;
};

/** ## VersionHistory Input */
export type VersionHistoryInput = {
  careplanData?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['CalDate']>;
  enrolledProgramId?: InputMaybe<Scalars['EID']>;
  itemsUpdated?: InputMaybe<Scalars['String']>;
  visitId?: InputMaybe<Scalars['EID']>;
};

export type ViewerIdOperatorsFilterFindManyTimeTrackerInput = {
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  gt?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  gte?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  lt?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  lte?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  ne?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type ViewerIdOperatorsFilterTimeTrackerInput = {
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  gt?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  gte?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  lt?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  lte?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  ne?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type ViewerIdOperatorsFilterUpdateManyTimeTrackerInput = {
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  gt?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  gte?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  lt?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  lte?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  ne?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user ID, i.e. the subject of the tracking. */
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type ViewerOrganizationIdOperatorsFilterFindManyTimeTrackerInput = {
  /** Currently logged-in user session ID (as part of the session token) */
  gt?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  gte?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** Currently logged-in user session ID (as part of the session token) */
  lt?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  lte?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  ne?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type ViewerOrganizationIdOperatorsFilterTimeTrackerInput = {
  /** Currently logged-in user session ID (as part of the session token) */
  gt?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  gte?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** Currently logged-in user session ID (as part of the session token) */
  lt?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  lte?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  ne?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type ViewerOrganizationIdOperatorsFilterUpdateManyTimeTrackerInput = {
  /** Currently logged-in user session ID (as part of the session token) */
  gt?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  gte?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  /** Currently logged-in user session ID (as part of the session token) */
  lt?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  lte?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  ne?: InputMaybe<Scalars['MongoID']>;
  /** Currently logged-in user session ID (as part of the session token) */
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export enum VirtualMeetingEventType {
  Accept = 'ACCEPT',
  CheckIn = 'CHECK_IN',
  CheckOut = 'CHECK_OUT',
  Invite = 'INVITE',
  Join = 'JOIN',
  Leave = 'LEAVE'
}

export type VirtualMeetingUserStatus = {
  __typename?: 'VirtualMeetingUserStatus';
  invited?: Maybe<Scalars['Boolean']>;
  isBusy?: Maybe<Scalars['Boolean']>;
  isHost?: Maybe<Scalars['Boolean']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  roleName?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export enum VirtualMeetingVideoType {
  Agora = 'AGORA',
  Zoom = 'ZOOM'
}

export type VirtualMeetingventParams = {
  invitee?: InputMaybe<Scalars['EID']>;
};

export type VirtualVisitStatus = {
  __typename?: 'VirtualVisitStatus';
  checkinAt?: Maybe<Scalars['Date']>;
  checkoutAt?: Maybe<Scalars['Date']>;
  joinable?: Maybe<Scalars['Boolean']>;
  memberJoined?: Maybe<Scalars['Boolean']>;
  needAction?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  step?: Maybe<VirtualVisitStep>;
  visitId?: Maybe<Scalars['EID']>;
  waitingTime?: Maybe<Scalars['Int']>;
};

export type VirtualVisitStep = {
  __typename?: 'VirtualVisitStep';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** ## Visit type */
export type Visit = {
  __typename?: 'Visit';
  appointmentAt?: Maybe<Scalars['Date']>;
  appointmentTo?: Maybe<Scalars['Date']>;
  assignees?: Maybe<AssigneeType>;
  associatedVisit?: Maybe<Visit>;
  category?: Maybe<VisitCategoryEnum>;
  checkinAt?: Maybe<Scalars['Date']>;
  checkoutAt?: Maybe<Scalars['Date']>;
  conditionsList?: Maybe<ConditionsListType>;
  confirm?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  declineToCheckVital?: Maybe<Scalars['Boolean']>;
  details?: Maybe<VisitDetailsType>;
  deviceRecordCount?: Maybe<UserDeviceRecordCountType>;
  doctorNotes?: Maybe<Scalars['String']>;
  enrolledProgram?: Maybe<EnrolledProgram>;
  enrolledProgramStatus?: Maybe<EnrolledProgramStatus>;
  id?: Maybe<Scalars['EID']>;
  inventoryCount?: Maybe<UserDeviceRecordCountType>;
  labResult?: Maybe<LabTestType>;
  labTests?: Maybe<Array<Maybe<LabTestType>>>;
  maWorkingList?: Maybe<VisitMaWorkingListType>;
  member?: Maybe<User>;
  modifiedAt?: Maybe<Scalars['Date']>;
  modifiedBy?: Maybe<User>;
  needInHouseA1C?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Organization>;
  patientRefer?: Maybe<PatientReferral>;
  programs?: Maybe<Array<Maybe<MiniEnrolledProgram>>>;
  providers?: Maybe<Array<Maybe<User>>>;
  rdWorkingList?: Maybe<VisitRdWorkingListType>;
  reason?: Maybe<Scalars['String']>;
  reviewBillable?: Maybe<Scalars['Boolean']>;
  selfEvaluation?: Maybe<SelfEvaluation>;
  shouldSeeDoctor?: Maybe<Scalars['Boolean']>;
  signOffStatus?: Maybe<SignOffStatus>;
  tasks?: Maybe<Array<Maybe<VisitTask>>>;
  type?: Maybe<VisitTypeEnum>;
  unableToCheckVital?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  visitRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  vitalTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  workingListStatus?: Maybe<VisitWorkStatus>;
};

/** Input Visit Details */
export type VisitBgMeasureInput = {
  beforeMeal?: InputMaybe<Scalars['Boolean']>;
  blood_glucose?: InputMaybe<Scalars['BGValue']>;
  mealType?: InputMaybe<BgMealType>;
};

export type VisitBgMeasureType = {
  __typename?: 'VisitBgMeasureType';
  beforeMeal?: Maybe<Scalars['Boolean']>;
  blood_glucose?: Maybe<Scalars['BGValue']>;
  mealType?: Maybe<BgMealType>;
};

export type VisitBpMeasureInput = {
  diastolic_blood_pressure?: InputMaybe<Scalars['BPValue']>;
  heart_rate?: InputMaybe<Scalars['HRValue']>;
  systolic_blood_pressure?: InputMaybe<Scalars['BPValue']>;
};

export type VisitBpMeasureType = {
  __typename?: 'VisitBpMeasureType';
  diastolic_blood_pressure?: Maybe<Scalars['BPValue']>;
  heart_rate?: Maybe<Scalars['HRValue']>;
  systolic_blood_pressure?: Maybe<Scalars['BPValue']>;
};

export enum VisitCategoryEnum {
  Inclinic = 'INCLINIC',
  Phone = 'PHONE',
  Virtual = 'VIRTUAL'
}

export type VisitDetailsInput = {
  EMRAttached?: InputMaybe<Scalars['Boolean']>;
  TechOnBoard?: InputMaybe<Scalars['Boolean']>;
  a1cTest?: InputMaybe<Scalars['Float']>;
  addToContactsStatus?: InputMaybe<VisitTaskStatus>;
  appIsUptoDate?: InputMaybe<VisitTaskStatus>;
  autoUpdateStatus?: InputMaybe<VisitTaskStatus>;
  bgMeasurement?: InputMaybe<VisitBgMeasureInput>;
  bpMeasurement?: InputMaybe<VisitBpMeasureInput>;
  cdeSession?: InputMaybe<VisitTaskStatus>;
  doctorSession?: InputMaybe<VisitTaskStatus>;
  eyeExam?: InputMaybe<VisitTaskStatus>;
  footExam?: InputMaybe<VisitTaskStatus>;
  height?: InputMaybe<Scalars['HTValue']>;
  mpTest?: InputMaybe<VisitTaskStatus>;
  spo2?: InputMaybe<Scalars['POValue']>;
  technicalSetup?: InputMaybe<VisitTaskStatus>;
  temperature?: InputMaybe<Scalars['TMValue']>;
  urineTest?: InputMaybe<VisitTaskStatus>;
  waistHipRatio?: InputMaybe<Scalars['Float']>;
  weight?: InputMaybe<Scalars['HSValue']>;
};

export type VisitDetailsType = {
  __typename?: 'VisitDetailsType';
  EMRAttached?: Maybe<Scalars['Boolean']>;
  a1cTest?: Maybe<Scalars['Float']>;
  addToContactsStatus?: Maybe<VisitTaskStatus>;
  appIsUptoDate?: Maybe<VisitTaskStatus>;
  autoUpdateStatus?: Maybe<VisitTaskStatus>;
  bgMeasurement?: Maybe<VisitBgMeasureType>;
  bpMeasurement?: Maybe<VisitBpMeasureType>;
  cdeSession?: Maybe<VisitTaskStatus>;
  doctorSession?: Maybe<VisitTaskStatus>;
  eyeExam?: Maybe<VisitTaskStatus>;
  footExam?: Maybe<VisitTaskStatus>;
  height?: Maybe<Scalars['HTValue']>;
  mpTest?: Maybe<VisitTaskStatus>;
  spo2?: Maybe<Scalars['POValue']>;
  technicalSetup?: Maybe<VisitTaskStatus>;
  temperature?: Maybe<Scalars['TMValue']>;
  urineTest?: Maybe<VisitTaskStatus>;
  waistHipRatio?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['HSValue']>;
};

export enum VisitDeviceRecordStatusEnum {
  AppSync = 'APP_SYNC',
  Invalid = 'INVALID',
  ManualInput = 'MANUAL_INPUT'
}

export type VisitListOpts = {
  appointmentFrom?: InputMaybe<Scalars['Date']>;
  appointmentTo?: InputMaybe<Scalars['Date']>;
  assignedToCA?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  assignedToHC?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  assignedToRD?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  categories?: InputMaybe<Array<InputMaybe<VisitCategoryEnum>>>;
  memberId?: InputMaybe<Scalars['EID']>;
  organizationId?: InputMaybe<Scalars['EID']>;
  providerIds?: InputMaybe<Array<InputMaybe<Scalars['EID']>>>;
  types?: InputMaybe<Array<InputMaybe<VisitTypeEnum>>>;
};

export type VisitListSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<VisitListSortField>;
};

export enum VisitListSortField {
  AppointmentAt = 'APPOINTMENT_AT',
  CreatedAt = 'CREATED_AT'
}

export type VisitMaWorkingListInput = {
  FollowUpVisit?: InputMaybe<VisitWorkStatus>;
  LabResults?: InputMaybe<VisitWorkStatus>;
  Medication?: InputMaybe<VisitWorkStatus>;
  VisitBilling?: InputMaybe<VisitWorkStatus>;
  onBoard?: InputMaybe<VisitWorkStatus>;
};

export type VisitMaWorkingListType = {
  __typename?: 'VisitMAWorkingListType';
  FollowUpVisit?: Maybe<VisitWorkStatus>;
  LabResults?: Maybe<VisitWorkStatus>;
  Medication?: Maybe<VisitWorkStatus>;
  VisitBilling?: Maybe<VisitWorkStatus>;
  onBoard?: Maybe<VisitWorkStatus>;
};

export type VisitRdWorkingListInput = {
  Assessment?: InputMaybe<VisitWorkStatus>;
  Charting?: InputMaybe<VisitWorkStatus>;
  ChronicDisease?: InputMaybe<VisitWorkStatus>;
  Education?: InputMaybe<VisitWorkStatus>;
  EnrollmentStatus?: InputMaybe<VisitWorkStatus>;
  GoalStatement?: InputMaybe<VisitWorkStatus>;
  LifestyleRoutine?: InputMaybe<VisitWorkStatus>;
  Medication?: InputMaybe<VisitWorkStatus>;
  MntReferral?: InputMaybe<VisitWorkStatus>;
  NutritionIntake?: InputMaybe<VisitWorkStatus>;
  NutritionInterpretation?: InputMaybe<VisitWorkStatus>;
  SocialSupport?: InputMaybe<VisitWorkStatus>;
};

export type VisitRdWorkingListType = {
  __typename?: 'VisitRDWorkingListType';
  Assessment?: Maybe<VisitWorkStatus>;
  Charting?: Maybe<VisitWorkStatus>;
  ChronicDisease?: Maybe<VisitWorkStatus>;
  Education?: Maybe<VisitWorkStatus>;
  EnrollmentStatus?: Maybe<VisitWorkStatus>;
  GoalStatement?: Maybe<VisitWorkStatus>;
  LifestyleRoutine?: Maybe<VisitWorkStatus>;
  Medication?: Maybe<VisitWorkStatus>;
  MntReferral?: Maybe<VisitWorkStatus>;
  NutritionIntake?: Maybe<VisitWorkStatus>;
  NutritionInterpretation?: Maybe<VisitWorkStatus>;
  SocialSupport?: Maybe<VisitWorkStatus>;
};

export type VisitTask = {
  __typename?: 'VisitTask';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<VisitTaskStatus>;
};

export enum VisitTaskStatus {
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

export enum VisitTypeEnum {
  Additional = 'ADDITIONAL',
  Annual = 'ANNUAL',
  ClinicFollowUp = 'CLINIC_FOLLOW_UP',
  FollowUp = 'FOLLOW_UP',
  FollowUp1 = 'FOLLOW_UP1',
  FollowUp2 = 'FOLLOW_UP2',
  FollowUp3 = 'FOLLOW_UP3',
  Init = 'INIT',
  Mnt = 'MNT',
  Tech = 'TECH',
  TechOnboarding = 'TECH_ONBOARDING'
}

export enum VisitWorkStatus {
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Todo = 'TODO'
}

/** List of vital types for measurements. HR (Heart Rate) is not included because iHealth devices measure heart rate together with BP and PO. */
export enum Vital {
  Bg = 'BG',
  Bp = 'BP',
  Ecg = 'ECG',
  Exercise = 'EXERCISE',
  Hr = 'HR',
  Hs = 'HS',
  Po = 'PO',
  Tm = 'TM'
}

export type VitalCount = {
  __typename?: 'VitalCount';
  count?: Maybe<Scalars['Int']>;
  vital?: Maybe<Vital>;
};

export type VitalStatDetailType = {
  __typename?: 'VitalStatDetailType';
  average?: Maybe<Array<Maybe<Scalars['Float']>>>;
  count?: Maybe<Scalars['Int']>;
  inRangePercentage?: Maybe<Scalars['Float']>;
  range?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export enum VitalSummary {
  Summary_1 = 'SUMMARY_1',
  Summary_2 = 'SUMMARY_2',
  Summary_3 = 'SUMMARY_3',
  Summary_4 = 'SUMMARY_4',
  Summary_5 = 'SUMMARY_5',
  Summary_6 = 'SUMMARY_6',
  Summary_7 = 'SUMMARY_7',
  Summary_8 = 'SUMMARY_8',
  Summary_9 = 'SUMMARY_9',
  Summary_10 = 'SUMMARY_10'
}

export enum VitalTypes {
  Bg = 'BG',
  Bp = 'BP',
  Ecg = 'ECG',
  Exercise = 'EXERCISE',
  Hr = 'HR',
  Hs = 'HS',
  Po = 'PO',
  Tm = 'TM'
}

export type WeeklyBgScheduleTemplates = {
  __typename?: 'WeeklyBGScheduleTemplates';
  _id?: Maybe<Scalars['MongoID']>;
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  schedule?: Maybe<WeeklyBgScheduleTemplatesSchedule>;
  updatedAt?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['MongoID']>;
  viewerId?: Maybe<Scalars['MongoID']>;
};

export type WeeklyBgScheduleTemplatesSchedule = {
  __typename?: 'WeeklyBGScheduleTemplatesSchedule';
  fri?: Maybe<WeeklyBgScheduleTemplatesScheduleFri>;
  mon?: Maybe<WeeklyBgScheduleTemplatesScheduleMon>;
  sat?: Maybe<WeeklyBgScheduleTemplatesScheduleSat>;
  sun?: Maybe<WeeklyBgScheduleTemplatesScheduleSun>;
  thurs?: Maybe<WeeklyBgScheduleTemplatesScheduleThurs>;
  tues?: Maybe<WeeklyBgScheduleTemplatesScheduleTues>;
  wed?: Maybe<WeeklyBgScheduleTemplatesScheduleWed>;
};

export type WeeklyBgScheduleTemplatesScheduleFri = {
  __typename?: 'WeeklyBGScheduleTemplatesScheduleFri';
  after_breakfast?: Maybe<Scalars['Boolean']>;
  after_dinner?: Maybe<Scalars['Boolean']>;
  after_lunch?: Maybe<Scalars['Boolean']>;
  bedtime?: Maybe<Scalars['Boolean']>;
  before_breakfast?: Maybe<Scalars['Boolean']>;
  before_dinner?: Maybe<Scalars['Boolean']>;
  before_lunch?: Maybe<Scalars['Boolean']>;
  overnight?: Maybe<Scalars['Boolean']>;
};

export type WeeklyBgScheduleTemplatesScheduleFriInput = {
  after_breakfast?: InputMaybe<Scalars['Boolean']>;
  after_dinner?: InputMaybe<Scalars['Boolean']>;
  after_lunch?: InputMaybe<Scalars['Boolean']>;
  bedtime?: InputMaybe<Scalars['Boolean']>;
  before_breakfast?: InputMaybe<Scalars['Boolean']>;
  before_dinner?: InputMaybe<Scalars['Boolean']>;
  before_lunch?: InputMaybe<Scalars['Boolean']>;
  overnight?: InputMaybe<Scalars['Boolean']>;
};

export type WeeklyBgScheduleTemplatesScheduleInput = {
  fri?: InputMaybe<WeeklyBgScheduleTemplatesScheduleFriInput>;
  mon?: InputMaybe<WeeklyBgScheduleTemplatesScheduleMonInput>;
  sat?: InputMaybe<WeeklyBgScheduleTemplatesScheduleSatInput>;
  sun?: InputMaybe<WeeklyBgScheduleTemplatesScheduleSunInput>;
  thurs?: InputMaybe<WeeklyBgScheduleTemplatesScheduleThursInput>;
  tues?: InputMaybe<WeeklyBgScheduleTemplatesScheduleTuesInput>;
  wed?: InputMaybe<WeeklyBgScheduleTemplatesScheduleWedInput>;
};

export type WeeklyBgScheduleTemplatesScheduleMon = {
  __typename?: 'WeeklyBGScheduleTemplatesScheduleMon';
  after_breakfast?: Maybe<Scalars['Boolean']>;
  after_dinner?: Maybe<Scalars['Boolean']>;
  after_lunch?: Maybe<Scalars['Boolean']>;
  bedtime?: Maybe<Scalars['Boolean']>;
  before_breakfast?: Maybe<Scalars['Boolean']>;
  before_dinner?: Maybe<Scalars['Boolean']>;
  before_lunch?: Maybe<Scalars['Boolean']>;
  overnight?: Maybe<Scalars['Boolean']>;
};

export type WeeklyBgScheduleTemplatesScheduleMonInput = {
  after_breakfast?: InputMaybe<Scalars['Boolean']>;
  after_dinner?: InputMaybe<Scalars['Boolean']>;
  after_lunch?: InputMaybe<Scalars['Boolean']>;
  bedtime?: InputMaybe<Scalars['Boolean']>;
  before_breakfast?: InputMaybe<Scalars['Boolean']>;
  before_dinner?: InputMaybe<Scalars['Boolean']>;
  before_lunch?: InputMaybe<Scalars['Boolean']>;
  overnight?: InputMaybe<Scalars['Boolean']>;
};

export type WeeklyBgScheduleTemplatesScheduleSat = {
  __typename?: 'WeeklyBGScheduleTemplatesScheduleSat';
  after_breakfast?: Maybe<Scalars['Boolean']>;
  after_dinner?: Maybe<Scalars['Boolean']>;
  after_lunch?: Maybe<Scalars['Boolean']>;
  bedtime?: Maybe<Scalars['Boolean']>;
  before_breakfast?: Maybe<Scalars['Boolean']>;
  before_dinner?: Maybe<Scalars['Boolean']>;
  before_lunch?: Maybe<Scalars['Boolean']>;
  overnight?: Maybe<Scalars['Boolean']>;
};

export type WeeklyBgScheduleTemplatesScheduleSatInput = {
  after_breakfast?: InputMaybe<Scalars['Boolean']>;
  after_dinner?: InputMaybe<Scalars['Boolean']>;
  after_lunch?: InputMaybe<Scalars['Boolean']>;
  bedtime?: InputMaybe<Scalars['Boolean']>;
  before_breakfast?: InputMaybe<Scalars['Boolean']>;
  before_dinner?: InputMaybe<Scalars['Boolean']>;
  before_lunch?: InputMaybe<Scalars['Boolean']>;
  overnight?: InputMaybe<Scalars['Boolean']>;
};

export type WeeklyBgScheduleTemplatesScheduleSun = {
  __typename?: 'WeeklyBGScheduleTemplatesScheduleSun';
  after_breakfast?: Maybe<Scalars['Boolean']>;
  after_dinner?: Maybe<Scalars['Boolean']>;
  after_lunch?: Maybe<Scalars['Boolean']>;
  bedtime?: Maybe<Scalars['Boolean']>;
  before_breakfast?: Maybe<Scalars['Boolean']>;
  before_dinner?: Maybe<Scalars['Boolean']>;
  before_lunch?: Maybe<Scalars['Boolean']>;
  overnight?: Maybe<Scalars['Boolean']>;
};

export type WeeklyBgScheduleTemplatesScheduleSunInput = {
  after_breakfast?: InputMaybe<Scalars['Boolean']>;
  after_dinner?: InputMaybe<Scalars['Boolean']>;
  after_lunch?: InputMaybe<Scalars['Boolean']>;
  bedtime?: InputMaybe<Scalars['Boolean']>;
  before_breakfast?: InputMaybe<Scalars['Boolean']>;
  before_dinner?: InputMaybe<Scalars['Boolean']>;
  before_lunch?: InputMaybe<Scalars['Boolean']>;
  overnight?: InputMaybe<Scalars['Boolean']>;
};

export type WeeklyBgScheduleTemplatesScheduleThurs = {
  __typename?: 'WeeklyBGScheduleTemplatesScheduleThurs';
  after_breakfast?: Maybe<Scalars['Boolean']>;
  after_dinner?: Maybe<Scalars['Boolean']>;
  after_lunch?: Maybe<Scalars['Boolean']>;
  bedtime?: Maybe<Scalars['Boolean']>;
  before_breakfast?: Maybe<Scalars['Boolean']>;
  before_dinner?: Maybe<Scalars['Boolean']>;
  before_lunch?: Maybe<Scalars['Boolean']>;
  overnight?: Maybe<Scalars['Boolean']>;
};

export type WeeklyBgScheduleTemplatesScheduleThursInput = {
  after_breakfast?: InputMaybe<Scalars['Boolean']>;
  after_dinner?: InputMaybe<Scalars['Boolean']>;
  after_lunch?: InputMaybe<Scalars['Boolean']>;
  bedtime?: InputMaybe<Scalars['Boolean']>;
  before_breakfast?: InputMaybe<Scalars['Boolean']>;
  before_dinner?: InputMaybe<Scalars['Boolean']>;
  before_lunch?: InputMaybe<Scalars['Boolean']>;
  overnight?: InputMaybe<Scalars['Boolean']>;
};

export type WeeklyBgScheduleTemplatesScheduleTues = {
  __typename?: 'WeeklyBGScheduleTemplatesScheduleTues';
  after_breakfast?: Maybe<Scalars['Boolean']>;
  after_dinner?: Maybe<Scalars['Boolean']>;
  after_lunch?: Maybe<Scalars['Boolean']>;
  bedtime?: Maybe<Scalars['Boolean']>;
  before_breakfast?: Maybe<Scalars['Boolean']>;
  before_dinner?: Maybe<Scalars['Boolean']>;
  before_lunch?: Maybe<Scalars['Boolean']>;
  overnight?: Maybe<Scalars['Boolean']>;
};

export type WeeklyBgScheduleTemplatesScheduleTuesInput = {
  after_breakfast?: InputMaybe<Scalars['Boolean']>;
  after_dinner?: InputMaybe<Scalars['Boolean']>;
  after_lunch?: InputMaybe<Scalars['Boolean']>;
  bedtime?: InputMaybe<Scalars['Boolean']>;
  before_breakfast?: InputMaybe<Scalars['Boolean']>;
  before_dinner?: InputMaybe<Scalars['Boolean']>;
  before_lunch?: InputMaybe<Scalars['Boolean']>;
  overnight?: InputMaybe<Scalars['Boolean']>;
};

export type WeeklyBgScheduleTemplatesScheduleWed = {
  __typename?: 'WeeklyBGScheduleTemplatesScheduleWed';
  after_breakfast?: Maybe<Scalars['Boolean']>;
  after_dinner?: Maybe<Scalars['Boolean']>;
  after_lunch?: Maybe<Scalars['Boolean']>;
  bedtime?: Maybe<Scalars['Boolean']>;
  before_breakfast?: Maybe<Scalars['Boolean']>;
  before_dinner?: Maybe<Scalars['Boolean']>;
  before_lunch?: Maybe<Scalars['Boolean']>;
  overnight?: Maybe<Scalars['Boolean']>;
};

export type WeeklyBgScheduleTemplatesScheduleWedInput = {
  after_breakfast?: InputMaybe<Scalars['Boolean']>;
  after_dinner?: InputMaybe<Scalars['Boolean']>;
  after_lunch?: InputMaybe<Scalars['Boolean']>;
  bedtime?: InputMaybe<Scalars['Boolean']>;
  before_breakfast?: InputMaybe<Scalars['Boolean']>;
  before_dinner?: InputMaybe<Scalars['Boolean']>;
  before_lunch?: InputMaybe<Scalars['Boolean']>;
  overnight?: InputMaybe<Scalars['Boolean']>;
};

export type WeeklyMealSchedule = {
  __typename?: 'WeeklyMealSchedule';
  fri?: Maybe<DailyMealSchedule>;
  mon?: Maybe<DailyMealSchedule>;
  sat?: Maybe<DailyMealSchedule>;
  sun?: Maybe<DailyMealSchedule>;
  thurs?: Maybe<DailyMealSchedule>;
  tues?: Maybe<DailyMealSchedule>;
  wed?: Maybe<DailyMealSchedule>;
};

export type YearOfDiagnose = {
  __typename?: 'YearOfDiagnose';
  condition?: Maybe<Scalars['String']>;
  diagnosedDate?: Maybe<Scalars['Float']>;
};

export enum Yes_No_Not_Sure_Enums {
  No = 'NO',
  NotSure = 'NOT_SURE',
  Skipped = 'SKIPPED',
  Yes = 'YES'
}

export type _IdOperatorsFilterFindManyAuditRecordInput = {
  gt?: InputMaybe<Scalars['MongoID']>;
  gte?: InputMaybe<Scalars['MongoID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  lt?: InputMaybe<Scalars['MongoID']>;
  lte?: InputMaybe<Scalars['MongoID']>;
  ne?: InputMaybe<Scalars['MongoID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyLabResultTemplatesInput = {
  gt?: InputMaybe<Scalars['MongoID']>;
  gte?: InputMaybe<Scalars['MongoID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  lt?: InputMaybe<Scalars['MongoID']>;
  lte?: InputMaybe<Scalars['MongoID']>;
  ne?: InputMaybe<Scalars['MongoID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyLabResultsInput = {
  gt?: InputMaybe<Scalars['MongoID']>;
  gte?: InputMaybe<Scalars['MongoID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  lt?: InputMaybe<Scalars['MongoID']>;
  lte?: InputMaybe<Scalars['MongoID']>;
  ne?: InputMaybe<Scalars['MongoID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyTimeTrackerInput = {
  gt?: InputMaybe<Scalars['MongoID']>;
  gte?: InputMaybe<Scalars['MongoID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  lt?: InputMaybe<Scalars['MongoID']>;
  lte?: InputMaybe<Scalars['MongoID']>;
  ne?: InputMaybe<Scalars['MongoID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyWeeklyBgScheduleTemplatesInput = {
  gt?: InputMaybe<Scalars['MongoID']>;
  gte?: InputMaybe<Scalars['MongoID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  lt?: InputMaybe<Scalars['MongoID']>;
  lte?: InputMaybe<Scalars['MongoID']>;
  ne?: InputMaybe<Scalars['MongoID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterLabResultsInput = {
  gt?: InputMaybe<Scalars['MongoID']>;
  gte?: InputMaybe<Scalars['MongoID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  lt?: InputMaybe<Scalars['MongoID']>;
  lte?: InputMaybe<Scalars['MongoID']>;
  ne?: InputMaybe<Scalars['MongoID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterTimeTrackerInput = {
  gt?: InputMaybe<Scalars['MongoID']>;
  gte?: InputMaybe<Scalars['MongoID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  lt?: InputMaybe<Scalars['MongoID']>;
  lte?: InputMaybe<Scalars['MongoID']>;
  ne?: InputMaybe<Scalars['MongoID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateManyLabResultsInput = {
  gt?: InputMaybe<Scalars['MongoID']>;
  gte?: InputMaybe<Scalars['MongoID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  lt?: InputMaybe<Scalars['MongoID']>;
  lte?: InputMaybe<Scalars['MongoID']>;
  ne?: InputMaybe<Scalars['MongoID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateManyTimeTrackerInput = {
  gt?: InputMaybe<Scalars['MongoID']>;
  gte?: InputMaybe<Scalars['MongoID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
  lt?: InputMaybe<Scalars['MongoID']>;
  lte?: InputMaybe<Scalars['MongoID']>;
  ne?: InputMaybe<Scalars['MongoID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']>>>;
};

export type AllEnrolledListOpts = {
  healthCondition?: InputMaybe<Array<InputMaybe<HealthCondition>>>;
  hideMyOrganization?: InputMaybe<Scalars['Boolean']>;
  memberId: Scalars['EID'];
  suspended?: InputMaybe<Scalars['Boolean']>;
};

export type AssigneeType = {
  __typename?: 'assigneeType';
  assignedToCA?: Maybe<MiniProvider>;
  assignedToHC?: Maybe<MiniProvider>;
  assignedToRD?: Maybe<MiniProvider>;
};

export type BillableHealthCondition = {
  __typename?: 'billableHealthCondition';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type CustomizedFunctionType = {
  __typename?: 'customizedFunctionType';
  isValid?: Maybe<Scalars['Boolean']>;
  name?: Maybe<CustomizedFunctionName>;
};

export enum DiabetesAnsEnums {
  Diabetes = 'DIABETES',
  Neither = 'NEITHER',
  NotSure = 'NOT_SURE',
  PreDiabetes = 'PRE_DIABETES',
  Skipped = 'SKIPPED'
}

export type DietHabits = {
  __typename?: 'dietHabits';
  dietProperties?: Maybe<Array<Maybe<Scalars['String']>>>;
  other?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type DrinkingHistory = {
  __typename?: 'drinkingHistory';
  amount?: Maybe<Scalars['String']>;
  drinkType?: Maybe<Scalars['String']>;
  freq?: Maybe<DrinkingHistoryFreq>;
  quitYear?: Maybe<Scalars['String']>;
  startSinceYear?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Boolean']>;
};

export type DrinkingHistoryFreq = {
  __typename?: 'drinkingHistoryFreq';
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export enum EatingRoutine {
  IrregularRoutine = 'IRREGULAR_ROUTINE',
  None = 'NONE',
  NoRoutine = 'NO_ROUTINE',
  RegularRoutine = 'REGULAR_ROUTINE',
  SomewhereIrregularRoutine = 'SOMEWHERE_IRREGULAR_ROUTINE'
}

export type ExerciseFreq = {
  __typename?: 'exerciseFreq';
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type ExerciseHabits = {
  __typename?: 'exerciseHabits';
  duration?: Maybe<Scalars['Float']>;
  freq?: Maybe<ExerciseFreq>;
  value?: Maybe<Scalars['Boolean']>;
};

export type HeartAttack = {
  __typename?: 'heartAttack';
  hasHeartAttack?: Maybe<Scalars['Boolean']>;
  year?: Maybe<Scalars['String']>;
};

/** hearbeat logs type */
export type HeartbeatLog = {
  __typename?: 'heartbeatLog';
  createdAt?: Maybe<Scalars['Date']>;
  heartbeatInterval?: Maybe<Scalars['Int']>;
  sendHeartbeatLog?: Maybe<Scalars['Boolean']>;
  snapshotInterval?: Maybe<Scalars['Int']>;
  snapshots: Array<Maybe<Snapshot>>;
  viewerId: Scalars['EID'];
};

export type InterventionType = {
  __typename?: 'interventionType';
  intervention?: Maybe<Scalars['String']>;
};

/** isRead Schema */
export type IsReadType = {
  __typename?: 'isReadType';
  readAt?: Maybe<Scalars['Date']>;
  readBy?: Maybe<Scalars['EID']>;
  value?: Maybe<Scalars['Boolean']>;
};

export type LastMeasuredByType = {
  __typename?: 'lastMeasuredByType';
  BG?: Maybe<Scalars['Date']>;
  BP?: Maybe<Scalars['Date']>;
  ECG?: Maybe<Scalars['Date']>;
  EXERCISE?: Maybe<Scalars['Date']>;
  HR?: Maybe<Scalars['Date']>;
  HS?: Maybe<Scalars['Date']>;
  PO?: Maybe<Scalars['Date']>;
  TM?: Maybe<Scalars['Date']>;
};

export type MiniClinic = {
  __typename?: 'miniClinic';
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['EID']>;
};

export type MiniMember = {
  __typename?: 'miniMember';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['EID']>;
};

export type MiniOrg = {
  __typename?: 'miniOrg';
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['EID']>;
};

export type MiniOrganization = {
  __typename?: 'miniOrganization';
  id?: Maybe<Scalars['EID']>;
  name?: Maybe<Scalars['String']>;
};

export type MiniProvider = {
  __typename?: 'miniProvider';
  name?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['EID']>;
};

export type MonthlyEnrollGoalCountHistoryItem = {
  __typename?: 'monthlyEnrollGoalCountHistoryItem';
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** ##monthlyReviewGoal */
export type MonthlyReviewGoal = {
  __typename?: 'monthlyReviewGoal';
  condition?: Maybe<Scalars['String']>;
  status?: Maybe<MonthlyReviewGoalStatusType>;
  value?: Maybe<Scalars['String']>;
  vital?: Maybe<Scalars['String']>;
};

export type MonthlyReviewGoalInput = {
  condition?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MonthlyReviewGoalStatusType>;
  value?: InputMaybe<Scalars['String']>;
  vital?: InputMaybe<Scalars['String']>;
};

export type ProgramCategories = {
  __typename?: 'programCategories';
  enrolled?: Maybe<Scalars['Boolean']>;
  enrolledDate?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
};

export type ResolvedNote = {
  note: Scalars['String'];
  resolvedBy: Scalars['EID'];
};

export type ShippingInfo = {
  __typename?: 'shippingInfo';
  shippingDate?: Maybe<Scalars['Date']>;
  shippingNumber?: Maybe<Scalars['String']>;
};

export enum SleepingRoutine {
  IrregularRoutine = 'IRREGULAR_ROUTINE',
  ModeratelyRegularRoutine = 'MODERATELY_REGULAR_ROUTINE',
  None = 'NONE',
  NoRoutine = 'NO_ROUTINE',
  RegularRoutine = 'REGULAR_ROUTINE'
}

export type SmartAlertConfig = {
  __typename?: 'smartAlertConfig';
  alertDisplay?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  isValid?: Maybe<Scalars['Boolean']>;
  snoozeInterval?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  taskType?: Maybe<SmartAlertTaskType>;
};

export type SmartAlertOpts = {
  alertLevel?: InputMaybe<Array<InputMaybe<SmartAlertLevel>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fromDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<SmartAlertStatus>;
  taskType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type SmokingHistory = {
  __typename?: 'smokingHistory';
  amount?: Maybe<Scalars['String']>;
  freq?: Maybe<DrinkingHistoryFreq>;
  quitYear?: Maybe<Scalars['String']>;
  startSinceYear?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Boolean']>;
};

export type SmokingHistoryFreq = {
  __typename?: 'smokingHistoryFreq';
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

/** snapshot type */
export type Snapshot = {
  __typename?: 'snapshot';
  componentPath?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  focused?: Maybe<Scalars['Boolean']>;
  patientId?: Maybe<Scalars['EID']>;
  snapshotInterval?: Maybe<Scalars['Int']>;
};

export type SubscriptionType = {
  __typename?: 'subscriptionType';
  expireDate?: Maybe<Scalars['Date']>;
  originalPurchaseDate?: Maybe<Scalars['Date']>;
  status?: Maybe<Subscribes_Status>;
};

export type TriggerSmartAlertsStatus = {
  __typename?: 'triggerSmartAlertsStatus';
  alerts?: Maybe<Array<Maybe<Scalars['String']>>>;
  total?: Maybe<Scalars['Int']>;
};

export type LoginWithUsernameOrEmailMutationVariables = Exact<{
  usernameOrEmail: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginWithUsernameOrEmailMutation = { __typename?: 'Mutation', loginWithUsernameOrEmail?: { __typename?: 'UserAuthentication', id?: any | null, isDefaultPassword?: boolean | null, sessionToken?: any | null } | null };


export const LoginWithUsernameOrEmailDocument = `
    mutation loginWithUsernameOrEmail($usernameOrEmail: String!, $password: String!) {
  loginWithUsernameOrEmail(usernameOrEmail: $usernameOrEmail, password: $password) {
    id
    isDefaultPassword
    sessionToken
  }
}
    `;
export const useLoginWithUsernameOrEmailMutation = <
      TError = unknown,
      TContext = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      options?: UseMutationOptions<LoginWithUsernameOrEmailMutation, TError, LoginWithUsernameOrEmailMutationVariables, TContext>
    ) =>
    useMutation<LoginWithUsernameOrEmailMutation, TError, LoginWithUsernameOrEmailMutationVariables, TContext>(
      ['loginWithUsernameOrEmail'],
      (variables?: LoginWithUsernameOrEmailMutationVariables) => fetcher<LoginWithUsernameOrEmailMutation, LoginWithUsernameOrEmailMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, LoginWithUsernameOrEmailDocument, variables)(),
      options
    );