import { Dispatch, SetStateAction, useState } from 'react';

interface ReturnType {
  value: number;
  increment: () => void;
  decrement: () => void;
  reset: () => void;
  setCount: Dispatch<SetStateAction<number>>;
}

function useCounter(initialValue?: number, min?: number, max?: number): ReturnType {
  const [value, setCount] = useState(initialValue || 0);

  const increment = () => setCount((x) => ((max !== undefined && x + 1 > max) ? max : (x + 1)));
  const decrement = () => setCount((x) => ((min !== undefined && x - 1 < min) ? min : (x - 1)));
  const reset = () => setCount(initialValue || 0);

  return {
    value,
    increment,
    decrement,
    reset,
    setCount,
  };
}

export default useCounter;
