import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import './login.scss';

export interface LoginArgs {
  username: string;
  password: string;
}
export interface LoginComponentProps {
  onLogin: (value: LoginArgs) => void;
  isLoading: boolean;
  errorMessage?: string;
}

const LoginComponent: React.FC<LoginComponentProps> = ({ onLogin, isLoading, errorMessage }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  return (
    <div className="loginComponent">
      <div className="login-sharecare-logo-container">
        <img className="login-sharecare-logo" src="/logos/Logomark_RGB.svg" alt="/" />
      </div>
      <div className="login-title-container">
        <span className="login-sharecare-title">
          Unified Care
        </span>
      </div>
      <Form name="normal_login" className="login-form" layout="vertical">
        <Form.Item name="username" label="Username" rules={[{ required: true, message: 'Username is required!' }]}>
          <Input value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" data-testid="username" />
        </Form.Item>
        <Form.Item name="password" label="Password" rules={[{ required: true, message: 'Password is required!' }]}>
          <Input.Password
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            data-testid="password"
          />
        </Form.Item>
        <div className="login-forgot-password-wrap">
          <button className="link-like" type="button" data-testid="forgot-password">Forgot password?</button>
        </div>
        {errorMessage && (
          <div>
            {errorMessage}
          </div>
        )}
        <div>
          <Button
            type="primary"
            className="login-btn uc-btn"
            onClick={() => onLogin({ username, password })}
            loading={isLoading}
            data-testid="login-button"
          >
            Log In
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default LoginComponent;
