import React from 'react';
import { LoginWithUsernameOrEmailMutation } from '../../../../generates';
import useLogin from '../../../../hooks/mutation/auth/useLogin';
import LoginComponent, { LoginArgs } from '../../component/LoginComponent/LoginComponent';

export interface LoginContainerProps {
  onSuccess?: (value: LoginWithUsernameOrEmailMutation['loginWithUsernameOrEmail']) => void;
}

const LoginContainer: React.FC<LoginContainerProps> = ({ onSuccess }) => {
  const { mutateAsync, error, isLoading } = useLogin();

  const login = async ({ username, password }: LoginArgs) => {
    const v = await mutateAsync({ usernameOrEmail: username, password });
    onSuccess?.(v.loginWithUsernameOrEmail);
  };

  return (
    <LoginComponent onLogin={login} errorMessage={error?.message} isLoading={isLoading} />
  );
};

export default LoginContainer;
