import React from 'react';

export interface CounterProps {
  value: string | number;
}

const Counter: React.FC<CounterProps> = ({ value }) => (
  <div>
    Counter:
    &nbsp;
    {value}
  </div>
);

export default Counter;
