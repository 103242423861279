import {
  QueryClient, QueryCache,
} from 'react-query';
import EnvConfig from '../../configs/envConfig/envConfig';
import { missingToken } from '../../errors/errors';
import { StorageKeyEnum, useSessionStorage } from '../../hooks';

export const graphQLClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error: any) => {
      // 🎉 only show error toasts if we already have data in the cache
      // which indicates a failed background update
      console.log(error);
    },
  }),
});

export const gqlUrl = EnvConfig.gqlServerUrl;
export const gqlHeaders = {
  'Access-Control-Allow-Origin': '*',
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'x-api-key': EnvConfig.gqlServerApiKey,
};

export const useGetGqlConfig = () => {
  const [token] = useSessionStorage(StorageKeyEnum.TOKEN, null);
  if (token === null) missingToken();
  return {
    endpoint: gqlUrl,
    fetchParams: {
      headers: {
        'x-session-token': token as string,
        ...gqlHeaders,
      },
    },
  };
};
